<template>
  <NbButtonLoading
    :variant="variant"
    :loading="loading"
    @click="generateOrderLabel"
  >
    <slot>
      <NbIcon icon="download" :size="18" class="mr-2" />
      {{ $t("ordersViewPage.lastMileLabel") }}
    </slot>
  </NbButtonLoading>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButtonLoading from "@/components/buttons/NbButtonLoading.vue";
import OrderLabelService from "@/services/OrderLabelService";

const orderLabelService = new OrderLabelService();

export default {
  components: {
    NbIcon,
    NbButtonLoading,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async generateOrderLabel() {
      try {
        this.loading = true;
        const { data } = await orderLabelService.generateOrderLabel(
          this.orderId,
        );

        window.open(data?.data?.download_url);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
