import api from "./HttpService";

export default class CarrierService {
  /**
   * Get carriers
   *
   * @returns {Promise}
   */
  getCarriers() {
    return api.get("/v1/carriers?limit=1000&offset=0");
  }

  /**
   * Get carrier
   *
   * @param id
   * @returns {Promise}
   */
  getCarrier(id) {
    return api.get("/v1/carriers/" + id);
  }

  /**
   * Get 20 Carriers
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyCarriers(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/carriers`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/carriers?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  /**
   * Delete carrier
   *
   * @param id
   * @returns {Promise}
   */
  deleteCarrier(id) {
    return api.delete("/v1/carriers/" + id);
  }

  /**
   * Set carrier
   *
   * @param data
   * @returns {Promise}
   */
  setCarrier(data = {}) {
    return api.post("/v1/carriers", data);
  }

  /**
   * Update carrier
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateCarrier(id, data = {}) {
    return api.put("/v1/carriers/" + id, data);
  }

  /**
   * Update carrier
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateAirportHub(id, data = {}) {
    return api.put("/v1/carriers/" + id + "/hub", data);
  }

  /**
   * Get carrier by id
   *
   * @param id
   * @returns {Promise}
   */
  getOneCarrier(id) {
    return api.get("/v1/carriers/" + id);
  }

  getCurrentCarrier() {
    return api.get("/v1/current-carrier");
  }

  /**
   * Import carrier
   *
   * @param data
   * @returns {Promise}
   */
  importCarrier(data) {
    return api.post("/v1/carriers/import", data);
  }

  /**
   * Export carrier
   *
   * @param id
   * @returns {Promise}
   */
  exportCarrier(id) {
    return api.get("/v1/carriers/" + id + "/export");
  }

  getCarriersList() {
    return api.get("/v1/carriers/index_full");
  }
}
