export default {
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onAction(action) {
      this.$emit(action);
    },
  },
};
