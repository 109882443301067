export default {
  computed: {
    tableColumns() {
      return [
        { key: "id", label: "ID", sortable: true },
        {
          key: "seller_id",
          label: this.$t("seller"),
          sortable: true,
        },
        {
          key: "seller_phone",
          label: this.$t("ordersPage.fields.sellerPhone"),
        },
        {
          key: "seller_email",
          label: this.$t("ordersPage.fields.sellerEmail"),
        },
        {
          key: "shipper_tax_number",
          label: this.$t("ordersPage.fields.sellerTaxNumber"),
        },
        {
          key: "seller_website",
          label: this.$t("ordersPage.fields.sellerWebsite"),
        },
        {
          key: "seller_address",
          label: this.$t("ordersPage.fields.sellerAddress"),
        },
        {
          key: "seller_address_number",
          label: this.$t("ordersPage.fields.sellerAddressNumber"),
        },
        {
          key: "shipper_address_complement",
          label: this.$t("ordersPage.fields.sellerAddressComplement"),
        },
        {
          key: "seller_zip_code",
          label: this.$t("ordersPage.fields.sellerPostalCode"),
        },
        {
          key: "seller_city",
          label: this.$t("ordersPage.fields.sellerCity"),
        },
        {
          key: "seller_state",
          label: this.$t("ordersPage.fields.sellerState"),
        },
        {
          key: "seller_country",
          label: this.$t("ordersPage.fields.sellerCountry"),
        },
        {
          key: "contract_id",
          label: this.$t("contract"),
          sortable: true,
        },
        { key: "prc", label: "PRC", sortable: true },
        {
          key: "overpack_id",
          label: this.$t("ordersPage.fields.overpackID"),
          sortable: true,
        },
        {
          key: "customer_full_name",
          label: this.$t("ordersPage.fields.customerName"),
        },
        {
          key: "customer_phone",
          label: this.$t("ordersPage.fields.customerPhone"),
        },
        {
          key: "customer_email",
          label: this.$t("ordersPage.fields.customerEmail"),
        },
        {
          key: "customer_document_type",
          label: this.$t("ordersPage.fields.customerDocumentType"),
        },
        {
          key: "customer_document_number",
          label: this.$t("ordersPage.fields.customerDocumentNumber"),
        },
        {
          key: "customer_postal_code",
          label: this.$t("ordersPage.fields.postalCode"),
        },
        {
          key: "customer_address",
          label: this.$t("ordersPage.fields.customerAddress"),
        },
        {
          key: "customer_address_number",
          label: this.$t("ordersPage.fields.customerAddressNumber"),
        },
        {
          key: "customer_address_complement",
          label: this.$t("ordersPage.fields.customerAddressComplement"),
        },
        {
          key: "customer_address_reference",
          label: this.$t("ordersPage.fields.customerAddressReference"),
        },
        {
          key: "customer_address_quarter",
          label: this.$t("ordersPage.fields.customerAddressQuarter"),
        },
        {
          key: "customer_city",
          label: this.$t("ordersPage.fields.customerCity"),
        },
        {
          key: "customer_state",
          label: this.$t("ordersPage.fields.customerState"),
        },
        {
          key: "customer_country",
          label: this.$t("ordersPage.fields.customerCountry"),
        },
        {
          key: "incoterm",
          label: this.$t("ordersPage.fields.incoterm"),
        },
        {
          key: "order_number",
          label: this.$t("ordersPage.fields.orderNumber"),
          tooltip: this.$t("ordersViewPage.orderNumberDescription"),
          sortable: true,
        },
        {
          key: "sales_channel",
          label: this.$t("ordersViewPage.salesChannel"),
        },
        {
          key: "sales_channel_order_number",
          label: this.$t("ordersPage.fields.salesChOrderN"),
          tooltip: this.$t("ordersViewPage.salesChannelOrderNumberDescription"),
          sortable: true,
        },
        {
          key: "is_commercial_destination",
          label: this.$t("volumesPage.fields.isCommercialDestination"),
          sortable: true,
        },
        {
          key: "seller_rfb_code",
          label: this.$t("volumesPage.fields.sellerRfbCode"),
          sortable: true,
        },
        {
          key: "seller_legal_name",
          label: this.$t("volumesPage.fields.sellerLegalName"),
          sortable: true,
        },
        {
          key: "currency",
          label: this.$t("ordersViewPage.currency"),
          sortable: true,
        },
        {
          key: "dollar_reais",
          label: this.$t("ordersPage.fields.dollarReais"),
        },
        {
          key: "products_value",
          label: this.$t("ordersPage.fields.productsValue"),
          sortable: true,
        },
        {
          key: "freight_value",
          label: this.$t("ordersPage.fields.freightValue"),
          sortable: true,
          tooltip: this.$t("ordersViewPage.informedBySeller"),
        },
        {
          key: "declared_value",
          label: this.$t("ordersPage.fields.declaredValue"),
          sortable: true,
        },
        {
          key: "duties_tax_value",
          label: this.$t("ordersPage.fields.dutiesTaxValue"),
          sortable: true,
          tooltip: this.$t("ordersViewPage.dutiesTaxDescription"),
        },
        {
          key: "sales_tax_value",
          label: this.$t("ordersPage.fields.salesTaxValue"),
          sortable: true,
          tooltip: this.$t("ordersViewPage.salesTaxDescription"),
        },
        {
          key: "tax_value",
          label: this.$t("ordersPage.fields.taxValue"),
          sortable: true,
        },
        {
          key: "total_value",
          label: this.$t("ordersPage.fields.totalValue"),
          sortable: true,
        },
        {
          key: "estimated_duties_tax_value",
          label: this.$t("ordersPage.fields.estimatedDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "estimated_sales_tax_value",
          label: this.$t("ordersPage.fields.estimatedSalesTaxValue"),
          sortable: true,
        },
        {
          key: "estimated_tax_value",
          label: this.$t("ordersPage.fields.estimatedTaxValue"),
          sortable: true,
        },
        {
          key: "estimated_delivery_date",
          label: this.$t("ordersPage.fields.estimatedDeliveryDate"),
        },
        {
          key: "freight_cost",
          label: this.$t("ordersPage.fields.freightCost"),
          sortable: true,
          toltip: this.$t("ordersViewPage.freightCostDescription"),
        },
        {
          key: "estimated_freight_cost",
          label: this.$t("ordersPage.fields.estimatedFreightCost"),
          sortable: true,
          tooltip: this.$t("ordersViewPage.estimatedFreightCostDescription"),
        },
        {
          key: "tax_cost",
          label: this.$t("ordersPage.fields.taxCost"),
        },
        {
          key: "insurance_cost",
          label: this.$t("ordersPage.fields.insuranceCost"),
        },
        {
          key: "insurance_coverage",
          label: this.$t("ordersPage.fields.insuranceCoverage"),
        },
        {
          key: "ddp_cost",
          label: this.$t("ordersPage.fields.dDPCost"),
        },
        {
          key: "order_costs",
          label: this.$t("ordersPage.fields.totalGeneralCosts"),
        },
        {
          key: "reference",
          label: this.$t("ordersPage.fields.trackingNumber"),
          tooltip: this.$t("ordersViewPage.orderReferenceDescription"),
          sortable: true,
        },
        {
          key: "observations",
          label: this.$t("ordersViewPage.observations"),
          tooltip: this.$t("ordersViewPage.freeFieldCreatedBySeller"),
          sortable: true,
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("ordersPage.fields.lastMileCarrierNumber"),
        },
        {
          key: "is_mini",
          label: this.$t("ordersPage.fields.isMini"),
        },
        {
          key: "financial_status",
          label: this.$t("ordersPage.fields.financialStatus"),
          sortable: true,
        },
        {
          key: "current_status",
          label: this.$t("ordersPage.fields.currentStatus"),
        },
        {
          key: "created_at",
          label: this.$t("ordersPage.fields.creationDate"),
          sortable: true,
        },
        {
          key: "msds",
          label: this.$t("ordersPage.fields.mSDS"),
        },
        {
          key: "label",
          label: this.$t("ordersPage.fields.label"),
        },
        {
          key: "first_mile_label",
          label: this.$t("ordersPage.fields.firstMileLabel"),
        },
        {
          key: "shipConfirmation",
          label: this.$t("ordersPage.fields.shipConfirmation"),
        },
        {
          key: "is_landed_cost",
          label: this.$t("ordersPage.fields.isLanded"),
          tooltip: this.$t("ordersViewPage.landedCostDescription"),
          sortable: true,
        },
        {
          key: "remetente_is_cpf",
          label: this.$t("ordersViewPage.senderIsCpf"),
          sortable: true,
        },
        {
          key: "is_dispatched",
          label: this.$t("ordersViewPage.dispatched"),
          tooltip: this.$t("ordersViewPage.dispatchedDescription"),
          sortable: true,
        },
        {
          label: this.$t("actions"),
          key: "downloads",
        },
      ];
    },
  },
};
