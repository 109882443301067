import { mapState } from "vuex/dist/vuex.common.js";
import { INCOTERM } from "../../../constants/constants";

export default {
  data() {
    return {
      countries: [],
    };
  },
  computed: {
    ...mapState({
      currentLocale: (store) => store.accessibility.currentLocale,
    }),
    coutriesOption() {
      return this.countries.map((country) => ({
        value: country.alpha2_code,
        text: this.currentLocale === "pt_BR" ? country.name_pt : country.name,
      }));
    },
    financial_statuses() {
      return [
        {
          value: "new_order",
          text: this.$t("ordersPage.financial_statuses.new"),
        },
        {
          value: "to_invoice",
          text: this.$t("ordersPage.financial_statuses.toInvoice"),
        },
        {
          value: "invoiced",
          text: this.$t("ordersPage.financial_statuses.invoiced"),
        },
        {
          value: "canceled",
          text: this.$t("ordersPage.financial_statuses.canceled"),
        },
        {
          value: "not_charged",
          text: this.$t("ordersPage.financial_statuses.notCharged"),
        },
      ];
    },
    tableFilters() {
      const filters = [
        {
          label: this.$t("contract"),
          inputs: [
            {
              key: "contract_id",
              type: "select",
              options: this.contracts,
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.currentStatus"),
          inputs: [
            {
              key: "current_status_tracking_code",
              type: "select",
              options: this.checkpoints,
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.overpackID"),
          inputs: [
            {
              key: "overpack_id",
            },
          ],
        },
        {
          label: "PRC",
          inputs: [
            {
              key: "prc",
              type: "boolean",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerName"),
          inputs: [
            {
              key: "customer_full_name_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerDocumentType"),
          inputs: [
            {
              key: "customer_document_type",
              type: "select",
              options: ["CPF", "CNPJ"].map((text) => ({ text, value: text })),
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerDocumentNumber"),
          inputs: [
            {
              key: "customer_document_number",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerAddress"),
          inputs: [
            {
              key: "customer_address_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerAddressNumber"),
          inputs: [
            {
              key: "customer_address_number",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerAddressComplement"),
          inputs: [
            {
              key: "customer_address_complement",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerAddressReference"),
          inputs: [
            {
              key: "customer_address_reference",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerAddressQuarter"),
          inputs: [
            {
              key: "customer_address_quarter",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerCity"),
          inputs: [
            {
              key: "customer_city",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerState"),
          inputs: [
            {
              key: "customer_state",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerCountry"),
          inputs: [
            {
              key: "customer_country",
              type: "select",
              options: this.coutriesOption,
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerPhone"),
          inputs: [
            {
              key: "customer_phone",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.customerEmail"),
          inputs: [
            {
              key: "customer_email",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.postalCode"),
          inputs: [
            {
              key: "customer_postal_code",
            },
          ],
        },
        {
          label: this.$t("seller"),
          inputs: [
            {
              key: "seller_id",
              type: "select",
              options: this.sellers,
              initialVal: this.$route.query.sellerId || "",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerName"),
          inputs: [
            {
              key: "shipper_name",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerAddress"),
          inputs: [
            {
              key: "shipper_address_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerTaxNumber"),
          inputs: [
            {
              key: "shipper_tax_number",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerAddressNumber"),
          inputs: [
            {
              key: "shipper_address_number_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerAddressComplement"),
          inputs: [
            {
              key: "shipper_address_complement",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerCity"),
          inputs: [
            {
              key: "shipper_city_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerState"),
          inputs: [
            {
              key: "shipper_state_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerCountry"),
          inputs: [
            {
              key: "shipper_country_in",
              type: "select",
              options: this.coutriesOption,
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerPhone"),
          inputs: [
            {
              key: "shipper_phone_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerEmail"),
          inputs: [
            {
              key: "shipper_email_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerPostalCode"),
          inputs: [
            {
              key: "shipper_zip_code_in",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.sellerWebsite"),
          inputs: [
            {
              key: "shipper_website_in",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.sellerRfbCode"),
          inputs: [
            {
              key: "seller_rfb_code_in",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.sellerLegalName"),
          inputs: [
            {
              key: "seller_legal_name",
            },
          ],
        },
        {
          label: this.$t("ordersViewPage.currency"),
          inputs: [
            {
              key: "currency",
              type: "select",
              options: ["USD", "BRL"].map((text) => ({ text, value: text })),
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.incoterm"),
          inputs: [
            {
              key: "incoterm",
              type: "select",
              options: [
                { value: INCOTERM.DDP, text: INCOTERM.DDP },
                { value: INCOTERM.DDU, text: INCOTERM.DDU },
              ],
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.isLanded"),
          inputs: [
            {
              key: "is_landed_cost",
              type: "boolean",
            },
          ],
        },
        {
          label: this.$t("ordersViewPage.senderIsCpf"),
          inputs: [
            {
              key: "remetente_is_cpf",
              type: "boolean",
            },
          ],
        },
        {
          label: this.$t("ordersViewPage.dispatched"),
          inputs: [
            {
              key: "is_dispatched",
              type: "boolean",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.orderNumber"),
          inputs: [
            {
              key: "order_number",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.salesChOrderN"),
          inputs: [
            {
              key: "sales_channel_order_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.isCommercialDestination"),
          inputs: [
            {
              key: "is_commercial_destination",
              type: "boolean",
            },
          ],
        },

        {
          label: this.$t("ordersPage.fields.trackingNumber"),
          inputs: [{ key: "reference" }],
        },
        {
          label: this.$t("volumesPage.filterOptions.lastMileTrackingNumber"),
          inputs: [
            {
              key: "last_mile_tracking_number",
              placeholder: "Ex: NBXXXXXXBR,NBXXXXXXBR,...",
              helpText: this.$t("volumesPage.filterOptions.lmtnPopover"),
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.lastMileCarrierNumber"),
          inputs: [
            {
              key: "last_mile_carrier_number",
              placeholder: "Ex: NBXXXXXXBR,NBXXXXXXBR,...",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
          inputs: [
            {
              key: "first_mile_tracking_number",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.financialStatus"),
          inputs: [
            {
              key: "financial_status",
              type: "select",
              options: this.financial_statuses,
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "created_at_gte",
              type: "date",
            },
            {
              key: "created_at_lte",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.estimatedDeliveryDate"),
          inputs: [
            {
              key: "estimated_delivery_date_gte",
              type: "date",
            },
            {
              key: "estimated_delivery_date_lte",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.dollarReais"),
          inputs: [
            {
              key: "dollar_reais_gte",
              type: "money",
            },
            {
              key: "dollar_reais_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersViewPage.freightValue"),
          inputs: [
            {
              key: "freight_value_gte",
              type: "money",
            },
            {
              key: "freight_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersViewPage.freightCost"),
          inputs: [
            {
              key: "freight_cost_gte",
              type: "money",
            },
            {
              key: "freight_cost_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.estimatedFreightCost"),
          inputs: [
            {
              key: "estimated_freight_cost_gte",
              type: "money",
            },
            {
              key: "estimated_freight_cost_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.dutiesTaxValue"),
          inputs: [
            {
              key: "duties_tax_value_gte",
              type: "money",
            },
            {
              key: "duties_tax_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.salesTaxValue"),
          inputs: [
            {
              key: "sales_tax_value_gte",
              type: "money",
            },
            {
              key: "sales_tax_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.taxValue"),
          inputs: [
            {
              key: "tax_value_gte",
              type: "money",
            },
            {
              key: "tax_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.estimatedSalesTaxValue"),
          inputs: [
            {
              key: "estimated_sales_tax_value_gte",
              type: "money",
            },
            {
              key: "estimated_sales_tax_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.estimatedDutiesTaxValue"),
          inputs: [
            {
              key: "estimated_duties_tax_value_gte",
              type: "money",
            },
            {
              key: "estimated_duties_tax_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.estimatedTaxValue"),
          inputs: [
            {
              key: "estimated_tax_value_gte",
              type: "money",
            },
            {
              key: "estimated_tax_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.declaredValue"),
          inputs: [
            {
              key: "declared_value_gte",
              type: "money",
            },
            {
              key: "declared_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.productsValue"),
          inputs: [
            {
              key: "products_value_gte",
              type: "money",
            },
            {
              key: "products_value_lte",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("ordersPage.fields.totalValue"),
          inputs: [
            {
              key: "total_value_gte",
              type: "money",
            },
            {
              key: "total_value_lte",
              type: "money",
            },
          ],
        },
      ];
      return filters;
    },
  },
};
