<template>
  <div>
    <div class="header">
      <NbPageTitle
        class="pb-3"
        :title="$t('usersPage.title')"
        :subTitle="$t('usersPage.subtitle')"
      >
      </NbPageTitle>
      <NbButton
        v-if="!isSuper"
        pill
        variant="primary"
        @click="showCreateUserModal"
        class="text-left"
        style="width: 13.12rem"
      >
        <NbIcon icon="plus" />
        {{ $t("usersPage.addNewUser") }}
      </NbButton>
    </div>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />
      <keep-alive>
        <AllUsersTable
          v-if="currentTab === tabs.all"
          @count="usersCount.all = $event"
          @userClick="showUserData"
          :ref="tabs.all"
        />
        <UsersAdminTable
          v-else-if="currentTab === tabs.admin"
          :profile="Profile.ADMIN"
          @count="usersCount.admin = $event"
          @userClick="showUserData"
          :ref="tabs.multiples"
        />
        <UsersSellerTable
          v-else-if="currentTab === tabs.sellers"
          :profile="Profile.SELLER"
          @count="usersCount.sellers = $event"
          @userClick="showUserData"
          :ref="tabs.simples"
        />
        <UsersCarrierTable
          v-else-if="currentTab === tabs.carriers"
          :profile="Profile.CARRIER"
          @count="usersCount.carriers = $event"
          @userClick="showUserData"
          :ref="tabs.carriers"
        />
      </keep-alive>
    </section>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <Modal
      v-model="isUserUpsertModalOpen"
      size="lg"
      @close="clearCreateWithCarrierQuery()"
    >
      <UserUpsertModal @success="onCreate" :carrierId="selectedCarrierId" />
    </Modal>

    <Modal v-model="isUserModalOpen" size="lg" @close="setUrlSearchParam('id')">
      <UserModal @updated="updateItem" @refresh="loadItems" />
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import GroupService from "@/services/GroupService";
import Modal from "@/components/modal/Modal.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";
import UserUpsertModal from "./components/UserUpsertModal.vue";
import AllUsersTable from "./components/UsersTable.vue";
import UsersSellerTable from "./components/UsersTable.vue";
import UsersAdminTable from "./components/UsersTable.vue";
import UsersCarrierTable from "./components/UsersTable.vue";
import { Profile } from "../../../constants/constants";
import UserModal from "./components/UserModal.vue";
import SellerService from "@/services/SellerService";
import CarrierService from "@/services/CarrierService";

const groupsService = new GroupService();
const sellerService = new SellerService();
const carrierService = new CarrierService();

const Tab = {
  ALL: "all",
  ADMIN: "admin",
  SELLERS: "sellers",
  CARRIERS: "carriers",
};

export default {
  name: "Users",
  mixins: [queryParamsMixin],
  components: {
    NbFooter,
    NbPageTitle,
    NbTabs,
    NbButton,
    NbIcon,
    Modal,
    UserUpsertModal,
    AllUsersTable,
    UsersSellerTable,
    UsersAdminTable,
    UsersCarrierTable,
    UserModal,
  },
  filters: {
    dateTime(value) {
      return value && moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data: () => {
    return {
      usersCount: {
        all: undefined,
        admin: undefined,
        sellers: undefined,
        carriers: undefined,
      },
      currentTab: Tab.ALL,
      tabs: {
        all: Tab.ALL,
        admin: Tab.ADMIN,
        sellers: Tab.SELLERS,
        carriers: Tab.CARRIERS,
      },
      profiles: ["admin", "seller", "carrier"],
      groups: [],
      sellers: [],
      carriers: [],
      groupOptions: [],
      sellerOptions: [
        {
          text: "Loading...",
          value: "",
          disabled: true,
        },
      ],
      carrierOptions: [],
      profileGroups: [],
      loadingGroups: false,
      isUserUpsertModalOpen: false,
      isUserModalOpen: false,
      Profile,
      selectedCarrierId: null,
      sellersLoading: false,
    };
  },
  provide() {
    const userContext = {};

    Object.defineProperties(userContext, {
      groupOptions: {
        get: () => this.groupOptions,
        enumerable: true,
      },
      sellerOptions: {
        get: () => this.sellerOptions,
        enumerable: true,
      },
      carrierOptions: {
        get: () => this.carrierOptions,
        enumerable: true,
      },
      profileOptions: {
        get: () => this.profileOptions,
        enumerable: true,
      },
      profileGroups: {
        get: () => this.profileGroups,
        enumerable: true,
      },
      companyOptions: {
        get: () => this.companyOptions,
        enumerable: true,
      },
      sellersLoading: {
        get: () => this.sellersLoading,
        enumerable: true,
      },
    });

    return {
      userContext,
    };
  },
  computed: {
    companyOptions() {
      const sellersOptions = this.sellers.map((seller) => ({
        text: `${seller.name} (${Profile.SELLER})`,
        value: "seller_id#".concat(seller.id.toString()),
      }));

      const carriersOptions = this.carriers.map((carrier) => ({
        text: `${carrier.name} (${Profile.CARRIER})`,
        value: "carrier_id#".concat(carrier.id.toString()),
      }));

      const options = [...sellersOptions, ...carriersOptions];

      return options.sort((a, b) => {
        const textA = a.text.toLowerCase();
        const textB = b.text.toLowerCase();

        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
    },
    profileOptions() {
      return this.profiles.map((value) => ({ text: value, value }));
    },
    tabsOptions() {
      return [
        {
          id: Tab.ALL,
          label: this.$t("usersPage.allUsers"),
          current: this.currentTab,
          found: this.usersCount.all,
        },
        {
          id: Tab.ADMIN,
          label: "Admin",
          found: this.usersCount.admin,
          current: this.currentTab,
        },
        {
          id: Tab.SELLERS,
          label: this.$t("usersPage.seller"),
          found: this.usersCount.sellers,
          current: this.currentTab,
        },
        {
          id: Tab.CARRIERS,
          label: this.$t("usersPage.carrier"),
          found: this.usersCount.carriers,
          current: this.currentTab,
        },
      ];
    },

    isSuper() {
      return this.$store.state.user.is_super;
    },
  },
  mounted() {
    this.checkQuery();
  },
  beforeMount() {
    this.loadSellers();
    this.loadGroups();
    this.loadCarriers();
  },
  methods: {
    clearCreateWithCarrierQuery() {
      this.setUrlSearchParam("create");
      this.setUrlSearchParam("carrierId");
    },
    checkQuery() {
      const { id: userId, create, carrierId } = this.$route.query;
      if (create === "true" && !isNaN(Number(carrierId))) {
        this.selectedCarrierId = Number(carrierId);
        this.showCreateUserModal();
        return;
      }

      if (userId) {
        this.showUserData(userId);
      }
    },
    showUserData(userId) {
      this.setUrlSearchParam("id", userId);
      this.isUserModalOpen = true;
    },
    updateItem(data) {
      this.$refs?.[this.currentTab]?.updateItem(data);
    },
    loadItems() {
      this.$refs?.[this.currentTab]?.loadItems();
    },
    onCreate() {
      this.isUserUpsertModalOpen = false;
      this.$refs[this.currentTab]?.loadItems();
    },
    showCreateUserModal() {
      this.setUrlSearchParam("id");
      this.isUserUpsertModalOpen = true;
    },
    switchTab(tab) {
      this.currentTab = tab;
    },
    optionTook(event) {
      this[event]();
    },
    setProfileOptions() {
      this.profileGroups = {
        admin: this.groups.filter((group) => {
          return group.name.toLowerCase().includes("platform");
        }),
        seller: this.groups.filter((group) => {
          return group.name.toLowerCase().includes("seller");
        }),
        carrier: this.groups.filter((group) => {
          return group.name.toLowerCase().includes("freightforwarder");
        }),
      };
    },
    setGroupOptions() {
      const groupsOption = this.groups.map((item) => ({
        value: item.id,
        text: item.name,
      }));

      this.groupOptions = groupsOption;
    },
    async loadGroups() {
      try {
        this.loadingGroups = true;
        const {
          data: { data },
        } = await groupsService.getGroups();
        this.groups = data;
        this.setGroupOptions();
        this.setProfileOptions();
      } finally {
        this.loadingGroups = false;
      }
    },
    async loadSellers() {
      try {
        this.sellersLoading = true;
        const { data } = await sellerService.getSellersList();
        this.sellers = data?.data || [];

        let sellersOption = this.sellers.sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }

          return -1;
        });

        sellersOption = sellersOption.map((item) => ({
          value: item.id,
          text: `${item.name}`,
        }));

        this.sellerOptions = sellersOption;
      } catch {
        this.sellerOptions = [];
      } finally {
        this.sellersLoading = false;
      }
    },
    async loadCarriers() {
      const { data } = await carrierService.getCarriersList();
      this.carriers = data.data;

      let carriersOption = this.carriers.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      carriersOption = carriersOption.map((item) => ({
        value: item.id,
        text: `${item.name}`,
      }));

      this.carrierOptions = carriersOption;
    },
    moment: function (date) {
      let formated = moment(date);
      return formated;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  @media screen and (min-width: 720px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.personal-data {
  margin-bottom: 1rem;
  .form-group {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 720px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.permissions {
  .form-group {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 720px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .col-span-2 {
    grid-column: span 2 / span 2;
  }
}

.statuses {
  display: flex;
  gap: 2rem;
  align-items: baseline;
}
</style>
