<template>
  <div>
    <div>
      <ModalHeader>
        <div class="d-flex gap-2">
          <ModalTitle>
            {{ $t("volumesViewPage.title", { id: volume?.reference || "" }) }}
          </ModalTitle>
          <div class="d-flex gap-2">
            <NbBadge
              v-if="volume?.current_status"
              :text="volume?.current_status?.title"
            />
            <NbBadge
              v-if="volume.order?.is_dispatched"
              :text="$t('volumesViewPage.dispatched')"
              type="success"
            />
            <NbBadge
              v-if="volume?.order?.prc"
              text="PRC"
              type="success"
              class="d-flex mb-3"
            />
            <NbBadge
              v-if="volume?.order"
              :text="volume?.order?.incoterm"
              type="warning"
            />
            <NbBadge
              v-if="volume?.order?.data_source"
              :text="volume?.order?.data_source"
              type="success"
            />
          </div>
        </div>
      </ModalHeader>
      <Spinner v-if="loading" size="md" class="m-auto" />
      <div v-if="volume?.id">
        <div class="cards grid grid-cols-3 mb-4">
          <VolumeCardLabels
            :volume-id="volume.id"
            :order-id="volume.order.id"
            :reference="volume.reference"
          />
          <VolumeCardLinks
            :volume="volume"
            :volume-reverse-id="volumeReverse?.id"
            :flight-id="flight?.id"
          />
        </div>

        <Accordion class="group-data" mode="always-open">
          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.generalInformations") }}
                <small class="d-block">
                  {{ $t("volumesViewPage.generalInformationsDescription") }}
                </small>
              </div>
            </AccordionTrigger>

            <AccordionContent>
              <VolumeGeneralInformation :volume="volume" />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.numbersAndReferences") }}
                <small class="d-block">
                  {{ $t("volumesViewPage.numbersAndReferencesDescription") }}
                </small>
              </div>
            </AccordionTrigger>

            <AccordionContent>
              <VolumeNumbersAndReference :volume="volume" />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.dimensions") }}
                <small class="d-block">
                  {{ $t("volumesViewPage.dimensionsDescription") }}
                </small>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <VolumeDimensions :volume="volume" />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              {{ $t("volumesViewPage.customer") }}
              <small class="d-block">
                {{ $t("volumesViewPage.nameAddressContactInfo") }}
              </small>
            </AccordionTrigger>

            <AccordionContent>
              <VolumeCustomer :volume="volume" />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.shipper") }}
                <small class="d-block">
                  {{ $t("volumesViewPage.nameAddressContactInfo") }}
                </small>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <VolumeShipper :volume="volume" />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.items") }} ({{
                  volume.order_items.length
                }})
                <small class="d-block">
                  {{ $t("volumesViewPage.itemsDescription") }}
                </small>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <VolumeItemsTable
                :items="volume.order_items"
                :currency="volume.order.currency"
              />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.costs") }}
                <small class="d-block">
                  {{ $t("volumesViewPage.costsDescription") }}
                </small>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <VolumeCosts :volume="volume" />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.relatedObjects") }}
                <small class="d-block">
                  {{ $t("volumesViewPage.relatedObjectsDescription") }}
                </small>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <VolumeRelatedObjects
                :volume="volume"
                :flight="flight"
                :masterbox="masterbox"
                :volumeRevese="volumeReverse"
              />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.additionalData") }}
                <small class="d-block">
                  {{ $t("volumesViewPage.additionalDataDescription") }}
                </small>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <VolumeAdditionalData :volume="volume" />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem class="group-item">
            <AccordionTrigger class="trigger">
              <div>
                {{ $t("volumesViewPage.trackingHistory") }}
                <small class="d-block">
                  {{ $t("volumesViewPage.trackingHistoryDescription") }}
                </small>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <VolumeTrackingHistory :checkpoints="volume.volume_checkpoints" />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <ModalFooter>
        <ModalClose class="d-flex ml-auto" />
      </ModalFooter>
    </div>

    <NbModal id="ModalUpdateLmt" prevent-close @close="modalActive = true">
      <template #header>
        <NbPageTitle :title="$t('volumesViewPage.editLastTrackingNumber')" />
      </template>
      <template #body>
        <div>
          <NbTextInput
            v-model="lastMileTrackingNumber"
            :name="$t('volumesViewPage.warningOnUpdateLastMileTrackingNumber')"
            id="lastMileTrackingNuber"
          />
        </div>
      </template>

      <template #footer>
        <div class="flex justify-content-end">
          <NbButton
            variant="secondary"
            @click="$helpers.closeModal('ModalUpdateLmt')"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="updateLastMileTrackingNumber" class="ml-2">
            {{ $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>
<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import Spinner from "@/components/Spinner.vue";
import VolumeCardLabels from "./components/volume/VolumeCardLabels.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import VolumeCardLinks from "./components/volume/VolumeCardLinks.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import AccordionTrigger from "@/components/accordion/AccordionTrigger.vue";
import AccordionContent from "@/components/accordion/AccordionContent.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import { copyClipboard } from "@/directives/copy-clipboard.js";
import VolumeItemsTable from "./components/volume/VolumeItemsTable.vue";
import VolumeCosts from "./components/volume/VolumeCosts.vue";
import VolumeService from "@/services/VolumeService";
import VolumeReverseService from "@/services/VolumeReverseService";
import MasterBoxService from "@/services/MasterBoxService";
import FlightService from "@/services/FlightService";
import VolumeGeneralInformation from "./components/volume/VolumeGeneralInformation.vue";
import VolumeNumbersAndReference from "./components/volume/VolumeNumbersAndReference.vue";
import VolumeCustomer from "./components/volume/VolumeCustomer.vue";
import VolumeShipper from "./components/volume/VolumeShipper.vue";
import VolumeRelatedObjects from "./components/volume/VolumeRelatedObjects.vue";
import VolumeAdditionalData from "./components/volume/VolumeAdditionalData.vue";
import VolumeDimensions from "./components/volume/VolumeDimensions.vue";
import VolumeTrackingHistory from "./components/volume/VolumeTrackingHistory.vue";

const volumeService = new VolumeService();
const volumeReverseService = new VolumeReverseService();
const masterboxService = new MasterBoxService();
const flightService = new FlightService();

export default {
  components: {
    NbModal,
    NbBadge,
    NbButton,
    NbPageTitle,
    NbTextInput,
    Spinner,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
    VolumeCardLinks,
    VolumeNumbersAndReference,
    VolumeItemsTable,
    VolumeCardLabels,
    VolumeCosts,
    VolumeGeneralInformation,
    VolumeCustomer,
    VolumeShipper,
    VolumeRelatedObjects,
    VolumeAdditionalData,
    VolumeDimensions,
    VolumeTrackingHistory,
  },
  props: {
    volumeId: {
      type: [String, Number],
      required: true,
    },
  },
  directives: {
    copy: copyClipboard,
  },
  data() {
    return {
      loading: false,
      currentItemId: "",
      volume: {},
      modalActive: true,
      values: {
        declaredValue: "",
        taxValue: "",
        total: "",
        estimatedTaxValue: "",
        freightCost: "",
        estimatedFreightCost: "",
        collectedIcms: "",
        collectedIi: "",
      },
      itemsFields: [],
      checkpointFields: [],
      lastMileTrackingNumber: "",
      volumeReverse: null,
      masterbox: null,
      flight: null,
    };
  },
  computed: {
    itemsAllFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "name",
          label: this.$t("volumesViewPage.fields.name"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$t("volumesViewPage.fields.quantity"),
          sortable: false,
        },
        {
          key: "hs_code",
          label: this.$t("volumesViewPage.fields.hsCode"),
          sortable: false,
        },
        {
          key: "origin_country",
          label: this.$t("volumesViewPage.fields.originCountry"),
          sortable: false,
        },
        {
          key: "unit_products_value",
          label: this.$t("volumesViewPage.fields.productValue"),
          sortable: false,
        },
        {
          key: "unit_freight_value",
          label: this.$t("volumesViewPage.fields.freightValue"),
          sortable: false,
        },
        {
          key: "unit_tax_value",
          label: this.$t("volumesViewPage.fields.taxValue"),
          sortable: false,
        },
      ];
    },
    checkpointAllFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "checkpoint.tracking_code",
          label: "Tracking Code",
          sortable: false,
        },
        {
          key: "checkpoint.title",
          label: this.$t("volumesViewPage.checkpointFields.title"),
          sortable: false,
        },
        {
          key: "status_code",
          label: this.$t("volumesViewPage.checkpointFields.statusCode"),
          sortable: false,
        },
        {
          key: "date_iso",
          label: this.$t("volumesViewPage.checkpointFields.dateISO"),
          sortable: false,
        },
        {
          key: "message",
          label: this.$t("volumesViewPage.checkpointFields.message"),
          sortable: false,
        },
        {
          key: "received_checkpoint.tracking_function",
          label: this.$t("volumesViewPage.checkpointFields.trackingFunction"),
          sortable: false,
        },
      ];
    },
  },
  methods: {
    closeModal() {
      this.modalActive = true;
      this.$helpers.closeModal("volume-modal");
      this.$emit("close");
    },
    async loadVolume() {
      try {
        this.loading = true;
        const response = await volumeService.getVolume(this.$route.query?.id);
        this.volume = response.data.data;

        await Promise.all([
          this.checkIfVolumeHasVolumeReverse(),
          this.loadMasterbox(),
        ]);
      } catch (error) {
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    showModalUpdateLmt() {
      this.modalActive = false;
      this.lastMileTrackingNumber = "";
      this.$helpers.openModal("ModalUpdateLmt");
    },
    updateLastMileTrackingNumber() {
      volumeService
        .updateLastMileTrackingNumber(
          this.volume.id,
          this.lastMileTrackingNumber,
        )
        .then(() => {
          this.$helpers.closeModal("ModalUpdateLmt");
          this.loadVolume();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    async checkIfVolumeHasVolumeReverse() {
      const { data } = await volumeReverseService.getVolumeReserves({
        volume_id: this.volumeId,
      });

      if (data?.data?.elements?.length) {
        this.volumeReverse = data.data.elements[0];
      }
    },
    async loadMasterbox() {
      if (this.volume?.masterbox_id) {
        const { data: masterboxData } = await masterboxService.getMasterBox(
          this.volume.masterbox_id,
        );
        if (masterboxData?.data?.id) {
          this.masterbox = masterboxData.data;

          if (masterboxData.data?.shipment?.flight_id) {
            const { data: flightData } = await flightService.getFlight(
              masterboxData.data.shipment.flight_id,
            );
            this.flight = flightData.data;
          }
        }
      }
    },
  },
  mounted() {
    this.loadVolume();
  },
};
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 768px) {
    &.grid-cols-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.grid-cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
form {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}

.group-data {
  display: grid;
  gap: 1rem;
  .group-item {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: 1px solid var(--gray-05);
  }
  .info {
    display: flex;
    flex-direction: column;
    small {
      font-weight: 700;
      font-size: 0.75rem;
    }
    span {
      line-height: 1.2;
    }
  }
}
</style>
