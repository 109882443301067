export default {
  components: {
    nav: {
      Overview: "概览",
      SearchHsCode: "搜索HS代码",
      Archives: "档案",
      Requests: "请求",
      NobordistCheckpoints: "Nobordist检查点",
      ReceivedCheckpoints: "收到的检查点",
      StatusCheckpoints: "状态检查点",
      VolumeCheckpoints: "体积检查点",
      TrackingNotifications: "跟踪通知",
      Checkpoints: "检查点",

      Platforms: "平台",

      Contracts: "合同",
      Deliverymethods: "交付方式",
      PriceTablesDeliveryTime: "价格表和交货时间",
      ShipstationContracts: "Shipstation合同",

      SellerAccounts: "卖家账户",
      DefaultPlans: "默认计划",
      Finances: "财务",

      AlertLogs: "警报日志",
      NobordistLogs: "Nobordist日志",
      BilLogs: "Bil日志",
      ExternalLogs: "外部日志",
      TrackingRules: "跟踪规则",
      RejectedOrdersLogs: "拒绝的订单日志",
      GeneralEmails: "普通电子邮件",
      Notifications: "通知",
      UserNotifications: "用户通知",
      DelayedJobs: "延迟任务",
      Logs: "日志",

      VolumesReverse: "反向体积",
      Volumes: "体积",
      Orders: "订单",
      Overpacks: "超包装",
      Masterboxes: "主箱",
      Shipments: "货物",
      Flights: "航班",
      Shipping: "运输",
      ShippingRules: "运输规则",
      DutiesAndTaxesStatus: "关税和税收状况",

      Users: "用户",
      Carriers: "承运人",
      Sellers: "卖家",
      Groups: "群组",
      Permissions: "权限",
      Usersmanagement: "用户管理",
      Settings: "设置",
      Accessibility: "无障碍",
      PrintingSetting: "打印设置",
      Account: "账户",
      Downloads: "下载",
      SendNotifications: "发送通知",
      FiscalPayments: "财政付款",
      TrackingRuleNotifications: "追蹤通知規則",
    },
    searchData: {
      printing_setting: "Printing Setting",
      volumes: "体积",
      volumesReverse: "反向体积",
      orders: "订单",
      overpacks: "超包装",
      masterboxes: "主箱",
      shipments: "货物",
      flights: "航班",
      shippingRules: "运输规则",
      dutiesAndTaxesStatus: "关税和税收状况",
      users: "用户",
      carriers: "承运人",
      sellers: "卖家",
      sellerAccounts: "卖家账户",
      defaultPlans: "默认计划",
      contracts: "合同",
      deliveryMethods: "交付方式",
      priceTableAndDeliveryTime: "价格表和交货时间",
      shipstationContracts: "Shipstation合同",
      alertLogs: "警报日志",
      nobordistLogs: "Nobordist日志",
      billLogs: "账单日志",
      externalLogs: "外部日志",
      trackingRules: "跟踪规则",
      rejectedOrdersLogs: "拒绝的订单日志",
      generalEmails: "普通电子邮件",
      notifications: "通知",
      delayedJobs: "延迟任务",
      searchHsCode: "搜索HS代码",
      archives: "档案",
      requests: "请求",
      nobordistCheckpoints: "Nobordist检查点",
      receivedCheckpoints: "收到的检查点",
      statusCheckpoints: "状态检查点",
      volumeCheckpoints: "体积检查点",
      trackingNotifications: "跟踪通知",
      checkpoints: "检查点",
      userProfile: "我的资料",
      printingSetting: "打印设置",
      userNotifications: "用户通知",
      accesibility: "无障碍",
      downloads: "下载",
      sendNotifications: "发送通知",
      accessibility: "无障碍",
      fiscalPayments: "财政付款",
      trackingRuleNotifications: "追蹤通知規則",
    },
    tableFilters: {
      title: "筛选器",
      subTitle: "选择以下值以创建筛选器",
      addNewFilter: "添加新筛选器",
      clearFilters: "清除筛选器",
      filterBy: "筛选依据: ",
      filteredBy: "筛选依据:",
      openFilterOptions: "打开筛选选项",
      clickToClearFilter: "点击清除所有筛选器",
      clickToFilter: "点击筛选",
      trueText: "真",
      falseText: "假",
    },
    selectTableColumns: {
      title: "选择表列",
      button: "表列",
      unSelectAll: "取消选择所有列",
      selectAll: "选择所有列",
      shownColumns: "显示的列",
      hiddenColumns: "隐藏列",
    },
    maxRows: {
      button: "行",
    },
    nbTable: {
      wereFound: "找到",
      all: "全部",
      onThisPage: "在这一页",
      isSelected: "被选择 | 选择",
      worksOnlyCurrentPage:
        "此筛选器仅适用于显示在表格这一页上的 {onThisPageItems} 项目，而不是所有 {totalItems} 项目。要这样做，请使用筛选按钮",
      sortBtn: "对表格这一页上的项目进行排序。（不对找到的所有项目进行排序）",
      properties: "属性",
      tableOptions: "表格选项",
      changeVisibilityReorderColumns: "更改可见性和重新排序列",
    },
    itensPerPage: "每页项目数",
    pagination: {
      first: "首页",
      prev: "上一页",
      next: "下一页",
      last: "尾页",
    },
    topBar: {
      shiftSide: "切换到侧边导航栏",
      shiftTop: "切换到顶部导航栏",
      myProfile: "我的资料",
      myNotifications: "我的通知",
      printingSetting: "打印设置",
      platformList: "平台列表",
      savingChanges: "儲存變更",
      lastChangesSaved: "上次儲存的更改",
    },
    footer: {
      needHelp: "需要帮助",
      checkOurSupport: "查看我们的指南或给我们发消息",
    },
  },
  locales: {
    ptBR: "Portuguese (Brazil)",
    en: "English",
    cn: "Chinese",
    es: "Spanish",
  },
  //words
  value: "價值",
  none: "無",
  flight: "航班",
  masterbox: "主箱",
  order: "訂單",
  contract: "合同",
  sku: "SKU",
  quantity: "數量",
  legalName: "法定名稱",
  website: "網站",
  taxNumber: "稅號",
  rfbCode: "RFB 代碼",
  documentNumber: "文件號碼",
  addressNumber: "號碼",
  addressComplement: "補充",
  addressReference: "參考",
  addressQuarter: "區",
  city: "城市",
  postalCode: "郵政編碼",
  state: "州",
  country: "國家",
  weight: "重量",
  flow: "流動",
  creationDate: "創建日期",
  enable: "啟用",
  disable: "停用",
  lastUpdate: "最後更新",
  next: "下一步",
  back: "返回",
  enabled: "啟用",
  disabled: "禁用",
  address: "地址",
  body: "正文",
  any: "任何",
  fill: "充满",
  sent: "傳送",
  help: "幫助",
  metrics: "指标",
  filterByPeriod: "按时间段过滤",
  until: "至",
  showMoreData: "顯示更多數據",
  collapseData: "折疊數據",
  false: "假",
  erro: "错误",
  noImageAdded: "未添加图像",
  clickOrDrag: "点击或拖动您的图像到这里",
  seller: "卖家",
  user: "用户",
  wouldYouLikeToRemoveSelectedItems: "您是否要删除所选项目？",
  documentsAndLabels: "文件和标签",
  downloadReport: "下载报告",
  generalData: "常规数据",
  suggestedActions: "建议操作",
  filename: "文件名",
  seeDetails: "查看详情",
  volumes: "体积",
  clickToCopy: "点击这里复制",
  copy: "复制",
  clickToDownload: "点击下载",
  active: "活跃",
  inactive: "不活跃",
  blocked: "已阻止",
  browseFilesUpload: "浏览您的文件进行上传",
  selectcsvFileUpload: "选择一个csv文件进行上传",
  downloadHere: "在这里下载",
  instructionsXLSfile: "说明XLS文件",
  fileUploaded: "文件已上传",
  blankTable: "空表",
  sureWantDelete: "您确定要永久删除此项吗？",
  sitePlaceholder: "https://site.com",
  dragDropFile: "拖放或點擊此處",
  clickHereUploadFile: "点击此处上传文件",
  uploadFromComputer: "从您的计算机上传",
  uploadFromLinks: "从链接上传",
  selectPdfFileUpload: "选择要上传的PDF文件",
  link: "链接",
  update: "更新",
  notFound: "未找到",
  generalInformation: "常规信息",
  item: "项目",
  items: "项目",
  clear: "清除",
  confirm: "确认",
  connect: "连接",
  disConnect: "断开连接",
  connected: "已连接",
  disConnected: "已断开连接",
  contactUs: "联系我们",
  placeholderEmail: "example@email.com",
  asynchronousRequests: "异步请求",
  loading: "加载中",
  login: "登录",
  logout: "登出",
  name: "名字",
  fullName: "全名",
  company: "公司",
  phoneNumber: "电话号码",
  whatsNumber: "Whatsapp号码",
  phone: "电话",
  cellphone: "手机",
  optional: "可选",
  email: "电子邮件",
  platform: "平台",
  select: "选择",
  selected: "已选择",
  notselected: "未选择",
  selectAll: "全选",
  clearSelected: "清除所选",
  selectColumns: "选择列",
  clearFilter: "清除过滤器",
  filters: "过滤器",
  filter: "过滤",
  all: "全部",
  anyCounties: "任何国家",
  close: "关闭",
  save: "保存",
  cancel: "取消",
  actions: "操作",
  add: "添加",
  delete: "删除",
  selectAnOption: "选择一个选项",
  start: "开始",
  end: "结束",
  yes: "是",
  no: "否",
  found: "找到",
  support: "支持",
  edit: "编辑",
  password: "密码",
  hidePass: "隐藏密码",
  showPass: "显示密码",
  changePass: "更改密码",
  remove: "移除",
  search: "搜索",
  upload: "上传",
  download: "下载",
  beginningDate: "开始日期",
  startDate: "開始日期",
  endDate: "结束日期",
  between: "之间",
  betweenDates: "日期之間",
  registrationDate: "注册日期",
  ok: "Ok",
  attention: "Attention",
  and: "和",
  selectOption: "选择一个选项",
  paymentDate: "支付日期",
  new: "新建",
  allowed: "允许",
  invalid: "无效",
  errorMessages: {
    isNotPDF: "文件不是 .pdf 格式",
    tryAgain: "请检查您的信息并重试",
    required: "不能为空",
    invalidCPF: "无效的 CPF",
    invalidCNPJ: "无效的 CNPJ",
    invalidDocument: "无效的文件",
    invalidZIPCode: "无效的邮政编码",
    invalidPhone: "无效的电话",
    invalidEmail: "无效的电子邮件",
    lowerMoney: "必须大于 0.01",
    genericError: "发生了意外情况。",
    whiteSpace: "不应包含空格",
    minLength: "至少 %{value} 个字符",
    minLowercase: "至少 %{value} 个小写字母",
    minUppercase: "至少 %{value} 个大写字母",
    minNumber: "至少 %{value} 个数字",
    minSpecialCharacter: "至少 %{value} 个特殊字符",
    betweenDigits: "%{min} 或 %{max} 位数字",
  },
  downloadCsvHelp: `首先，点击“在此下载空白表格”按钮。您将下载一个带有空数据的CSV文件。然后，填写它并点击“上传”以开始导入过程。`,
  //pages
  homePage: {
    greeting: "您好, {name}",
    subtitle: "今天您想做什麼？",
    averageShippingCost: "平均運輸成本",
    completedOrders: "已完成訂單",
    closedFlights: "已結束的航班",
    bestSellers: "暢銷商品",
    seller: "賣家",
    ordersCreated: "創建的訂單",
    noSellerRanking: "沒有足夠的信息來創建賣家排名",
    whatPeriodAnalyze: "您想分析哪個時段？",
    deliveredOrders: "已送達訂單",
    createdOrders: "創建的訂單",
    links: {
      orders: {
        title: "查看訂單",
        description: "檢查訂單並下載報告。",
        action: "前往訂單",
      },
      sellers: {
        title: "賣家管理",
        description: "檢查用戶和賣家。",
        action: "前往賣家",
      },
      masterboxes: {
        title: "查看主箱",
        description: "查看主箱。",
        action: "前往主箱",
      },
      flights: {
        title: "管理航班",
        description: "檢查並告知每個訂單所在的航班。",
        action: "前往航班",
      },
      contracts: {
        title: "管理合同",
        description: "訪問管理合同。",
        action: "前往合同",
      },
      checkpoints: {
        title: "查看檢查點",
        description: "查看您訂單的所有跟踪事件。",
        action: "前往檢查點",
      },
    },
  },

  loginPage: {
    forgotPassword: "忘记密码",
    resetPassword: "重置密码电子邮件已发送",
    checkInbox: "检查您的收件箱",
    sendReset: "发送重置密码说明",
    login: "登录",
    welcome: "欢迎",
    pleaseYourAcc: "请输入您的帐户",
    wantoToApp: "想要访问管理系统",
    goToApp: "想要访问管理系统",
    checkOur: "查看我们的",
    generalConditionsService: "服务条款",
    resetPasswordSent: "重置密码电子邮件已发送！请检查您的收件箱。",
    sendResetPasswordInstructions: "发送重置密码说明",
    iAgree: "我同意",
    agreementText:
      "通过勾选此框，您确认已阅读并同意我们的服务条款，并同意受此协议约束。",
    createYourAccount: "创建您的帐户",
    fillInfoToCreateAcc: "填写以下字段以创建您的帐户",
    userData: "用户数据",
    pleaseYourData: "请填写您的数据",
    companyData: "公司数据",
    pleaseCompanyData: "请填写公司数据",
    hasRegistration: "已注册",
    accessMyAcc: "访问我的账户",
    needHelp: "需要帮助",
    talkWithOur: "与我们的顾问交谈",
    ifCheckOut: "如果您愿意，请查看我们的",
  },
  pintingSettingsPage: {
    title: "打印设置",
    subtitle: "在此处定义标签打印选项的标准配置",
    cN23Label: "CN23标签",
    commercialInvoice: "商业发票",
    packingSlip: "装运单",
    cevaBatteryShipperConfirmation: "Ceva电池发货人确认",
  },
  userProfileEditPage: {
    title: "更新我的个人资料",
  },
  userProfilePage: {
    title: "我的账户",
    subtitle: "检查并在必要时更新您的个人信息。",
    userData: "用户数据",
    loginInformation: "登录信息",
    loginInformationSubtitle: "如果需要，请更新您用于访问平台的数据。",
    checkYourData: "检查并编辑您的数据（如有必要）",
    editPass: "编辑密码",
    newPass: "新密码",
    currentPass: "当前密码",
    newPassConfirm: "输入新密码以更改",
    editEmail: "编辑电子邮件",
    editProfile: "编辑个人资料",
    newEmail: "新电子邮件",
    checkEmail: "请检查您的电子邮件格式",
    sameEmail: "两个输入的电子邮件不同，请检查",
    confirmCurrentPassword: "执行此操作前，请输入您的当前密码",
  },
  overViewPage: {
    title: "概览",
    getInfoCorreios: "从 Correios 获取信息",
    putTrackingNumber: "输入跟踪号码",
    putDispatchNumberUnitCode: "输入分派号码或单元代码",
    manuallyUpdateCN38Number: "手动更新 CN38 号码",
    shipmentID: "装运 ID",
    newCN38Number: "新 CN38 号码",
    updateCN38: "更新 CN38",
    resetDispatchNumbersCounter: "重置分派号码计数器",
    newCounterStart: "新计数器开始",
    reset: "重置",
    removeOverpack: "移除超包",
    testDelayedJob: "测试延迟作业",
    test: "测试",
    generateRequestID: "生成请求 ID",
    updateCn38Number: "更新 cn38 号码",
    sureOverwrite: "您将要覆盖货物的 CN38 号码",
    definitiveAction: "此操作是确定性的。您确定要继续吗",
    sureResetCorreiosCounter: "您将要重置分派号码的 Correios 计数器",
    sureRemoveOverpack: "您确定要移除此超包吗",
  },
  downloadsPage: {
    title: "档案",
    subtitle: "所有下载都集中在此页面",
    errors: "错误",
    found: "没有找到下载 | 下载 | 下载",
    downloadReport: "下载报告",
    showErrors: "显示错误",
    status: {
      failed: "失败",
      processing: "处理中",
      done: "完成",
      starting_upload: "开始上传",
      checking_csv_format: "检查 CSV 格式",
      checking_columns: "检查列",
      checking_equal_orders_volumes: "检查相等的订单量",
      checking_cells: "检查单元格",
      validation_success: "验证已成功结束！正在创建表",
    },
    fields: {
      id: "ID",
      type: "类型",
      status: "状态",
      fileName: "文件名",
      creationDate: "创建日期",
      messages: "消息",
    },
  },
  checkpointsPage: {
    title: "检查点",
    subTitle: "查看客户订单的重要检查点",
    subTitleView: "查看此检查点的数据",
    moreAboutCheckpoint: "了解更多关于检查点的信息",
    helpTextTitle:
      "这个页面是您在跨境流程中的可靠指南。在这里，您将找到一系列关键的检查点，这些检查点将帮助您轻松完成旅程，确保每个步骤都能成功完成",
    nobordistCheckpoints: "Nobordist 检查点",
    receivedCheckpoints: "已接收检查点",
    statusCheckpoints: "状态检查点",
    volumeCheckpoints: "体积检查点",
    trackingNotifications: "跟踪通知",
  },
  nobordistCheckpointsPage: {
    title: "Nobordist 检查点",
    description: "這是將構成訂單追蹤歷史的完整 Nobordist 追蹤狀態列表",
    downloadCheckpointsCSV: "下载 CSV 中的检查点",
    downloadCheckpointsExcel: "下载 Excel 中的检查点",
    checkDownloadsAt: "在下载页面检查处理中的下载...",
    fields: {
      type: "类型",
      slug: "标识",
      name: "名称",
      title: "标题",
      trackingCode: "跟踪代码",
      namePT: "名称 PT",
      titlePT: "标题 PT",
      finalStatus: "最終狀態",
      category: "类别",
      description: "描述",
    },
  },
  nobordistCheckpointsViewPage: {
    title: "Nobordist 检查点 #",
    type: "类型",
    slug: "标识",
    titleField: "标题",
    titlePt: "标题（葡萄牙语）",
    description: "描述",
    descriptionPt: "描述（葡萄牙语）",
    name: "名称",
    trackingCode: "跟踪代码",
  },
  receivedCheckpointsPage: {
    title: "接收检查点",
    description:
      "我們從運輸合作夥伴那裡捕獲的所有追蹤狀態列表。有些可能會因為冗餘或不相關而在訂單追蹤歷史中被忽略。如果您想查看添加到追蹤歷史中的內容，請點擊「體積檢查點」部分",
    fields: {
      trackingNumber: "跟踪号码",
      trackingFunction: "跟踪功能",
      statusCode: "状态码",
      message: "消息",
      dateIso: "日期 ISO",
      creationDate: "创建日期",
    },
  },
  receivedCheckpointsViewPage: {
    title: "接收检查点 #",
    trackingNumber: "跟踪号码",
    trackingFunction: "跟踪功能",
    statusCode: "状态码",
    dateIso: "日期 ISO",
    date: "日期",
    sentNotification: "发送通知",
    message: "消息",
    fields: {
      receiverType: "接收者类型",
      notificationID: "通知 ID",
      volumeID: "体积 ID",
      creationDate: "创建日期",
    },
  },
  sendNotificationsViewPage: {
    title: "发送服务 #",
    receivedNotification: "接收通知",
    receiverType: "接收者类型",
    email: "电子邮件",
    webhook: "Webhook",
    phone: "电话",
    notificationID: "通知 ID",
    volumeID: "体积 ID",
    sellerName: "卖家名称",
    trackingCode: "跟踪代码",
    titleField: "标题",
    createdDate: "创建日期",
    time: "时间",
    receivedCheckpointID: "接收检查点 ID",
    trackingNumber: "跟踪号码",
    trackingFunction: "跟踪功能",
    statusCode: "状态码",
    dateIso: "日期 ISO",
  },
  statusCheckpointsPage: {
    title: "状态检查点",
    description:
      "將我們運輸合作夥伴的追蹤狀態翻譯為我們自己的 Nobordist 追蹤狀態",
    addStatusCheckpoint: "添加状态检查点",
    addNewStatusCheckpoint: "添加新状态检查点",
    uploadStatusCheckpoint: "上传状态检查点",
    checkpointID: "检查点 ID",
    fields: {
      statusCode: "状态码",
      trackingFunction: "跟踪功能",
      checkpoint: "检查点",
      creationDate: "创建日期",
      edit: "编辑",
    },
    notApplied: "Não aplicado",
  },
  statusCheckpointsPageView: {
    title: "状态检查点",
  },
  trackingNotificationsPage: {
    title: "跟踪通知",
    description:
      "關於追蹤更新發送的所有通知（電子郵件、短信、webhooks，...）的列表",
    fields: {
      notificationType: "通知类型",
      volumeCheckpointID: "体积检查点 ID",
      data: "收件人",
      sent: "已发送",
    },
  },
  volumeCheckpointsPage: {
    title: "体积检查点",
    description: "我們添加到訂單追蹤歷史中的所有追蹤狀態列表",
    generateVolumeLabel: "產生卷標",
    fields: {
      volumeID: "体积 ID",
      receivedCheckpointID: "接收检查点 ID",
      convertedNotifs: "已转换通知",
      checkpoint: "检查点",
      DateISO: "日期 ISO",
      message: "消息",
      notificationType: "通知類型",
      recipient: "收件人",
      trackingNotification: "跟蹤通知",
    },
  },
  volumeCheckpointsViewPage: {
    title: "体积检查点 #",
    checkpoint: "检查点",
    volumeData: "体积数据 #",
    volumes: "体积",
    message: "消息",
    attachment: "附件",
    volumeID: "体积 ID",
    receivedCheckpointID: "接收检查点 ID",
    convertedNotifs: "已转换通知",
    checkpointID: "检查点 ID",
    created: "创建",
    dateIso: "日期 ISO",
    type: "类型",
    slug: "Slug",
    name: "名称",
    description: "描述",
    trackingCode: "跟踪代码",
    titleField: "标题",
    namePT: "名称 PT",
    descriptionPT: "描述 PT",
    titlePT: "标题 PT",
    hierarchyStatus: "层次状态",
    height: "高度",
    width: "宽度",
    length: "长度",
    weight: "重量",
    cubageFactor: "体积因子",
    volumeType: "体积类型",
    orderID: "订单 ID",
    lastMileTrackingNumber: "末端跟踪号码",
    orderReference: "订单参考",
    lastMileCarrierNumber: "末端承运商编号",
    fields: {
      name: "名称",
      description: "描述",
      sku: "SKU",
      valueWithTax: "含税价值",
      value: "价值",
      hsCode: "HS 代码",
      quantity: "数量",
      originCountry: "原产国",
      length: "长度",
      width: "宽度",
      height: "高度",
      weight: "重量",
    },
  },
  contractsPage: {
    logo: "标志",
    title: "合同",
    importContract: "从JSON导入合同",
    subTitle: "查看和管理合同数据",
    addContracts: "添加合同",
    updateValidityDate: "更新有效期日期",
    showAdditionalServices: "显示其他服务",
    addNewContract: "添加新合同",
    name: "名称",
    slug: "Slug",
    incoterm: "国际贸易术语",
    taxingType: "税收类型",
    maximumWeight: "最大重量",
    cubageFactor: "体积因子",
    maximumLength: "最大长度",
    maximumHeight: "最大高度",
    maximumWidth: "最大宽度",
    maximumPriceLimit: "最大价格限制",
    additionalServices: "附加服务",
    externalID: "外部 ID",
    hSCodes: "HS 代码",
    triggerOption: "触发选项",
    triggerCondition: "触发条件",
    primaryType: "主要类型",
    primaryValue: "主要值",
    primaryVariable: "主要变量",
    secondaryVariable: "次要变量",
    secondaryType: "次要类型",
    secondaryValue: "次要值",
    selecttype: "选择类型",
    addToServices: "添加到服务",
    cancelNewService: "取消新服务",
    newService: "新服务",
    botNotifications: "机器人通知",
    isOldFlow: "是否旧流程",
    old: "旧",
    new: "新",
    mode: "模式",
    airporttoDoor: "机场到门",
    doorToDoor: "门到门",
    automatizePayment: "自动支付",
    needSupervisorApproval: "此操作需要主管批准",
    chooseSendTo: "请选择发送请求的对象",
    firstMileDM: "第一程交付方式",
    freightForwarderDM: "货运公司交付方式",
    customClearenceDM: "自定义清关交付方式",
    lastMileDM: "末端交付方式",
    priceTable: "价格表",
    reverseDM: "反向交付方式",
    deliveryTime: "交付时间",
    destinationCountry: "目的地国家",
    originCountry: "原产国",
    fields: {
      name: "名称",
      slug: "Slug",
      deliveryMethods: "交付方式",
      firstMileDeliveryMethod: "第一程交付方式",
      freightForwarderDeliveryMethod: "货运公司交付方式",
      customClearenceDeliveryMethod: "自定义清关交付方式",
      lastMileDeliveryMethod: "末端交付方式",
      reverseDeliveryMethod: "反向交付方式",
      priceTable: "价格表",
      deliveryTime: "交付时间",
      priceLmit: "价格限制",
      creationDate: "创建日期",
      taxingType: "税收类型",
      incoterm: "国际贸易术语",
      automaticPayment: "自动支付",
      maximumWeight: "最大重量",
      maximumWidth: "最大宽度",
      maximumHeight: "最大高度",
      maximumLength: "最大长度",
      cubageFactor: "体积因子",
      additionalServices: "附加服务",
      isMadeBySeller: "由賣家製作",
    },
    nameHelpText: "這是賣家查看合同的方式",
    slugHelpText: "這是供內部使用，賣家看不到這個名稱",
    incotermHelpText: "此合同允許哪些國際貿易術語",
    beginAndDateHelpText:
      "這設置了合同的有效日期。超過這個期限，賣家將無法使用它",
    flowHelpText:
      "舊流程將創建overpacks的責任委託給賣家。 在新流程中，管理員將通過masterboxes完成此操作。 推薦：新流程",
    modeHelpText:
      "從機場到門口表示合同僅負責海關和最後一公里，而門到門則表示合同負責從原籍國開始的整個過程",
    importContractFromJsonHelpText:
      "這允許您從另一個環境重新創建合同。 例如：您在測試環境中創建了一個合同，並希望在生產環境中創建完全相同的合同。 在這種情況下，您只需在測試環境中的合同頁面上點擊“下載合同設置”，然後在此處導入下載的json文件",
    downloadContractsSettingsHelpText:
      "這將生成一個json文檔，您可以用它將此合同複製到另一個環境",
    ifOrderReceives: "如果訂單收到以下檢查點之一",
    sellerWillBeBilled: "賣家將被收取額外費用，名為",
    andTheItemHasOneOfHscodes: "且該商品具有以下HS代碼之一",

    valueBilledFlat: "收費金額為每 {type} {value}",
    valueBilledFlatType: "@:contractsPage.valueBilledFlat 並且每 {type2}",

    andValueBilledFlat: " 並且每 {type} {value}",
    andValueBilledFlatType: " 並且每 {type1} {value} 和每 {type2}",

    valueBilledPercent: "收費金額為 {value} 的 {type} 的價值",
    valueBilledPercentType1: "@:contractsPage.valueBilledPercent，每個 {type2}",
    valueBilledPercentType2:
      "@:contractsPage.valueBilledPercent，每個 {type2} 和每個 {type3}",

    andValueBilledPercent: " 並且收費金額為 {type} 價值的 {value}",
    andValueBilledPercentType1: " 並且每個 {type2} 的 {type1} 價值的 {value}",
    andValueBilledPercentType2:
      " 並且每個 {type2} 和每個 {type3} 的 {type1} 價值的 {value}",

    day: "天",
    volume: "體積",
    item: "項目 | 項目",
  },
  contractsViewPage: {
    title: "合同 #",
    downloadContractSettings: "下载合同设置",
    subTitle: "检查并在必要时编辑承运商的数据",
    nameDefinitions: "名称和定义",
    destinationandOrigin: "目的地和原产地",
    destinationandOriginSubtitle: "定义合同覆盖的国家",
    separeteByCommas: "用逗号分隔每个代码",
    descriptionName: "描述性名称（显示在卖家应用程序中）",
    weightMeasure: "重量和测量",
    termContract: "合同期限",
    deliveryMethods: "交付方式",
    deliveryMethod: "交付方式",
    deliveryMethodsSubtitle: "为每个阶段的过程注册负责人和相应的交付方式",
    notFoundDM: "找不到您的交付方式",
    registerDM: "注册新的交付方式",
    uniqueDM: "唯一的交付方式",
    uniqueDMSubtitle: "此交付方式负责整个合同",
    pricesMeasuresCubage: "重量、尺寸和体积",
    pricesMeasuresCubageSubtitle: "根据注册的承运商和方法填写值",
    cubageCalcHelper: "根据最大尺寸和重量计算",
    priceDeliveryTime: "价格表和交货时间",
    priceDeliveryTimeSubtitle: "这些表确定了此合同的货物估计成本和交货时间",
    anyCountry: "任何国家",
    priceTableDeliveryTime: "价格表和交货时间",
    pdf: "PDF",
    csv: "CSV",
    name: "名称",
    ID: "ID",
    slug: "Slug",
    created: "创建日期",
    firstMileDeliveryMethod: "首程交付方式",
    freightForwarderDeliveryMethod: "货运公司交付方式",
    customClearenceDeliveryMethod: "自定义清关交付方式",
    lastMileDeliveryMethod: "末端交付方式",
    isOldFlow: "是否旧流程",
    priceTable: "价格表",
    obsolete: "已过时",
    generatePaymentLink: "生成支付链接",
    taxingType: "税收类型",
    incoterm: "国际贸易术语",
    automatizedpayment: "自动支付",
    reverseDeliveryMethod: "反向交付方式",
    deliveryTime: "交货时间",
    airporttoDoor: "机场到门",
    doorToDoor: "門到門",
    maximumWeight: "最大重量",
    cubageFactor: "体积因子",
    maximumPrice: "最大价格",
    maximumLength: "最大长度",
    maximumHeight: "最大高度",
    maximumWidth: "最大宽度",
    externalID: "外部 ID",
    triggerOption: "触发选项",
    triggerCondition: "触发条件",
    primaryVariable: "主要变量",
    primaryValue: "主要值",
    secondaryVariable: "次要变量",
    secondaryValue: "次要值",
    additionalServices: "附加服务",
    addedServices: "已添加服务",
    additionalServicesSubtitle: "为此合同设置一些规则",
    additional: "附加",
    hSCodes: "HS 代码",
    editContract: "编辑合同",
    cloneContract: "克隆合同",
    slugForNewContract: "新合同的 Slug",
    isObsolete: "是否过时",
    primaryType: "主要类型",
    secondaryType: "次要类型",
    selecttype: "选择类型",
    addServices: "添加到服务",
    cancelNewService: "取消新服务",
    newService: "新服务",
    automatizedPayment: "自动支付",
    airportToDoor: "机场到门",
    needsSupervisorApproval: "此操作需要主管批准",
    chooseWhoSendRequest: "请选择发送请求的对象",
    wantToMakeContractObsolete: "您是否要使此合同过时",
    notBePossibleVisualize: "将无法查看或更改",
    removeService: "删除服务",
    firstMileDM: "首程交付方式",
    freightForwarderDM: "货运公司交付方式",
    customClearenceDM: "自定义清关交付方式",
    lastMileDM: "末端交付方式",
    reverseDM: "反向交付方式",
    generalData: "常规数据",
    generalDataSubtitle: "填写以下详细信息",
    automatized: "自动化",
    notAutomatized: "未自动化",
    slugHelpText: "供管理員使用的內部名稱，不會顯示給賣家",
    configurations: "配置",
  },
  deliveryMethodsPage: {
    title: "配送方式",
    subTitle: "管理配送方式或创建新的合作关系",
    addDeliveryMethod: "添加配送方式",
    cooperationTitle: "标题",
    cooperationList: "合作关系列表",
    cooperations: "合作关系",
    addCooperations: "添加更多合作关系",
    eachCooperationUniqueTitle: "每个合作关系都有一个唯一的标题",
    name: "名称",
    service: "服务",
    selectGroup: "选择组",
    type: "类型",
    nationalization: "国家化",
    trackingCredentials: "跟踪凭证",
    addTracking: "添加跟踪",
    dispatchCredentials: "派遣凭证",
    addDispatch: "添加派遣",
    key: "键",
    value: "值",
    carrier: "承运商",
    cooperationSlug: "合作关系标识",
    fields: {
      name: "名称",
      service: "服务",
      type: "类型",
      carrier: "承运商",
      carrierID: "承运商ID",
      creationDate: "创建日期",
      cooperationSlug: "合作关系标识",
      createdBySeller: "由賣家創建",
    },
  },
  deliveryMethodsViewPage: {
    title: "配送方式 #",
    usedContracts: "使用此交付方式的合約",
    editDeliveryMethod: "编辑配送方式",
    edit: "编辑",
    name: "名称",
    service: "服务",
    selectgroup: "选择组",
    type: "类型",
    carrier: "承运商",
    selectcarrier: "选择承运商",
    nationalization: "国家化",
    trackingCredentials: "跟踪凭证",
    key: "键",
    value: "值",
    addTracking: "添加跟踪",
    dispatchCredentials: "派遣凭证",
    addDispatch: "添加派遣",
    owner: "所有者",
    ownerReference: "所有者参考",
    cooperationSlug: "合作关系标识",
  },
  priceTablesPage: {
    title: "价格表和交货时间",
    subTitle: "查看客户的价格表和交货时间",
    helpTextTitle: "创建、删除、下载客户的交货时间和价格表",
    titlePrice: "价格表",
    titleDelivery: "交货时间",
    importPricesTable: "导入价格表",
    importDeliveryTime: "导入交货时间",
    suretodelete: "您确定要删除",
    selectCsv: "选择要上传的 CSV 文件",
    fileUploaded: "文件已上传",
    downloadHere: "在此下载",
    ablankTable: "空表",
    import: "导入",
    name: "名称",
    removeOrders: "删除订单",
    shouldRemove1: "您确定要删除这些",
    shouldRemove2: "个订单吗？此操作不可逆",
    shouldRemove3: " 此操作不可逆",
    alreadyInMasterbox: "选定的订单已关联到主包装箱，将不会被删除",
    someInMasterbox: "这些订单已关联到主包装箱，将不会被删除。",
    toBeRemoved: "剩余的订单可以被删除。您确定要删除它们吗？此操作不可逆",
    filesUpload: "浏览您的文件并上传您的",
    needsSupervisorApproval: "此操作需要主管批准",
    chooseToSendRequest: "请选择您要发送请求的对象",
    fields: {
      name: "名称",
      actions: "操作",
    },
    priceTableDescription: "通过价格表，您可以为每个邮政编码和重量设置运费",
    deliveryTimeTableDescription:
      "通过交货时间表，您可以为每个邮政编码设置交货时间",
    nameTooltip: "提供任意名稱來標識您的文件。您的賣家不會看到它",
    dropDownHelp:
      "首先，点击“在这里下载空白表格”按钮。 您将下载一个空的 CSV 文件。 然后，填写它并点击“上传”以开始导入过程。",
  },
  shipstationContractsPage: {
    title: "Shipstation 合同",
    addShipstationContract: "添加 Shipstation 合同",
    shipstationContract: "Shipstation 合同",
    shipstationContractName: "Shipstation 合同名称",
    seller: "卖家",
    contract: "合同",
    fields: {
      contract: "合同",
      sellerID: "卖家 ID",
      shipstationContractName: "Shipstation 合同名称",
      actions: "操作",
    },
  },
  defaultPlansPage: {
    title: "卖家账户计划",
    name: "名称",
    selectMultipleSellers: "选择多个卖家",
    email: "电子邮件",
    applySellers: "应用到卖家",
    newPlan: "新计划",
    components: {
      categoryForm: {
        chargeDay: "计费日",
        firstChargeDay: "首次计费日",
        chargeFrequency: "计费频率",
        limit: "限制",
        fixedChargeDay: "固定计费日",
      },
      groupCategoryCards: {
        cost: "费用",
        trackingStatus: "跟踪状态",
        sMSCost: "短信费用",
        whatsappCost: "WhatsApp 费用",
      },
    },
  },
  sellerAccountEventsPage: {
    title: "卖家账户事件",
    addCredit: "添加信用额",
    filterCategory: "按类别筛选",
    limit: "限制",
    balanceIs: "余额为",
    nBxBelowLimit: "NBx 低于限制",
    noLimit: "无限制",
    seller: "卖家",
    amount: "金额",
    currentExchange: "当前汇率",
    description: "描述",
    defaultPlans: "默认计划",
    choosePlan: "选择计划",
    generalSettings: "常规设置",
    subscriptionFee: "订阅费",
    nBXvalueUSD: "NBX 美元价值",
    limitTax: "限制税",
    notifications: "通知",
    whatsappCost: "Whatsapp 费用",
    sMSCost: "短信费用",
    freight: "货运",
    freightCost: "货运费用",
    settings: "设置",
    chargeDay: "计费日",
    firstChargeDay: "首次计费日",
    chargeFrequency: "计费频率",
    fixedChargeDay: "固定计费日",
    trackingCode: "跟踪代码",
    updateSettings: "更新设置",
    fields: {
      sellerID: "卖家 ID",
      sellerName: "卖家名称",
      category: "类别",
      attachment: "附件",
      value: "价值",
      exchange: "汇率",
      description: "描述",
      creationDate: "创建日期",
      startValue: "起始值",
      endValue: "结束值",
    },
    currentExchangeHelpText: "目前匯率：1 美元等於 R$ %{value}",
    howMuchDoYouWantAdd: "您想添加多少？",
    creditOperation: "按一下以切換到 CREDIT 操作",
    debitOperation: "點擊以切換到借記操作",
  },
  alertLogsPage: {
    title: "日志管理",
    subTitle: "检查平台上生成的日志",
    helpTextTitle: "系统生成或记录的消息位于此处",
    alertLogs: "警报日志",
    importValuesandStatuses: "导入值和状态",
    updateStatus: "更新状态",
    downloadLogs: "下载日志",
    updateStatusInfo:
      "如果您只想更新状态，请将“tax_value”列留空（必须保留列名）",
    new: "新建",
    solved: "已解决",
    priority: "优先级",
    inProgress: "进行中",
    waitingforPayment: "等待付款",
    sentAgain: "重新发送",
    paymentDone: "付款完成",
    pending: "待处理",
    paidExternally: "外部已支付",
    needSellerAction: "需要卖家操作",
    status: "状态",
    comment: "评论",
    checktheprocessingdownloads: "在...下载页面检查处理下载",
    sureWantSave: "您确定要保存更改吗",
    newTrackingNumbers: "新跟踪编号",
    shouldbeCommaSeparated:
      "新编号应以逗号分隔，并按照所选跟踪警报日志的顺序排列：TAL_IDs：",
    unsaveddata: "未保存的数据",
    pleasesaveunsaveddata: "请保存未保存的数据或取消",
    okay: "好的",
    name: "名称",
    browseFilesUpload: "浏览文件并上传文件",
    selectcsvFileUpload: "选择要上传的 CSV 文件",
    downloadHere: "在此下载",
    instructionsXLSfile: "说明 XLS 文件",
    fileUploaded: "文件已上传",
    blankTable: "空表",
    updateAllSelectedLogs: "更新所有選定的日誌",
    barcode: "条形码",
    importPaymentCertificates: "進口付款憑證",
    importPdfPaymentCertificatesHelp:
      '導入合併的付款證書PDF：系統將分開它們，並在"付款證書"中提供',
    fields: {
      volumeId: "体积 ID",
      trackingCode: "跟踪代码",
      sellerId: "卖家 ID",
      seller: "卖家",
      message: "消息",
      attachment: "附件",
      lastMileTrackingNumber: "末端跟踪号",
      dateISO: "日期 ISO",
      creationTime: "创建时间",
      title: "标题",
      rule: "规则",
      type: "类型",
      status: "状态",
      comment: "评论",
      dutiesPayment: "税收支付",
      volume: "体积",
    },
  },
  alertLogsViewPage: {
    title: "警报日志 #",
    sellerChargedAmount: "卖家扣款金额",
    exchange: "汇率",
    incoterm: "国际贸易术语",
    status: "状态",
    date: "日期",
    dateIso: "日期 ISO",
    orderID: "订单 ID",
    orderNumber: "订单编号",
    volumeID: "体积 ID",
    sellerID: "卖家 ID",
    taxID: "税务 ID",
    lastMileNumber: "末端号码",
    productsValue: "产品价值",
    freightValue: "货运价值",
    expectedTax: "预期税金",
    realTax: "实际税金",
    valueR$: "价值 R$",
    editableValues: "可编辑的值",
    downloadAttachment: "下載附件",
    downloadPaymentCertificate: "下載付款憑證",
  },
  bilLogsPage: {
    title: "Bil 日志",
    fields: {
      mawb: "总提单号",
      house: "分提单号",
      volumeID: "体积 ID",
      status: "状态",
      creationDate: "创建日期",
    },
  },
  bilLogsViewPage: {
    title: "账单日志 #",
    mawb: "总提单号",
    house: "分提单号",
    creationDate: "创建日期",
    status: "状态",
    volumeID: "体积 ID",
    logs: "日志",
  },
  delayedJobsPage: {
    title: "延迟作业日志",
    fields: {
      queue: "队列",
      priority: "优先级",
      attempts: "尝试次数",
      handler: "处理器",
      lastError: "最后错误",
      runat: "运行于",
      lockedat: "锁定于",
      failedat: "失败于",
      lockedby: "锁定者",
    },
  },
  externalLogs: {
    title: "外部 API 日志",
    fields: {
      apiName: "API 名称",
      function: "功能",
      userID: "用户 ID",
      response: "响应",
      creationDate: "创建日期",
    },
  },
  externalViewLogs: {
    title: "外部 API 日志 #",
    apiName: "API 名称",
    userName: "用户名",
    function: "功能",
    creationDate: "创建日期",
    updateDate: "更新日期",
    params: "参数",
    response: "响应",
  },
  generalEmailsPage: {
    title: "常规邮件",
    subTitle: "在此頁面，您可以使用已配置的發件人地址發送電子郵件給任何人",
    createGeneralEmails: "创建常规邮件",
    titleGeneral: "标题",
    sellersSelected: "选择的卖家",
    body: "正文",
    destination: "目标",
    destinationsSellers: "发送给卖家的目标",
    fields: {
      titleGeneral: "标题",
      Destination: "目标",
      Body: "正文",
      Sent: "发送",
    },
    emailTo: "發送電子郵件至 {email}",
    chooseATitle: "選擇您的電子郵件標題",
    chooseTheRecipient: "您想如何選擇收件人？",
    typeEmailAddress: "輸入電子郵件地址",
    chooseAmongMySellers: "從我的賣家中選擇",
    clickHereToChangeThisAddress: "點擊此處更改地址",
    changeYourConfiguratedSenderAddress: "更改您的已配置發件人地址",
    youDontHaveAnyAddressSetUpYet: "您尚未設置任何地址",
  },
  gGeneralEmailsViewPage: {
    title: "通用电子邮件 #",
    iD: "ID",
    sent: "发送",
    titleGeneral: "标题",
    destination: "目标",
    body: "正文",
    editGeneralEmail: "编辑通用电子邮件",
    deleteGeneralEmail: "删除通用电子邮件",
    sureWantDelete: "您确定要删除此通用电子邮件吗",
  },
  nobordistLogsPage: {
    title: "Nobordist 日志",
    fields: {
      action: "操作",
      actionType: "操作类型",
      userID: "用户 ID",
      logs: "日志",
      creationDate: "创建日期",
    },
  },
  nobordistLogsViewPage: {
    title: "日志 #",
    action: "操作",
    userName: "用户名",
    creationDate: "创建日期",
    updateDate: "更新日期",
    logs: "日志",
  },
  notificationsPage: {
    title: "发送通知",
    subtitle: "向您的客户发送电子邮件和通知。",
    newNotification: "新通知",
    settingYourNotification: "设置您的通知。",
    generalData: "通用数据",
    settingNotificationParams: "设置通知参数",
    selectRecipientType: "选择收件人类型",
    to: "到",
    recipient: "收件人",
    subject: "主题",
    message: "消息",
    sendNow: "立即发送",
    subjects: {
      finance: "财务",
      insufficientBalance: "余额不足",
      trackingIssue: "跟踪问题",
      newTicket: "新工单",
    },
    seller: "卖家",
    user: "用户",
    readed: "已读",
    seeMessage: "查看消息",
    notification: "通知",
    seeSendedNotification: "查看已发送的消息",
    sendedMessage: "已发送消息",
    sendDate: "发送日期",
    createNotification: "创建通知",
    body: "内容",
    respondApprovalRequest: "回复批准请求",
    notificationMessage: "通知消息",
    contract: "合同",
    name: "名称",
    file: "文件",
    validityDate: "有效日期",
    contracts: "合同",
    click: "点击",
    hERE: "这里",
    seeDetailedData: "查看详细数据",
    thisRequest: "此请求",
    is: "是",
    hasBeen: "已被",
    supervisorCommented: "监督员已评论",
    addCommentResponse: "您可以在回复中添加评论",
    approve: "批准",
    deny: "拒绝",
    by: "通过",
    ChooseRecipientType: "选择收件人类型*",
    Seller: "卖家",
    User: "用户",
    Title: "标题",
    finance: "财务",
    insufficientBalance: "余额不足",
    trackingIssue: "跟踪问题",
    newTicket: "新工单",
    dataUpdate: "数据更新",
    contractPermission: "合同权限",
    contractUpdate: "合同更新",
    priceTableUpdate: "价格表更新",
    generalIssue: "一般問題",
    fields: {
      newRead: "新/已读",
      tType: "类型",
      recipient: "收件人",
      title: "标题",
      body: "内容",
      status: "状态",
    },
    titleNotification: "标题",
    type: "类型",
    new: "新",
    recipientType: "收件者類型",
  },
  rejectedOrderPage: {
    title: "被拒绝订单日志",
    subTitle:
      "此頁面顯示了您的賣家在導入頁面上嘗試創建但失敗的所有導入訂單。您可以查看導入的參數以及導致導入失敗的錯誤。",
    sellerName: "卖家名称",
    fields: {
      orderNumber: "订单编号",
      dateImport: "导入日期",
      orderInfo: "订单信息",
      sellerID: "卖家ID",
      error: "错误",
    },
  },
  rejectedOrderViewPage: {
    title: "被拒绝订单日志 #",
    seller: "卖家",
    creationDate: "创建日期",
    orderInfo: "订单信息",
    erroInfo: "错误信息",
  },
  trackingRulesPage: {
    title: "跟踪规则",
    subTitle: "當某些追蹤事件發生時，自動化通知",
    addTrackingRule: "添加跟踪规则",
    addNewTrackingRule: "添加新的跟踪规则",
    trackingMode: "跟踪模式",
    receivedCheckpoint: "已接收检查点",
    timeBetweenTwoCheckpoints: "两个检查点之间的时间",
    name: "名称",
    contract: "合同",
    incoterm: "国际贸易术语",
    any: "任何",
    startCheckpoint: "起始检查点",
    endCheckpoint: "结束检查点",
    maximumDays: "最大天数",
    checkpoint: "检查点",
    log: "生成日志",
    notifySeller: "通知卖家",
    sellerNotification: "卖家通知",
    notifyCustomer: "通知客户",
    notifyNobordist: "通知Nobordist",
    webhook: "使用Webhook",
    method: "方法",
    pOST: "POST",
    pUT: "PUT",
    headers: "标头",
    addHeaders: "添加标头",
    key: "键",
    value: "值",
    seller: "卖家",
    titleRules: "标题",
    fields: {
      name: "名称",
      startCheckpoint: "起始检查点",
      endCheckpoint: "结束检查点",
      incoterm: "国际贸易术语",
      seller: "卖家",
      contract: "合同",
      daysLimit: "天数限制",
      mode: "模式",
      creationDate: "创建日期",
    },
    allSellerNotificationTitles: {
      finance: "财务",
      insufficientBalance: "余额不足",
      trackingIssue: "跟踪问题",
    },
    chooseTrigger: "選擇您要用來應用規則的觸發器：",
    whenSpecificCheckpointOccur: "當特定檢查點發生時",
    whenDelayIsTooLong: "當兩個檢查點之間的延遲過長時",
    applyRuleToSellersHelpText:
      "您可以通過不選擇此字段來將規則應用於所有賣家。",
    applyRuleToContractsHelpText:
      "您可以通過不選擇此字段來將規則應用於所有合同。",
    amountToWaitCheckpointsHelpText: "在兩個檢查點之間等待的最長時間",
    endCheckpoointHelpText:
      "目標結束檢查點；如果您希望將其應用於任何檢查點，只需將此字段保持未選中",
    giveNameToYourRule: "為您的規則命名",
    chooseWhatWillBeSent: "選擇將發送的內容",
    logHelpText: "在管理網站上創建跟踪警報日誌",
    notifySellerHelpText: "向註冊的賣家電子郵件發送電子郵件",
    notifyCustomerHelpText: "使用訂單中提供的電子郵件地址向客戶發送電子郵件",
    notifyNbHelpText: "向某些管理用戶或一般企業電子郵件發送電子郵件",
    sellerNotificationHelpText: "向賣家在其網站帳戶上發送通知",
    webhookHelpText: "向任何API發送webhook",
    sellerInternalNotification: "賣家內部通知",
  },
  trackingRulesViewPage: {
    title: "跟踪规则",
    tracking: "跟踪",
    editTrackingRule: "编辑跟踪规则",
    deleteTrackingRule: "删除跟踪规则",
    confirmDeleteRule: "您確定要刪除此規則嗎？",
    edit: "编辑",
    name: "名称",
    contract: "合同",
    startCheckpoint: "起始检查点",
    endCheckpoint: "结束检查点",
    maximumDays: "最大天数",
    log: "日志",
    notifySeller: "通知卖家",
    notifyCustomer: "通知客户",
    notifyNobordist: "通知Nobordist",
    webhook: "Webhook",
    method: "方法",
    pOST: "POST",
    pUT: "PUT",
    headers: "标头",
    key: "键",
    value: "值",
    addHeaders: "添加标头",
    sureWantDelete: "您确定要删除此跟踪规则吗",
    seller: "卖家",
    customer: "客户",
    nobordist: "Nobordist",
    daysLimit: "天数限制",
    trackingMode: "跟踪模式",
    sellerMailTitle: "卖家邮件标题",
    sellerMailBody: "卖家邮件正文",
    customerMailTitle: "客户邮件标题",
    customerMailBody: "客户邮件正文",
    nobordistMailTitle: "Nobordist邮件标题",
    destination: "目的地",
    nobordistMailBody: "Nobordist邮件正文",
    notifyWebhook: "通知Webhook",
    endpoint: "端点",
    body: "正文",
    noActionConfigured: "未配置任何操作",
  },
  userNotificationsPage: {
    title: "用户通知",
    subTitle: "阅读发送给您的通知",
    respondRequest: "回复批准请求",
    notificationMessage: "通知消息",
    name: "姓名",
    file: "文件",
    validityDate: "有效日期",
    contracts: "合同",
    contract: "合同",
    click: "点击",
    hERE: "这里",
    seeDetailedData: "查看详细数据",
    thisRequest: "此请求",
    is: "是",
    hasBeen: "已经",
    by: "由",
    supervisorCommented: "主管评论",
    addCommentResponse: "您可以添加评论以回复",
    approve: "批准",
    deny: "拒绝",
    seller: "卖方",
    fields: {
      data: "数据",
      title: "标题",
      body: "内容",
    },
    filterOptions: {
      title: "标题",
      seller: "卖家",
      body: "内容",
      new: "新",
      status: "地位",
      read: "讀",
    },
    allSellerNotificationTitles: {
      finance: "财务",
      insufficientBalance: "余额不足",
      trackingIssue: "跟踪问题",
    },
    sellerNotificationTitles: {
      finance: "财务",
      insufficientBalance: "余额不足",
      trackingIssue: "跟踪问题",
      newTicket: "新票务",
    },
    userNotificationTitles: {
      finance: "财务",
      insufficientBalance: "余额不足",
      trackingIssue: "跟踪问题",
      newTicket: "新票务",
      dataUpdate: "数据更新",
      contractPermission: "合同权限",
      contractUpdate: "合同更新",
      priceTableUpdate: "价格表更新",
    },
    seconds: "秒",
    minuteAgo: "1分钟前",
    minutes: "分钟",
    hourAgo: "1小时前",
    hours: "小时",
    yesterday: "昨天",
    days: "天",
    lastWeek: "上周",
    weeks: "周",
    lastMonth: "上个月",
    months: "月",
    lastYear: "去年",
    years: "年",
  },
  myNotificationsListPage: {
    title: "通知",
    found: "未找到通知 | 通知 | 通知",
    filters: {
      isNew: "新的或已读的",
    },
    allNotifications: "所有通知",
    youHaveNewNotification: "您有新的未讀通知。",
  },
  platformsPage: {
    title: "平台",
    addPlatform: "添加平台",
    AddNewPlatform: "添加新平台",
    platformName: "平台名称",
    correiosCredentials: "邮政凭据",
    addDispatch: "添加派遣",
    key: "键",
    value: "值",
    fields: {
      name: "姓名",
      actions: "操作",
    },
  },
  flightsPage: {
    title: "航班",
    subtitle: "检查并通知每个订单所在的航班。",
    nextFlights: "即将到来的航班",
    createNewFlight: "创建新航班",
    newFlight: "新航班",
    selectMasterboxes: "选择主运单",
    findAndSelectMasterboxes: "搜索并选择要添加到您的航班中的主运单",
    typeMasterboxesReference: "输入主运单参考编号，然后按“Enter”",
    selectedMasterboxes: "已选择的主运单",
    reference: "参考",
    next: "下一步",
    insertFlightData: "输入航班详情",
    pleaseInsertFlightData: "请添加航班详情以添加主运单。",
    howWouldLikeToUploadMawb: "您想如何上传 MAWB?",
    generalData: "一般信息",
    departureAndArrive: "出发和到达",
    taxes: "税费",
    returnsToPreviousStep: "返回上一步",
    createFlight: "创建航班",
    uploadFromMyComputer: "从我的电脑上传",
    documentsAndLabels: "文件和标签",
    editFlightData: "编辑航班数据",
    suggestedActions: "建议操作",
    copyLabelCode: "复制 MAWB 号码",
    taxesAndOthers: "税费和其他",
    masterboxes: "主运单",
    departure: "出发",
    stopover: "经停",
    arrival: "到达",
    noStopOver: "直飞航班",
    closeManifestConfirm:
      "您要关闭此清单吗？执行此操作后，您将无法再更新内容。",
    closeManifest: "关闭清单",
    confirmDeparture: "确认出发",
    deleteFlight: "删除航班",
    fields: {
      airline: "航空公司",
      flightNumber: "航班号",
      flightLabel: "航班标签",
      mawbNumber: "MAWB 号码",
      greenLight: "绿灯",
      prealertsSent: "已发送预警",
      departureAirport: "出發機場",
      departureDate: "出發日期",
      departureTime: "出發時間",
      arrivalAirport: "到達機場",
      arrivalDate: "到達日期",
      arrivalTime: "到達時間",
      stopoverAirport: "中途停留機場",
      freightValue: "貨運價值",
      creationDate: "创建日期",
      status: "状态",
    },
  },
  flightsViewPage: {
    title: "航班 #",
    mawbFileLink: "MAWB 文件链接",
    mawbLabel: "MAWB 标签",
    updateFlight: "更新航班",
    information: "信息",
    mawb: "MAWB",
    shipments: "货运",
    pages: "页数",
    closeManifest: "关闭清单",
    confirmDeparture: "确认出发",
    wantRemoveFlight: "您确定要永久删除此航班吗？",
    departureAirport: "出发机场",
    arrivalAirport: "到达机场",
    stopoverAirport: "经停机场",
    departureDate: "出发日期",
    arrivalDate: "到达日期",
    departureTime: "出发时间",
    arrivalTime: "到达时间",
    airline: "航空公司",
    flightNumber: "航班号",
    subtotal: "小计",
    chargesKilo: "每公斤费用",
    otherCharges: "其他费用",
    mawbNumber: "MAWB 号码",
    mawbFileFormat: "MAWB 文件格式",
    binary: "二进制",
    link: "链接",
    mawbFile: "MAWB 文件",
    copyReference_id: "点击此处复制参考编号",
    greenLight: "绿灯",
    prealertsSend: "预警已发送",
    date: "日期",
    downloadCurrent: "下载当前 MAWB",
    noMawbDocument: "目前没有该航班的 MAWB 文件",
  },
  masterboxesPage: {
    title: "主运单",
    subtitle: "过滤并检查具有体积和订单的箱子。",
    createMasterbox: "创建主运单",
    documentsAndLabels: "文件和标签",
    suggestedActions: "建议操作",
    masterboxData: "主运单数据",
    labels: "标签",
    mbCarriers: "承运商",
    reference: "参考",
    shipmentID: "货运编号",
    shipmentSequenceNumber: "货运顺序编号",
    date: "日期",
    cN35Label: "CN35 标签",
    volumes: "体积",
    dispatchID: "派遣编号",
    goToShipment: "查看货运",
    creationDate: "创建日期",
    weight: "重量",
    cubedWeight: "立方重量",
    lastMileTrackingNumber: "末端追踪编号",
    lastMileCarrierNumber: "末端承运商编号",
    totalItems: "总项目数",
    deleteMasterbox: "移除主运单",
    confirmDeleteMasterbox: "您确定要永久删除此主运单吗？",
    selectVolumes: "选择体积",
    selectedVolumes: "已选择的体积",
    findAndSelectVolumes: "浏览并选择要添加到主运单的体积",
    typeVolumeReference: "输入体积参考编号，然后按“Enter”",
    downloadMasterboxErrors: "下载主运单错误的 CSV",
    commentDownloadMasterboxErrors:
      "* 只有具有末端追踪编号的体积将添加到 CSV 中",
    goToFlight: "转到航班",
    volumesCount: "卷數",
  },
  masterboxesViewPage: {
    title: "主运单 #",
    dispatchID: "派遣编号",
    reference: "参考",
    shipmentID: "货运编号",
    shipmentSequenceNumber: "货运顺序编号",
    date: "日期",
    cN35Label: "CN35 标签",
    volumes: "体积",
    copyReference_id: "点击此处复制参考编号",
    fields: {
      weight: "重量",
      cubedWeight: "立方重量",
      lastMileTrackingNumber: "末端追踪编号",
      lastMileCarrierNumber: "末端承运商编号",
      totalItems: "总项目数",
    },
  },
  ordersPage: {
    title: "订单管理",
    subtitle: "过滤和检查订单，下载报告等。",
    importMabangOrders: "导入马帮订单",
    importOrderCosts: "导入订单成本",
    downloadOrders: "下载订单",
    downloadOrdersCosts: "下载订单成本",
    updateFinancialStatus: "更新财务状态",
    lastMileCarrierNotFound: "未找到末端承运商编号",
    new: "新订单",
    invoiced: "已开票",
    toInvoice: "待开票",
    canceled: "已取消",
    notCharged: "未收费",
    checkDownloadsAt: "在...下载页面检查处理下载。",
    selectOption: "请选择一个选项",
    orderNo: "订单编号",
    fieldsCurrentOrderCosts: {
      externalID: "外部编号",
      type: "类型",
      value: "价值",
      creationDate: "创建日期",
    },
    financial_statuses: {
      new: "新订单",
      toInvoice: "待开票",
      invoiced: "已开票",
      canceled: "已取消",
      notCharged: "未收费",
    },
    fields: {
      sellerID: "卖家ID",
      sellerName: "卖家名称",
      sellerTaxNumber: "卖家税号",
      contractID: "合同ID",
      overpackID: "外包装ID",
      customerName: "客户名称",
      postalCode: "客户邮政编码",
      customerCity: "客户城市",
      customerState: "客户州/省",
      incoterm: "国际贸易术语",
      orderNumber: "订单号码",
      data_source: "起源",
      salesChOrderN: "销售渠道订单号码",
      productsValue: "产品价值",
      freightValue: "运费价值",
      declaredValue: "申报价值",
      dutiesTaxValue: "关税价值",
      salesTaxValue: "销售税价值",
      taxValue: "税费价值",
      totalValue: "总价值",
      estimatedDutiesTaxValue: "预计关税价值",
      estimatedSalesTaxValue: "预计销售税价值",
      estimatedTaxValue: "预计税费价值",
      freightCost: "运费成本",
      estimatedFreightCost: "预计运费成本",
      taxCost: "税费成本",
      insuranceCost: "保险费用",
      insuranceCoverage: "保险覆盖",
      dDPCost: "DDP费用",
      totalGeneralCosts: "总成本",
      trackingNumber: "追踪号码",
      lastMileCarrierNumber: "最后一公里承运商编号",
      isMini: "是否迷你?",
      financialStatus: "财务状态",
      creationDate: "创建日期",
      mSDS: "安全数据表",
      label: "标签",
      firstMileLabel: "第一英里标签",
      shipConfirmation: "发货确认",
      isLanded: "是否已落地?",
      customerDocumentType: "客户文件类型",
      customerDocumentNumber: "客户文件编号",
      customerAddress: "客户地址",
      customerAddressNumber: "客户地址编号",
      customerAddressComplement: "客户地址补充信息",
      customerAddressReference: "客户地址参考",
      customerAddressQuarter: "客户地址区",
      customerPhone: "客户电话",
      customerEmail: "客户电子邮件",
      customerCountry: "客户国家",
      sellerAddress: "卖家地址",
      sellerAddressNumber: "卖家地址编号",
      sellerAddressComplement: "卖家地址补充信息",
      sellerCity: "卖家城市",
      sellerState: "卖家州/省",
      sellerPhone: "卖家电话",
      sellerEmail: "卖家电子邮件",
      sellerPostalCode: "卖家邮政编码",
      sellerCountry: "卖家国家",
      sellerWebsite: "卖家网站",
      currentStatus: "当前状态",
      estimatedDeliveryDate: "预计交货日期",
      salesChannelOrderNumber: "销售渠道订单号",
      orderReference: "订单参考",
      dollarReais: "美元雷亚尔汇率",
    },
    removeOrdersCount: "删除 %{count} 个订单",
    removeOrders: "删除订单",
    toBeRemoved:
      "剩余 %{count} 个订单可供删除。您确定要删除它们吗？此操作不可逆转",
  },
  ordersViewPage: {
    title: "订单 {id}",
    label: "标签",
    addCost: "添加成本",
    commercialInvoice: "商业发票",
    trackingPage: "追踪页面",
    packingSlip: "装箱单",
    cevaShippersConfirmation: "Ceva 发货人确认",
    cUSTOMER: "客户",
    relatedObjects: "相关对象",
    freight: "货运",
    editOrder: "编辑订单",
    generalInformations: "一般信息",
    valuesSummary: "价值摘要",
    generalCosts: "总成本",
    volumes: "体积",
    costType: "成本类型",
    selecttype: "选择类型",
    value: "价值",
    name: "名称",
    documentType: "文件类型",
    documentNumber: "文件编号",
    address: "地址",
    addressNumber: "地址编号",
    addressComplement: "地址补充",
    addressReference: "地址参考",
    postalCode: "邮政编码",
    addressQuarter: "地址区",
    city: "城市",
    state: "州",
    country: "国家",
    phone: "电话",
    email: "电子邮件",
    mailbox: "邮箱",
    phoneCountryCode: "电话国家代码",
    salesChannelID: "销售渠道编号",
    sellerIDcomp: "卖家编号",
    contractID: "合同编号",
    overpackID: "超级包装编号",
    currency: "货币",
    incoterm: "国际贸易术语",
    returnInsurance: "退货保险",
    insuranceCoverage: "保险覆盖范围",
    returnModality: "退货方式",
    freightValue: "运费价值",
    orderNumbe: "订单编号",
    createdDate: "创建日期",
    sellerName: "卖家名称",
    estimatedDeliveryDate: "预计交货日期",
    trackingNumber: "跟踪编号",
    isMini: "是否 Mini",
    observation: "观察",
    declaredValue: "申报价值",
    taxValue: "税值",
    total: "总计",
    remove: "删除",
    wantToRemore: "您确定要删除此请求吗？此操作不可逆",
    estimatedTaxValue: "估计税值",
    freightCost: "运费成本",
    volumesFields: {
      Weight: "重量",
      cubedWeight: "立方重量",
      lastMileTrackingNumber: "末端追踪编号",
      lastMileCarrierNumber: "末端承运商编号",
      productValue: "产品价值",
      freightValue: "运费价值",
      estimatedFreightCost: "预计运费成本",
      taxValue: "税值",
      totalItems: "总项目数",
      updatedWeight: "更新后重量",
      productsValue: "產品價值",
      itemsAmount: "項目數量",
      collectedIcms: "徵收的 ICMS",
      collectedII: "徵收的 II",
    },
    fieldsCurrentOrderCosts: {
      externalID: "外部编号",
      type: "类型",
      value: "价值",
      creationDate: "创建日期",
    },
    orderData: "订单数据",
    customerData: "客户数据",
    shippingInfo: "运输信息",
    reviewValues: "查看价值",
    copyTrackingNumber: "复制跟踪编号",
    showAllCustomerData: "显示所有客户数据",
    collapseCustomerData: "折叠客户数据",
    showAllOrderData: "显示所有订单数据",
    collapseOrderData: "折叠订单数据",
    dispatched: "已發貨",
    goToOrder: "前往訂單",
    goToSeller: "前往賣家",
    goToContract: "前往合約",
    goToTrackingPage: "前往追蹤頁面",
    lastMileLabel: "最後一英里標籤",
    generalInformationsTitle: "一般資訊",
    generalInformationsDescription: "查看主要設置",
    landedCost: "到岸成本",
    landedCostDescription: "表示創建時所有值已包含稅金和運費",
    dispatchedDescription: "當已發貨時，訂單準備跟隨流程，獲取追蹤狀態等...",
    senderIsCpf: "發送者是 CPF",
    receiverIsCommercial: "接收者是商業",
    numbersAndReferences: "號碼與參考",
    numbersAndReferencesDescription: "你可能需要的所有號碼",
    orderReference: "參考",
    orderReferenceDescription: "由 Nobordist 生成的訂單唯一標識符",
    orderNumberDescription: "由賣家選擇的號碼，用於識別訂單",
    internalNBID: "內部 NB ID",
    internalNBIDDescription: "用於內部操作",
    copyReference: "複製參考",
    salesChannelOrderNumberDescription: "銷售渠道中的訂單號碼",
    customer: "客戶",
    nameAddressContactInformation: "姓名，地址，聯繫信息",
    shipper: "發貨人",
    rfbCodeDescription: "賣家在巴西國稅局系統中的識別碼，允許創建 PRC 訂單",
    items: "項目",
    itemsDescription: "項目列表及其信息",
    goToVolume: "前往體積",
    itemDescription: "項目描述",
    costs: "成本",
    dimensions: "尺寸",
    seeFullDescription: "查看完整描述",
    seeCosts: "查看成本",
    seeDimensions: "查看尺寸",
    seeVolume: "查看體積",
    informedBySeller: "由賣家提供",
    volumesDescription: "體積列表及其信息",
    costsDescription: "成本和價值的完整描述",
    exchangeOnCreation: "創建時的匯率",
    estimatedTaxes: "估計稅金",
    computedByNbSystem: "由 NB 系統計算",
    dutiesTax: "進口稅",
    dutiesTaxDescription: "國家稅 (I.I.)",
    estimatedFreightCostDescription: "根據價格表估算",
    salesTax: "銷售稅",
    salesTaxDescription: "州稅 (ICMS)",
    totalTax: "總稅金",
    actualTaxes: "實際稅金",
    totalValue: "總價值",
    summary: "摘要",
    products: "產品",
    freightCostDescription: "當前的運費，取自估計的運費，但可能已被後續更新",
    orderCosts: "訂單成本",
    orderCostsDescription: "查看此訂單的所有收費",
    relatedObjectsDescription: "找到相關的賣家，合同...",
    salesChannel: "銷售渠道",
    observations: "觀察",
    freeFieldCreatedBySeller: "由賣家創建的自由字段",
    integrationData: "集成數據",
    additionalData: "附加數據",
    additionalDataDescription: "可能有用的可選數據",
  },
  overpacksPage: {
    title: "超级包装管理",
    updateFirstMileInformation: "更新第一英里信息",
    carriername: "承运商名称",
    trackingNumber: "跟踪编号",
    updateInformation: "更新信息",
    updateTrackingStatus: "更新跟踪状态",
    updateOverpacks: "更新超级包装",
    overpackSelected: "已选择超级包装",
    selectOneStatus: "选择一个状态",
    statusSelected: "已选择状态",
    fields: {
      sentDate: "发送日期",
      trackingNumber: "跟踪编号",
      masterboxID: "主运单编号",
      fMTrackingN: "第一英里跟踪编号",
      firstMileCompany: "第一英里公司",
      creationDate: "创建日期",
    },
    trackingOptions: {
      forwardedlocalhub: "货物已转发到本地枢纽",
      arrivedHubSorting: "货物已到达枢纽或分拣中心",
      clearanceCompleted: "通关流程已完成",
      shipmentArrivedDestinationCountry: "国际货物已到达目的国",
      handedCustomsCustomsClearance: "货物已交给海关进行通关",
      customsClearanceCompleted: "通关流程已完成",
      arrivedHubSortingCenter: "货物已到达枢纽或分拣中心",
    },
  },
  overpacksViewPage: {
    title: "超级包装",
    new: "新",
    ready: "准备就绪",
    dispatched: "已派送",
    orders: "订单",
    orderFoundOverpack: "未找到超级包装订单",
    cN23Numbers: "CN23 编号",
    cN23foundforOverpack: "未找到 CN23 超级包装",
    masterboxID: "主运单编号",
    createdDate: "创建日期",
    sentDate: "发送日期",
    trackingNumber: "跟踪编号",
    firstMileCarrierName: "第一英里承运商名称",
    firstMileTrackingN: "第一英里跟踪编号",
    fields: {
      orderNumber: "订单编号",
      trackingNumber: "跟踪编号",
      creationDate: "创建日期",
      customerName: "客户名称",
    },
  },
  shipmentsPage: {
    title: "货运",
    subtitle: "过滤和检查货运，下载报告等。",
    downloadShipments: "下载货运",
    actualWeight: "实际重量",
    checkProcessingDownloads: "在...下载页面检查处理下载",
    goToFlight: "转到航班",
    fields: {
      cn38: "CN38",
      cn38Label: "CN38 标签",
      sSellerWeight: "卖家重量",
      actualWeight: "实际重量",
      category: "类别",
      flightID: "航班编号",
      creationDate: "创建日期",
    },
    filterOptions: {
      sellerStartWeight: "卖家开始重量",
      sellerEndWeight: "卖家结束重量",
      actualStartWeight: "实际开始重量",
      actualEndWeight: "实际结束重量",
    },
  },
  shipmentsViewPage: {
    title: "货运 #",
    category: "类别",
    flightID: "航班编号",
    cn38: "CN38",
    date: "日期",
    cn38Label: "CN38 标签",
    masterboxes: "主运单",
    pages: "页",
    fields: {
      reference: "参考",
      dispatchID: "派遣编号",
      lastMileTrackingNumbers: "末端追踪编号",
    },
  },
  volumeReversePage: {
    title: "卷反向",
    subtitle: "过滤和检查订单，下载报告等。",
    fields: {
      orderNumber: "订单编号",
      orderID: "订单编号",
      volumeID: "卷编号",
      sellerID: "卖家编号",
      name: "名称",
      creationdate: "创建日期",
      reverseNumber: "反向编号",
      address: "地址",
      addressNumber: "地址编号",
      addressComplement: "地址补充",
      state: "州",
      postalCode: "邮政编码",
      country: "国家",
      city: "城市",
      customerName: "客户名称",
      orderItemsName: "订单项目名称",
      orderItemsdescription: "订单项目描述",
      orderItemsHSCode: "订单项目HS代码",
      startRegistrationDate: "开始注册日期",
      endRegistrationDate: "结束注册日期",
      order: "订单",
      seller: "卖家",
      volume: "卷",
    },
  },
  volumeReverseViewPage: {
    title: "卷反向 #",
    volumeReverse: "卷反向",
    order: "订单",
    volume: "卷",
    customer: "客户",
    items: "项目",
    reverseNumber: "反向编号",
    createdDate: "创建日期",
    name: "名称",
    address: "地址",
    addressNumber: "地址编号",
    addressComplement: "地址补充",
    state: "州",
    postalCode: "邮政编码",
    country: "国家",
    city: "城市",
    orderID: "订单编号",
    orderNumber: "订单编号",
    salesChannelOrderNumber: "销售渠道订单编号",
    totalValue: "总价值",
    trackingNumber: "跟踪编号",
    volumeID: "卷编号",
    height: "高度",
    width: "宽度",
    length: "长度",
    weight: "重量",
    lastMileTrackingNumber: "末端追踪编号",
    documentType: "文件类型",
    documentNumber: "文件编号",
    addressReference: "地址参考",
    addressQuarter: "地址季度",
    phone: "电话",
    email: "电子邮件",
    mailbox: "邮箱",
    phoneCountryCode: "电话国家代码",
    fields: {
      name: "名称",
      value: "价值",
      hsCode: "HS代码",
      Quantity: "数量",
      originCountry: "原产国",
    },
  },
  volumesPage: {
    title: "卷",
    subtitle: "Filter and check, download volumes or create masterbox",
    editVolumes: "Edit Volumes",
    new: "新",
    invoiced: "已开票",
    toInvoice: "待开票",
    canceled: "已取消",
    notCharged: "未收费",
    downloadMasterboxErrors: "下载 Masterbox 错误的 CSV",
    commentDownloadMasterboxErrors:
      "* 只有帶有last_mile_tracking_number的磁碟區才會加入到csv中",
    downloadVolumes: "Download volumes",
    createMasterbox: "Create masterbox",
    wantCreateMasterbox: "您想用这些卷创建一个主运单吗",
    volumes: "卷",
    copyReference_id: "点击这里复制参考编号",
    fields: {
      weight: "重量",
      height: "高度",
      Width: "宽度",
      length: "长度",
      cubedWeight: "立方重量",
      reference: "参考",
      lastMileCarrierNumber: "末端承运商编号",
      lastMileTrackingNumber: "末端追踪编号",
      collectedIcms: "已收取的ICMS",
      collectedIi: "已收取的II",
      orderIsCommercialDestination: "订单为商业目的地",
      isCommercialDestination: "商业目的地",
      orderSellerRfbCode: "订单卖方RFB代码",
      sellerRfbCode: "卖方RFB代码",
      orderSellerLegalName: "订单卖方法定名称",
      sellerLegalName: "卖方法定名称",
      firstMileTrackingNumber: "第一英里追踪号码",
      cN23: "CN23",
      firstMileLabel: "第一英里标签",
      creationDate: "创建日期",
      volumeProductsValue: "卷产品价值",
      volumeFreightValue: "卷运费价值",
      volumeDeclaredValue: "卷申报价值",
      volumeDutiesTaxValue: "卷关税税额",
      volumeSalesTaxValue: "卷销售税额",
      volumeTaxValue: "卷税额",
      volumeTotalValue: "卷总价值",
      volumeEstimatedDutiesTaxValue: "卷预估关税税额",
      volumeEstimatedSalesTaxValue: "卷预估销售税额",
      volumeEstimatedTaxValue: "卷预估税额",
      volumeFreightCost: "卷运费成本",
      volumeEstimatedFreightCost: "卷预估运费成本",
      hSCode: "HS代码",
      description: "描述",
      currentStatus: "当前状态",
      updatedWeight: "更新后重量",
      orderID: "订单ID",
      mSDS: "MSDS",
      sellerID: "卖家ID",
      firstMileN: "第一英里编号",
      contractID: "合同ID",
      customerName: "客户名称",
      cPostalCode: "邮政编码",
      customerCity: "客户城市",
      customerState: "客户州",
      customerAddress: "客户地址",
      customerAddressNumber: "客户地址编号",
      customerAddressComplement: "客户地址补充",
      customerAddressReference: "客户地址参考",
      customerAddressQuarter: "客户地址季度",
      customerDocumentType: "客户文档类型",
      customerDocumentNumber: "客户文档编号",
      customerCountry: "客户国家",
      customerEmail: "客户电子邮件",
      customerPhone: "客户电话",
      currency: "货币",
      estimatedDeliveryDate: "预计交货日期",
      incoterm: "国际贸易术语",
      orderNumber: "订单编号",
      salesChannel: "销售渠道",
      salesChOrderNumber: "销售渠道订单编号",
      totalValue: "总价值",
      orderReference: "订单参考",
      financialStatus: "财务状态",
      orderProductsValue: "订单产品价值",
      orderFreightValue: "订单运费价值",
      orderDeclaredValue: "订单申报价值",
      orderDutiesTaxValue: "订单关税税额",
      orderSalesTaxValue: "订单销售税额",
      orderTaxValue: "订单税额",
      orderTotalValue: "订单总价值",
      orderEstimatedDutiesTaxValue: "订单预估关税税额",
      orderEstimatedSalesTaxValue: "订单预估销售税额",
      orderEstimatedTaxValue: "订单预估税额",
      orderFreightCost: "订单运费成本",
      overpackID: "超包ID",
      overpackSentDate: "超包发送日期",
      overpackReference: "超包参考",
      fMTrackingNumber: "第一英里追踪编号",
      firstMileCompany: "第一英里公司",
      masterboxID: "主运单ID",
      dispatchID: "派遣ID",
      cN35: "CN35",
      cN35Label: "CN35标签",
      shipmentID: "货运ID",
      shipmentCategory: "货运类别",
      cN38: "CN38",
      cN38Label: "CN38标签",
      flightID: "航班ID",
      flightN: "航班编号",
      flightMAWB: "航班MAWB",
      mAWBLabel: "MAWB标签",
      flightReference: "航班参考",
      airline: "航空公司",
      greenLight: "绿灯",
      prealertsSent: "预警发送",
      departureAirport: "出发机场",
      arrivalAirport: "到达机场",
      departureStartDate: "出发起始日期",
    },
    filterOptions: {
      volumeStartDate: "卷起始日期",
      volumeEndDate: "卷结束日期",
      volumeID: "卷ID",
      reference: "参考",
      lastMileTrackingNumber: "末端追踪编号",
      lmtnPopover: "接受以下方式分隔的值：逗号、分号、空格或换行符。",
      withoutLastMileTrackingNumber: "没有末端追踪编号",
      lastMileCarrierNumber: "末端承运商编号",
      estimatedFreightCost: "预估运费成本",
      orderStartDate: "订单起始日期",
      orderEndDate: "订单结束日期",
      orderID: "订单ID",
      contractID: "合同ID",
      sellerID: "卖家ID",
      freightCost: "运费成本",
      firstMileN: "第一英里编号",
      lastMileN: "末端编号",
      orderNumber: "订单编号",
      salesChannel: "销售渠道",
      salesChOrderNumber: "销售渠道订单编号",
      orderReference: "订单参考",
      incoterm: "国际贸易术语",
      customerName: "客户名称",
      customerAddress: "客户地址",
      customerAddressNumber: "客户地址编号",
      customerAddressComplement: "客户地址补充",
      customerAddressReference: "客户地址参考",
      customerAddressQuarter: "客户地址季度",
      customerDocumentType: "客户文档类型",
      customerDocumentNumber: "客户文档编号",
      customerCountry: "客户国家",
      customerEmail: "客户电子邮件",
      customerPhone: "客户电话",
      financialStatus: "财务状态",
      overpackStartDate: "超包起始日期",
      overpackEndDate: "超包结束日期",
      overpackStartSendDate: "超包开始发送日期",
      overpackEndSendDate: "超包结束发送日期",
      overpackID: "超包ID",
      overpackReference: "超包参考",
      bagNumber: "袋编号",
      firstMileTrackingN: "第一英里追踪编号",
      firstMileCarrierName: "第一英里承运商名称",
      masterboxStartDate: "主运单起始日期",
      masterboxEndDate: "主运单结束日期",
      masterboxID: "主运单ID",
      cn35: "CN35",
      dispatchID: "派遣ID",
      shipmentStartDate: "货运起始日期",
      shipmentEndDate: "货运结束日期",
      shipmentID: "货运ID",
      cN38: "CN38",
      shipmentCategory: "货运类别",
      flightStartDate: "航班起始日期",
      flightEndDate: "航班结束日期",
      flightID: "航班ID",
      flightMAWB: "航班MAWB",
      flightN: "航班编号",
      flightreference: "航班参考",
      airline: "航空公司",
      greenLight: "绿灯",
      departureAirport: "出发机场",
      arrivalAirport: "到达机场",
      departureStartDate: "出发起始日期",
      departureEndDate: "出发结束日期",
      orderDate: "訂購日期",
      shipmentDate: "出貨日期",
      flightDate: "航班日期",
      flightDepartureDate: "航班出发日期",
      departureDate: "出發日期",
      masterboxDate: "大師盒日期",
      overpackDate: "外包裝日期",
      overpackSendDate: "超包发送日期",
    },
    downloadFields: {
      weight: "重量",
      height: "高度",
      width: "宽度",
      length: "长度",
      reference: "参考",
      lastMileTrackingNumber: "末端追踪编号",
      creationDate: "创建日期",
      volumeProductsValue: "体积产品价值",
      volumeFreightValue: "体积运费价值",
      volumeDeclaredValue: "体积申报价值",
      volumeDutiesTaxValue: "体积关税税值",
      volumeSalesTaxValue: "体积销售税值",
      volumeTaxValue: "体积税值",
      volumeTotalValue: "体积总价值",
      volumeEstimatedDutiesTaxValue: "体积估计关税税值",
      volumeEstimatedSalesTaxValue: "体积估计销售税值",
      volumeEstimatedTaxValue: "体积估计税值",
      volumeFreightCost: "体积运费成本",
      volumeEstimatedFreightCost: "体积估计运费成本",
      hSCode: "HS 编码",
      description: "描述",
      currentStatus: "当前状态",
      orderID: "订单ID",
      sellerID: "卖家ID",
      contractID: "合同ID",
      customerName: "客户姓名",
      cPostalCode: "客户邮政编码",
      customerCity: "客户城市",
      customerState: "客户州",
      customerAddress: "客户地址",
      customerAddressNumber: "客户地址编号",
      customerAddressComplement: "客户地址补充",
      customerAddressReference: "客户地址参考",
      customerAddressQuarter: "客户地址季度",
      customerDocumentType: "客户文件类型",
      customerDocumentNumber: "客户文件编号",
      customerCountry: "客户国家",
      customerEmail: "客户电子邮件",
      customerPhone: "客户电话",
      currency: "货币",
      freightCost: "运费成本",
      freigtValue: "运费价值",
      estimatedDeliveryDate: "预计交货日期",
      incoterm: "国际贸易术语",
      orderNumber: "订单编号",
      salesChannel: "销售渠道",
      salesChOrderNumber: "销售渠道订单编号",
      totalValue: "总价值",
      orderReference: "订单参考",
      financialStatus: "财务状态",
      overpackID: "超包ID",
      overpackSentDate: "超包发送日期",
      overpackReference: "超包参考",
      fMTrackingNumber: "第一英里追踪编号",
      firstMileCompany: "第一英里公司",
      masterboxID: "主运单ID",
      dispatchID: "派遣ID",
      cN35: "CN35",
      shipmentID: "货运ID",
      shipmentCategory: "货运类别",
      cN38: "CN38",
      flightID: "航班ID",
      flightN: "航班编号",
      mAWBLabel: "MAWB标签",
      flightReference: "航班参考",
      airline: "航空公司",
      greenLight: "绿灯",
      prealertsSent: "预警已发送",
      departureAirport: "出发机场",
      arrivalAirport: "到达机场",
      departureStartDate: "出发开始日期",
    },
    financialStatuses: {
      nNew: "新订单",
      toInvoice: "待开发票",
      invoiced: "已开发票",
      canceled: "已取消",
      notCharged: "未收费",
    },
  },
  volumesViewPage: {
    copyReference: "Copy reference",
    goToOrder: "Go to order",
    summaryValues: "Summary of values",
    trackingHistory: "Tracking history",
    editLastTrackingNumber: "Edit Last Mile Tracking Number",
    warningOnUpdateLastMileTrackingNumber:
      "Attention! This action cannot be undone.",
    title: "体积 {id}",
    label: "标签",
    cubed_weight: "立方重量",
    packingSlip: "装箱单",
    commercialInvoice: "商业发票",
    valuesSummary: "值摘要",
    updatedWeight: "更新后重量",
    items: "物品",
    trackinghistory: "跟踪历史",
    pages: "页面",
    item: "项目",
    height: "高度",
    heightbycurrier: "承运人高度",
    width: "宽度",
    widthbycurrier: "承运人宽度",
    weight: "重量",
    weightbycurrier: "承运人重量",
    length: "长度",
    lengthbycurrier: "承运人长度",
    orderID: "订单ID",
    creationDate: "创建日期",
    currentStatus: "当前状态",
    reference: "参考",
    lastMileCarrierNumber: "末端运输承运人编号",
    lastMileTrackingNumber: "末端运输追踪号码",
    declaredValue: "申报价值",
    taxValue: "税值",
    total: "总计",
    estimatedTaxValue: "预计税值",
    freightCost: "运费成本",
    estimatedFreightCost: "预计运费成本",
    name: "名称",
    value: "价值",
    hsCode: "海关编码",
    quantity: "数量",
    originCountry: "原产国",
    decsription: "描述",
    valueWithTax: "含税价值",
    fields: {
      name: "名称",
      quantity: "数量",
      hsCode: "海关编码",
      originCountry: "原产国",
      productValue: "产品价值",
      freightValue: "运费价值",
      taxValue: "税值",
    },
    checkpointFields: {
      title: "标题",
      statusCode: "状态码",
      dateISO: "日期ISO",
      message: "消息",
      trackingFunction: "跟踪功能",
    },
    dirNumber: "DIR 编号",
    seePayments: "查看付款",

    goToSeller: "前往賣家",
    goToContract: "前往合約",
    goToMasterbox: "前往主箱",
    goToFlight: "前往航班",
    goToTracking: "前往追蹤",
    goToVolumeReverse: "前往退貨",
    seeTrackingAlertLogs: "查看追蹤警報日誌",
    generalInformations: "一般資訊",
    generalInformationsDescription: "查看主要設定",
    landedCost: "到岸成本",
    landedCostDescription: "表示所有創建時的數值已包含稅金和運費",
    dispatched: "已發貨",
    dispatchedDescription: "發貨後，訂單準備好進入流程，獲取追蹤狀態等。",
    senderIsCpf: "寄件人是CPF",
    invoice: "發票",
    receiverIsCommercial: "收件人是商業用途",
    lastMileLabel: "最後一公里標籤",
    numbersAndReferences: "編號與參考",
    numbersAndReferencesDescription: "您可能需要的所有編號",
    referenceDescription: "由Nobordist生成的唯一體積標識符",
    lastMileTrackingNumberDescription: "用於最後一公里追蹤的編號",
    firstMileTrackingNumberDescription: "用於第一公里追蹤的編號",
    lastMileCarrierNumberDescription: "承運商系統中的體積識別號",
    orderNumberDescription: "賣家選擇的訂單編號",
    salesChannelOrderNumberDescription: "銷售渠道中的訂單編號",
    orderReferenceDescription: "由Nobordist生成的唯一訂單標識符",
    dirNumberDescription: "用於支付關稅",
    internalNBID: "內部NB ID",
    internalNBIDDescription: "用於內部操作",
    dimensions: "尺寸",
    dimensionsDescription: "重量與尺寸",
    customer: "客戶",
    nameAddressContactInfo: "姓名、地址和聯繫資訊",
    shipper: "托運人",
    rfbCodeDescription: "巴西國家稅務局系統中的賣家識別碼，允許創建PRC訂單",
    itemsDescription: "物品清單及其資訊",
    seeDimensions: "查看尺寸",
    seeCosts: "查看成本",
    costs: "成本",
    seeFullDescription: "查看完整描述",
    itemDescription: "物品描述",
    costsDescription: "成本和價值的完整描述",
    relatedObjects: "相關對象",
    relatedObjectsDescription: "查找相關賣家、合約、航班...",
    trackingAlertLog: "追蹤警報日誌",
    seeLogs: "查看日誌",
    volumeReverse: "退貨",
    seeVolumeReverse: "查看退貨",
    additionalData: "額外資料",
    additionalDataDescription: "可能有用的選擇性資料",
    freight: "運費",
    freightValue: "運費金額",
    informedBySeller: "由賣家提供",
    estimatedFreightCostDescription: "從價格表估算",
    freightCostDescription:
      "當前運費成本，來自估算的運費成本，但後期可能有所更新",
    estimatedTaxes: "估算的稅款",
    computedByNbSystem: "由NB系統計算",
    dutiesTax: "進口稅",
    dutiesTaxDescription: "國家稅 (I.I.)",
    salesTax: "銷售稅",
    salesTaxDescription: "州稅 (ICMS)",
    totalTax: "稅款總額",
    actualTaxes: "實際稅款",
    summary: "摘要",
    productsValue: "產品價值",
    totalValue: "總價值",
    prcWarningDescription: "若為PRC體積，這些是賣家已收取的稅款",
    exchangeOnCreation: "創建時的匯率",
    generalDescription: "一般描述",
    uniqueExpressionToDescribeItems: "描述體積中所有物品的唯一表達式",
    observations: "觀察",
    freeFieldCreatedBySeller: "由賣家創建的自由欄位",
    integrationData: "整合資料",
    updatedWeightDescription: "發貨後由承運商驗證的重量",
    cubedWeightDescription: "透過將3個尺寸乘以厘米數並除以6000來計算",
    withoutMAWBNumber: "無MAWB編號",
    products: "產品",
    trackingHistoryDescription: "查看所有跟踪状态",
    provider: "供应商",
    providerStatus: "供应商状态",
    providerMessage: "供应商消息",
  },
  carriersPage: {
    title: "承运商管理",
    subtitle: "添加和管理您的承运商及其相应的交付方式。",
    legalName: "法定名称",
    rfbCode: "RFB 代码",
    country: "国家",
    addNewCarrier: "添加新承运商",
    addCarrier: "添加承运商",
    allowed: "允许",
    blocked: "阻止",
    addnewCarrier: "添加新承运商",
    editCarrier: "编辑承运商",
    name: "名称",
    address: "地址",
    addressnumber: "地址编号",
    zipcode: "邮政编码",
    state: "州",
    city: "城市",
    phone: "电话",
    site: "网站",
    email: "电子邮件",
    taxnumber: "税务识别号",
    namereference: "参考名称",
    slug: "短标识",
    trackstatusfunction: "状态跟踪功能",
    carrierCode: "承运商代码",
    addressComplement: "地址补充",
    airportHub: "机场中心",
    carrierIsSeller: "This carrier is also a seller",
    sellerReminder: "Don't forget to change the seller information",
    importCarrier: "Import Carrier from JSON",
    downloadCarrierSettings:
      "Download Carrier, Delivery Methods and Users Settings",
    sellers: "Sellers",
    sellerSelect: "Fill with Seller data",
    fields: {
      name: "名称",
      slug: "短标识",
      city: "城市",
      country: "国家",
      email: "电子邮件",
      carrierCode: "承运商代码",
      trackFunction: "跟踪功能",
      creationDate: "创建日期",
      isSeller: "是否卖家？",
    },
    generalData: "常规数据",
    addCarrierDetails: "添加承运商详细信息",
    carrierAddress: "承运商地址",
    addCarrierOriginAddress: "添加承运商起始地址",
    newCarrier: "新的承运商",
    addDeliveryMethod: "添加交付方式",
    noDeliveryMethodFound: "未找到交付方式",
    noUserFound: "未找到用户",
    deliveryMethods: "交付方式",
    users: "用户",
    carriers: "承运商",
    deliveryMethodFields: {
      name: "添加的方法",
      type: "类型",
      service: "服务",
      originCountry: "起始国家",
      destinationCountry: "目的国家",
    },
    userFields: {
      name: "名称",
      email: "电子邮件",
      groupName: "组名",
      status: "状态",
    },
    seeMethod: "查看方法",
    seeUser: "查看用户",
    airportHubHelpText: "承運人運營的機場的3字母IATA代碼",
    rfbCodeHelpText: "在巴西國家稅務局的承運人標識符",
    isSellerHelpText: "表明該企業也作為賣方運營",
    importFromJsonHelpText:
      "此部分允許您從另一個環境重新創建賣家。例子：您在測試環境中創建了一個賣家，並希望在生產環境中創建相同的賣家。在這種情況下，只需在測試環境的賣家頁面中點擊“下載賣家和用戶設置”，然後在此處導入下載的json文件。",
    fillWithSellerDataHelpText:
      "您可以使用已創建的賣家預填寫信息。只需選擇賣家並點擊“填充”。請放心，您可以在完成創建之前更改某些字段。",
    fillWithSellerData: "使用賣家數據填充",
  },
  carriersViewPage: {
    title: "承运商 {name}",
    deliverymethods: "交付方式",
    nodeliverymethodfoundfor: "未找到交付方式",
    users: "用户",
    nouserfoundfor: "未找到用户",
    editCarrier: "编辑承运商",
    updateAirportHub: "更新机场中心",
    airportHub: "机场中心",
    name: "名称",
    address: "地址",
    addressnumber: "地址编号",
    addresscomplement: "地址补充",
    zipcode: "邮政编码",
    state: "州",
    city: "城市",
    phone: "电话",
    site: "网站",
    email: "电子邮件",
    taxnumber: "税号",
    namereference: "名称参考",
    slug: "短标识",
    trackstatusfunction: "跟踪状态功能",
    carrierCode: "承运商代码",
    country: "国家",
    taxNumber: "税号",
    trackingfunction: "跟踪功能",
    platform: "平台",
    subsidiary: "子公司",
    deliveryCenter: "交付中心",
    createdDate: "创建日期",
    activityTypes: "活动类型",
    deliveryMethodFields: {
      name: "名称",
      type: "类型",
      service: "服务",
      originCountry: "起始国家",
      destinationCountry: "目的国家",
    },
    userFields: {
      name: "名称",
      email: "电子邮件",
      groupName: "组名",
    },
    seller: "卖家",
    thisCarrierIsSeller: "该承运商是卖家",
    addDeliveryMethodToThisCarrier: "將交付方法添加到此承運人",
    addUserToThisCarrier: "將用戶新增至該運營商",
  },
  groupsPage: {
    title: "组管理",
    fields: {
      name: "名称",
    },
  },
  groupsViewPage: {
    title: "组 #",
    permissions: "权限",
  },
  permissionsPage: {
    title: "权限",
    fields: {
      name: "名称",
      description: "描述",
      groupsName: "组名",
    },
  },
  permissionsViewPage: {
    title: "权限 #",
    name: "名称",
    description: "描述",
  },
  saldoViewPage: {
    currentBalance: "當前餘額",
    events: "事件",
    fields: {
      category: "類別",
      eventType: "事件類型",
      value: "數值",
      description: "描述",
      paid: "已支付",
    },
  },
  sellersPage: {
    title: "卖家管理",
    legalName: "法定名称",
    rfbCode: "RFB 代码",
    addNewSeller: "添加新卖家",
    importSeller: "从 JSON 导入卖家",
    downloadSellers: "下载卖家",
    processingDownloads: "检查处理下载，请前往...下载页面",
    addseller: "添加卖家",
    address: "地址",
    addressnumber: "地址编号",
    addresscomplement: "地址补充",
    city: "城市",
    state: "州",
    postalcode: "邮政编码",
    name: "名称",
    phone: "电话",
    email: "电子邮件",
    site: "网站",
    taxnumber: "税号",
    signature: "签名",
    billingEmail: "结算电子邮件",
    responsableContact: "负责联系人",
    allowedcontracts: "允许的合同",
    contractOperations: "合同操作",
    issubsidiary: "是子公司",
    isdistributioncenter: "是分销中心",
    ismultiple: "是多个",
    country: "国家",
    carriers: "Carriers",
    carrierSelect: "Fill with Carrier data",
    fields: {
      name: "名称",
      email: "电子邮件",
      city: "城市",
      country: "国家",
      ismultiple: "是多个",
      site: "网站",
      billingEmail: "结算电子邮件",
      responsableContact: "负责联系人",
      creationDate: "创建日期",
      currentBalance: "当前余额",
      isCarrier: "是承运商？",
      zipcode: "邮政编码",
    },
    multiples: "倍數",
    simples: "簡單",
    importSellerJsonPopup:
      "此部分可讓您從另一個環境重新建立賣家。範例：您已在同源環境中建立了一個賣家，並希望在生產環境中建立完全相同的賣家。此時，您只需在同源環境下的賣家頁面點擊“下載賣家和使用者設定”，並在此處匯入下載的json檔案即可。",
    balance: "当前余额",
    fillWithCarrierPopup: `您可以使用已建立的運營商預先填寫資訊。只需選擇運營商並點擊“填寫”即可。不用擔心，您將能夠在完成建立之前更改某些欄位。`,
    contractsPopup: `您已經可以允許該賣家進行一些聯繫。如果您不想這樣做，請知道，不用擔心！您稍後可以隨時進行`,
    startValidityDate: "開始有效日期",
    endValidityDate: "結束有效日期",
    downloadUserSettingsPopup:
      "这将生成一个 JSON 文档，您可以使用该文档将此卖家复制到另一个环境",
    languagePreferred: "首選語言",
    measurementSystem: "測量系統",
    validateCpf: "驗證所有訂單上的客戶CPF",
    seeSellerVolumes: "查看賣家數量",
    seeSellerOrders: "查看賣家訂單",
  },
  sellersViewPage: {
    chooseSendRequest: "请选择您要发送请求的对象：",
    website: "网站",
    legalName: "法定名称",
    rfbCode: "RFB 代码",
    taxNumber: "税号",
    signature: "签名",
    isSubsidiary: "是子公司",
    isDistributionCenter: "是分销中心",
    sellerID: "卖家 ID",
    currentBalance: "当前余额",
    seller: "卖家",
    name: "名称",
    email: "电子邮件",
    phone: "电话",
    downloadSellerSettings: "Download Seller and Users Settings",
    sellerIsCarrier: "This seller is also a carrier",
    carrierReminder: "Don't forget to change the carrier information",
    ismultiple: "是多个",
    allowedContracts: "允许的合同",
    contractOperations: "合同操作",
    noContractFound: "未找到合同",
    creationDate: "创建日期",
    contractLink: "合同链接",
    notFound: "未找到",
    billingEmail: "结算电子邮件",
    responsableContact: "负责联系人",
    platform: "平台",
    address: "地址",
    country: "国家",
    postalCode: "邮政编码",
    state: "州",
    devolutionAddress: "退货地址",
    addressNumber: "地址编号",
    addressQuarter: "地址区",
    addressComplement: "地址补充",
    addressReference: "地址参考",
    city: "城市",
    devolutionEmail: "退货电子邮件",
    useSellerDevolutionAddress: "使用卖家退货地址",
    users: "用户",
    active: "活跃",
    blocked: "已阻止",
    clear: "清除",
    noUserFoundFor: "未找到用户",
    includeLogo: "包括标志",
    wantAllowContract: "您要允许合同吗",
    forSeller: "给卖家",
    needsSupervisorApproval: "此操作需要监督员的批准",
    wantForbidContract: "您要禁止合同吗",
    fields: {
      fullName: "全名",
      email: "电子邮件",
      status: "状态",
    },
    carrier: "承运商",
    thisSellerIsCarrier: "此卖家是承运商",
    accessWebsite: "造訪網站",
    understandCurrentBalance: "了解當前餘額",
    startValidityDate: "開始有效日期",
    endValidityDate: "結束有效日期",
  },
  usersPage: {
    title: "用户",
    subtitle: "查看并添加新用户到系统",
    personalData: "个人资料",
    seller: "卖家",
    carrier: "承运商",
    downloadUsers: "下载报告",
    allowed: "允许",
    blocked: "已阻止",
    active: "活跃",
    denerateAPIToken: "生成 API 令牌",
    tokenCopiedSuccesfully: "令牌已成功复制！",
    yourTokenWasCopiedToClipboard: "您的令牌已复制到剪贴板，可以使用了。",
    processingDownloads: "检查处理下载，请前往...下载页面",
    allUsers: "所有用户",
    addNewUser: "添加新用户",
    name: "名称",
    email: "电子邮件",
    phone: "电话",
    whatsappNumber: "WhatsApp 号码",
    profile: "个人资料",
    group: "组",
    selectGroup: "选择组",
    aPIToken: "API 令牌",
    permissionsAndAccess: "权限和访问",
    userStatus: "用户状态",
    editUser: "编辑用户",
    fields: {
      name: "名称",
      carrierID: "承运商",
      sellerID: "卖家",
      company: "公司",
      status: "状态",
      email: "电子邮件",
      profile: "个人资料",
      group: "组",
      creationDate: "创建日期",
      aPIToken: "API 令牌",
      supervisor: "導師",
    },
    insertUserData: "插入您要创建的用户的数据。",
    archive: "存档",
    archiveUser: "存档用户？",
    confirmArchiveUser: "您确定要存档用户吗？此操作无法撤消。",
    yeahArchiveUser: "是的，存档用户",
    confirmBlockUser:
      "該用戶將立即失去對網站和 API 的存取權。此操作可以隨時撤銷",
    confirmUnblockUser:
      "該用戶將立即被授予存取網站和 API 的權限。此操作可以隨時撤銷",
    confirmRemoveUser: "您確實要刪除該用戶嗎？此操作無法撤銷",
    profileHelp:
      "管理員使用者將有權訪問該網站並管理帳戶、航班、日誌、財務資料...賣家使用者將有權訪問賣家網站並建立訂單和產生標籤。營運商用戶將管理 masterbox 和航班",
    groupHelp:
      "管理員擁有帳戶的最大權限，可以對貨件或使用者執行任何操作，包括刪除、建立或封鎖其他使用者。操作權限基本上相同，除非建立、封鎖或刪除其他使用者。查看者只能查看數據，但不允許建立、更新或刪除任何內容",
  },
  usersViewPage: {
    title: "用户资料",
    blocked: "已阻止",
    allowed: "允许",
    blockUser: "阻止此用户",
    unblockUser: "解除阻止此用户",
    archiveUser: "存档此用户",
    unarchiveUser: "取消存档此用户",
    company: "公司",
    editUserProfile: "编辑用户资料",
    name: "名称",
    email: "电子邮件",
    phone: "电话",
    whatsappNumber: "WhatsApp 号码",
    group: "组",
    selectgroup: "选择组",
    profile: "个人资料",
    seller: "卖家",
    carrier: "承运商",
    phoneNumber: "电话号码",
    createdDate: "创建日期",
    sellerID: "卖家 ID",
    carrierID: "承运商 ID",
    platform: "平台",
    resetUserPassword: "重置用户密码",
    resetUserPasswordWarning:
      "注意！一旦更新用户 {user} 的密码，其当前密码将立即失效，无法访问网站和 APP。",
  },
  dutiesAndTaxesStatusPage: {
    title: "关税和税收状态",
    subTitle:
      "在此頁面上，您可以查看已完成的訂單的關稅付款並將數據提取為 CSV 文件",
    dutiesTaxes: "Duties and Taxes",
    importValuesandStatuses: "导入值和状态",
    updateStatus: "更新状态",
    download: "下载",
    new: "新",
    solved: "已解决",
    priority: "优先级",
    inProgress: "进行中",
    waitingforPayment: "等待付款",
    sentAgain: "再次发送",
    paymentDone: "已付款",
    pending: "待定",
    paidExternally: "外部已支付",
    needSellerAction: "需要卖家操作",
    status: "状态",
    comment: "评论",
    checktheprocessingdownloads: "查看处理下载，请前往...下载页面",
    sureWantSave: "确定要保存更改吗",
    newTrackingNumbers: "新跟踪号码",
    shouldbeCommaSeparated:
      "新号码应以逗号分隔，并按照所选的 Tracking Alert Logs 序列：TAL_IDs：",
    unsaveddata: "未保存的数据",
    pleasesaveunsaveddata: "请保存未保存的数据或取消",
    okay: "好的",
    name: "名称",
    browseFilesUpload: "浏览文件并上传您的文件",
    selectcsvFileUpload: "选择要上传的 csv 文件",
    downloadHere: "在此下载",
    instructionsXLSfile: "说明 XLS 文件",
    fileUploaded: "文件已上传",
    blankTable: "一个空白表格",
    fields: {
      id: "NB内部付款参考",
      sellerName: "卖家名称",
      sellerId: "卖家标识",

      orderNumber: "订单号",
      orderCreatedAt: "订单建立日期",
      orderCreatedAtGte: "开始订单建立日期",
      orderCreatedAtLte: "结束订单建立日期",

      volumeId: "卷标识",
      volumeLastMileTrackingNumber: "末程跟踪号",
      orderEstimatedTaxValue: "预计关税和税金总额",
      orderEstimatedTaxValueGte: "开始预计关税和税金总额",
      orderEstimatedTaxValueLte: "结束预计关税和税金总额",
      orderEstimatedDutiesTaxValue: "预计关税 II",
      orderEstimatedDutiesTaxValueGte: "开始预计关税 II",
      orderEstimatedDutiesTaxValueLte: "结束预计关税 II",
      orderEstimatedSalesTaxValue: "预计税款 ICMS",
      orderEstimatedSalesTaxValueGte: "开始预计税款 ICMS",
      orderEstimatedSalesTaxValueLte: "结束预计税款 ICMS",

      currentStatusId: "状态",
      currentStatusTitle: "跟踪状态",
      currentStatusTrackingCode: "跟踪代码",
      currentStatusTrackingCodeGte: "开始跟踪代码",
      currentStatusTrackingCodeLte: "结束跟踪代码",

      createdAt: "海关开始日期",
      createdAtGte: "开始海关开始日期",
      createdAtLte: "结束海关开始日期",

      statusPaymentDoneAt: "付款日期",
      statusPaymentDoneAtGte: "开始付款日期",
      statusPaymentDoneAtLte: "结束付款日期",
      status: "支付状态",
      paymentCertificate: "付款证书",

      realTax: "已付关税和税款总额",
      realTaxGte: "开始已付关税和税款总额",
      realTaxLte: "结束已付关税和税款总额",

      exchangeRate: "汇率",
      seller: "卖家",
    },
  },
  dutiesAndTaxesStatusViewPage: {
    title: "關稅和稅收狀況 #",
    sellerChargedAmount: "卖家已收金额",
    exchange: "汇率",
    incoterm: "国际贸易术语",
    date: "日期",
    dateIso: "ISO 日期",
    orderID: "订单编号",
    volumeID: "卷编号",
    sellerID: "卖家编号",
    taxID: "税收编号",
    lastMileNumber: "末程号码",
    productsValue: "产品价值",
    freightValue: "货运价值",
    expectedTax: "预计税款",
    valueR$: "价值 R$",

    id: "NB內部付款參考",
    sellerName: "卖家名称",
    sellerId: "卖家标识",

    orderNumber: "订单号",
    orderCreatedAt: "訂單建立日期",
    orderCreatedAtGte: "開始訂單建立日期",
    orderCreatedAtLte: "結尾訂單建立日期",

    volumeId: "卷标识",
    volumeLastMileTrackingNumber: "末程跟踪号",
    orderEstimatedTaxValue: "預計關稅和稅金總額",
    orderEstimatedTaxValueGte: "開始預計關稅和稅金總額",
    orderEstimatedTaxValueLte: "結尾預計關稅和稅金總額",
    orderEstimatedDutiesTaxValue: "预计关税 II",
    orderEstimatedDutiesTaxValueGte: "開始预计关税 II",
    orderEstimatedDutiesTaxValueLte: "結尾预计关税 II",
    orderEstimatedSalesTaxValue: "预计税款 ICMS",
    orderEstimatedSalesTaxValueGte: "開始预计税款 ICMS",
    orderEstimatedSalesTaxValueLte: "結尾预计税款 ICMS",

    currentStatusId: "状态",
    currentStatusTitle: "跟踪状态",
    currentStatusTrackingCode: "跟踪代码",
    currentStatusTrackingCodeGte: "開始跟踪代码",
    currentStatusTrackingCodeLte: "結尾跟踪代码",

    createdAt: "海關開始日期",
    createdAtGte: "開始海關開始日期",
    createdAtLte: "結尾海關開始日期",

    statusPaymentDoneAt: "付款日期",
    statusPaymentDoneAtGte: "開始付款日期",
    statusPaymentDoneAtLte: "開始付款日期",

    status: "支付狀態",
    paymentCertificate: "付款证书",

    realTax: "已付关税和税款总额",
    realTaxGte: "開始已付关税和税款总额",
    realTaxLte: "結尾已付关税和税款总额",

    exchangeRate: "汇率",
  },
  accessibilitySettingsPage: {
    subtitle: "配置排版、首選項、語言等。",
    typography: "版式",
    textPreferenceDescription: "在下面設定您的文字首選項。",
    textSize: "字體大小",
    language: "語言",
    mainLanguageDescription: "定義平台的主要語言。",
    selectLanguage: "選擇語言",
    changeUnitsText: "更改重量和測量單位",
    stillHavingDifficulties: "仍有困難嗎？",
    weWantToHelpYou: "我們想幫助您！ 以您喜歡的任何方式聯絡我們",
    contactViaPhone: "電話聯繫",
    contactViaEmail: "透過電子郵件聯繫",
    contactViaChat: "透過聊天聯繫",
  },
  searchHsCodePage: {
    title: "搜索 HS 编码",
    subtitle: "您想知道 HS 编码或该产品是否获得许可吗？ 立即搜索!",
    code: "输入代码",
    category: "类别",
    noHscode: "找不到记录.",
    tooltip:
      "Hs 代码或 NCM 是一个 6 或 8 位数字的代码，用于标识要进口的产品类型。 此页面可让您查看是否允许特定代码进入巴西",
  },
  requestsPage: {
    title: "要求",
    subTitle: "使用您的 ID 检查并跟踪请求状态",
    requestId: "要求 ID",
    asynchronousRequests: "异步请求",
    result: "结果",
  },
  shippingRulesPage: {
    title: "运输规则",
    subtitle:
      "在此頁面上，您可以定義運送規則，這些規則將允許您根據您將配置的某些觸發器對訂單套用一些操作",
    addNewRule: "添加新规则",
    seller: "卖家",
    sellers: "卖家",
    name: "规则名称",
    expiration: "有效期",
    condition: "条件",
    conditions: "条件",
    options: "选项",
    operator: "操作符",
    value: "数值",
    selectMultipleSellers: "选择一个或多个卖家",
    createConditionTitle: "添加新规则",
    createCondition:
      "選擇您的貨件需要滿足的條件，然後按一下「新增條件」以驗證您的選擇。您可以選擇多個條件，為了使規則適用，必須驗證所有條件”",
    addCondition: "添加条件",
    createActionTitle: "操作",
    createAction:
      '在此字段中，您将根据条件选择要执行的操作。例如，对于条件"所有订单"，您可以选择"始终从位置X发货"',
    is_active: "激活",
    is_active_helptext: "如果您想要创建规则但现在不应用它，只需取消选中此字段",
    freightValue: "运费价值",
    priority: "优先级",
    destinationCountry: "目的国家",
    destinationStates: "目的州",
    rulePriorityOptions: {
      veryHigh: "非常高",
      high: "高",
      medium: "中",
      low: "低",
      veryLow: "非常低",
    },
    dateFieldHelpText:
      "在此日期之後，規則將不再適用，但如果您希望稍後延長有效期，它仍將可在此頁面中使用",
    priorityFieldHelpText:
      "如果多個規則之間存在衝突，優先級將指示哪個規則優先於其他規則",
    sellersFieldHelpText: "選擇將受到規則影響的賣家",
    activeFieldHelpText: "如果未啟用，規則將被創建，但在您啟用之前不會應用",
    addNewCondition: "新增條件",
    freightValueActionDescription:
      "訂單的運費價值將始終從運費獲取（透過價格表計算）",
    fields: {
      priority: "優先級",
      name: "名稱",
      sellers: "賣家",
      expiration: "到期",
      condition: "條件",
      action: "操作",
      isActive: "是否啟用？",
      actions: "操作",
    },
  },
  fiscalPaymentsPage: {
    title: "财政支付",
    fiscalInfo: "财政信息",
    fiscalParcelData: "财政包裹数据",
    customerData: "客户数据",
    fields: {
      reference_number: "参考号",
      barcode: "条形码",
      authentication: "认证",
      status: "状态",
      payment_type: "支付类型",
      value: "金额",
      fiscal_parcel_id: "财政包裹编号",
      payment_date: "支付日期",
      created_at: "创建日期",
      dir_number: "DIR 号码",
      customer_name: "客户名称",
      customer_cpf: "客户 CPF",
      customer_cep: "客户 CEP",
      tracking_number: "跟踪编号",
      products_description: "产品描述",
      customer_state: "客户州",
      seller_id: "卖家编号",
      dir_date: "DIR 日期",
      start_payment_date: "开始支付日期",
      end_payment_date: "结束支付日期",
      start_created_at: "开始创建日期",
      end_created_at: "结束创建日期",
      start_value: "开始金额",
      end_value: "结束金额",
      start_dir_date: "开始 DIR 日期",
      end_dir_date: "结束 DIR 日期",
      boletoGNRE: "GNRE票据",
      paymentDate: "支付日期",
      masterNumber: "Master Number",
      messages: "消息",
      courrierName: "Courrier Name",
      courrierCNPJ: "Courrier CNPJ",
    },
    status: {
      processing: "处理中",
      processed: "已处理",
      error: "错误",
    },
    downloadBoletoGNRE: "下載門票",
  },
  notFoundPage: {
    pageNotFound: "找不到網頁",
    goToHome: "去首頁",
  },
  trackingRuleNotificationsPage: {
    title: "追蹤通知規則",
    addConfig: "添加配置",
    createRule: "創建規則",
    giveRuleName: "為您的規則命名",
    ruleName: "規則名稱",
    applyRuleTo: "您想將規則應用於：",
    allShipments: "所有出貨",
    someShipmentsOnly: "僅部分出貨",
    chooseSellers: "首先，選擇將使用這些規則的賣家",
    WhenNewSellersIsCreated: "創建新賣家時，您想做什麼",
    addThisRuleAutomatically: "自動添加到此規則",
    addManually: "不要將其添加到此規則，如果需要，將手動添加",
    notifications: "通知",
    conditions: "條件",
    wouldYouLikeToRemoveRule: "您是否想要移除規則",
    wouldYouLikeToDisableRule: "您是否想要禁用規則",
    wouldYouLikeToEnableRule: "您是否想要啟用規則",
    removalIsFinal: "移除是不可逆的",
    disableRuleMessage:
      "該規則將保存在系統中，但不會應用於任何訂單，並立即生效。 這隨時可以撤銷。",
    enableRuleMessage: "該規則將立即應用於相關訂單。 這隨時可以撤銷。",
    sellersPermission: "賣家權限",
    sellersThatCanConfigureTheirOwnRules: "可以配置自己規則的賣家",
    sellersThatCanConfigureTheirOwnRulesDescription:
      "對於這些賣家，這裡創建的任何規則都不會適用，並且他們將能夠配置自己的規則",
    sellersThatWillUseRulesConfiguredHere: "將使用此處配置規則的賣家",
    sellersThatWillUseRulesConfiguredHereDescription:
      "這些賣家將無法配置自己的規則",
    createOrUseExistingRule: "您想創建或使用現有規則嗎？",
    createFromScratch: "從頭開始創建",
    useExistingRule: "使用現有規則作為預設配置",
    selectRuleToUse: "選擇您要使用的規則",
    defineNotificationRules: "為檢查點組定義通知規則",
    allSelected: "全選",
    selectAll: "全選",
    onSellerCreation: "創建新賣家時該怎麼做？",
    onContractCreation: "創建新合同時該怎麼做？",
    toEditSellersList: "要編輯賣家列表，您需要選擇：",
    toEditContractsList: "要編輯合同列表，您需要選擇：",
    autoAddRule: "自動添加到此規則",
    notAddThisRule: "不要將其添加到此規則，如果需要，將手動添加",
    searchByNameOrId: "搜索：按名稱或ID查找",
    noItemsSelected: "未選擇任何項目",
    selectedItems: "選中的項目",
    chooseContracts: "現在，選擇您希望將規則應用於以下合同",
    allShipmentWarning: "您即將創建適用於所有出貨的規則",
    keepEditing: "继续编辑",
    goIt: "明白了",
    wantToCreateRule: "您想创建配置的规则吗？",
    totalSellersLinked: "与此规则关联的卖家总数",
    totalContractsLinked: "与此规则关联的合同总数",
    allSellersIncluded: "所有卖家都已包含在此规则中，包括稍后将添加的新卖家。",
    allContractsIncluded:
      "所有合同都已包含在此规则中，包括稍后将添加的新合同。",
    almostThere: "快到了..",
    ruleCreatedSuccessfully: "规则创建成功！",
    youWantActivate: "目前已禁用。您现在要启用此规则吗？",
    maybeLater: "也许以后",
    notifsConfigured: "已配置 {total} 个通知",
    allSellers: "所有卖家",
    allContracts: "所有合同",
    totalSellers: "{total} 个卖家",
    totalContracts: "{total} 个合同",
    updateRule: "更新规则",
    wantToUpdateRule: "您想更新配置的规则吗？",
    ruleUpdatedSuccessfully: "规则更新成功！",
    activateUpdatedRule:
      "該規則已成功更新，並因安全性原因已停用。您想立即啟用該規則嗎？",
    noSellerSelected: "未選擇賣家",
    noContractSelected: "未選擇合約",
    sellersSelected: "已選擇賣家",
    contractsSelected: "已選擇合約",
    youDontHaveSeller:
      "您還沒有任何賣家。要配置規則，您必須先創建您的第一個賣家",
    youDontHaveContractAllowed:
      "這些賣家還沒有任何合約。您必須先允許這些賣家一些合約",
    goToSellersPage: "前往卖家页面",
    gotToContractsPage: "前往合同页面",
    ruleNameAlreadyExists: "提供的規則名稱已存在",
  },
};
