<template>
  <nav class="top-bar">
    <div class="d-flex align-items-center">
      <NbSearchRoute />
      <AutoSaveDisplay class="ml-3" />
    </div>
    <div class="items-left">
      <div v-if="user.is_super" class="d-inline-flex">
        <platform-select />
      </div>

      <div class="dropdown">
        <button
          class="btn-dropdown"
          type="button"
          id="dropdownMenuUser"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ user.name }}
        </button>
        <div class="dropdown-menu user-menu dropdown-menu-right text-center">
          <router-link
            :to="'/user_profile'"
            class="dropdown-item text-top-bar"
            >{{ $t("components.topBar.myProfile") }}</router-link
          >
          <router-link
            :to="'/notifications/user_notifications'"
            class="dropdown-item text-top-bar"
            >{{ $t("components.topBar.myNotifications") }}
          </router-link>
          <Cannot :profiles="[Profile.CARRIER]">
            <router-link
              :to="'/settings/printing_setting'"
              class="dropdown-item text-top-bar"
              >{{ $t("components.topBar.printingSetting") }}</router-link
            >
          </Cannot>
          <button class="dropdown-item text-top-bar" @click="logout">
            <i class="fas fa-sign-out-alt"></i> &nbsp; {{ $t("logout") }}
          </button>
        </div>
      </div>

      <NbNotificationsBtn
        id="notifications-btn"
        :options="[]"
        optionsWidth="345"
        expandTo="right"
        size="sm"
      >
        <NbIcon icon="bell" />
      </NbNotificationsBtn>
      <LangButton />
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import PlatformSelect from "@/components/PlatformSelect";
import LangButton from "@/components/buttons/LangButton.vue";
import NbNotificationsBtn from "./buttons/NbNotificationsBtn.vue";
import NbSearchRoute from "@/components/input/text/NbSearchRoute.vue";
import NbIcon from "./icons/NbIcon.vue";
import Cannot from "./Cannot.vue";
import { Profile } from "../constants/constants";
import AutoSaveDisplay from "./AutoSaveDisplay.vue";
import { cancellAllRequests } from "../services/HttpService";

export default {
  name: "TopBar",
  components: {
    PlatformSelect,
    LangButton,
    NbNotificationsBtn,
    NbSearchRoute,
    NbIcon,
    Cannot,
    AutoSaveDisplay,
  },
  data() {
    return {
      Profile,
    };
  },
  computed: {
    ...mapState("config", {
      fullScreen: (state) => state.fullScreen,
    }),
    ...mapState({
      user: (state) => state.user,
      site: (state) => state.site,
    }),
    isVisible() {
      let classVisible = "";
      if (this.fullScreen) {
        classVisible = "top-menu-full-screen";
      } else {
        classVisible = "top-menu-split-screen";
      }
      return classVisible;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("columns_configuration"); //force reset columns_configuration
      this.$store.commit("DELETE_LOGGEDIN_USER");
      let prefix = localStorage.getItem("platform");

      if (!prefix) {
        const url = window.location.host;
        const urlPlatform = url.split(".")[0];
        prefix = urlPlatform.toLowerCase().includes("total")
          ? "total"
          : urlPlatform.toLowerCase().includes("asia")
            ? "asia"
            : urlPlatform.toLowerCase().includes("e-cross")
              ? "e-cross"
              : urlPlatform.toLowerCase().includes("anjun")
                ? "anjun"
                : urlPlatform.toLowerCase().includes("tiendamia")
                  ? "tiendamia"
                  : urlPlatform.toLowerCase().includes("mirakl")
                    ? "mirakl"
                    : urlPlatform.toLowerCase().includes("shopee")
                      ? "shopee"
                      : urlPlatform.toLowerCase().includes("loggi")
                        ? "loggi"
                        : urlPlatform.toLowerCase().includes("shipsmart")
                          ? "shipsmart"
                          : "nb";
      }
      this.$router.push(
        `/${prefix}-${this.site == "carrier" ? "carrier-" : ""}login`,
      );
      //this.$router.push(`/${this.$store.state.platform}-login`);

      cancellAllRequests();
    },
    switchFullScreen() {
      this.$store.commit("config/SET_FULLSCREEN", !this.fullScreen);
    },
    showSideBar() {
      this.showSideBar(!this.showSideBar);
    },
  },
};
</script>

<style scoped lang="scss">
.badge-notifications-topbar {
  position: absolute;
  bottom: 0;
  right: 0;
}

.items-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.btn-dropdown {
  background-color: var(--gray-05);
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border: none;
  transition: all 0.3s;
  font-weight: bold;
  &:hover {
    background-color: var(--gray-10);
  }
}

.btn-notifications {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.8rem;
}
</style>
