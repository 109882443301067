import TablesService from "@/services/TablesService";

const LOCAL_STORAGE_COLUMNS_KEY = "columns_configuration";

const tableService = new TablesService();

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    storageTableKey() {
      return this.name || this.namespace?.replace(/\//g, "_");
    },
  },
  methods: {
    saveColumns(columnList) {
      const data = this.getTableColumnsFromStorage();
      data[this.storageTableKey] = columnList;

      tableService.setColumns(JSON.stringify(data));
      localStorage.setItem(LOCAL_STORAGE_COLUMNS_KEY, JSON.stringify(data));
    },
    getTableColumnsFromStorage() {
      try {
        const localData = localStorage.getItem(LOCAL_STORAGE_COLUMNS_KEY);
        const parser = JSON.parse(localData);
        return parser || {};
      } catch {
        return {};
      }
    },
    async getExternalTableColumns() {
      const localData = localStorage.getItem(LOCAL_STORAGE_COLUMNS_KEY);
      if (localData) {
        const parser = JSON.parse(localData);

        if (parser?.[this.storageTableKey]) {
          return parser[this.storageTableKey];
        }
      }

      const { data } = await tableService.getColumns();

      if (data.data?.[this.storageTableKey]) {
        return data.data[this.storageTableKey];
      }
    },
  },
};
