<template>
  <NbBadge :text="profile" :type="status" class="d-flex" />
</template>
<script>
import NbBadge from "@/components/alerts/NbBadge.vue";
import { Profile } from "@/constants/constants.js";
export default {
  components: {
    NbBadge,
  },
  props: {
    profile: {
      type: String,
      required: true,
    },
  },

  computed: {
    status() {
      const profiles = {
        [Profile.ADMIN]: "success",
        [Profile.SELLER]: "secondary",
        [Profile.CARRIER]: "warning",
      };

      return profiles?.[this.profile] || "success";
    },
  },
};
</script>

<style lang="scss" scoped></style>
