<template>
  <div>
    <div
      class="heading d-md-flex justify-content-md-between align-items-md-center"
    >
      <NbPageTitle
        :title="$t('flightsPage.title')"
        :subTitle="$t('flightsPage.subtitle')"
      />
      <NbButton
        @click="isCreateFlightModalOpen = true"
        class="btn-add d-flex justify-content-start align-items-center"
      >
        <NbIcon icon="plus" class="mr-2" />
        {{ $t("flightsPage.createNewFlight") }}
      </NbButton>
    </div>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab" />

      <keep-alive>
        <AllFlightsTable
          v-if="currentTab === tabs.ALL"
          :ref="tabs.ALL"
          @rowClick="checkPaymentLoadModalData"
        />

        <FlightsClosedManifestTable
          v-else-if="currentTab === tabs.CLOSED"
          :ref="tabs.CLOSED"
          @rowClick="checkPaymentLoadModalData"
          :status="FlightStatusEnum.manifest_closed"
        />

        <FlightsOpendManifestTable
          v-else-if="currentTab === tabs.OPEN"
          :ref="tabs.OPEN"
          @rowClick="checkPaymentLoadModalData"
          :status="FlightStatusEnum.open_manifest"
        />
      </keep-alive>
    </section>

    <Modal v-model="isFlightModalOpen" size="lg" @close="removeQueryParam()">
      <FlightModal
        :flight-id="flightId"
        @refresh="$refs?.[currentTab]?.getData()"
      />
    </Modal>

    <Modal v-model="isCreateFlightModalOpen" size="lg">
      <FlightCreateModal @success="$refs?.[currentTab]?.getData()" />
    </Modal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import FlightModal from "./components/FlightModal.vue";
import FlightCreateModal from "./components/FlightCreateModal.vue";
import Modal from "@/components/modal/Modal.vue";
import queryParamsMixin from "../../../mixins/query-params-mixin";
import AllFlightsTable from "./components/FlightsTable.vue";
import FlightsClosedManifestTable from "./components/FlightsTable.vue";
import FlightsOpendManifestTable from "./components/FlightsTable.vue";
import { FlightStatus } from "@/constants/constants.js";

const tabs = {
  ALL: "all",
  CLOSED: "closed",
  OPEN: "open",
};

export default {
  name: "Flights",
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbButton,
    NbTabs,
    NbIcon,
    FlightCreateModal,
    FlightModal,
    Modal,
    AllFlightsTable,
    FlightsClosedManifestTable,
    FlightsOpendManifestTable,
  },

  data: () => {
    return {
      filter: null,
      namespace: "flights",
      currentTab: tabs.ALL,
      flightId: null,
      isFlightModalOpen: false,
      isCreateFlightModalOpen: false,
      tabs,
      flightsCount: {
        all: null,
        open: null,
        close: null,
      },
      FlightStatusEnum: FlightStatus,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: tabs.ALL,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.flightsCount.all,
        },
        {
          id: tabs.CLOSED,
          label: "Closed manifest",
          current: this.currentTab,
          found: this.flightsCount.close,
        },
        {
          id: tabs.OPEN,
          label: "Open manifest",
          current: this.currentTab,
          found: this.flightsCount.open,
        },
      ];
    },
  },
  methods: {
    switchTab(tab) {
      this.currentTab = tab;
    },
    onDelete() {
      this.isFlightModalOpen = false;
      this.$refs?.[this.currentTab]?.getData();
    },
    checkPaymentLoadModalData(event) {
      if (event.id) {
        this.showFlight(event.id);
        return;
      }
    },
    closeModal(loadContent = false) {
      this.flightId = null;
      this.removeQueryParam();

      if (loadContent) {
        this.$refs?.[this.currentTab]?.getData();
      }
    },
    showFlight(id) {
      this.flightId = id;
      this.isFlightModalOpen = true;
      this.setUrlSearchParam("id", id);
    },
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
  },
  mounted() {
    const queryId = this.$route.query?.id;
    if (queryId) {
      this.showFlight(queryId);
    }
  },
  created() {
    this.allFields = this.fields;
  },
};
</script>

<style lang="scss" scoped>
.btn-add {
  width: 250px;
}
</style>
