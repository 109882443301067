<template>
  <div class="brand">
    <router-link to="/" tag="div" href="/" class="pointer">
      <TotalLogoStamp
        v-if="$store.state.platform === 'total'"
        :image="require('../assets/img/PNG/total-express-logo-white.png')"
        class="bg-header"
        height="40"
        width="auto"
      />
      <TotalLogoStamp
        v-else-if="$store.state.platform === 'e-cross'"
        :image="require('../assets/img/PNG/e-cross-logo-stamp.png')"
        :class="`bg-header ${$store.state.platform}`"
        height="40"
        width="auto"
      />
      <TotalLogoStamp
        v-else-if="$store.state.platform === 'anjun'"
        :image="require('../assets/img/PNG/anjun-logo.png')"
        :class="`bg-header ${$store.state.platform}`"
        height="45"
        width="auto"
      />
      <TotalLogoStamp
        v-else-if="$store.state.platform === 'tiendamia'"
        :image="require('../assets/img/PNG/tiendamia-logo.png')"
        height="45"
        width="auto"
      />
      <TotalLogoStamp
        v-else-if="$store.state.platform === 'mirakl'"
        :image="require('../assets/img/PNG/mirakl-logo-lateral-branco.png')"
        height="35"
        width="auto"
      />
      <TotalLogoStamp
        v-else-if="$store.state.platform === 'skypostal'"
        :image="require('../assets/img/PNG/skypostal-logo.png')"
        height="40"
        width="auto"
      />
      <AsiaLogoStamp
        v-else-if="$store.state.platform === 'asia'"
        class="bg-header"
      />
      <TotalLogoStamp
        v-else-if="$store.state.platform === 'jt'"
        :image="require('../assets/img/PNG/jt-logo.png')"
        height="40"
        width="auto"
      />
      <TotalLogoStamp
        v-else-if="$store.state.platform === 'correios'"
        :image="require('../assets/img/PNG/correios-logo1.png')"
        height="50"
        width="auto"
        style="
          border-radius: 5px;
          background: var(--top-bar-focus);
          padding: 10px;
        "
      />
      <TotalLogoStamp
        v-else-if="$store.state.platform === 'shopee'"
        :image="require('../assets/img/PNG/shopee-logo.png')"
        height="50"
        width="auto"
        style="
          border-radius: 5px;
          background-color: #ef5436;
          padding: 5px 118px;
        "
      />
      <IconLogo
        v-else
        class="bg-header"
        logoLeft="#FFF"
        logoRight="#FFF"
        fill="#FFF"
      />
      <NbBadge
        class="admin-badge"
        :text="badgeLogoText"
        :type="site == 'carrier' ? 'gray-50' : 'success-dark'"
      />
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconLogo from "@/components/icons/IconLogo.vue";
import TotalLogoStamp from "@/components/icons/TotalLogoStamp.vue";
import AsiaLogoStamp from "@/components/icons/AsiaLogoStamp.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";

export default {
  components: {
    TotalLogoStamp,
    AsiaLogoStamp,
    IconLogo,
    NbBadge,
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
    }),
    badgeLogoText() {
      return this.site.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.brand {
  background-color: var(--bg-top-bar);
  width: 100%;
  border-bottom-right-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-badge {
  width: fit-content;
  margin-left: auto;
  transform: scale(0.8);
}
</style>
