import { NotificationsTitle } from "../../../../constants/constants";

export default {
  computed: {
    recipientOptions() {
      return [
        { text: this.$t("notificationsPage.user"), value: "user" },
        { text: this.$t("notificationsPage.seller"), value: "seller" },
      ];
    },
    sellerNotificationTitles() {
      return this.userNotificationTitles;
    },
    userNotificationTitles() {
      return {
        [NotificationsTitle.FINANCE]: this.$t("notificationsPage.finance"),
        [NotificationsTitle.INSUFFICIENT_BALANCE]: this.$t(
          "notificationsPage.insufficientBalance",
        ),
        [NotificationsTitle.TRACKING_ISSUE]: this.$t(
          "notificationsPage.trackingIssue",
        ),
        [NotificationsTitle.NEW_TICKET]: this.$t("notificationsPage.newTicket"),
        [NotificationsTitle.DATA_UPDATE]: this.$t(
          "notificationsPage.dataUpdate",
        ),
        [NotificationsTitle.CONTRACT_PERMISSION]: this.$t(
          "notificationsPage.contractPermission",
        ),
        [NotificationsTitle.CONTRACT_UPDATE]: this.$t(
          "notificationsPage.contractUpdate",
        ),
        [NotificationsTitle.PRICE_TABLE_UPDATE]: this.$t(
          "notificationsPage.priceTableUpdate",
        ),
        [NotificationsTitle.GENERAL_ISSUE]: this.$t(
          "notificationsPage.generalIssue",
        ),
      };
    },
    users() {
      return this.$store.state.sellers.all_items?.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
    sellers() {
      return this.$store.state.users.all_items?.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
    recipients() {
      if (!this.formData.recipient) {
        return [];
      }

      if (this.formData.recipient === "user") {
        return this.users;
      }

      return this.sellers;
    },
  },
};
