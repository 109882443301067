<template>
  <div>
    <h3 class="heading-4">
      {{ $t("trackingRuleNotificationsPage.defineNotificationRules") }}
    </h3>

    <Spinner v-if="loading" size="lg" class="mx-auto" />
    <div v-else>
      <Accordion>
        <AccordionItem
          v-for="(group, idx) in groups"
          :key="group.zoneCode"
          :open="idx === 0"
        >
          <AccordionTrigger>{{ group.name }}</AccordionTrigger>
          <AccordionContent>
            <div class="table-content">
              <CheckpointTableNotificationsWrapper
                @selectAllColumn="onSelectAllColumn($event, idx)"
                :group="group"
              >
                <tr
                  v-for="checkpoint in group.checkpoints"
                  :key="checkpoint.id"
                >
                  <td>
                    {{ checkpoint.title }} - {{ checkpoint.trackingCode }}
                  </td>
                  <td
                    v-for="type in notificationsType"
                    :key="`checkpoint-${checkpoint.id}-notification-${type}`"
                  >
                    <CheckInput
                      v-model="checkpoint.notifications[type]"
                      :html-for="`checkpoint-${checkpoint.id}-notification-${type}`"
                    />
                  </td>
                </tr>
              </CheckpointTableNotificationsWrapper>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <NbButton variant="secondary" @click="goBack()">
        {{ $t("back") }}
      </NbButton>
      <NbButton :disabled="!isValid" @click="goNext()">
        {{ $t("next") }}
      </NbButton>
    </div>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import AccordionTrigger from "@/components/accordion/AccordionTrigger.vue";
import AccordionContent from "@/components/accordion/AccordionContent.vue";
import Spinner from "@/components/Spinner.vue";
import {
  CHECKPOINT_GROUP,
  NotificationType,
} from "../../../../constants/constants";
import CheckInput from "../CheckInput.vue";
import CheckpointTableNotificationsWrapper from "../CheckpointTableNotificationsWrapper.vue";
import { mapMutations, mapState } from "vuex";

const {
  FIRST_MILE,
  PICKUP,
  FREIGHT_INTERNATIONAL,
  DESEMBARACO,
  LAST_MILE,
  REVERSE,
} = CHECKPOINT_GROUP;

export default {
  components: {
    NbButton,
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
    Spinner,
    CheckInput,
    CheckpointTableNotificationsWrapper,
  },
  inject: ["ruleContext"],
  data() {
    return {
      loading: false,
      notificationsType: [
        NotificationType.SMS_CUSTOMER,
        NotificationType.WHATSAPP_CUSTOMER,
        NotificationType.EMAIL_CUSTOMER,
        NotificationType.EMAIL_SELLER,
        NotificationType.WEBHOOK_SELLER,
        NotificationType.WEBHOOKE_ADMIN,
      ],
      groups: [
        {
          name: "First Mile",
          zoneCode: FIRST_MILE.zoneCode,
          checkpoints: [],
        },
        {
          name: "Pickup",
          zoneCode: PICKUP.zoneCode,
          checkpoints: [],
        },
        {
          name: "Freight International",
          zoneCode: FREIGHT_INTERNATIONAL.zoneCode,
          checkpoints: [],
        },
        {
          name: "Desembaraco",
          zoneCode: DESEMBARACO.zoneCode,
          checkpoints: [],
        },
        {
          name: "Last Mile",
          zoneCode: LAST_MILE.zoneCode,
          checkpoints: [],
        },
        {
          name: "Reverse",
          zoneCode: REVERSE.zoneCode,
          checkpoints: [],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      groupedNotifications: (state) =>
        state.notificationRuleSettings.notifications,
      checkpoints: (state) => state.notificationRuleSettings.checkpoints,
    }),
    isValid() {
      return true;
    },
    notifications() {
      return this.notificationsType.reduce((acc, current) => {
        acc[current] = false;
        return acc;
      }, {});
    },
  },
  methods: {
    ...mapMutations("notificationRuleSettings", {
      updateNotifications: "UPDATE_NOTIFICATIONS",
    }),
    goBack() {
      this.$emit("back");
    },
    goNext() {
      this.updateNotifications(this.groups);
      this.$emit("next");
    },
    setGroup(zoneCode, data = []) {
      const groupIndex = this.groups.findIndex(
        (group) => group.zoneCode === zoneCode,
      );
      if (groupIndex !== -1) {
        this.groups[groupIndex].checkpoints = data.map((item) => ({
          id: item.id,
          title: item.title,
          trackingCode: item.tracking_code,
          notifications: this.notificationsType.reduce((acc, current) => {
            acc[current] = false;
            return acc;
          }, {}),
        }));
      }
    },
    setGroups(data = []) {
      const firstMile = data.filter(
        (checkpoint) => checkpoint.zone_code === FIRST_MILE.zoneCode,
      );
      const pickup = data.filter(
        (checkpoint) => checkpoint.zone_code === PICKUP.zoneCode,
      );
      const freightInternational = data.filter(
        (checkpoint) => checkpoint.zone_code === FREIGHT_INTERNATIONAL.zoneCode,
      );
      const desembaraco = data.filter(
        (checkpoint) => checkpoint.zone_code === DESEMBARACO.zoneCode,
      );
      const lastMile = data.filter(
        (checkpoint) => checkpoint.zone_code === LAST_MILE.zoneCode,
      );
      const reverse = data.filter(
        (checkpoint) => checkpoint.zone_code === REVERSE.zoneCode,
      );

      this.setGroup(FIRST_MILE.zoneCode, firstMile);
      this.setGroup(PICKUP.zoneCode, pickup);
      this.setGroup(FREIGHT_INTERNATIONAL.zoneCode, freightInternational);
      this.setGroup(DESEMBARACO.zoneCode, desembaraco);
      this.setGroup(LAST_MILE.zoneCode, lastMile);
      this.setGroup(reverse.zoneCode, reverse);
    },
    onSelectAllColumn({ type: notificationType, value }, groupIndex) {
      this.groups[groupIndex].checkpoints = this.groups[
        groupIndex
      ].checkpoints.map((checkpoint) => {
        checkpoint.notifications[notificationType] = value;

        return checkpoint;
      });
    },
  },
  activated() {
    if (this.ruleContext?.ruleId || this.groupedNotifications?.length) {
      this.groups = this.groupedNotifications;
      return;
    }

    this.setGroups(this.checkpoints);
  },
};
</script>

<style lang="scss" scoped>
.table-content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
