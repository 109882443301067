<template>
  <div>
    <div class="d-md-flex justify-content-between">
      <NbPageTitle
        :title="$t('notificationsPage.title')"
        :subTitle="$t('notificationsPage.subtitle')"
      />

      <div class="btn-groups d-flex flex-column">
        <NbButton class="text-left" @click="onOpenModal">
          <NbIcon class="mr-2" icon="plus" />
          {{ $t("notificationsPage.newNotification") }}
        </NbButton>
      </div>
    </div>

    <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

    <DatatableRoot
      tableOf="notifications"
      namespace="notifications"
      ref="notificationsTable"
      clickable
      :columns="tableColumns"
      :filters="tableFilters"
      @count="totalNotifications = $event"
      @rowClick="showMessage($event)"
    >
      <template #title="{ row }">
        <div :class="row.is_new ? 'btn font-weight-bold' : 'btn'">
          {{
            row.kind == "seller_notifications"
              ? sellerNotificationTitles[row.title]
              : userNotificationTitles[row.title]
          }}
        </div>
      </template>
      <template #is_new="{ row }">
        <NbBadge
          class="d-flex"
          :text="row.is_new ? $t('sent') : $t('notificationsPage.readed')"
          :type="!row.is_new ? 'success' : 'attention-dark'"
        />
      </template>

      <template #kind="{ row }">
        <div :class="row.is_new ? 'btn font-weight-bold' : 'btn'">
          {{
            row.kind == "seller_notifications"
              ? $t("notificationsPage.seller")
              : $t("notificationsPage.user")
          }}
        </div>
      </template>
      <template #name="{ row }">
        <div :class="row.is_new ? 'btn font-weight-bold' : 'btn'">
          <router-link
            :to="
              `/users_management/${
                row.kind === 'seller_notifications' ? 'sellers' : 'users'
              }/` + row.member_id
            "
            >{{ row.name }}</router-link
          >
        </div>
      </template>

      <template #created_at="{ row }">
        <div>
          <span class="d-block body-3">
            {{ row.created_at | parseDate }}
          </span>
          <!-- <small class="body-2">{{ row.created_at | parseTime }}</small> -->
        </div>
      </template>

      <template #action="{ row }">
        <NbButton variant="tertiary" @click="showMessage(row)"
          >{{ $t("notificationsPage.seeMessage") }}></NbButton
        >
      </template>
    </DatatableRoot>

    <Modal v-model="isModalSendNotificationOpen" preventClose>
      <ModalSendNotification @added="loadItems" />
    </Modal>

    <Modal v-model="isModalMessageOpen">
      <ModalMessage
        @reload="loadItems"
        :notificationData="selectedNotification"
      />
    </Modal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import ModalSendNotification from "./components/ModalSendNotification.vue";
import ModalMessage from "./components/ModalMessage.vue";
import Modal from "@/components/modal/Modal.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import moment from "moment";
import notificationMixin from "./mixins/notification-mixin";
import { NotificationsTitle } from "../../../constants/constants";

export default {
  components: {
    NbPageTitle,
    NbButton,
    NbIcon,
    NbTabs,
    DatatableRoot,
    ModalSendNotification,
    ModalMessage,
    NbBadge,
    Modal,
  },
  mixins: [notificationMixin],
  filters: {
    parseDate(value) {
      return value;
    },
    parseTime(value) {
      return moment(value).format("HH[h]mm");
    },
  },
  data() {
    return {
      selectedNotification: {},
      totalNotifications: -1,
      currentTable: "all",
      isModalMessageOpen: false,
      isModalSendNotificationOpen: false,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("notificationsPage.title"),
          found: this.totalNotifications,
          current: this.currentTable,
        },
      ];
    },
    tableColumns() {
      return [
        {
          key: "title",
          label: this.$t("notificationsPage.subject"),
        },
        {
          key: "kind",
          label: this.$t("notificationsPage.recipientType"),
        },
        {
          key: "name",
          label: this.$t("notificationsPage.fields.recipient"),
        },
        {
          key: "created_at",
          label: this.$t("notificationsPage.sendDate"),
        },
        {
          key: "is_new",
          label: this.$t("notificationsPage.fields.status"),
        },
        {
          key: "action",
          isCustomizable: false,
          label: this.$t("actions"),
          class: "right--20 right-sticky",
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("notificationsPage.titleNotification"),
          inputs: [
            {
              key: "title",
              type: "select",
              options: [
                {
                  value: NotificationsTitle.FINANCE,
                  text: this.$t("notificationsPage.finance"),
                },
                {
                  value: NotificationsTitle.INSUFFICIENT_BALANCE,
                  text: this.$t("notificationsPage.insufficientBalance"),
                },
                {
                  value: NotificationsTitle.TRACKING_ISSUE,
                  text: this.$t("notificationsPage.trackingIssue"),
                },
                {
                  value: NotificationsTitle.NEW_TICKET,
                  text: this.$t("notificationsPage.newTicket"),
                },
                {
                  value: NotificationsTitle.DATA_UPDATE,
                  text: this.$t("notificationsPage.dataUpdate"),
                },
                {
                  value: NotificationsTitle.CONTRACT_PERMISSION,
                  text: this.$t("notificationsPage.contractPermission"),
                },
                {
                  value: NotificationsTitle.CONTRACT_UPDATE,
                  text: this.$t("notificationsPage.contractUpdate"),
                },
                {
                  value: NotificationsTitle.PRICE_TABLE_UPDATE,
                  text: this.$t("notificationsPage.priceTableUpdate"),
                },
                {
                  value: NotificationsTitle.GENERAL_ISSUE,
                  text: this.$t("notificationsPage.generalIssue"),
                },
              ],
            },
          ],
        },
        {
          label: this.$t("notificationsPage.recipient"),
          inputs: [
            {
              key: "name",
              type: "select",
              options: this.listRecipients(),
            },
          ],
        },
        {
          label: this.$t("notificationsPage.recipientType"),
          inputs: [
            {
              key: "kind",
              type: "select",
              options: [
                { value: "user_notifications", text: "User" },
                { value: "seller_notifications", text: "Seller" },
              ],
            },
          ],
        },
        {
          label: this.$t("userNotificationsPage.filterOptions.status"),
          inputs: [
            {
              key: "is_new",
              type: "select",
              options: [
                { value: "true", text: this.$t("sent") },
                {
                  value: "false",
                  text: this.$t("userNotificationsPage.filterOptions.read"),
                },
              ],
            },
          ],
        },
      ];
    },
  },
  methods: {
    switchTab(tab) {
      this.currentTable = tab;
    },
    showMessage(notification) {
      this.isModalMessageOpen = true;
      this.selectedNotification = notification;
    },
    loadItems() {
      this.$refs.notificationsTable?.getData();
    },
    listRecipients() {
      let recipients = [];
      this.$store.state.sellers.all_items.forEach((seller) => {
        recipients.push(seller);
      });
      this.$store.state.users.all_items.forEach((user) => {
        recipients.push(user);
      });
      return recipients.map((entity) => ({
        value: entity.name,
        text: Object.prototype.hasOwnProperty.call(entity, "seller_id")
          ? `(U) ${entity.name}`
          : `(S) ${entity.name}`,
      }));
    },
    onOpenModal() {
      this.isModalSendNotificationOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-groups {
  min-width: 15rem;
}
</style>
