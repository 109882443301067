<template>
  <div class="app-pagination">
    <div class="per-page">
      <span>{{ $t("components.itensPerPage") }}:</span>
      <select v-model="limitPerPage" @change="onPaginate">
        <option v-for="item in limits" :value="item" :key="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="selected">
      <slot></slot>
    </div>
    <div class="current">
      <span> {{ displayItemsText }} de {{ count }}</span>
      <div class="d-flex align-items-center">
        <button :disabled="!hasPreviousPage" @click="setPage(1)">
          <NbIcon
            icon="minus"
            :attributes="{
              style: 'transform: rotate(90deg); margin-right: -12px',
            }"
            size="20"
          />
          <NbIcon icon="chevron-left" size="20" />
        </button>

        <span v-if="displayPreviousEllipsis">...</span>
        <button
          v-for="page in pages"
          :key="page"
          @click="setPage(page)"
          :class="{ active: currentPage === page }"
        >
          {{ page }}
        </button>
        <span v-if="displayNextEllipsis">...</span>

        <button :disabled="!hasNextPage" @click="setPage(totalPages)">
          <NbIcon icon="chevron-right" size="20" />
          <NbIcon
            icon="minus"
            :attributes="{
              style: 'transform: rotate(90deg); margin-left: -12px',
            }"
            size="20"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
export default {
  components: {
    NbIcon,
  },
  props: {
    page: {
      type: [String, Number],
      required: true,
    },
    limit: {
      type: [String, Number],
      default: 20,
    },
    limits: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
    count: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      limitPerPage: this.limit,
      currentPage: Number(this.page),
    };
  },
  watch: {
    page(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = Number(newValue);
        this.limitPerPage = this.limit;
      }
    },
    limit(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.limitPerPage = newValue;
        this.currentPage = Number(this.page);
      }
    },
  },
  computed: {
    displayItemsText() {
      const startItem = (this.page - 1) * this.limit + 1;
      const endItem = Math.min(this.page * this.limit, this.count);

      return `${startItem}-${endItem}`;
    },
    totalPages() {
      return Math.ceil(this.count / this.limit);
    },
    hasNextPage() {
      return this.page < this.totalPages;
    },
    hasPreviousPage() {
      return this.page > 1;
    },
    pages() {
      const total = this.totalPages;
      const range = 9;
      let offset = Math.ceil(range / 2);
      let pagesArray = [];

      for (let i = 1; i <= total; i++) {
        pagesArray.push(i);
      }

      pagesArray.splice(0, this.currentPage - offset);
      pagesArray.splice(range, total);
      return pagesArray;
    },
    displayPreviousEllipsis() {
      return this.pages[this.pages.length - 1] > this.pages.length;
    },
    displayNextEllipsis() {
      return this.pages[this.pages.length - 1] < this.totalPages;
    },
  },
  methods: {
    setPage(page) {
      this.currentPage = page;
      this.onPaginate();
    },
    previousPage(firstPage = false) {
      if (this.hasPreviousPage) {
        this.currentPage = firstPage ? 1 : this.currentPage - 1;
        this.onPaginate();
      }
    },
    nextPage(lastPage = false) {
      if (this.hasNextPage) {
        this.currentPage = lastPage ? this.totalPages : this.currentPage + 1;
        this.onPaginate();
      }
    },
    onPaginate() {
      this.$emit("paginate", {
        page: this.currentPage,
        limit: this.limitPerPage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  .per-page {
    span {
      font-size: 0.875rem;
    }
    select {
      background-color: transparent;
      border: none;
      margin-left: 1rem;
      font-size: 0.875rem;
    }
  }
  .current {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      font-size: 0.875rem;
    }
    button {
      background-color: transparent;
      border: none;
      padding: 0.375rem 1rem;
      color: #000;
      border-radius: 4px;
      transition: all 0.1s;
      &:hover:not(:disabled) {
        background-color: var(--gray-05);
      }
      &:disabled {
        color: #c8c8dc; // gray-20
        cursor: not-allowed;
      }
      &.active {
        background-color: var(--primary);
        color: var(--white);
      }
    }
  }
}
</style>
