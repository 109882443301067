<template>
  <NbButtonLoading
    :variant="variant"
    :loading="loading"
    @click="generatePackingSlip"
  >
    <slot>
      <NbIcon icon="download" :size="18" class="mr-2" />
      {{ $t("ordersViewPage.packingSlip") }}
    </slot>
  </NbButtonLoading>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButtonLoading from "@/components/buttons/NbButtonLoading.vue";
import PackingSlipService from "@/services/PackingSlipService";
const packingSlipService = new PackingSlipService();

export default {
  components: {
    NbIcon,
    NbButtonLoading,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async generatePackingSlip() {
      try {
        this.loading = true;
        const { data } = await packingSlipService.generate(this.orderId);

        window.open(data?.data?.download_url);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
