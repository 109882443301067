<template>
  <div class="table-wrapper">
    <table class="app-table">
      <slot name="thead">
        <thead>
          <tr>
            <th v-if="selectable">
              <div class="custom-checkbox">
                <input
                  v-model="isSelectedAllRows"
                  type="checkbox"
                  class="nb-checkbox"
                  @change="onSelectAllRows"
                  @click.stop
                />
              </div>
            </th>
            <th v-for="column in visibleColumns" :key="column.key">
              <div>
                {{ column.label }}
                <NbHelpText
                  v-if="column.tooltip"
                  :id="`datatable-tooltip-${column.key}`"
                  class="mx-1"
                  size="sm"
                  placement="topright"
                >
                  {{ column.tooltip }}
                </NbHelpText>
                <button
                  v-if="column.sortable"
                  @click="onSort(column.key)"
                  :class="{ current: sortKey === column.key }"
                  :data-dir="sortDir"
                >
                  <NbIcon
                    icon="arrow-down"
                    :attributes="{
                      style:
                        sortKey === column.key && sortDir === 'asc'
                          ? 'transform: rotate(180deg)'
                          : '',
                    }"
                    :size="18"
                  />
                </button>
              </div>
            </th>
          </tr>
        </thead>
      </slot>

      <slot name="tbody">
        <tbody>
          <tr
            v-for="item in data"
            :key="item[keyValue]"
            @click="onRowClick($event, item)"
            :class="{ clickable }"
          >
            <td v-if="selectable">
              <div class="custom-checkbox">
                <input
                  v-model="selectedItemIds"
                  type="checkbox"
                  class="nb-checkbox"
                  :value="item.id"
                  @change="onSelectRow"
                  @click.stop
                />
              </div>
            </td>
            <td
              v-for="column in visibleColumns"
              :key="column.key"
              :class="column.tdClassName ?? ''"
            >
              <slot :name="column.key" :row="item">
                {{ item?.[column.key] ?? "-" }}
              </slot>
            </td>
          </tr>
        </tbody>
      </slot>
    </table>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

const SORT_ASC = "asc";
const SORT_DESC = "desc";

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    keyValue: {
      type: String,
      default: "id",
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Array, undefined],
      default: undefined,
    },
    count: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  components: {
    NbIcon,
    NbHelpText,
  },
  data() {
    return {
      isSelectedAllRows: false,
      selectedItemIds: [],
      sortKey: "",
      sortDir: "",
    };
  },
  computed: {
    visibleColumns() {
      return this.columns.filter((item) => item.show !== false);
    },
    totalColumns() {
      return this.visibleColumns.length || 5;
    },
    totalRows() {
      return this.data.length;
    },
  },
  watch: {
    selected(data) {
      this.selectedItemIds = data;
      this.setIsSelectedAllRows();
    },
  },
  methods: {
    onSort(key) {
      if (this.sortKey !== key) {
        this.sortKey = key;
        this.sortDir = SORT_DESC;
      } else {
        this.sortDir = this.sortDir === SORT_DESC ? SORT_ASC : SORT_DESC;
      }

      this.$emit("sort", { key: this.sortKey, dir: this.sortDir });
    },
    onSelectRow() {
      this.$emit("update:selected", this.selectedItemIds);
      this.setIsSelectedAllRows();
    },
    onSelectAllRows(event) {
      const itemIds = this.data?.map((item) => item.id);
      this.selectedItemIds = event.target.checked ? itemIds : [];
      this.$emit("update:selected", event.target.checked ? itemIds : []);
    },
    setIsSelectedAllRows() {
      this.isSelectedAllRows =
        this.selectedItemIds.length === this.totalRows ||
        this.selectedItemIds.length === this.count;
    },
    onRowClick(event, item) {
      if (this.clickable) {
        const element = event.target;
        if (
          element.classList.contains("link") ||
          element.classList.contains("link-1") ||
          element.classList.contains("link-2") ||
          element.tagName === "BUTTON" ||
          element.tagName === "A"
        ) {
          return;
        }
        event.stopPropagation();
        this.$emit("rowClick", item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  max-height: calc(100vh - 25rem);
}
.app-table {
  width: 100%;
  thead {
    background-color: #f0f0fa; //gray-05
    font-size: 0.875rem;
    color: #000;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
    &::after {
      border: none;
    }
    th {
      padding: 0.75rem;
      white-space: nowrap;
      div {
        display: flex;
        align-items: center;
      }
      button {
        outline: none;
        border: none;
        background-color: transparent;
        color: var(--gray-30);
        transition: transform 0.3s;
        &.current {
          color: var(--black);
          &[data-dir="ASC"] {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #c8c8dc; //gray-20
      &.clickable {
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background-color: var(--gray-05);
        }
      }
      td {
        padding: 1.25rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
}
</style>
