<template>
  <div
    :class="[
      loadingCount || loadingState ? 'loading-bar-on' : 'loading-bar-off',
      loadingState,
    ]"
  >
    <div class="loading-bar-base" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LoadingBar",
  computed: {
    ...mapState("config", {
      loadingState: (state) => state.loadingState,
      loadingCount: (state) => state.loadingCount,
    }),
  },
};
</script>
