<template>
  <div>
    <span v-if="balance !== null" class="ml-2">
      {{ balance | currency }}
    </span>
    <NbButtonLoading
      v-else
      @click="loadBalance"
      variant="tertiary"
      :loading="loading"
      class="d-flex align-items-center gap-1"
    >
      <NbIcon icon="eye" />
      Visualizar
    </NbButtonLoading>
  </div>
</template>

<script>
import NbButtonLoading from "@/components/buttons/NbButtonLoading.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import SellerService from "../../../../services/SellerService";

const sellerService = new SellerService();
export default {
  props: {
    sellerId: {
      type: Number,
      required: true,
    },
  },
  components: {
    NbButtonLoading,
    NbIcon,
  },
  data() {
    return {
      loading: false,
      balance: null,
    };
  },
  methods: {
    async loadBalance() {
      try {
        this.loading = true;
        const { data } = await sellerService.getCurrentBalance(this.sellerId);
        this.balance = data?.data?.balance ?? 0;
      } catch (error) {
        this.$toast.danger("Hello World");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
