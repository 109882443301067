import api from "@/services/HttpService";
import axios from "axios";

export default {
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    showColumnOptions: {
      type: Boolean,
      default: true,
    },
    method: {
      type: String,
      default: "GET",
    },
  },
  data() {
    return {
      cancelTokenSource: null,
      selectedItemIds: [],
      tableColumns: this.columns,
      sort: {
        order_by: "",
        order_dir: "",
      },
    };
  },
  methods: {
    resetSelectedItems() {
      this.selectedItemIds = [];
    },
    selectAllItems() {
      this.selectedItemIds = this.resource.ids;
    },
    async getTableData(namespace, params) {
      try {
        if (this.cancelTokenSource) {
          await this.cancelTokenSource.cancel("Request canceled");
        }
        const source = axios.CancelToken.source();
        const cancelToken = source.token;
        this.cancelTokenSource = source;

        if (this.method?.toUpperCase() === "GET") {
          return api.get(`/v1/${namespace}`, {
            params,
            cancelToken,
          });
        }

        return api.patch(`/v1/${namespace}`, params, { cancelToken });
      } catch (error) {
        if (axios.isCancel(error)) {
          this.cancelTokenSource = null;
        } else {
          throw error;
        }
      }
    },
    setTableColumns(data) {
      const mappedVisibleColumns = this.columns.map((column) => {
        let columnIsVisible = true;
        const item = data.find((item) => item.key === column.key);
        if (item) {
          columnIsVisible = item.show;
        }

        column.show = columnIsVisible;
        return column;
      });

      const sortableColumns = mappedVisibleColumns.sort((a, b) => {
        const indexA = data.findIndex((col) => col.key === a.key);
        const indexB = data.findIndex((col) => col.key === b.key);

        return indexA - indexB;
      });
      this.tableColumns = sortableColumns;
    },
  },
};
