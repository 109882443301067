<template>
  <div>
    <ModalHeader>
      <div class="d-flex flex-column align-items-start">
        <ModalTitle>
          {{ $t("overpacksViewPage.title") }}
          {{ overpack?.reference || "---" }}
        </ModalTitle>
        <OverpackBadgeStatus v-show="!loading" :overpack="overpack" />
      </div>
    </ModalHeader>

    <Spinner v-if="loading" class="mx-auto" size="lg" />

    <form v-else class="grid gap-4">
      <NbCard id="general-data" :title="$t('generalData')">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <div
              @click="
                overpack.masterbox_id &&
                navigateTo(`/shipping/masterboxes?id=${overpack.masterbox_id}`)
              "
              :class="{ 'link-1': overpack.masterbox_id }"
            >
              <NbTextInput
                :name="$t('overpacksViewPage.masterboxID')"
                v-model="overpack.masterbox_id"
                variant="borderless-gray-60"
                disabled
                id="overpack.masterbox_id"
                placeholder="-"
              />
            </div>

            <NbTextInput
              :name="$t('overpacksViewPage.sentDate')"
              v-model="overpack.sent_at"
              variant="borderless-gray-60"
              disabled
              id="overpack.sent_at"
              placeholder="-"
            />
            <NbTextInput
              :name="$t('overpacksViewPage.trackingNumber')"
              v-model="overpack.reference"
              variant="borderless-gray-60"
              disabled
              id="overpack.reference"
              placeholder="-"
            />
            <NbTextInput
              :name="$t('overpacksViewPage.firstMileCarrierName')"
              v-model="overpack.first_mile_carrier_name"
              variant="borderless-gray-60"
              disabled
              id="overpack.first_mile_carrier_name"
              placeholder="-"
            />
            <NbTextInput
              :name="$t('overpacksViewPage.firstMileTrackingN')"
              v-model="overpack.first_mile_tracking_number"
              variant="borderless-gray-60"
              disabled
              id="overpack.first_mile_tracking_number"
              class="col-span-2"
              placeholder="-"
            />

            <div class="col-span-2">
              <label for="" class="input-label m-0 p-0">
                {{ $t("overpacksViewPage.dispatched") }}
              </label>
              <div class="input-view">
                <IsCheckedIcon :checked="overpack.is_dispatched" />
              </div>
            </div>

            <div class="col-span-2 d-flex justify-content-end">
              <div class="d-flex flex-column">
                <label class="input-label">
                  {{ $t("overpacksViewPage.createdDate") }}
                </label>
                <span>{{ overpack.created_at | datetime }}</span>
              </div>
            </div>
          </div>
        </template>
      </NbCard>

      <NbCard id="orders" :title="$t('overpacksViewPage.orders')">
        <template #body>
          <NbTablev2
            class="mt-5"
            tableOf="ordersOverpackTable"
            :hasParentItens="true"
            ref="ordersOverpackTableRef"
            :hiddeTableOptions="true"
            height="fit-content"
            :allFields="ordersAllTableFields"
            :fields="ordersFields"
            @reloadFields="ordersFields = $event"
            :startItems="overpack.orders"
          >
            <template #cell(id)="data">
              <router-link
                class="link-1"
                :to="`/shipping/orders?id=` + data.item.id"
              >
                {{ data.item.id }}
              </router-link>
            </template>
            <template #cell(created_at)="data">
              {{ data.value | formatDate }}
            </template>
          </NbTablev2>
        </template>
      </NbCard>

      <NbCard id="cn23-numbers" :title="$t('overpacksViewPage.cN23Numbers')">
        <template #body>
          <div>
            <NbButton
              v-for="cn23Number in cn23Numbers"
              :key="cn23Number"
              v-copy="cn23Number"
              variant="tertiary"
              v-b-tooltip.hover
              type="button"
              :title="$t('clickToCopy')"
            >
              {{ cn23Number }}
              <NbIcon icon="copy" />
            </NbButton>

            <blockquote
              v-if="!cn23Numbers.length"
              class="blockquote text-center"
            >
              <p class="mb-0 mt-2">
                {{ $t("overpacksViewPage.cN23foundforOverpack") }}
                {{ overpack.id }}
              </p>
            </blockquote>
          </div>
        </template>
      </NbCard>
    </form>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import OverpackService from "@/services/OverpackService";
import OverpackBadgeStatus from "./OverpackBadgeStatus.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbCard from "@/components/cards/NbCard.vue";
import Spinner from "@/components/Spinner.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import { copyClipboard } from "@/directives/copy-clipboard.js";
import moment from "moment";

const overpackService = new OverpackService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    OverpackBadgeStatus,
    NbButton,
    NbTextInput,
    NbTablev2,
    NbIcon,
    NbCard,
    Spinner,
    IsCheckedIcon,
  },
  directives: {
    copy: copyClipboard,
  },
  filters: {
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    datetime(value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  props: {
    overpackId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      ordersFields: [],
      cn23Numbers: [],
      overpack: {
        sent_at: null,
        is_dispatched: false,
        orders: [],
      },
    };
  },
  computed: {
    ordersAllTableFields() {
      return [
        { key: "id", label: "Id", sortable: false },
        {
          key: "order_number",
          label: this.$t("overpacksViewPage.fields.orderNumber"),
          sortable: false,
        },
        {
          key: "reference",
          label: this.$t("overpacksViewPage.fields.trackingNumber"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("overpacksViewPage.fields.creationDate"),
          sortable: false,
        },
        {
          key: "customer_full_name",
          label: this.$t("overpacksViewPage.fields.customerName"),
          sortable: false,
        },
      ];
    },
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    async loadCN23() {
      try {
        this.loading = true;
        const response = await overpackService.getCn23Numbers(this.overpackId);
        this.cn23Numbers = response.data.data.cn23_numbers;
      } finally {
        this.loading = false;
      }
    },
    async load() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await overpackService.getOverpack(this.overpackId);
        this.overpack = {
          ...data,
          sent_at: data?.sent_at
            ? moment(data.sent_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        };

        await this.loadCN23();
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped></style>
