<template>
  <div>
    <DatatableHeader>
      <DatatableSearch
        v-model="search"
        @submit="onSearch"
        class="flex-grow-1"
      />
      <NbButton
        variant="quaternary"
        v-b-tooltip.hover.left
        title="Refresh"
        class="d-flex gap-2 align-items-center"
        @click="getData()"
        :disabled="loading"
      >
        <NbIcon
          :class="{ refreshing: loading }"
          icon="refresh-ccw"
          :size="16"
        />
      </NbButton>
      <DatatableFilters
        v-if="filters.length"
        :filters="filters"
        :filtered="appliedFilters"
        @filters="onFilters"
        @reset="onReset"
      />
      <DatatableColumns
        v-if="showColumnOptions"
        :columns="tableColumns"
        @update="onChangeColumns"
      />
      <slot name="actions">
        <DatatableActions
          v-if="actions?.length"
          :disabled="!selectedItemIds.length"
        >
          <DatatableAction
            v-for="action in actions"
            @click="onAction(action.action)"
            :key="action.action"
          >
            {{ action.label }}
          </DatatableAction>
        </DatatableActions>
      </slot>
    </DatatableHeader>
    <Datatable
      :columns="tableColumns"
      :data="resource.elements"
      :selected.sync="selectedItemIds"
      :selectable="selectable"
      :clickable="clickable"
      :count="resource.count"
      @sort="onSort"
      @rowClick="onRowClick"
    >
      <template #thead>
        <HeaderSkeleton v-if="loadingColumns" :columns="totalVisibleColumns" />
      </template>

      <template #tbody>
        <BodySkeleton
          v-if="loading"
          :columns="totalVisibleColumns"
          :rows="+pagination.limit"
        />
      </template>

      <template v-for="column in columns" v-slot:[column.key]="data">
        <slot :name="column.key" :row="data.row"></slot>
      </template>
    </Datatable>
    <p
      v-if="!loading && !resource.elements.length"
      class="body-4 p-4 text-center"
    >
      {{ $t("notFound") }}
    </p>
    <Pagination
      v-if="resource.elements.length"
      v-bind="pagination"
      :count="resource.count"
      @paginate="onPaginate"
      :limits="limits"
    >
      <div v-if="selectedItemIds.length">
        <DatatableSelectable
          :page-length="resource.elements.length"
          :selected="selectedItemIds"
          :total="resource.count"
          @selectAll="selectAllItems"
          @clear="resetSelectedItems"
        />
      </div>
    </Pagination>
  </div>
</template>

<script>
import DatatableHeader from "@/components/datatable/DatatableHeader.vue";
import DatatableSearch from "@/components/datatable/DatatableSearch.vue";
import DatatableFilters from "@/components/datatable/DatatableFilters.vue";
import DatatableColumns from "@/components/datatable/DatatableColumns.vue";
import Datatable from "@/components/datatable/Datatable.vue";
import Pagination from "@/components/datatable/Pagination.vue";
import HeaderSkeleton from "./HeaderSkeleton.vue";
import BodySkeleton from "./BodySkeleton.vue";
import DatatableSelectable from "./DatatableSelectable.vue";
import DatatableActions from "./DatatableActions.vue";
import DatatableAction from "./DatatableAction.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import datatableSearchMixin from "./mixins/datatable-search-mixin";
import datatableFiltersMixin from "./mixins/datatable-filters-mixin";
import datatableActionsMixin from "./mixins/datatable-actions-mixin";
import datatableMixin from "./mixins/datatable-mixin";
import datatablePaginationMixin from "./mixins/datatable-pagination-mixin";
import datatableStorageMixin from "./mixins/datatable-storage-mixin";
import datatableUrlStateMixin from "./mixins/datatable-url-state-mixin";
import axios from "axios";

export default {
  mixins: [
    datatableMixin,
    datatableSearchMixin,
    datatableFiltersMixin,
    datatableActionsMixin,
    datatablePaginationMixin,
    datatableStorageMixin,
    datatableUrlStateMixin,
  ],
  components: {
    DatatableHeader,
    DatatableSearch,
    DatatableFilters,
    DatatableColumns,
    Datatable,
    Pagination,
    HeaderSkeleton,
    BodySkeleton,
    DatatableSelectable,
    DatatableActions,
    DatatableAction,
    NbIcon,
    NbButton,
  },
  props: {
    namespace: {
      type: String,
      required: true,
    },
    selected: {
      type: [Array, undefined],
      default: undefined,
    },
    searchParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      loadingColumns: false,
      resource: {
        ids: [],
        elements: [],
        count: 0,
      },
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },

    totalVisibleColumns() {
      const length = this.tableColumns.filter(
        (item) => item.show !== false,
      )?.length;

      return this.selectable ? length + 1 : length;
    },
  },
  watch: {
    currentLocale() {
      this.loadColumnsFromAPI();
    },
    selectedItemIds(val) {
      this.$emit("update:selected", val);
    },
    selected(val) {
      this.selectedItemIds = val;
    },
  },
  methods: {
    updateRowData({ key = "id", value, data }) {
      const elementIndex = this.resource.elements.findIndex(
        (item) => item[key] === value,
      );

      if (elementIndex !== -1) {
        this.resource.elements.splice(elementIndex, 1, {
          ...this.resource.elements[elementIndex],
          ...data,
        });
      }
    },
    buildParams() {
      let params = {
        sort: this.sort.key,
        order: this.sort.dir,
        search: this.search,
      };

      if (this.paginate) {
        Object.assign(params, this.buildPaginationParams());
      }

      if (this.filters.length) {
        Object.assign(params, this.buildFiltersParams());
      }

      return Object.fromEntries(
        Object.entries(params).filter(([, value]) => value !== ""),
      );
    },

    async getData() {
      try {
        this.loading = true;
        const params = {
          ...this.searchParams,
          ...this.buildParams(),
        };
        const { data } = await this.getTableData(this.namespace, params);

        this.resource = data.data;

        this.$emit("count", data?.data?.count);
        this.$emit("ids", data?.data?.ids || []);
        this.loading = false;
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.loading = false;
        }
      }
    },
    onPaginate(data) {
      this.pagination = data;
      this.getData();
      this.mergeQueryParams(data);
    },
    onSort(data) {
      this.sort = data;
      this.getData();
    },
    onRowClick(data) {
      this.$emit("rowClick", data);
    },
    onReset() {
      this.appliedFilters = {};
      this.$router.replace({
        query: undefined,
      });

      this.getData();
    },
    onFilters(data) {
      this.pagination.page = 1;
      this.mergeQueryParams({
        ...this.pagination,
        ...data,
      });
      this.appliedFilters = Object.fromEntries(
        Object.entries(data).filter(([, value]) => value !== undefined),
      );
      this.getData();
    },
    onSearch() {
      this.getData();
      this.mergeQueryParams({ search: this.search });
    },
    checkUrlSearchParams() {
      this.loadUrlSearchParams();
      this.getData();
    },
    onChangeColumns(columnList) {
      this.saveColumns(columnList);
      this.setTableColumns(columnList);
    },
    async loadTableColumns() {
      try {
        this.loadingColumns = true;
        const cols = await this.getExternalTableColumns();
        if (!cols) {
          this.tableColumns = this.columns;
          return;
        }

        this.setTableColumns(cols);
      } finally {
        this.loadingColumns = false;
      }
    },
  },
  async mounted() {
    this.loadTableColumns();
    this.checkUrlSearchParams();
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinner {
  to {
    transform: rotate(-360deg);
  }
}

.refreshing {
  animation: spinner 0.8s infinite forwards;
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
</style>
