<template>
  <NbBadge :text="data" :type="notificationType" />
</template>

<script>
import NbBadge from "@/components/alerts/NbBadge.vue";
import { NotificationType } from "../../../constants/constants";
export default {
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  components: {
    NbBadge,
  },
  computed: {
    notificationType() {
      const subjects = {
        [NotificationType.EMAIL_CUSTOMER]: "primary",
        [NotificationType.EMAIL_SELLER]: "success",
        [NotificationType.SMS_CUSTOMER]: "warning",
        [NotificationType.WHATSAPP_CUSTOMER]: "primary",
        [NotificationType.WEBHOOK_SELLER]: "success",
        [NotificationType.WEBHOOKE_ADMIN]: "warning",
        email_internal: "success",
      };

      return subjects[this.data];
    },
  },
};
</script>

<style lang="scss" scoped></style>
