export default {
  namespaced: true,
  state: {
    fullScreen: false,
    loadingState: "",
    loadingBar: "loading-bar-off",
    loadingCount: 0,
  },
  mutations: {
    SET_FULLSCREEN(state, payload) {
      state.fullScreen = payload;
    },
    TURN_OFF_LOADING_BAR(state) {
      state.loadingBar = "loading-bar-off";
    },
    TURN_ON_LOADING_BAR(state) {
      state.loadingBar = "loading-bar-on";
    },
    TURN_ON_LOADING_BAR_SUCCESS(state) {
      state.loadingState = "loading-bar-success";
      setTimeout(function () {
        state.loadingState = "";
      }, 1000);
    },
    TURN_ON_LOADING_BAR_ERROR(state) {
      state.loadingState = "loading-bar-error";
      setTimeout(function () {
        state.loadingState = "";
      }, 3000);
    },
    INCREASE_LOADING_COUNT(state) {
      state.loadingCount++;
    },
    DECREASE_LOADING_COUNT(state) {
      state.loadingCount--;
    },
    UPDATE_LOADING_COUNT(state, payload) {
      state.loadingCount = payload;
    },
  },
};
