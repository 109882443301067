import api from "./HttpService";

export default class TrackingRuleService {
  /**
   * Get trackingRuleService
   *
   * @param {*} id
   * @returns {Promise}
   */
  getTrackingRule(id = null) {
    return api.get("/v1/tracking_rules/" + id);
  }

  /**
   * Get trackingRuleService
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyTrackingRules(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/tracking_rules`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/tracking_rules?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  getAllTrackingRules() {
    return api.get("/v1/tracking_rules?limit=1000&offset=0");
  }

  /**
   * Update trackingRuleService
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateTrackingRule(id, data = {}) {
    return api.put("/v1/tracking_rules/" + id, data);
  }
  /**
   * Set trackingRuleService
   *
   * @param data
   * @returns {Promise}
   */
  setTrackingRule(data = {}) {
    return api.post("/v1/tracking_rules/", data);
  }

  /**
   * Delete trackingRuleService
   *
   * @param id
   * @returns {Promise}
   */
  deleteTrackingRule(id) {
    return api.delete("/v1/tracking_rules/" + id);
  }

  getTrackingRulesList() {
    return api.get("/v1/tracking_rules/index_full");
  }
}
