export default {
  props: {
    paginate: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: [Number, undefined],
      default: undefined,
    },
    limits: {
      type: [Array, undefined],
      default: undefined,
    },
    paginateByOffset: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pagination: {
        page: this.$route.query.page || 1,
        limit: this.limit || this.$route.query.limit || 20,
      },
    };
  },
  methods: {
    buildPaginationParams() {
      let params = {
        limit: Number(this.pagination.limit),
        page: Number(this.pagination.page),
      };

      if (this.paginateByOffset) {
        delete params.page;

        const offset =
          1 * (this.pagination.page * this.pagination.limit) -
          this.pagination.limit;

        params["offset"] = offset;
      }

      return params;
    },
  },
};
