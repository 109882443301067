export default {
  components: {
    nav: {
      Overview: "Overview",
      SearchHsCode: "Search HS Code",
      Archives: "Archives",
      Requests: "Requests",
      NobordistCheckpoints: "Nobordist Checkpoints",
      ReceivedCheckpoints: "Received Checkpoints",
      StatusCheckpoints: "Status Checkpoints",
      VolumeCheckpoints: "Volume Checkpoints",
      TrackingNotifications: "Tracking Notifications",
      Checkpoints: "Checkpoints",

      Platforms: "Platforms",

      Contracts: "Contracts",
      Deliverymethods: "Delivery Methods",
      PriceTablesDeliveryTime: "Price Tables & Delivery Time",
      ShipstationContracts: "Shipstation Contracts",

      SellerAccounts: "Seller Accounts",
      DefaultPlans: "Default Plans",
      Finances: "Finances",

      AlertLogs: "Alert Logs",
      NobordistLogs: "Nobordist Logs",
      BilLogs: "Bil Logs",
      ExternalLogs: "External Logs",
      TrackingRules: "Tracking Rules",
      RejectedOrdersLogs: "Rejected Orders Logs",
      GeneralEmails: "General Emails",
      Notifications: "Notifications",
      UserNotifications: "User Notifications",
      DelayedJobs: "Delayed Jobs",
      Logs: "Logs",

      VolumesReverse: "Volumes Reverse",
      Volumes: "Volumes",
      Orders: "Orders",
      Overpacks: "Overpacks",
      Masterboxes: "Masterboxes",
      Shipments: "Shipments",
      Flights: "Flights",
      Shipping: "Shipping",
      ShippingRules: "Shipping Rules",
      DutiesAndTaxesStatus: "Duties and Taxes Status",

      Users: "Users",
      Carriers: "Carriers",
      Sellers: "Sellers",
      Groups: "Groups",
      Permissions: "Permissions",
      Usersmanagement: "Users and sellers",
      Settings: "Settings",
      Accessibility: "Accessibility",
      PrintingSetting: "Printing Setting",
      Account: "Account",
      Downloads: "Downloads",
      SendNotifications: "Send notifications",
      FiscalPayments: "Fiscal payments",
      TrackingRuleNotifications: "Tracking Notification Rules",
    },
    searchData: {
      printing_setting: "Printing Setting",
      volumes: "Volumes",
      volumesReverse: "Volumes Reverse",
      orders: "Orders",
      overpacks: "Overpacks",
      masterboxes: "Masterboxes",
      shipments: "Shipments",
      flights: "Flights",
      shippingRules: "Shipping Rules",
      dutiesAndTaxesStatus: "Duties and taxes status",
      users: "Users",
      carriers: "Carriers",
      sellers: "Sellers",
      sellerAccounts: "Seller accounts",
      defaultPlans: "Default plans",
      contracts: "Contracts",
      deliveryMethods: "Delivery Methods",
      priceTableAndDeliveryTime: "Price table and delivery time",
      shipstationContracts: "Shipstation contracts",
      alertLogs: "Alert logs",
      nobordistLogs: "Nobordist logs",
      billLogs: "Bill logs",
      externalLogs: "External logs",
      trackingRules: "Tracking rules",
      rejectedOrdersLogs: "Rejected orders logs",
      generalEmails: "General emails",
      notifications: "Notifications",
      delayedJobs: "Delayed jobs",
      searchHsCode: "Search Hs Code",
      archives: "Archives",
      requests: "Requests",
      nobordistCheckpoints: "Nobordist checkpoints",
      receivedCheckpoints: "Received checkpoints",
      statusCheckpoints: "Status checkpoints",
      volumeCheckpoints: "Volume checkpoints",
      trackingNotifications: "Tracking notifications",
      checkpoints: "Checkpoints",
      userProfile: "My profile",
      printingSetting: "Printing settings",
      userNotifications: "User notifications",
      accesibility: "Accessibility",
      downloads: "Downloads",
      sendNotifications: "Send notifications",
      accessibility: "Accessibility",
      fiscalPayments: "Fiscal payments",
      trackingRuleNotifications: "Tracking Notification Rules",
    },
    tableFilters: {
      title: "Filters",
      subTitle: "Select the values below to create a filter",
      addNewFilter: "Add new filter",
      clearFilters: "Clear Filters",
      filterBy: "Filter by: ",
      filteredBy: "Filtered by:",
      openFilterOptions: "Open Filter Options",
      clickToClearFilter: "Click to Clear All Filters",
      clickToFilter: "Click to Filter",
      trueText: "True",
      falseText: "False",
    },
    selectTableColumns: {
      title: "Select Table Column",
      button: "Table Columns",
      unSelectAll: "Unselect All Columns",
      selectAll: "Select All Columns",
      shownColumns: "Shown columns",
      hiddenColumns: "Hidden columns",
    },
    maxRows: {
      button: "Rows",
    },
    nbTable: {
      wereFound: "were found",
      all: "All",
      onThisPage: "on this page",
      isSelected: "is selected | selected",
      worksOnlyCurrentPage:
        "This filter only works for the {onThisPageItems}  items shown on this page of the table, not the all {totalItems} items. To do this, use the filter button",
      sortBtn:
        "Sort the items on this page of the table. (Does not sort all items found)",
      properties: "Properties",
      tableOptions: "Table Options",
      changeVisibilityReorderColumns: "Change visibility and reorder columns",
    },
    itensPerPage: "Items Per Page",
    pagination: {
      first: "First",
      prev: "Prev",
      next: "Next",
      last: "Last",
    },
    topBar: {
      shiftSide: "Shifts to SIDE nav-bar",
      shiftTop: "Shifts to TOP nav-bar",
      myProfile: "My Profile",
      myNotifications: "My Notifications",
      printingSetting: "Printing Setting",
      platformList: "Platform list",
      savingChanges: "Saving changes...",
      lastChangesSaved: "Last changes saved",
    },
    footer: {
      needHelp: "Need help",
      checkOurSupport: "Check out our guides or send us a message",
    },
  },
  locales: {
    ptBR: "Portuguese (Brazil)",
    en: "English",
    cn: "Chinese",
    es: "Spanish",
  },
  //words
  value: "Value",
  none: "None",
  flight: "Flight",
  masterbox: "Masterbox",
  order: "Order",
  contract: "Contract",
  sku: "SKU",
  quantity: "Quantity",
  legalName: "Legal name",
  website: "Website",
  taxNumber: "Tax number",
  rfbCode: "RFB code",
  documentNumber: "Document number",
  addressNumber: "Number",
  addressComplement: "Complement",
  addressReference: "Reference",
  addressQuarter: "Quarter",
  city: "City",
  postalCode: "Postal code",
  state: "State",
  country: "Country",
  weight: "Weight",
  flow: "Flow",
  enabled: "Enabled",
  disabled: "Disabled",
  address: "Address",
  body: "Body",
  any: "Any",
  fill: "Fill",
  sent: "Sent",
  help: "Help",
  creationDate: "Creation date",
  enable: "Enable",
  disable: "Disable",
  lastUpdate: "Last update",
  next: "Next",
  back: "Back",
  metrics: "Metrics",
  filterByPeriod: "Filter by period",
  until: "To",
  showMoreData: "Show more data",
  collapseData: "Collapse data",
  false: "false",
  erro: "Error",
  noImageAdded: "No image addeds",
  clickOrDrag: "Click or drag your image here",
  seller: "Seller",
  user: "User",
  wouldYouLikeToRemoveSelectedItems:
    "Would you like to remove the selected items?",
  documentsAndLabels: "Documents and labels",
  downloadReport: "Download Report",
  generalData: "General data",
  suggestedActions: "Suggested actions",
  filename: "File Name",
  seeDetails: "See details",
  volumes: "Volumes",
  clickToCopy: "Click here to copy",
  copy: "Copy",
  clickToDownload: "Click to download",
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  browseFilesUpload: "Browse your file to upload",
  selectcsvFileUpload: "Select a csv file to upload",
  downloadHere: "Download here",
  instructionsXLSfile: "the instructions XLS file",
  fileUploaded: "File Uploaded",
  blankTable: "a blank table",
  sureWantDelete: "Are you sure you want to delete this permanently?",
  sitePlaceholder: "https://site.com",
  dragDropFile: "Drag and drop or click here",
  clickHereUploadFile: "Click here to upload your file",
  uploadFromComputer: "Upload from your computer",
  uploadFromLinks: "Upload from link",
  selectPdfFileUpload: "Select a PDF file to upload",
  link: "Link",
  update: "Update",
  notFound: "Not found",
  generalInformation: "General Information",
  item: "Item",
  items: "Items",
  clear: "Clear",
  confirm: "Confirm",
  connect: "Connect",
  disConnect: "Disconnect",
  connected: "Connected",
  disConnected: "Disconnected",
  contactUs: "Contact Us",
  placeholderEmail: "example@email.com",
  asynchronousRequests: "Asynchronous Requests",
  loading: "Loading",
  login: "Login",
  logout: "Logout",
  name: "Name",
  fullName: "Full name",
  company: "Company",
  phoneNumber: "Phone Number",
  whatsNumber: "Whatsapp Number",
  phone: "Phone",
  cellphone: "Cell phone",
  optional: "Optional",
  email: "Email",
  platform: "Platform",
  select: "Select",
  selected: "Selected",
  notselected: "Not selected",
  selectAll: "Select All",
  clearSelected: "Clear Selected",
  selectColumns: "Select Columns",
  clearFilter: "Clear Filters",
  filters: "Filters",
  filter: "Filter",
  all: "All",
  anyCounties: "Any country",
  close: "Close",
  save: "Save",
  cancel: "Cancel",
  actions: "Actions",
  add: "add",
  delete: "Delete",
  selectAnOption: "Select an option",
  start: "Start",
  end: "End",
  yes: "Yes",
  no: "No",
  ok: "Ok",
  attention: "Attention",
  found: "found",
  support: "Support",
  edit: "Edit",
  password: "Password",
  hidePass: "Hide Password",
  showPass: "Show Password",
  changePass: "Change Password",
  remove: "Remove",
  search: "Search",
  upload: "Upload",
  download: "Download",
  beginningDate: "Beginning Date",
  startDate: "Start Date",
  endDate: "End Date",
  between: "Between",
  betweenDates: "Between Dates",
  registrationDate: "Registration Date",
  and: "And",
  selectOption: "Select an option",
  paymentDate: "Payment Date",
  new: "New",
  allowed: "Allowed",
  invalid: "Invalid",
  errorMessages: {
    isNotPDF: "File is not .pdf",
    tryAgain: "Please, check you information and try again",
    required: "Can't be blank",
    invalidCPF: "Invalid CPF",
    invalidCNPJ: "Invalid CNPJ",
    invalidDocument: "Invalid document",
    invalidZIPCode: "Invalid zip code",
    invalidPhone: "Invalid phone",
    invalidEmail: "Invalid E-mail",
    lowerMoney: "must be greater than 0.01",
    genericError: "Something unexcepted happened.",
    whiteSpace: "should not contain white space",
    minLength: "at least %{value} characters",
    minLowercase: "at least %{value} lowercase letter",
    minUppercase: "at least %{value} uppercase letter",
    minNumber: "at least %{value} number",
    minSpecialCharacter: "at least %{value} special character",
    betweenDigits: "%{min} or %{max} digits",
  },
  downloadCsvHelp: `First, click on the "Download here blank table" button. You will download a csv file with void data. Then, fill it and click on "Upload" to start the importation process.`,
  //pages
  homePage: {
    greeting: `Hello, {name}`,
    subtitle: "What would you like to do today?",
    averageShippingCost: "Average shipping cost",
    completedOrders: "Completed orders",
    closedFlights: "Closed flights",
    bestSellers: "Best sellers",
    seller: "Seller",
    ordersCreated: "Orders created",
    noSellerRanking:
      "Without enough information to create a ranking of sellers",
    whatPeriodAnalyze: "What period do you want to analyze?",
    deliveredOrders: "Delivered orders",
    createdOrders: "Created orders",
    links: {
      orders: {
        title: "See orders",
        description: "Check orders and download reports.",
        action: "Go to orders",
      },
      sellers: {
        title: "Sellers management",
        description: "Check users and sellers.",
        action: "Go to sellers",
      },
      masterboxes: {
        title: "View Masterboxes",
        description: "Check out the masterboxes.",
        action: "Go to masterboxes",
      },
      flights: {
        title: "Manage Flights",
        description: "Check and inform which flight each order is on.",
        action: "Go to flights",
      },
      contracts: {
        title: "Manage Contracts",
        description: "Access to manage contracts.",
        action: "Go to contracts",
      },
      checkpoints: {
        title: "View Checkpoints",
        description: "See all tracking events of your orders",
        action: "Go to checkpoints",
      },
    },
  },
  loginPage: {
    forgotPassword: "Forgot your password",
    resetPassword: "Reset password email has sent",
    checkInbox: "Check your inbox",
    sendReset: "Send me reset password instructions",
    login: "Login",
    welcome: "Welcome",
    pleaseYourAcc: "Please enter your account",
    wantoToApp: "Want to access management system",
    goToApp: "Go to {val} app",
    checkOur: "Check out our",
    generalConditionsService: "Conditions of Service",
    resetPasswordSent: "Reset password email has sent! Check your inbox.",
    sendResetPasswordInstructions: "Send me reset password instructions",
    iAgree: "I agree",
    agreementText:
      "By cheking this box, you acknowledge that you have read and agree to our General Conditions of Service to be bound by this Agreement.",
    createYourAccount: "Create your account",
    fillInfoToCreateAcc: "Fill in the following fields to create your account",
    userData: "User data",
    pleaseYourData: "Please fill your data",
    companyData: "Company data",
    pleaseCompanyData: "Please fill company data",
    hasRegistration: "Already registered",
    accessMyAcc: "Access my account",
    needHelp: "Need help",
    talkWithOur: "Talk to one of our consultants",
    ifCheckOut: "If you prefer, check out our",
  },
  pintingSettingsPage: {
    title: "Printing Settings",
    subtitle:
      "Define here standard configurations to printing options of the labels",
    cN23Label: "CN23 Label",
    commercialInvoice: "Commercial Invoice",
    packingSlip: "Packing Slip",
    cevaBatteryShipperConfirmation: "Ceva Battery Shipper Confirmation",
  },
  userProfileEditPage: {
    title: "Update My Profile",
  },
  userProfilePage: {
    title: "My Account",
    subtitle: "Check and update, if necessary, your personal information.",
    userData: "User data",
    loginInformation: "Login information",
    loginInformationSubtitle:
      "If needed, update the data you use to access the platform.",
    checkYourData: "Check and edit your data, if necessary",
    editPass: "Edit Password",
    newPass: "New Password",
    currentPass: "Current Password",
    newPassConfirm: "Type new password to change",
    editEmail: "Edit Email",
    editProfile: "Edit Profile",
    newEmail: "New Email",
    checkEmail: "Please check your email format",
    sameEmail: "The two input emails are diferent, please check it",
    confirmCurrentPassword:
      "To perform this action, please type your current password",
  },
  overViewPage: {
    title: "Overview",
    getInfoCorreios: "Get information from Correios",
    putTrackingNumber: "Put Tracking Number",
    putDispatchNumberUnitCode: "Put Dispatch Number or Unit Code",
    manuallyUpdateCN38Number: "Manually update CN38 number",
    shipmentID: "Shipment ID",
    newCN38Number: "New CN38 Number",
    updateCN38: "Update CN38",
    resetDispatchNumbersCounter: "Reset Dispatch numbers counter",
    newCounterStart: "New counter start",
    reset: "Reset",
    removeOverpack: "Remove Overpack",
    testDelayedJob: "Test Delayed Job",
    test: "Test",
    generateRequestID: "Generate Request ID",
    updateCn38Number: "Update cn38 number",
    sureOverwrite: "You are going to overwrite the CN38 number of Shipment",
    definitiveAction: "This action is definitive. Are you willing to continue",
    sureResetCorreiosCounter:
      "You are going to reset Correios counter for dispatch numbers",
    sureRemoveOverpack: "Do you want to remove this overpack",
  },
  downloadsPage: {
    title: "Archive",
    subtitle: "All downloads are centralized on this page",
    errors: "Errors",
    found: "no download found | download | downloads",
    downloadReport: "Download report",
    showErrors: "Show errors",
    status: {
      failed: "failed",
      processing: "processing",
      done: "done",
      starting_upload: "starting upload",
      checking_csv_format: "checking CSV format",
      checking_columns: "checking columns",
      checking_equal_orders_volumes: "verifying equal order volumes",
      checking_cells: "checking cells",
      validation_success:
        "validations completed successfully! creating the table",
    },
    fields: {
      id: "ID",
      type: "Type",
      status: "Status",
      fileName: "Filename",
      creationDate: "Creation date",
      messages: "Messages",
    },
  },
  checkpointsPage: {
    title: "Checkpoint",
    subTitle: "Confira pontos importantes dos pedidos de seus clientes",
    subTitleView: "Check this checkpoint data",
    moreAboutCheckpoint: "Learn more about checkpoints",
    helpTextTitle:
      "This page is your trusted guide through the crossboarding process. Here, you will find a series of essential checkpoints that will ease your journey, ensuring that each step is completed successfully.",
    nobordistCheckpoints: "Nobordist Checkpoints",
    receivedCheckpoints: "Received Checkpoints",
    statusCheckpoints: "Status Checkpoints",
    volumeCheckpoints: "Volume Checkpoints",
    trackingNotifications: "Tracking Notifications",
  },
  nobordistCheckpointsPage: {
    title: "Nobordist Checkpoints",
    description:
      "This is the full list of Nobordist tracking statuses that will compose the orders tracking history",
    downloadCheckpointsCSV: "Download Checkpoints in CSV",
    downloadCheckpointsExcel: "Download Checkpoints in Excel",
    checkDownloadsAt: "Check the processing downloads at... downloads page",
    fields: {
      type: "Type",
      slug: "Slug",
      name: "Name",
      title: "Title",
      trackingCode: "Tracking Code",
      namePT: "Name PT",
      titlePT: "Title PT",
      finalStatus: "Final Status",
      category: "Category",
      description: "Description",
    },
  },
  nobordistCheckpointsViewPage: {
    title: "Nobordist Checkpoint #",
    type: "Type",
    slug: "Slug",
    titleField: "Title",
    titlePt: "Title Pt",
    description: "description",
    descriptionPt: "Description Pt",
    name: "Name",
    trackingCode: "Tracking Code",
  },
  receivedCheckpointsPage: {
    title: "Received Checkpoints",
    description:
      "The list of all tracking statuses we caught from our transport partners. Some of them might be ignored in the oder tracking history because they are redundant or irrelevant, if you want to see what was added to the tracking history, click in the Volume Checkpoints section",
    fields: {
      trackingNumber: "Tracking Number",
      trackingFunction: "Tracking Function",
      statusCode: "Status Code",
      message: "Message",
      dateIso: "Date Iso",
      creationDate: "Creation Date",
    },
  },
  receivedCheckpointsViewPage: {
    title: "Received Checkpoint #",
    trackingNumber: "Tracking Number",
    trackingFunction: "Tracking Function",
    statusCode: "Status Code",
    dateIso: "Date Iso",
    date: "Date",
    sentNotification: "Sent Notification",
    message: "Message",
    fields: {
      receiverType: "Receiver Type",
      notificationID: "Nb Notification ID",
      volumeID: "Volume ID",
      creationDate: "Creation Date",
    },
  },
  sendNotificationsViewPage: {
    title: "Send Service #",
    receivedNotification: "Received Notification",
    receiverType: "Receiver Type",
    email: "E-mail",
    webhook: "Webhook",
    phone: "Phone",
    notificationID: "Nb Notification ID",
    volumeID: "Volume ID",
    sellerName: "Seller Name",
    trackingCode: "Tracking Code",
    titleField: "Title",
    createdDate: "Created Date",
    time: "Time",
    receivedCheckpointID: "Received Checkpoint ID",
    trackingNumber: "Tracking Number",
    trackingFunction: "Tracking Function",
    statusCode: "Status Code",
    dateIso: "Date Iso",
  },
  statusCheckpointsPage: {
    title: "Status Checkpoints",
    description:
      "The translation of tracking statuses from our transport partners with our own Nobordist tracking statuses",
    addStatusCheckpoint: "Add Status Checkpoint",
    addNewStatusCheckpoint: "Add New Status Checkpoint",
    uploadStatusCheckpoint: "Upload Status Checkpoint",
    checkpointID: "Checkpoint ID",
    fields: {
      statusCode: "Status Code",
      trackingFunction: "Tracking Function",
      checkpoint: "Checkpoint",
      creationDate: "Creation Date",
      edit: "Edit",
    },
    notApplied: "Not applied",
  },
  statusCheckpointsPageView: {
    title: "Status Checkpoint",
  },
  trackingNotificationsPage: {
    title: "Tracking Notifications",
    description:
      "The list of all notifications (emails, sms, webhooks, …) sent about tracking updates",
    fields: {
      notificationType: "Notification Type",
      volumeCheckpointID: "Volume Checkpoint ID",
      data: "Recipient",
      sent: "Sent",
    },
  },
  volumeCheckpointsPage: {
    title: "Volume Checkpoints",
    description:
      "The list of all tracking statuses we added to the orders tracking history",
    generateVolumeLabel: "Generate volume label",
    fields: {
      volumeID: "Volume ID",
      receivedCheckpointID: "Received Checkpoint ID",
      convertedNotifs: "Converted Notifs",
      checkpoint: "Checkpoint",
      DateISO: "Date ISO",
      message: "Message",
      notificationType: "Notification type",
      recipient: "Recipient",
      trackingNotification: "Tracking notification",
    },
  },
  volumeCheckpointsViewPage: {
    title: "Volume Checkpoint #",
    checkpoint: "Checkpoint",
    volumeData: "Volume Data #",
    volumes: "Volumes",
    message: "Message",
    attachment: "Attachment",
    volumeID: "Volume ID",
    receivedCheckpointID: "Received Checkpoint ID",
    convertedNotifs: "Converted Notifs",
    checkpointID: "Checkpoint ID",
    created: "Created",
    dateIso: "Date Iso",
    type: "Type",
    slug: "Slug",
    name: "name",
    description: "Description",
    trackingCode: "Tracking Code",
    titleField: "Title",
    namePT: "Name PT",
    descriptionPT: "Description PT",
    titlePT: "Title PT",
    hierarchyStatus: "Hierarchy Status",
    height: "Height",
    width: "Width",
    length: "Length",
    weight: "Weight",
    cubageFactor: "Cubage Factor",
    volumeType: "Volume Type",
    orderID: "Order ID",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    orderReference: "Order Reference",
    lastMileCarrierNumber: "Last Mile Carrier Number",
    fields: {
      name: "Name",
      description: "Description",
      sku: "Sku",
      valueWithTax: "Value With Tax",
      value: "Value",
      hsCode: "Hs Code",
      quantity: "Quantity",
      originCountry: "Origin Country",
      length: "Length",
      width: "Width",
      height: "Height",
      weight: "Weight",
    },
  },
  contractsPage: {
    logo: "Logo",
    title: "Contracts",
    importContract: "Import Contract from JSON",
    subTitle: "Check and manage contract's data",
    addContracts: "Add Contracts",
    updateValidityDate: "Update Validity Date",
    showAdditionalServices: "Show Additional Services",
    addNewContract: "Add new Contract",
    name: "Name",
    slug: "Slug",
    incoterm: "Incoterm",
    taxingType: "Taxing Type",
    maximumWeight: "Maximum Weight",
    cubageFactor: "Cubage Factor",
    maximumLength: "Maximum Length",
    maximumHeight: "Maximum Height",
    maximumWidth: "Maximum Width",
    maximumPriceLimit: "Maximum Price Limit",
    additionalServices: "Additional Services",
    externalID: "External ID",
    hSCodes: "HS Codes",
    triggerOption: "Trigger Option",
    triggerCondition: "Trigger Condition",
    primaryType: "Primary Type",
    primaryValue: "Primary Value",
    primaryVariable: "Primary Variable",
    secondaryVariable: "Secondary Variable",
    secondaryType: "Secondary Type",
    secondaryValue: "Secondary Value",
    selecttype: "Select type",
    addToServices: "Add to Services",
    cancelNewService: "Cancel New Service",
    newService: "New Service",
    botNotifications: "Dutypay Notifications",
    isOldFlow: "Is Old Flow",
    old: "Old",
    new: "New",
    mode: "Mode",
    airporttoDoor: "Airport to Door",
    doorToDoor: "Door to Door",
    automatizePayment: "Automatize Payment",
    needSupervisorApproval: "This action needs a supervisor's approval",
    chooseSendTo: "Please choose who you are going to send the request to",
    firstMileDM: "First Mile DM",
    freightForwarderDM: "Freight Forwarder DM",
    customClearenceDM: "Custom Clearence DM",
    lastMileDM: "Last Mile DM",
    priceTable: "Price Table",
    reverseDM: "Reverse DM",
    deliveryTime: "Delivery Time",
    destinationCountry: "Destination Country",
    originCountry: "Origin Country",
    fields: {
      name: "Name",
      slug: "Slug",
      deliveryMethods: "Delivery Methods",
      firstMileDeliveryMethod: "First Mile Delivery Method",
      freightForwarderDeliveryMethod: "Freight Forwarder Delivery Method",
      customClearenceDeliveryMethod: "Custom Clearence DeliveryMethod",
      lastMileDeliveryMethod: "Last Mile Delivery Method",
      reverseDeliveryMethod: "Reverse Delivery Method",
      priceTable: "Price Table",
      deliveryTime: "Delivery Time",
      priceLmit: "Price Limit",
      creationDate: "Creation Date",
      taxingType: "Taxing Type",
      incoterm: "Incoterm",
      automaticPayment: "Automatic Payment",
      maximumWeight: "Maximum Weight",
      maximumWidth: "Maximum Width",
      maximumHeight: "Maximum Height",
      maximumLength: "Maximum Length",
      cubageFactor: "Cubage Factor",
      additionalServices: "Additional Services",
      isMadeBySeller: "Is Made By Seller",
    },
    nameHelpText: "This is how the seller will view the contract",
    slugHelpText:
      "This is for your interna use, the seller won’t see this name",
    incotermHelpText: "What incoterms will be allowed with this contract",
    beginAndDateHelpText:
      "This sets the validity dat eof the contract. Outside of this period, the seller won’t be able to use it",
    flowHelpText:
      "The OLD flow delegates to the seller the responsability of creating overpacks. In the NEW flow, the admin will do it through masterboxes. Recommended: NEW",
    modeHelpText:
      "Airport to Door indicates that the contract will only take  charge of the Customs and Last Mile, while Door to Door indicates that the contract is in charge of the whole process staring in the origin country",
    importContractFromJsonHelpText:
      "This allows you to re-create a contract from another environment. Example: you have created a contract in homolog environment and would like to create the exact same contract in production environment. In that case, you just need to click on “Download Contract Settings” in the contract page in homolog environment, and import the downloaded json file here",
    downloadContractsSettingsHelpText:
      "This will generate a json document that you can use to copy this contract to another environment",

    ifOrderReceives: "If the order receives one of following checkpoint",
    sellerWillBeBilled:
      "The seller will be billed with an additional cost named",
    andTheItemHasOneOfHscodes: "And the item has one of following hs codes",

    valueBilledFlat: "The value billed will be of {value} per {type}",
    valueBilledFlatType: "@:contractsPage.valueBilledFlat and per {type2}",

    andValueBilledFlat: " AND {value} per {type}",
    andValueBilledFlatType: " AND {value} per {type1} and per {type2}",

    valueBilledPercent:
      "The value billed will be of {value} of the value of the {type}",
    valueBilledPercentType1:
      "@:contractsPage.valueBilledPercent, per each {type2}",
    valueBilledPercentType2:
      "@:contractsPage.valueBilledPercent, per each {type2} and each {type3}",

    andValueBilledPercent: " AND {value} of the value of the {type}",
    andValueBilledPercentType1:
      " AND {value} of the value of the {type1}, per each {type2}",
    andValueBilledPercentType2:
      " AND {value} of the value of the {type1}, per each {type2} and each {type3}",

    day: "day",
    volume: "volume",
    item: "item | items",
  },
  contractsViewPage: {
    title: "Contract #",
    downloadContractSettings: "Download Contract Settings",
    subTitle: "Check and edit, if necessary, the carrier's data",
    nameDefinitions: "Name and Definitions",
    destinationandOrigin: "Destination and Origin Country",
    destinationandOriginSubtitle: "Define which countries the contract covers",
    separeteByCommas: "Separate each code with commas",
    descriptionName: "Descriptive name (appear in the seller's app)",
    weightMeasure: "Weight and Measure",
    termContract: "Term of the Contract",
    deliveryMethods: "Delivery Methods",
    deliveryMethod: "Delivery Method",
    deliveryMethodsSubtitle:
      "Register those responsible and the corresponding delivery method for each stage of the process",
    notFoundDM: "Didn't find your delivery method",
    registerDM: "Register a new delivery method",
    uniqueDM: "Unique delivery method",
    uniqueDMSubtitle:
      "This delivery method is responsible for the entire contract",
    pricesMeasuresCubage: "Weights, measures and cubage",
    pricesMeasuresCubageSubtitle:
      "Fill in the values according to the registered carriers and methods",
    cubageCalcHelper: "Calculate based on maximum dimensions and weight",
    priceDeliveryTime: "Prices Table and Delivery Time",
    priceDeliveryTimeSubtitle:
      "These tables determine the estimated cost and delivery time of freight for this contract",
    anyCountry: "Any country",
    priceTableDeliveryTime: "Price Table and Delivery times",
    pdf: "pdf",
    csv: "csv",
    name: "Name",
    ID: "ID",
    slug: "Slug",
    created: "Created",
    firstMileDeliveryMethod: "First Mile",
    freightForwarderDeliveryMethod: "Freight Forwarder",
    customClearenceDeliveryMethod: "Custom Clearence",
    lastMileDeliveryMethod: "Last Mile",
    isOldFlow: "Is Old Flow",
    priceTable: "Price Table",
    obsolete: "Obsolete",
    taxingType: "Taxing Type",
    incoterm: "Incoterm",
    automatizedpayment: "Automatized payment",
    generatePaymentLink: "Generate Payment Link",
    reverseDeliveryMethod: "Reverse Delivery Method",
    deliveryTime: "Delivery Time",
    airporttoDoor: "Airport to Door",
    doorToDoor: "Door to Door",
    maximumWeight: "Maximum Weight",
    cubageFactor: "Cubage Factor",
    maximumPrice: "Maximum Price",
    maximumLength: "Maximum Length",
    maximumHeight: "Maximum Height",
    maximumWidth: "Maximum Width",
    externalID: "External ID",
    triggerOption: "Trigger Option",
    triggerCondition: "Trigger Condition",
    primaryVariable: "Primary Variable",
    primaryValue: "Primary Value",
    secondaryVariable: "Secondary Variable",
    secondaryValue: "Secondary Value",
    additionalServices: "Additional Services",
    addedServices: "Added Services",
    additionalServicesSubtitle: "Set some rules for this contract",
    additional: "Additional",
    hSCodes: "HS Codes",
    editContract: "Edit Contract",
    cloneContract: "Clone Contract",
    slugForNewContract: "Slug For New Contract",
    isObsolete: "Is Obsolete",
    primaryType: "Primary Type",
    secondaryType: "Secondary Type",
    selecttype: "Select type",
    addServices: "Add to Services",
    cancelNewService: "Cancel New Service",
    newService: "New Service",
    automatizedPayment: "Automatized Payment",
    airportToDoor: "Airport to Door",
    needsSupervisorApproval: "This action needs a supervisor's approval",
    chooseWhoSendRequest:
      "Please choose who you are going to send the request to",
    wantToMakeContractObsolete: "Do you want to make this contract obsolete",
    notBePossibleVisualize:
      "It will not be possible to visualize it or make changes",
    removeService: "Remove Service",
    firstMileDM: "First Mile DM",
    freightForwarderDM: "Freight Forwarder DM",
    customClearenceDM: "Custom Clearence DM",
    lastMileDM: "Last Mile DM",
    reverseDM: "Reverse DM",
    generalData: "General Data",
    generalDataSubtitle: "Fill in the details below",
    automatized: "Automatized",
    notAutomatized: "Not Automatized",
    slugHelpText:
      "Internal name for your administrator use, won’t appear to the seller",
    configurations: "Configurations",
  },
  deliveryMethodsPage: {
    title: "Delivery Methods",
    subTitle: "Manage Delivery Metods or create new cooperations",
    addDeliveryMethod: "Add Delivery Method",
    cooperationTitle: "Title",
    cooperationList: "List of cooperations",
    cooperations: "Cooperations",
    addCooperations: "Add one more cooperations",
    eachCooperationUniqueTitle: "Each cooperation has an unique title",
    name: "Name",
    service: "Service",
    selectGroup: "Select group",
    type: "Type",
    nationalization: "Nationalization",
    trackingCredentials: "Tracking Credentials",
    addTracking: "Add Tracking",
    dispatchCredentials: "Dispatch Credentials",
    addDispatch: "Add Dispatch",
    key: "Key",
    value: "Value",
    carrier: "Carrier",
    cooperationSlug: "Cooperation Slug",
    fields: {
      name: "Name",
      service: "Service",
      type: "Type",
      carrier: "Carrier",
      carrierID: "Carrier ID",
      creationDate: "Creation Date",
      cooperationSlug: "Cooperation Slug",
      createdBySeller: "Created by Seller",
    },
  },
  deliveryMethodsViewPage: {
    title: "Delivery Method #",
    usedContracts: "Contracts using this Delivery Method",
    editDeliveryMethod: "Edit Delivery Method",
    edit: "Edit",
    name: "Name",
    service: "Service",
    selectgroup: "Select group",
    type: "Type",
    carrier: "Carrier",
    selectcarrier: "Select carrier",
    nationalization: "Nationalization",
    trackingCredentials: "Tracking Credentials",
    key: "Key",
    value: "Value",
    addTracking: "Add Tracking",
    dispatchCredentials: "Dispatch Credentials",
    addDispatch: "Add Dispatch",
    owner: "Owner",
    ownerReference: "Owner Reference",
    cooperationSlug: "Cooperation Slug",
  },
  priceTablesPage: {
    title: "Price Tables and Delivery Times",
    subTitle: "Check and price tables and delivery times of your customers",
    helpTextTitle:
      "Create, delete, download delivery times and price tables of your costumers",
    titlePrice: "Prices Table",
    titleDelivery: "Delivery Times",
    importPricesTable: "Import Prices Table",
    importDeliveryTime: "Import Delivery Time",
    suretodelete: "Are you sure to delete",
    selectCsv: "Select a csv file to upload",
    fileUploaded: "File Uploaded",
    downloadHere: "Download here",
    ablankTable: "a blank table",
    import: "Import",
    name: "Name",
    removeOrders: "Remove Orders",
    shouldRemove1: "Do you really want to remove these",
    shouldRemove2: " orders? This action is irreversible",
    shouldRemove3: " This action is irreversible",
    alreadyInMasterbox:
      "Selected Orders are already linked to a masterbox and will not be removed",
    someInMasterbox:
      "These orders are already linked to a masterbox and will not be removed.",
    toBeRemoved:
      "remaining orders are available for removing. Are you sure you want to remove them? This action is irreversible",
    filesUpload: "Browse your files and upload your",
    needsSupervisorApproval: "This action needs a supervisor's approval",
    chooseToSendRequest:
      "Please choose who you are going to send the request to",
    fields: {
      name: "Name",
      actions: "Actions",
    },
    priceTableDescription:
      "With the price table, you can set the freight cost for each postal code and weigh",
    deliveryTimeTableDescription:
      "With the delivery time table, you can set the delivery time for each postal code",
    nameTooltip:
      "Give any name to identify your file. Your sellers won’t see it",
  },
  shipstationContractsPage: {
    title: "Shipstation Contracts",
    addShipstationContract: "Add Shipstation Contract",
    shipstationContract: "Shipstation Contract",
    shipstationContractName: "Shipstation Contract Name",
    seller: "Seller",
    contract: "Contract",
    fields: {
      contract: "Contract",
      sellerID: "Seller ID",
      shipstationContractName: "Shipstation Contract Name",
      actions: "Actions",
    },
  },
  defaultPlansPage: {
    title: "Seller Account Plans",
    name: "Name",
    selectMultipleSellers: "Select multiple sellers",
    email: "Email",
    applySellers: "Apply to sellers",
    newPlan: "New Plan",
    components: {
      categoryForm: {
        chargeDay: "Charge Day",
        firstChargeDay: "First Charge Day",
        chargeFrequency: "Charge Frequency",
        limit: "Limit",
        fixedChargeDay: "Fixed Charge Day",
      },
      groupCategoryCards: {
        cost: "Cost",
        trackingStatus: "Tracking Status",
        sMSCost: "SMS Cost",
        whatsappCost: "Whatsapp Cost",
      },
    },
  },
  sellerAccountEventsPage: {
    title: "Seller Account Events",
    addCredit: "Add Credit",
    filterCategory: "Filter by category",
    limit: "Limit",
    balanceIs: "Balance is",
    nBxBelowLimit: "NBx below the limit",
    noLimit: "No limit",
    seller: "Seller",
    amount: "Amount",
    currentExchange: "Current Exchange",
    description: "Description",
    defaultPlans: "Default Plans",
    choosePlan: "Choose a Plan",
    generalSettings: "General Settings",
    subscriptionFee: "Subscription Fee",
    nBXvalueUSD: "NBX value in USD",
    limitTax: "Limit Tax",
    notifications: "Notifications",
    whatsappCost: "Whatsapp Cost",
    sMSCost: "SMS Cost",
    freight: "Freight",
    freightCost: "Freight Cost",
    settings: "Settings",
    chargeDay: "Charge Day",
    firstChargeDay: "First Charge Day",
    chargeFrequency: "Charge Frequency",
    fixedChargeDay: "Fixed Charge Day",
    trackingCode: "Tracking Code",
    updateSettings: "Update Settings",
    fields: {
      sellerID: "Seller ID",
      sellerName: "Seller Name",
      category: "Category",
      attachment: "Attachment",
      value: "Value",
      exchange: "Exchange",
      description: "Description",
      creationDate: "Creation Date",
      startValue: "Start Value",
      endValue: "End Value",
    },
    currentExchangeHelpText:
      "Current exchange rate: $1 is equal to R$ %{value}",
    howMuchDoYouWantAdd: "How much do you want to add?",
    creditOperation: "Click to switch to a CREDIT operation",
    debitOperation: "Click to switch to a DEBIT operation",
  },
  alertLogsPage: {
    title: "Logs Management",
    subTitle: "Check logs generated on the platform",
    helpTextTitle:
      "Messages generated or recorded by the system are located here",
    alertLogs: "Alert Logs",
    importValuesandStatuses: "Import Values and Statuses",
    updateStatus: "Update Status",
    downloadLogs: "Download Logs",
    updateStatusInfo:
      "If you just want to update statuses, please leave the 'tax_value' column empty (column name must be maintained)",
    new: "New",
    solved: "Solved",
    priority: "Priority",
    inProgress: "In Progress",
    waitingforPayment: "Waiting for Payment",
    sentAgain: "Sent Again",
    paymentDone: "Payment Done",
    pending: "Pending",
    paidExternally: "Paid Externally",
    needSellerAction: "Need Seller Action",
    status: "Status",
    comment: "Comment",
    checktheprocessingdownloads:
      "Check the processing downloads at... downloads page",
    sureWantSave: "Are you sure you want to save the changes",
    newTrackingNumbers: "New Tracking Numbers ",
    shouldbeCommaSeparated:
      "The new numbers should be comma separated and follow the sequence of Tracking Alert Logs selected: TAL_IDs:",
    unsaveddata: "Unsaved data",
    pleasesaveunsaveddata: "Please save unsaved data or cancel",
    okay: "Okay",
    name: "Name",
    browseFilesUpload: "Browse your file to upload",
    selectcsvFileUpload: "Select a csv file to upload",
    downloadHere: "Download here",
    instructionsXLSfile: "the instructions XLS file",
    fileUploaded: "File Uploaded",
    blankTable: "a blank table",

    updateAllSelectedLogs: "Update all selected logs",
    barcode: "Barcode",
    importPaymentCertificates: "Import payment certificates",
    importPdfPaymentCertificatesHelp: `Import the PDF of merged payment certificates: the system will separate them and make them availbale in the "Payment Certificate" column`,

    fields: {
      volumeId: "Volume Id",
      trackingCode: "Tracking Code",
      sellerId: "Seller ID",
      seller: "Seller",
      message: "Message",
      attachment: "Attachment",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      dateISO: "Date ISO",
      creationTime: "Creation time",
      title: "Title",
      rule: "Rule",
      type: "Type",
      status: "Status",
      comment: "Comment",
      dutiesPayment: "Duties Payment",
      volume: "Volume",
    },
  },
  alertLogsViewPage: {
    title: "Alert Log #",
    sellerChargedAmount: "Seller Charged Amount",
    exchange: "Exchange",
    incoterm: "Incoterm",
    status: "Status",
    date: "Date",
    dateIso: "Date Iso",
    orderID: "Order ID",
    orderNumber: "Order Number",
    volumeID: "Volume ID",
    sellerID: "Seller ID",
    taxID: "Tax ID",
    lastMileNumber: "Last Mile Number",
    productsValue: "Products Value",
    freightValue: "Freight Value",
    expectedTax: "Expected Tax",
    realTax: "Real Tax",
    valueR$: "Value R$",
    editableValues: "Editable values",
    downloadAttachment: "Download Attachment",
    downloadPaymentCertificate: "Download Payment Certificate",
  },
  bilLogsPage: {
    title: "Bil Logs",
    fields: {
      mawb: "MAWB",
      house: "House",
      volumeID: "Volume ID",
      status: "Status",
      creationDate: "Creation Date",
    },
  },
  bilLogsViewPage: {
    title: "Bill Log #",
    mawb: "Mawb",
    house: "House",
    creationDate: "Creation Date",
    status: "Status",
    volumeID: "Volume ID",
    logs: "Logs",
  },
  delayedJobsPage: {
    title: "Delayed Jobs Logs",
    fields: {
      queue: "Queue",
      priority: "Priority",
      attempts: "Attempts",
      handler: "Handler",
      lastError: "Last Error",
      runat: "Run at",
      lockedat: "Locked at",
      failedat: "Failed at",
      lockedby: "Locked by",
    },
  },
  externalLogs: {
    title: "External Apis Logs",
    fields: {
      apiName: "API Name",
      function: "Function",
      userID: "User ID",
      response: "Response",
      creationDate: "Creation Date",
    },
  },
  externalViewLogs: {
    title: "External API Log #",
    apiName: "API Name",
    userName: "User Name",
    function: "Function",
    creationDate: "Creation Date",
    updateDate: "Update Date",
    params: "Params",
    response: "Response",
  },
  generalEmailsPage: {
    title: "General Emails",
    subTitle:
      "On this page, you can send emails to whoever you want, using your configurated sender address",
    createGeneralEmails: "Create General Email",
    titleGeneral: "Title",
    sellersSelected: "sellers selected",
    body: "Body",
    destination: "Destination",
    destinationsSellers: "Destinations to Sellers",
    fields: {
      titleGeneral: "Title",
      Destination: "Destination",
      Body: "Body",
      Sent: "Sent",
    },
    emailTo: "Email to {email}",
    chooseATitle: "Choose a title for your email",
    chooseTheRecipient: "How do you want to choose the recipient?",
    typeEmailAddress: "Type the email address",
    chooseAmongMySellers: "Choose among my sellers",
    clickHereToChangeThisAddress: "Click here to change this address",
    changeYourConfiguratedSenderAddress:
      "Change your configurated sender address",
    youDontHaveAnyAddressSetUpYet: "You don't have any addresses set up yet",
  },
  gGeneralEmailsViewPage: {
    title: "General E-mail #",
    iD: "ID",
    sent: "Sent",
    titleGeneral: "Title",
    destination: "Destination",
    body: "Body",
    editGeneralEmail: "Edit General Email",
    deleteGeneralEmail: "Delete General Email",
    sureWantDelete: "Are you sure you want to delete this General Email",
  },
  nobordistLogsPage: {
    title: "Nobordist Logs",
    fields: {
      action: "Action",
      actionType: "Action Type",
      userID: "User ID",
      logs: "Logs",
      creationDate: "Creation Date",
    },
  },
  nobordistLogsViewPage: {
    title: "Log #",
    action: "Action",
    userName: "User Name",
    creationDate: "Creation Date",
    updateDate: "Update Date",
    logs: "Logs",
  },
  notificationsPage: {
    title: "Send notifications",
    subtitle:
      "Read notifications template or create notifications to sellers or customers",
    newNotification: "New notification",
    settingYourNotification: "Setting your notification.",
    generalData: "General data",
    settingNotificationParams: "Set notification params",
    selectRecipientType: "Select the type recipient",
    to: "To",
    recipient: "Recipient",
    subject: "Subject",
    message: "Message",
    sendNow: "Send now",
    subjects: {
      finance: "Finance",
      insufficientBalance: "Insufficient balance",
      trackingIssue: "Tracking issue",
      newTicket: "New ticket",
    },
    seller: "Seller",
    user: "User",
    readed: "Read",
    seeMessage: "See message",
    notification: "Notification",
    seeSendedNotification: "View the message that was sent",
    sendedMessage: "Sended message",
    sendDate: "Send date",
    createNotification: "Create Notification",
    body: "Body",
    respondApprovalRequest: "Respond to approval request",
    notificationMessage: "Notification Message",
    contract: "Contract",
    name: "Name",
    file: "File",
    validityDate: "Validity Date",
    contracts: "Contracts",
    click: "Click",
    hERE: "HERE",
    seeDetailedData: "to see the detailed data",
    thisRequest: "This request",
    is: "is",
    hasBeen: "has been",
    supervisorCommented: "The supervisor commented",
    addCommentResponse: "You can add a comment to your response",
    approve: "Approve",
    deny: "Deny",
    by: "by",
    ChooseRecipientType: "Choose recipient type",
    Seller: "Seller",
    User: "User",
    Title: "Title",
    finance: "Finance",
    insufficientBalance: "Insufficient Balance",
    trackingIssue: "Tracking Issue",
    newTicket: "New Ticket",
    dataUpdate: "Data Update",
    contractPermission: "Contract Permission",
    contractUpdate: "Contract Update",
    priceTableUpdate: "Price Table Update",
    generalIssue: "General Issue",
    fields: {
      newRead: "New/Read",
      tType: "Type",
      recipient: "Recipient",
      title: "Title",
      body: "Body",
      status: "Status",
    },
    titleNotification: "Title",
    type: "Type",
    new: "New",
    recipientType: "Recipient type",
  },
  rejectedOrderPage: {
    title: "Rejected Orders",
    subTitle:
      "This pages presents all the failed imported orders that your sellers tried to create on their importation page. You are able to look at the parameters imported as well as the error that made the importation fail",
    sellerName: "Seller Name",
    fields: {
      orderNumber: "Order Number",
      dateImport: "Date of import",
      orderInfo: "Order Info",
      sellerID: "Seller ID",
      error: "Error",
    },
  },
  rejectedOrderViewPage: {
    title: "Rejected Order Log #",
    seller: "Seller",
    creationDate: "Creation Date",
    orderInfo: "Order Info",
    erroInfo: "Erro Info",
  },
  trackingRulesPage: {
    title: "Tracking Rules",
    subTitle: "Automatize notifications when certain tracking events occur",
    addTrackingRule: "Add Tracking Rule",
    addNewTrackingRule: "Add New Tracking Rule",
    trackingMode: "Tracking Mode",
    receivedCheckpoint: "Received Checkpoint",
    timeBetweenTwoCheckpoints: "Time Between Two Checkpoints ",
    name: "Name",
    contract: "Contract",
    incoterm: "Incoterm",
    any: "Any",
    startCheckpoint: "Start Checkpoint",
    endCheckpoint: "End Checkpoint",
    maximumDays: "Maximum Days",
    checkpoint: "Checkpoint",
    log: "Generate Logs",
    notifySeller: "Notify Seller",
    sellerNotification: "Seller Notification",
    notifyCustomer: "Notify Customer",
    notifyNobordist: "Notify Nobordist",
    webhook: "Use Webhook",
    method: "Method",
    pOST: "POST",
    pUT: "PUT",
    headers: "Headers",
    addHeaders: "Add Headers",
    key: "Key",
    value: "Value",
    seller: "Seller",
    titleRules: "Title",
    fields: {
      name: "Name",
      startCheckpoint: "Start Checkpoint",
      endCheckpoint: "End Checkpoint",
      incoterm: "Incoterm",
      seller: "Seller",
      contract: "Contract",
      daysLimit: "Days Limit",
      mode: "Mode",
      creationDate: "Creation Date",
    },
    allSellerNotificationTitles: {
      finance: "Finance",
      insufficientBalance: "Insufficient Balance",
      trackingIssue: "Tracking Issue",
    },
    chooseTrigger: "Choose the trigger you want to use to apply the rule",
    whenSpecificCheckpointOccur: "When a specific checkpoint occurs",
    whenDelayIsTooLong: "When the delay is too long between 2 checkpoints",
    applyRuleToSellersHelpText:
      "You can apply the rule to all sellers by letting this field unselected",
    applyRuleToContractsHelpText:
      "You can apply the rule to all contracts by letting this field unselected",

    amountToWaitCheckpointsHelpText:
      "The maximum time to wait between the 2 checkpoints",
    endCheckpoointHelpText:
      "The target end checkpoint, if you want to apply it to any checkpoint, just let this field unselected",
    giveNameToYourRule: "Give a name to your rule",
    chooseWhatWillBeSent: "Choose what will be sent",
    logHelpText: "Create a Tracking Alert Log in the administrative site",
    notifySellerHelpText: "Send an email to the registered seller email",
    notifyCustomerHelpText:
      "Send an email to the customer using the email address informed in the order",
    notifyNbHelpText:
      "Send an email to some admin user or general corporate email",
    sellerNotificationHelpText:
      "Send a notification to the seller on his website account",
    webhookHelpText: "Send a webhook to any API",
    sellerInternalNotification: "Seller Internal Notification",
  },
  trackingRulesViewPage: {
    title: "Tracking Rule",
    tracking: "Tracking",
    editTrackingRule: "Edit Tracking Rule",
    deleteTrackingRule: "Delete Tracking Rule",
    confirmDeleteRule: "Are you sure you want to delete this rule?",
    edit: "Edit",
    name: "Name",
    contract: "Contract",
    startCheckpoint: "Start Checkpoint",
    endCheckpoint: "End Checkpoint",
    maximumDays: "Maximum Days",
    log: "Log",
    notifySeller: "Notify Seller",
    notifyCustomer: "Notify Customer",
    notifyNobordist: "Notify Nobordist",
    webhook: "Webhook",
    method: "Method",
    pOST: "POST",
    pUT: "PUT",
    headers: "Headers",
    key: "Key",
    value: "Value",
    addHeaders: "Add Headers",
    sureWantDelete: "Are you sure you want to delete this tracking Rule",
    seller: "Seller",
    customer: "Customer",
    nobordist: "Nobordist",
    daysLimit: "Days Limit",
    trackingMode: "Tracking Mode",
    sellerMailTitle: "Seller Mail Title",
    sellerMailBody: "Seller Mail Body",
    customerMailTitle: "Customer Mail Title",
    customerMailBody: "Customer Mail Body",
    nobordistMailTitle: "Nobordist Mail Title",
    destination: "Destination",
    nobordistMailBody: "Nobordist Mail Body",
    notifyWebhook: "Notify Webhook",
    endpoint: "Endpoint",
    body: "Body",
    noActionConfigured: "No action configured",
  },
  userNotificationsPage: {
    title: "User Notifications",
    subTitle: "Read notifications sent to you",
    respondRequest: "Respond to approval request",
    notificationMessage: "Notification Message",
    name: "Name",
    file: "File",
    validityDate: "Validity Date",
    contracts: "Contracts",
    contract: "Contract",
    click: "Click",
    hERE: "HERE",
    seeDetailedData: "to see the detailed data",
    thisRequest: "This F",
    is: "is",
    hasBeen: "has been",
    by: "by",
    supervisorCommented: "The supervisor commented",
    addCommentResponse: "You can add a comment to your response",
    approve: "Approve",
    deny: "Deny",
    seller: "Seller",
    fields: {
      data: "Data",
      title: "Title",
      body: "Body",
    },
    filterOptions: {
      title: "Title",
      seller: "Seller",
      body: "Body",
      new: "New",
      status: "Status",
      read: "Read",
    },
    allSellerNotificationTitles: {
      finance: "Finance",
      insufficientBalance: "Insufficient Balance",
      trackingIssue: "Tracking Issue",
    },
    sellerNotificationTitles: {
      finance: "Finance",
      insufficientBalance: "Insufficient Balance",
      trackingIssue: "Tracking Issue",
      newTicket: "New Ticket",
    },
    userNotificationTitles: {
      finance: "Finance",
      insufficientBalance: "Insufficient Balance",
      trackingIssue: "Tracking Issue",
      newTicket: "New Ticket",
      dataUpdate: "Data Update",
      contractPermission: "Contract Permission",
      contractUpdate: "Contract Update",
      priceTableUpdate: "Price Table Update",
    },
    seconds: "seconds",
    minuteAgo: "1 minute ago",
    minutes: "minutes",
    hourAgo: "1 hour ago",
    hours: "hours",
    yesterday: "yesterday",
    days: "days",
    lastWeek: "Last week",
    weeks: "weeks",
    lastMonth: "Last month",
    months: "months",
    lastYear: "Last year",
    years: "years",
  },
  myNotificationsListPage: {
    title: "Notifications",
    found: "no notification found | notification | notifications",
    filters: {
      isNew: "New or Read",
    },
    allNotifications: "All notifications",
    youHaveNewNotification: "You have new unread notifications.",
  },
  platformsPage: {
    title: "Platforms",
    addPlatform: "Add Platform",
    AddNewPlatform: "Add new Platform",
    platformName: "Platform Name",
    correiosCredentials: "Correios Credentials",
    addDispatch: "Add Dispatch",
    key: "Key",
    value: "Value",
    fields: {
      name: "Name",
      actions: "Actions",
    },
  },
  flightsPage: {
    title: "Flights",
    subtitle: "Check and inform which flight each order is on.",
    nextFlights: "Upcoming flights",
    createNewFlight: "Create new flight",
    newFlight: "New flight",
    selectMasterboxes: "Select masterboxes",
    findAndSelectMasterboxes:
      "Search and select the masterboxes you want to add to your flight",
    typeMasterboxesReference: 'Type the Masterbox reference and press "Enter"',
    selectedMasterboxes: "Selected masterboxes",
    reference: "Reference",
    next: "Proceed",
    insertFlightData: "Enter flight details",
    pleaseInsertFlightData: "Please enter flight details to add masterboxes.",
    howWouldLikeToUploadMawb: "How would you like to upload the MAWB?",
    generalData: "General information",
    departureAndArrive: "Departure and arrival",
    taxes: "Fees",
    returnsToPreviousStep: "Return to previous step",
    createFlight: "Create flight",
    uploadFromMyComputer: "Upload from my computer",
    documentsAndLabels: "Documents and labels",
    editFlightData: "Edit flight data",
    suggestedActions: "Suggested actions",
    copyLabelCode: "Copy MAWB number",
    taxesAndOthers: "Fees and others",
    masterboxes: "Masterboxes",
    departure: "Match",
    stopover: "Scale",
    arrival: "Arrival",
    noStopOver: "Non-stop flight",
    closeManifestConfirm:
      "Do you want to close this manifest? You will no longer be able to update your content after this action.",
    closeManifest: "Close manifest",
    confirmDeparture: "Confirm departure",
    deleteFlight: "Delete flight",
    fields: {
      airline: "Airline",
      flightNumber: "Flight Number",
      flightLabel: "Flight Label",
      mawbNumber: "Mawb Number",
      greenLight: "Green Light",
      prealertsSent: "Prealerts Sent",
      departureAirport: "Departure Airport",
      departureDate: "Departure Date",
      departureTime: "Departure time",
      arrivalAirport: "Arrival Airport",
      arrivalDate: "Arrival Date",
      arrivalTime: "Arrival Time",
      stopoverAirport: "Stopover Airport",
      freightValue: "Freight Value",
      creationDate: "Creation Date",
      status: "Status",
    },
  },
  flightsViewPage: {
    title: "Flight #",
    mawbFileLink: "Mawb File Link",
    mawbLabel: "Mawb Label",
    updateFlight: "Update Flight",
    information: "Information",
    mawb: "Mawb",
    shipments: "Shipments",
    pages: "Pages",
    closeManifest: "Close Manifest",
    confirmDeparture: "Confirm Departure",
    wantRemoveFlight: "Do you want to remove this flight permanently",
    departureAirport: "Departure Airport",
    arrivalAirport: "Arrival Airport",
    stopoverAirport: "Stopover Airport",
    departureDate: "Departure Date",
    arrivalDate: "Arrival Date",
    departureTime: "Departure Time",
    arrivalTime: "Arrival Time",
    airline: "Airline",
    flightNumber: "Flight Number",
    subtotal: "Subtotal",
    chargesKilo: "Charges per Kilo",
    otherCharges: "Other Charges",
    mawbNumber: "Mawb Number",
    mawbFileFormat: "Mawb File Format",
    binary: "binary",
    link: "link",
    mawbFile: "Mawb File",
    copyReference_id: "click here to copy the reference_id",
    greenLight: "Green Light",
    prealertsSend: "Prealerts Send",
    date: "Date",
    downloadCurrent: "Download current MAWB",
    noMawbDocument: "There is currently no MAWB document for this flight",
  },
  masterboxesPage: {
    title: "Masterboxes",
    subtitle: "Filter and check boxes with volumes and orders.",
    createMasterbox: "Create masterbox",
    documentsAndLabels: "Documents and labels",
    suggestedActions: "Suggested actions",
    masterboxData: "Masterbox data",
    labels: "Labels",
    mbCarriers: "Carriers",
    reference: "Reference",
    shipmentID: "Shipment ID",
    shipmentSequenceNumber: "Shipment Sequence Number",
    date: "Date",
    cN35Label: "CN35 Label",
    volumes: "Volumes",
    dispatchID: "Dispatch ID",
    goToShipment: "View shipment",
    creationDate: "Creation date",
    weight: "Weight",
    cubedWeight: "Cubed Weight",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    lastMileCarrierNumber: "Last Mile Carrier Number",
    totalItems: "Total Items",
    deleteMasterbox: "Remove Masterbox",
    confirmDeleteMasterbox: "Do you want to remove this masterbox permanently?",
    selectVolumes: "Select volumes",
    selectedVolumes: "Selected volumes",
    findAndSelectVolumes:
      "Browse and select volumes you want to add to the masterbox",
    typeVolumeReference: 'Type volume reference and press "Enter"',
    downloadMasterboxErrors: "Download CSV of Masterbox errors",
    commentDownloadMasterboxErrors:
      "* Only volumes with last_mile_tracking_number will be added to the csv",
    goToFlight: "Go to flight",
    volumesCount: "Volumes Count",
  },
  masterboxesViewPage: {
    title: "Masterbox #",
    dispatchID: "Dispatch ID",
    reference: "reference",
    shipmentID: "Shipment ID",
    shipmentSequenceNumber: "Shipment Sequence Number",
    date: "Date",
    cN35Label: "CN35 Label",
    volumes: "Volumes",
    copyReference_id: "click here to copy the reference_id",
    fields: {
      weight: "Weight",
      cubedWeight: "Cubed Weight",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      totalItems: "Total Items",
    },
  },
  ordersPage: {
    title: "Orders Management",
    subtitle: "Filter and check orders, download reports and more.",
    importMabangOrders: "Import Mabang's Orders",
    importOrderCosts: "Import Order Costs",
    downloadOrders: "Download Orders",
    downloadOrdersCosts: "Download Orders Costs",
    updateFinancialStatus: "Update Financial Status",
    lastMileCarrierNotFound: "Last Mile Carrier Nº not found",
    new: "New",
    invoiced: "Invoiced",
    toInvoice: "To Invoice",
    canceled: "Canceled",
    notCharged: "Not Charged",
    checkDownloadsAt: "Check the processing downloads at... downloads page.",
    selectOption: "Please select an option",
    orderNo: "Order No.",
    fieldsCurrentOrderCosts: {
      externalID: "External ID",
      type: "Type",
      value: "Value",
      creationDate: "Creation Date",
    },
    financial_statuses: {
      new: "New",
      toInvoice: "To Invoice",
      invoiced: "Invoiced",
      canceled: "Canceled",
      notCharged: "Not Charged",
    },
    fields: {
      sellerID: "Seller ID",
      sellerName: "Seller name",
      sellerTaxNumber: "Seller tax number",
      contractID: "Contract ID",
      overpackID: "Overpack ID",
      customerName: "Customer Name",
      postalCode: "Customer Postal Code",
      customerCity: "Customer City",
      customerState: "Customer State",
      incoterm: "Incoterm",
      data_source: "Source",
      orderNumber: "Order Number",
      salesChOrderN: "Sales Ch. Order Nº",
      productsValue: "Products Value",
      freightValue: "Freight Value",
      declaredValue: "Declared Value",
      dutiesTaxValue: "Duties Tax Value",
      salesTaxValue: "Sales Tax Value",
      taxValue: "Tax Value",
      totalValue: "Total Value",
      estimatedDutiesTaxValue: "Estimated Duties Tax Value",
      estimatedSalesTaxValue: "Estimated Sales Tax Value",
      estimatedTaxValue: "Estimated Tax Value",
      freightCost: "Freight Cost",
      estimatedFreightCost: "Estimated Freight Cost",
      taxCost: "Tax Cost",
      insuranceCost: "Insurance Cost",
      insuranceCoverage: "Insurance Coverage",
      dDPCost: "DDP Cost",
      totalGeneralCosts: "Total General Costs",
      trackingNumber: "Tracking Number",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      isMini: "Is Mini?",
      financialStatus: "Financial Status",
      creationDate: "Creation Date",
      mSDS: "MSDS",
      label: "Label",
      firstMileLabel: "First Mile Label",
      shipConfirmation: "Ship Confirmation",
      isLanded: "Is Landed?",
      customerDocumentType: "Customer document type",
      customerDocumentNumber: "Customer document number",
      customerAddress: "Customer address",
      customerAddressNumber: "Customer address number",
      customerAddressComplement: "Customer address complement",
      customerAddressReference: "Customer address reference",
      customerAddressQuarter: "Customer address quarter",
      customerPhone: "Customer phone",
      customerEmail: "Customer email",
      customerCountry: "Customer country",
      sellerAddress: "Seller address",
      sellerAddressNumber: "Seller address number",
      sellerAddressComplement: "Seller address complement",
      sellerCity: "Seller city",
      sellerState: "Seller state",
      sellerPhone: "Seller phone",
      sellerEmail: "Seller email",
      sellerPostalCode: "Seller postal code",
      sellerCountry: "Seller country",
      sellerWebsite: "Seller website",
      currentStatus: "Current status",
      estimatedDeliveryDate: "Estimated delivery date",
      salesChannelOrderNumber: "Sales channel order number",
      orderReference: "Order reference",
      dollarReais: "Dollar Reais",
    },
    removeOrdersCount: "Remove %{count} orders",
    removeOrders: "Remove orders",
    toBeRemoved:
      "The remaining %{count} orders are available for removal. Are you sure you want to remove them? This action is irreversible",
  },
  ordersViewPage: {
    title: "Order {id}",
    label: "Label",
    addCost: "Add Cost",
    commercialInvoice: "Commercial Invoice",
    trackingPage: "Tracking Page",
    packingSlip: "Packing Slip",
    cevaShippersConfirmation: "Ceva Shippers Confirmation",
    cUSTOMER: "CUSTOMER",
    relatedObjects: "Related objects",
    freight: "Freight",
    editOrder: "Edit Order",
    generalInformations: "GENERAL INFORMATIONS",
    valuesSummary: "VALUES SUMMARY",
    generalCosts: "General Costs",
    volumes: "Volumes",
    costType: "Cost Type",
    selecttype: "Select type",
    value: "Value",
    name: "Name",
    documentType: "Document Type",
    documentNumber: "Document Number",
    address: "Address",
    addressNumber: "Address Number",
    addressComplement: "Address Complement",
    addressReference: "Address Reference",
    postalCode: "Postal Code",
    addressQuarter: "Address Quarter",
    city: "City",
    state: "State",
    country: "Country",
    phone: "Phone",
    email: "Email",
    mailbox: "Mailbox",
    phoneCountryCode: "Phone Country Code",
    salesChannelID: "Sales Channel ID",
    sellerIDcomp: "Seller ID comp",
    contractID: "Contract ID",
    overpackID: "Overpack ID",
    currency: "Currency",
    incoterm: "Incoterm",
    returnInsurance: "Return Insurance",
    insuranceCoverage: "Insurance Coverage",
    returnModality: "Return Modality",
    freightValue: "Freight Value",
    orderNumbe: "Order Numbe",
    createdDate: "Created Date",
    sellerName: "Seller's name",
    estimatedDeliveryDate: "Estimated Delivery Date",
    trackingNumber: "Tracking Number",
    isMini: "Is Mini",
    observation: "Observation",
    declaredValue: "Declared Value",
    taxValue: "Tax Value",
    total: "Total",
    remove: "Remove",
    wantToRemore:
      "Are you sure you want to delete this order? This operation is irreversible",
    estimatedTaxValue: "Estimated Tax Value",
    freightCost: "Freight Cost",
    volumesFields: {
      Weight: "Weight",
      cubedWeight: "Cubed Weight",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      productValue: "Product Value",
      freightValue: "Freight Value",
      estimatedFreightCost: "Estimated Freight Cost",
      taxValue: "Tax Value",
      totalItems: "Total Items",
      updatedWeight: "Updated Weight",
      productsValue: "Products value",
      itemsAmount: "Items amount",
      collectedIcms: "Collected ICMS",
      collectedII: "Collected II",
    },
    fieldsCurrentOrderCosts: {
      externalID: "External ID",
      type: "Type",
      value: "Value",
      creationDate: "Creation Date",
    },
    orderData: "Order data",
    customerData: "Customer data",
    shippingInfo: "Shipping information",
    reviewValues: "Review values",
    copyTrackingNumber: "Copy tracking number",
    showAllCustomerData: "Show all customer data",
    collapseCustomerData: "Colapse customer data",
    showAllOrderData: "Show all order data",
    collapseOrderData: "Collapse order data",
    dispatched: "Dispatched",
    goToOrder: "Go to order",
    goToSeller: "Go to seller",
    goToContract: "Go to contract",
    goToTrackingPage: "Go to tracking page",
    lastMileLabel: "Last Mile Label",
    generalInformationsTitle: "General information",
    generalInformationsDescription: "See main settings",
    landedCost: "Landed cost",
    landedCostDescription:
      "Indicates that all the values passed on the creation already contain taxes and fret",
    dispatchedDescription:
      "When dispatched, the order is ready to follow the flow, get tracking statuses, etc…",
    senderIsCpf: "Sender is CPF",
    receiverIsCommercial: "Receiver is commercial",
    numbersAndReferences: "Numbers & references",
    numbersAndReferencesDescription: "All numbers you may need",
    orderReference: "Reference",
    orderReferenceDescription:
      "Unique identifier to the order, generated by Nobordist",
    orderNumberDescription: "Number chosen by the seller to identify the order",
    internalNBID: "Internal NB Id",
    internalNBIDDescription: "Used for internal operations",
    copyReference: "Copy reference",
    salesChannelOrderNumberDescription:
      "Number identifying the order in the sales channel",
    customer: "Customer",
    nameAddressContactInformation: "Name, address, contact information",
    shipper: "Shipper",
    rfbCodeDescription:
      "The seller identifier in the Brazilian IRS system, allowing to create PRC orders",
    items: "Items",
    itemsDescription: "List of items and their info",
    goToVolume: "Go to volume",
    itemDescription: "Item description",
    costs: "Costs",
    dimensions: "Dimensions",
    seeFullDescription: "See full description",
    seeCosts: "See costs",
    seeDimensions: "See dimensions",
    seeVolume: "See volume",
    informedBySeller: "Informed by seller",
    volumesDescription: "List of volumes and their information",
    costsDescription: "Full description of costs and values",
    exchangeOnCreation: "Exchange on creation",
    estimatedTaxes: "Estimated taxes",
    computedByNbSystem: "Computed by NB system",
    dutiesTax: "Duties tax",
    dutiesTaxDescription: "National Tax (I.I.)",
    estimatedFreightCostDescription: "Estimated from price table",
    salesTax: "Sales tax",
    salesTaxDescription: "State Tax (ICMS)",
    totalTax: "Total tax",
    actualTaxes: "Actual taxes",
    totalValue: "Total value",
    summary: "Summary",
    products: "Products",
    freightCostDescription:
      "Current freight cost, taken from the estimated freight cost but might have been updated later on",
    orderCosts: "Order costs",
    orderCostsDescription: "See all chargings made for this order",
    relatedObjectsDescription: "Find related seller, contract…",
    salesChannel: "Sales channel",
    observations: "Observations",
    freeFieldCreatedBySeller: "Free field created by the seller",
    integrationData: "Integration data",
    additionalData: "Additional Data",
    additionalDataDescription: "Optional data that might be useful",
  },
  overpacksPage: {
    title: "Overpacks Management",
    updateFirstMileInformation: "Update First Mile Information",
    carriername: "Carrier name",
    trackingNumber: "Tracking number",
    updateInformation: "Update Information",
    updateTrackingStatus: "Update Tracking Status",
    updateOverpacks: "Update Overpacks",
    overpackSelected: "Overpack Selected",
    selectOneStatus: "Select One Status",
    statusSelected: "Status Selected",
    fields: {
      sentDate: "Sent Date",
      trackingNumber: "Tracking Number",
      masterboxID: "Masterbox ID",
      fMTrackingN: "FM Tracking N°",
      firstMileCompany: "First Mile Company",
      creationDate: "Creation Date",
    },
    trackingOptions: {
      forwardedlocalhub: "The shipment has been forwarded to a local hub",
      arrivedHubSorting: "The shipment has arrived at a hub or sorting center",
      clearanceCompleted: "The process of customs clearance is completed",
      shipmentArrivedDestinationCountry:
        "The international shipment has arrived at the destination country",
      handedCustomsCustomsClearance:
        "The shipment has been handed over to the customs for the process of customs clearance",
      customsClearanceCompleted:
        "The process of customs clearance is completed",
      arrivedHubSortingCenter:
        "The shipment has arrived at a hub or sorting center",
    },
  },
  overpacksViewPage: {
    title: "Overpack",
    new: "New",
    ready: "Ready",
    dispatched: "Dispatched",
    orders: "Orders",
    orderFoundOverpack: "No order found for Overpack #",
    cN23Numbers: "CN23 Numbers",
    cN23foundforOverpack: "No CN23 found for Overpack #",
    masterboxID: "Masterbox ID",
    createdDate: "Created Date",
    sentDate: "Sent Date",
    trackingNumber: "Tracking Number",
    firstMileCarrierName: "First Mile Carrier Name",
    firstMileTrackingN: "First Mile Tracking Nº",
    fields: {
      orderNumber: "Order Number",
      trackingNumber: "Tracking Number",
      creationDate: "Creation Date",
      customerName: "Customer Name",
    },
  },
  shipmentsPage: {
    title: "Shipments",
    subtitle: "Filter and check shipments, download reports and more.",
    downloadShipments: "Download Shipments",
    actualWeight: "Actual Weight",
    checkProcessingDownloads:
      "Check the processing downloads at... downloads page",
    goToFlight: "Go to flight",
    fields: {
      cn38: "cn38",
      cn38Label: "cn38 Label",
      sSellerWeight: "Seller Weight",
      actualWeight: "Actual Weight",
      category: "Category",
      flightID: "Flight ID",
      creationDate: "Creation Date",
    },
    filterOptions: {
      sellerStartWeight: "Seller Start Weight",
      sellerEndWeight: "Seller End Weight",
      actualStartWeight: "Actual Start Weight",
      actualEndWeight: "Actual End Weight",
    },
  },
  shipmentsViewPage: {
    title: "Shipment #",
    category: "Category",
    flightID: "Flight ID",
    cn38: "cn38",
    date: "Date",
    cn38Label: "cn38 Label",
    masterboxes: "Masterboxes",
    pages: "Pages",
    fields: {
      reference: "Reference",
      dispatchID: "Dispatch ID",
      lastMileTrackingNumbers: "Last Mile Tracking Numbers",
    },
  },
  volumeReversePage: {
    title: "Volumes Reverse",
    subtitle: "Filter and check orders, download reports and more.",
    fields: {
      orderNumber: "Order Number",
      orderID: "Order ID",
      volumeID: "Volume ID",
      sellerID: "Seller ID",
      name: "Name",
      creationdate: "Creation date",
      reverseNumber: "Reverse Number",
      address: "Address",
      addressNumber: "Address Number",
      addressComplement: "Address Complement",
      state: "State",
      postalCode: "Postal Code",
      country: "Country",
      city: "City",
      customerName: "Customer Name",
      orderItemsName: "Order Items Name",
      orderItemsdescription: "Order Items description",
      orderItemsHSCode: "Order Items HS Code",
      startRegistrationDate: "Start Registration Date",
      endRegistrationDate: "End Registration Date",
      order: "Order",
      seller: "Seller",
      volume: "Volume",
    },
  },
  volumeReverseViewPage: {
    title: "Volume Reverse #",
    volumeReverse: "VOLUME REVERSE",
    order: "ORDER",
    volume: "VOLUME",
    customer: "CUSTOMER",
    items: "Items",
    reverseNumber: "Reverse Number",
    createdDate: "Created Date",
    name: "Name",
    address: "Address",
    addressNumber: "Address Number",
    addressComplement: "Address Complement",
    state: "State",
    postalCode: "Postal Code",
    country: "Country",
    city: "City",
    orderID: "Order ID",
    orderNumber: "Order Number",
    salesChannelOrderNumber: "Sales Channel Order Number",
    totalValue: "Total Value",
    trackingNumber: "Tracking Number",
    volumeID: "Volume ID",
    height: "Height",
    width: "Width",
    length: "Length",
    weight: "Weight",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    documentType: "Document Type",
    documentNumber: "Document Number",
    addressReference: "Address Reference",
    addressQuarter: "Address Quarter",
    phone: "Phone",
    email: "Email",
    mailbox: "Mailbox",
    phoneCountryCode: "Phone Country Code",
    fields: {
      name: "Name",
      value: "Value",
      hsCode: "Hs Code",
      Quantity: "Quantity",
      originCountry: "Origin Country",
    },
  },
  volumesPage: {
    title: "Volumes",
    subtitle: "Filter and check, download volumes or create masterbox",
    editVolumes: "Edit Volumes",
    new: "New",
    invoiced: "Invoiced",
    toInvoice: "To Invoice",
    canceled: "Canceled",
    notCharged: "Not Charged",
    downloadMasterboxErrors: "Download CSV of Masterbox errors",
    commentDownloadMasterboxErrors:
      "* Only volumes with last_mile_tracking_number will be added to the csv",
    wantCreateMasterbox:
      "Do you want to create a masterbox with the volumes selected below?",
    volumes: "volumes",
    copyReference_id: "click here to copy the reference_id",
    downloadVolumes: "Download volumes",
    createMasterbox: "Create masterbox",
    fields: {
      weight: "Weight",
      height: "Height",
      Width: "Width",
      length: "Length",
      cubedWeight: "Cubed Weight",
      reference: "Reference",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      collectedIcms: "Collected ICMS",
      collectedIi: "Collected II",
      orderIsCommercialDestination: "Order Is Commercial Destination",
      isCommercialDestination: "Is Commercial Destination",
      orderSellerRfbCode: "Order Seller RFB Code",
      sellerRfbCode: "Seller RFB Code",
      orderSellerLegalName: "Order Seller Legal Name",
      sellerLegalName: "Seller Legal Name",
      cN23: "CN23",
      firstMileLabel: "First Mile Label",
      firstMileTrackingNumber: "First Mile Tracking Number",
      creationDate: "Creation Date",
      volumeProductsValue: "Volume Products Value",
      volumeFreightValue: "Volume Freight Value",
      volumeDeclaredValue: "Volume Declared Value",
      volumeDutiesTaxValue: "Volume Duties Tax Value",
      volumeSalesTaxValue: "Volume Sales Tax Value",
      volumeTaxValue: "Volume Tax Value",
      volumeTotalValue: "Volume Total Value",
      volumeEstimatedDutiesTaxValue: "Volume Estimated Duties Tax Value",
      volumeEstimatedSalesTaxValue: "Volume Estimated Sales Tax Value",
      volumeEstimatedTaxValue: "Volume Estimated Tax Value",
      volumeFreightCost: "Volume Freight Cost",
      volumeEstimatedFreightCost: "Volume Estimated Freight Cost",
      hSCode: "HS Code",
      description: "Description",
      currentStatus: "Current Status",
      updatedWeight: "Updated Weight",
      orderID: "Order ID",
      mSDS: "MSDS",
      sellerID: "Seller ID",
      firstMileN: "First Mile Nº",
      contractID: "Contract ID",
      customerName: "Customer Name",
      cPostalCode: "C. Postal Code",
      customerCity: "Customer City",
      customerState: "Customer State",
      customerAddress: "Customer Address",
      customerAddressNumber: "Customer Address Number",
      customerAddressComplement: "Customer Address Complement",
      customerAddressReference: "Customer Address Reference",
      customerAddressQuarter: "Customer Address Quarter",
      customerDocumentType: "Customer Document Type",
      customerDocumentNumber: "Customer Document Number",
      customerCountry: "Customer Country",
      customerEmail: "Customer Email",
      customerPhone: "Customer Phone",
      currency: "Currency",
      estimatedDeliveryDate: "Estimated Delivery Date",
      incoterm: "Incoterm",
      orderNumber: "Order Number",
      salesChannel: "Sales Channel",
      salesChOrderNumber: "Sales Ch. Order Number",
      totalValue: "Total Value",
      orderReference: "Order Reference",
      financialStatus: "Financial Status",
      orderProductsValue: "Order Products Value",
      orderFreightValue: "Order Freight Value",
      orderDeclaredValue: "Order Declared Value",
      orderDutiesTaxValue: "Order Duties Tax Value",
      orderSalesTaxValue: "Order Sales Tax Value",
      orderTaxValue: "Order Tax Value",
      orderTotalValue: "Order Total Value",
      orderEstimatedDutiesTaxValue: "Order Estimated Duties Tax Value",
      orderEstimatedSalesTaxValue: "Order Estimated Sales Tax Value",
      orderEstimatedTaxValue: "Order Estimated Tax Value",
      orderFreightCost: "Order Freight Cost",
      overpackID: "Overpack ID",
      overpackSentDate: "Overpack Sent Date",
      overpackReference: "Overpack Reference",
      fMTrackingNumber: "FM Tracking Number",
      firstMileCompany: "First Mile Company",
      masterboxID: "Masterbox ID",
      dispatchID: "Dispatch ID",
      cN35: "CN35",
      cN35Label: "CN35 Label",
      shipmentID: "Shipment ID",
      shipmentCategory: "Shipment Category",
      cN38: "CN38",
      cN38Label: "CN38 Label",
      flightID: "Flight ID",
      flightN: "Flight Nº",
      flightMAWB: "Flight MAWB",
      mAWBLabel: "MAWB Label",
      flightReference: "Flight Reference",
      airline: "Airline",
      greenLight: "Green Light",
      prealertsSent: "Prealerts Sent",
      departureAirport: "Departure Airport",
      arrivalAirport: "Arrival Airport",
      departureStartDate: "Departure Start Date",
    },
    filterOptions: {
      volumeStartDate: "Volume Start Date",
      volumeEndDate: "Volume End Date",
      volumeID: "Volume ID",
      reference: "Reference",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      lmtnPopover:
        "Accepts values separated by: commas, semicolons, spaces, or line breaks",
      withoutLastMileTrackingNumber: "Without Last Mile Tracking Number",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      estimatedFreightCost: "Estimated Freight Cost",
      orderStartDate: "Order Start Date",
      orderEndDate: "Order End Date",
      orderID: "Order ID",
      contractID: "Contract ID",
      sellerID: "Seller ID",
      freightCost: "Freight Cost",
      firstMileN: "First Mile Nº",
      lastMileN: "Last Mile Nº",
      orderNumber: "Order Number",
      salesChannel: "Sales Channel",
      salesChOrderNumber: "Sales Ch. Order Number",
      orderReference: "Order Reference",
      incoterm: "Incoterm",
      customerName: "Customer Name",
      customerAddress: "Customer Address",
      customerAddressNumber: "Customer Address Number",
      customerAddressComplement: "Customer Address Complement",
      customerAddressReference: "Customer Address Reference",
      customerAddressQuarter: "Customer Address Quarter",
      customerDocumentType: "Customer Document Type",
      customerDocumentNumber: "Customer Document Number",
      customerCountry: "Customer Country",
      customerEmail: "Customer Email",
      customerPhone: "Customer Phone",
      financialStatus: "Financial Status",
      overpackStartDate: "Overpack Start Date",
      overpackEndDate: "Overpack End Date",
      overpackStartSendDate: "Overpack Start Send Date",
      overpackEndSendDate: "Overpack End Send Date",
      overpackID: "Overpack ID",
      overpackReference: "Overpack Reference",
      bagNumber: "Bag Number",
      firstMileTrackingN: "First Mile Tracking Nº",
      firstMileCarrierName: "First Mile Carrier Name",
      masterboxStartDate: "Masterbox Start Date",
      masterboxEndDate: "Masterbox End Date",
      masterboxID: "Masterbox ID",
      cn35: "cn35",
      dispatchID: "Dispatch ID",
      shipmentStartDate: "Shipment Start Date",
      shipmentEndDate: "Shipment End Date",
      shipmentID: "Shipment ID",
      cN38: "CN38",
      shipmentCategory: "Shipment Category",
      flightStartDate: "Flight Start Date",
      flightEndDate: "Flight End Date",
      flightID: "Flight ID",
      flightMAWB: "Flight MAWB",
      flightN: "Flight Nº",
      flightreference: "Flight reference",
      airline: "Airline",
      greenLight: "Green Light",
      departureAirport: "Departure Airport",
      arrivalAirport: "Arrival Airport",
      departureStartDate: "Departure Start Date",
      departureEndDate: "Departure End Date",
      orderDate: "Order Date",
      shipmentDate: "Shipment Date",
      flightDate: "Flight Date",
      flightDepartureDate: "Flight Departure Date",
      departureDate: "Departure Date",
      masterboxDate: "Masterbox Date",
      overpackDate: "Overpack Date",
      overpackSendDate: "Overpack Send Date",
    },
    downloadFields: {
      weight: "Weight",
      height: "Height",
      width: "Width",
      length: "Length",
      reference: "Reference",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      creationDate: "Creation Date",
      volumeProductsValue: "Volume Products Value",
      volumeFreightValue: "Volume Freight Value",
      volumeDeclaredValue: "Volume Declared Value",
      volumeDutiesTaxValue: "Volume Duties Tax Value",
      volumeSalesTaxValue: "Volume Sales Tax Value",
      volumeTaxValue: "Volume Tax Value",
      volumeTotalValue: "Volume Total Value",
      volumeEstimatedDutiesTaxValue: "Volume Estimated Duties Tax Value",
      volumeEstimatedSalesTaxValue: "Volume Estimated Sales Tax Value",
      volumeEstimatedTaxValue: "Volume Estimated Tax Value",
      volumeFreightCost: "Volume Freight Cost",
      volumeEstimatedFreightCost: "Volume Estimated Freight Cost",
      hSCode: "HS Code",
      description: "Description",
      currentStatus: "Current Status",
      orderID: "Order ID",
      sellerID: "Seller ID",
      contractID: "Contract ID",
      customerName: "Customer Name",
      cPostalCode: "C. Postal Code",
      customerCity: "Customer City",
      customerState: "Customer State",
      customerAddress: "Customer Address",
      customerAddressNumber: "Customer Address Number",
      customerAddressComplement: "Customer Address Complement",
      customerAddressReference: "Customer Address Reference",
      customerAddressQuarter: "Customer Address Quarter",
      customerDocumentType: "Customer Document Type",
      customerDocumentNumber: "Customer Document Number",
      customerCountry: "Customer Country",
      customerEmail: "Customer Email",
      customerPhone: "Customer Phone",
      currency: "Currency",
      freightCost: "Freight Cost",
      freigtValue: "Freigt Value",
      estimatedDeliveryDate: "Estimated Delivery Date",
      incoterm: "Incoterm",
      orderNumber: "Order Number",
      salesChannel: "Sales Channel",
      salesChOrderNumber: "Sales Ch. Order Number",
      totalValue: "Total Value",
      orderReference: "Order Reference",
      financialStatus: "Financial Status",
      overpackID: "Overpack ID",
      overpackSentDate: "Overpack Sent Date",
      overpackReference: "Overpack Reference",
      fMTrackingNumber: "FM Tracking Number",
      firstMileCompany: "First Mile Company",
      masterboxID: "Masterbox ID",
      dispatchID: "Dispatch ID",
      cN35: "CN35",
      shipmentID: "Shipment ID",
      shipmentCategory: "Shipment Category",
      cN38: "CN38",
      flightID: "Flight ID",
      flightN: "Flight Nº",
      mAWBLabel: "MAWB Label",
      flightReference: "Flight Reference",
      airline: "Airline",
      greenLight: "Green Light",
      prealertsSent: "Prealerts Sent",
      departureAirport: "Departure Airport",
      arrivalAirport: "Arrival Airport",
      departureStartDate: "Departure Start Date",
    },
    financialStatuses: {
      nNew: "New",
      toInvoice: "To Invoice",
      invoiced: "Invoiced",
      canceled: "Canceled",
      notCharged: "Not Charged",
    },
  },
  volumesViewPage: {
    copyReference: "Copy reference",
    goToOrder: "Go to order",
    summaryValues: "Summary of values",
    trackingHistory: "Tracking history",
    editLastTrackingNumber: "Edit Last Mile Tracking Number",
    warningOnUpdateLastMileTrackingNumber:
      "Attention! This action cannot be undone.",
    title: "Volume {id}",
    label: "Label",
    cubed_weight: "Cubed Weight",
    packingSlip: "Packing Slip",
    commercialInvoice: "Commercial Invoice",
    valuesSummary: "VALUES SUMMARY",
    updatedWeight: "Updated Weight",
    items: "Items",
    trackinghistory: "Tracking history",
    pages: "Pages",
    item: "Item",
    height: "Height",
    heightbycurrier: "Height by currier",
    width: "Width",
    widthbycurrier: "Width by currier",
    weight: "Weight",
    weightbycurrier: "Weight by currier",
    length: "Length",
    lengthbycurrier: "Length by currier",
    orderID: "Order ID",
    creationDate: "Creation Date",
    currentStatus: "Current Status",
    reference: "Reference",
    lastMileCarrierNumber: "Last Mile Carrier Number",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    declaredValue: "Declared Value",
    taxValue: "Tax Value",
    total: "Total",
    estimatedTaxValue: "Estimated Tax Value",
    freightCost: "Freight Cost",
    estimatedFreightCost: "Estimated Freight Cost",
    name: "Name",
    value: "Value",
    hsCode: "Hs Code",
    quantity: "Quantity",
    originCountry: "Origin Country",
    decsription: "Decsription",
    valueWithTax: "Value With Tax",
    fields: {
      name: "Name",
      quantity: "Quantity",
      hsCode: "Hs Code",
      originCountry: "Origin Country",
      productValue: "Product Value",
      freightValue: "Freight Value",
      taxValue: "Tax Value",
    },
    checkpointFields: {
      title: "Title",
      statusCode: "Status Code",
      dateISO: "Date ISO",
      message: "Message",
      trackingFunction: "Tracking Function",
    },
    dirNumber: "DIR number",
    seePayments: "See payments",

    goToSeller: "Go to seller",
    goToContract: "Go to contract",
    goToMasterbox: "Go to masterbox",
    goToFlight: "Go to flight",
    goToTracking: "Go to tracking",
    goToVolumeReverse: "Go to volume reverse",
    seeTrackingAlertLogs: "See tracking alert logs",
    generalInformations: "General Informations",
    generalInformationsDescription: "See main settings",
    landedCost: "Landed Cost",
    landedCostDescription:
      "Indicates that all the values passed on the creation already contain taxes and freight",
    dispatched: "Dispatched",
    dispatchedDescription:
      "When dispatched, the order is ready to follow the flow, get tracking statuses, etc…",
    senderIsCpf: "Sender is CPF",
    invoice: "Invoice",
    receiverIsCommercial: "Receiver is Commercial",
    lastMileLabel: "Last Mile Label",
    numbersAndReferences: "Numbers & References",
    numbersAndReferencesDescription: "All numbers you may need",
    referenceDescription:
      "Unique identifier to the volume, generated by Nobordist",
    lastMileTrackingNumberDescription: "Number used for last mile tracking",
    firstMileTrackingNumberDescription: "Number used for first mile tracking",
    lastMileCarrierNumberDescription:
      "Number identifying the volume in the carrier’s system",
    orderNumberDescription: "Number chosen by the seller to identify the order",
    salesChannelOrderNumberDescription:
      "Number identifying the order in the sales channel",
    orderReferenceDescription:
      "Unique identifier to the order, generated by Nobordist",
    dirNumberDescription: "Used for duties payment",
    internalNBID: "Internal NB Id",
    internalNBIDDescription: "Used for internal operations",
    dimensions: "Dimensions",
    dimensionsDescription: "Weights and Measures",
    customer: "Customer",
    nameAddressContactInfo: "Name, address and contact information",
    shipper: "Shipper",
    rfbCodeDescription:
      "The seller identifier in the Brazilian IRS system, allowing to create PRC orders",
    itemsDescription: "List of items and their info",
    seeDimensions: "See dimensions",
    seeCosts: "See costs",
    costs: "Costs",
    seeFullDescription: "See full description",
    itemDescription: "Item description",
    costsDescription: "Full description of costs and values",
    relatedObjects: "Related objects",
    relatedObjectsDescription: "Find related seller, contract, flight…",
    trackingAlertLog: "Tracking alert log",
    seeLogs: "See logs",
    volumeReverse: "Volume reverse",
    seeVolumeReverse: "See volume reverse",
    additionalData: "Additional Data",
    additionalDataDescription: "Optional data that might be useful",
    freight: "Freight",
    freightValue: "Freight value",
    informedBySeller: "Informed by the seller",
    estimatedFreightCostDescription: "Estimated from price table",
    freightCostDescription:
      "Current freight cost, taken from the estimated freight cost but might have been updated later on",
    estimatedTaxes: "Estimated Taxes",
    computedByNbSystem: "Computed by NB system",
    dutiesTax: "Duties tax",
    dutiesTaxDescription: "National Tax (I.I.)",
    salesTax: "Salex tax",
    salesTaxDescription: "State Tax (ICMS)",
    totalTax: "Total tax",
    actualTaxes: "Actual taxes",
    summary: "Summary",
    productsValue: "Products value",
    totalValue: "Total value",
    prcWarningDescription:
      "In case of a PRC volume, these are the duties already collected by the seller",
    exchangeOnCreation: "Exchange on creation",
    generalDescription: "General description",
    uniqueExpressionToDescribeItems:
      "Unique expression to describe all the items in the volume",
    observations: "Observations",
    freeFieldCreatedBySeller: "Free field created by the seller",
    integrationData: "Integration data",
    updatedWeightDescription: "Weight verified by carrier after dispatch",
    cubedWeightDescription:
      "computed by multiplying the 3 dimensions in cm and dividing by 6000",
    withoutMAWBNumber: "Without MAWB number",
    products: "Products",
    trackingHistoryDescription: "Explore all tracking statuses",
    provider: "Provider",
    providerStatus: "Provider Status",
    providerMessage: "Provider Message",
  },
  carriersPage: {
    title: "Carrier Management",
    subtitle:
      "Add and manage your carriers and their respective delivery methods.",
    legalName: "Legal Name",
    rfbCode: "RFB Code",
    country: "Country",
    carrierIsSeller: "This carrier is also a seller",
    sellerReminder: "Don't forget to change the seller information",
    importCarrier: "Import Carrier from JSON",
    downloadCarrierSettings:
      "Download Carrier, Delivery Methods and Users Settings",
    addNewCarrier: "Add New Carrier",
    addCarrier: "Add Carrier",
    sellers: "Sellers",
    sellerSelect: "Fill with Seller data",
    allowed: "Allowed",
    blocked: "Blocked",
    addnewCarrier: "Add New Carrier",
    editCarrier: "Edit Carrier",
    name: "Name",
    address: "Address",
    addressnumber: "Address Number",
    zipcode: "Zip Code",
    state: "State",
    city: "City",
    phone: "Phone",
    site: "Website",
    email: "Email",
    taxnumber: "Tax Identification Number",
    namereference: "Reference Name",
    slug: "Slug",
    trackstatusfunction: "Status Tracking Function",
    carrierCode: "Carrier Code",
    addressComplement: "Address Complement",
    airportHub: "Airport Hub",
    fields: {
      name: "Name",
      slug: "Slug",
      city: "City",
      country: "Country",
      email: "Email",
      carrierCode: "Carrier Code",
      trackFunction: "Tracking Function",
      creationDate: "Creation Date",
      isSeller: "Is Seller?",
    },
    generalData: "General Data",
    addCarrierDetails: "Add carrier details",
    carrierAddress: "Carrier address",
    addCarrierOriginAddress: "Add carrier origin address",
    newCarrier: "New carrier",
    addDeliveryMethod: "Add delivery method",
    noDeliveryMethodFound: "No delivery methods found",
    noUserFound: "No users found",
    deliveryMethods: "Delivery methods",
    users: "Users",
    carriers: "Carriers",
    deliveryMethodFields: {
      name: "Added Methods",
      type: "Type",
      service: "Service",
      originCountry: "Origin Country",
      destinationCountry: "Destination Country",
    },
    userFields: {
      name: "Name",
      email: "Email",
      groupName: "Group Name",
      status: "Status",
    },
    seeMethod: "See Method",
    seeUser: "See User",
    airportHubHelpText:
      "The 3 letter IATA code of the airport from which the carrier is operating",
    rfbCodeHelpText: "Carrier identifier at the Brazilian IRS",
    isSellerHelpText: "Indicates that this business is also acting as a seller",
    importFromJsonHelpText:
      "This sections allows you to re-create a seller from another environment. Example: you have created a seller in homolog environment and would like to create the exact same seller in production environment. In that case, you just need to click on “Download Seller and User Settings” in the seller page in homolog environment, and import the downloaded json file here.",
    fillWithSellerDataHelpText: `You can pre fill the information with a seller that you have already created. Just choose the seller and click on "Fill". No worries, you will be able to changes somes fields before finalizing the creation.`,
    fillWithSellerData: "Fill with seller data",
  },
  carriersViewPage: {
    title: "Carrier {name}",
    deliverymethods: "Delivery methods",
    nodeliverymethodfoundfor: "No delivery method found for",
    users: "Users",
    nouserfoundfor: "No user found for",
    editCarrier: "Edit Carrier",
    updateAirportHub: "Update Airport Hub",
    airportHub: "Airport Hub",
    name: "Name",
    address: "Address",
    addressnumber: "Address number",
    addresscomplement: "Address complement",
    zipcode: "Zipcode",
    state: "State",
    city: "City",
    phone: "Phone",
    site: "Site",
    email: "E-mail",
    taxnumber: "Tax number",
    namereference: "Name reference",
    slug: "Slug",
    trackstatusfunction: "Track status function",
    carrierCode: "Carrier Code",
    country: "Country",
    taxNumber: "Tax Number",
    trackingfunction: "Tracking function",
    platform: "Platform",
    subsidiary: "Subsidiary",
    deliveryCenter: "Delivery Center",
    createdDate: "Created Date",
    activityTypes: "Activity Types",
    deliveryMethodFields: {
      name: "Name",
      type: "Type",
      service: "Service",
      originCountry: "Origin country",
      destinationCountry: "Destination country",
    },
    userFields: {
      name: "Name",
      email: "E-mail",
      groupName: "Group name",
    },
    seller: "Seller",
    thisCarrierIsSeller: "This carrier is seller",
    addDeliveryMethodToThisCarrier: "Add Delivery Method to this Carrier",
    addUserToThisCarrier: "Add User to this Carrier",
  },
  groupsPage: {
    title: "Groups Management",
    fields: {
      name: "Name",
    },
  },
  groupsViewPage: {
    title: "Group #",
    permissions: "Permissions",
  },
  permissionsPage: {
    title: "Permissions",
    fields: {
      name: "Name",
      description: "Description",
      groupsName: "Groups Name",
    },
  },
  permissionsViewPage: {
    title: "Permission #",
    name: "Name",
    description: "Description",
  },
  saldoViewPage: {
    currentBalance: "Current Balance",
    events: "Events",
    fields: {
      category: "Category",
      eventType: "Event",
      value: "Value",
      description: "Description",
      paid: "Paid",
    },
  },
  sellersPage: {
    title: "Sellers",
    carriers: "Carriers",
    carrierSelect: "Fill with Carrier data",
    addNewSeller: "Add New Seller",
    importSeller: "Import Seller from JSON",
    downloadSellers: "Download Sellers",
    processingDownloads: "Check the processing downloads at... downloads page",
    addseller: "Add seller",
    address: "Address",
    addressnumber: "Address number",
    addresscomplement: "Address complement",
    city: "City",
    state: "State",
    postalcode: "Postal code",
    name: "Name",
    phone: "Phone",
    email: "Email",
    site: "Site",
    taxnumber: "Tax number",
    legalName: "Legal Name",
    rfbCode: "RFB Code",
    signature: "Signature",
    billingEmail: "Billing Email",
    responsableContact: "Responsable Contact",
    allowedcontracts: "Allowed contracts",
    contractOperations: "Contract operations",
    issubsidiary: "Is subsidiary",
    isdistributioncenter: "Is distribution center",
    ismultiple: "Is multiple",
    country: "Country",
    fields: {
      name: "Name",
      email: "Email",
      city: "City",
      ismultiple: "Is multiple",
      currentBalance: "Current Balance",
      country: "Country",
      site: "Site",
      billingEmail: "Billing Email",
      responsableContact: "Responsable Contact",
      creationDate: "Creation Date",
      isCarrier: "Is Carrier?",
      zipcode: "Zip code",
    },
    multiples: "Multiples",
    simples: "Simples",
    importSellerJsonPopup: `This sections allows you to re-create a seller from another environment. Example: you have created a seller in homolog environment and would like to create the exact same seller in production environment. In that case, you just need to click on "Download Seller and User Settings" in the seller page in homolog environment, and import the downloaded json file here.`,
    balance: "Balance",
    fillWithCarrierPopup: `You can pre fill the information with a carrier that you have already created. Just choose the carrier and click on "Fill". No worries, you will be able to changes somes fields before finalizing the creation.`,
    contractsPopup: `You can already allow some contracts to this seller. If you don’t wish to do it know, no worries! You will be able to do it anytime later`,
    startValidityDate: "Validity start date",
    endValidityDate: "End date of validity",
    downloadUserSettingsPopup: `This will generate a json document that you can use to copy this seller to another environment`,
    languagePreferred: "Language preferred",
    measurementSystem: "Measurement system",
    validateCpf: "Validate customer CPFs on all orders",
    seeSellerVolumes: "See seller volumes",
    seeSellerOrders: "See seller orders",
  },
  sellersViewPage: {
    chooseSendRequest:
      "Please choose who you are going to send the request to:",
    downloadSellerSettings: "Download Seller and Users Settings",
    website: "Website",
    sellerIsCarrier: "This seller is also a carrier",
    carrierReminder: "Don't forget to change the carrier information",
    taxNumber: "Tax number",
    legalName: "Legal Name",
    rfbCode: "RFB Code",
    signature: "Signature",
    isSubsidiary: "Is subsidiary",
    isDistributionCenter: "Is distribution center",
    sellerID: "Seller ID",
    currentBalance: "Current Balance",
    seller: "Seller",
    name: "Name",
    email: "Email",
    phone: "Phone",
    ismultiple: "Is multiple",
    allowedContracts: "Allowed contracts",
    contractOperations: "Contract operations",
    noContractFound: "No contract found",
    creationDate: "Creation Date",
    contractLink: "Contract Link",
    notFound: "Not found",
    billingEmail: "Billing Email",
    responsableContact: "Responsable Contact",
    platform: "Platform",
    address: "Address",
    country: "Country",
    postalCode: "Postal code",
    state: "State",
    devolutionAddress: "Devolution Address",
    addressNumber: "Address Number",
    addressQuarter: "Address Quarter",
    addressComplement: "Address Complement",
    addressReference: "Address Reference",
    city: "City",
    devolutionEmail: "Devolution Email",
    useSellerDevolutionAddress: "Use seller devolution address",
    users: "Users ",
    active: "Active",
    blocked: "Blocked",
    clear: "Clear",
    noUserFoundFor: "No user found for",
    includeLogo: "Include logo",
    wantAllowContract: "Do you want to allow contract",
    forSeller: "for seller",
    needsSupervisorApproval: "This action needs a supervisor's approval",
    wantForbidContract: "Do you want to forbid contract",
    fields: {
      fullName: "Full name",
      email: "Email",
      status: "Status",
    },
    carrier: "Carrier",
    thisSellerIsCarrier: "This seller is carrier",
    accessWebsite: "Access website",
    understandCurrentBalance: "Understand current balance",
    startValidityDate: "Start validity date",
    endValidityDate: "End validity date",
  },
  usersPage: {
    title: "Users",
    subtitle: "Check and add new users to the system",
    personalData: "Personal data",
    seller: "Seller",
    carrier: "Carrier",
    downloadUsers: "Download report",
    allowed: "Allowed",
    blocked: "Blocked",
    active: "Active",
    denerateAPIToken: "Generate API Token",
    tokenCopiedSuccesfully: "Token Copied Successfully!",
    yourTokenWasCopiedToClipboard:
      "Your token was copied to the clipboard and is ready to use.",
    processingDownloads: "Check the processing downloads at... downloads page",
    allUsers: "All users",
    addNewUser: "Add New User",
    name: "Name",
    email: "Email",
    phone: "Phone",
    whatsappNumber: "Whatsapp number",
    profile: "Profile",
    group: "Group",
    selectGroup: "Select group",
    aPIToken: "API Token",
    permissionsAndAccess: "Permissions and access",
    userStatus: "User status",
    editUser: "Edit user",
    fields: {
      name: "Name",
      carrierID: "Carrier",
      sellerID: "Seller",
      company: "Company",
      status: "Status",
      email: "Email",
      profile: "Profile",
      group: "Group",
      creationDate: "Creation Date",
      aPIToken: "API Token",
      supervisor: "Supervisor",
    },
    insertUserData: "Insert the data of the user you want to create.",
    archive: "Archive",
    archiveUser: "Archive user?",
    confirmArchiveUser:
      "Are you sure you want to archive the user? This can't be undone.",
    yeahArchiveUser: "Yes, archive user",
    confirmBlockUser:
      "This user will immediately loose access to site and API. This can be undone at any time",
    confirmUnblockUser:
      "This user will be immediately granted access to site and API. This can be undone at any time",
    confirmRemoveUser:
      "Do you really want to remove this user? This action can not be undone",
    profileHelp:
      "Admin users will have access to this site and will manage accounts, flights, logs, fiscal data... Seller users will have access to the seller site and will create orders and generate labels. Carrier users will manage masterboxes and flights",
    groupHelp:
      "Admin has the maximum permissions on the account and can perform any action on shipments or users, including removing, creating or blocking other users. Operation has basically the same permissions, unless creating, blocking or removing other users. Viewer can only see data but is not allowed to create, update or delete anything",
  },
  usersViewPage: {
    title: "User Profile",
    blocked: "Blocked",
    allowed: "Allowed",
    blockUser: "Block this user",
    unblockUser: "Unblock this user",
    archiveUser: "Archive this user",
    unarchiveUser: "Unarchive this user",
    company: "Company",
    editUserProfile: "Edit User Profile",
    name: "Name",
    email: "Email",
    phone: "Phone",
    whatsappNumber: "Whatsapp number",
    group: "Group",
    selectgroup: "Select group",
    profile: "Profile",
    seller: "Seller",
    carrier: "Carrier",
    phoneNumber: "Phone Number",
    createdDate: "Created Date",
    sellerID: "Seller ID",
    carrierID: "Carrier ID",
    platform: "Platform",
    resetUserPassword: "Reset user password",
    resetUserPasswordWarning:
      "Attention! Once you update the password of user {user}, his current password will imediately lose validity both for site access and APP.",
  },
  dutiesAndTaxesStatusPage: {
    title: "Duties and Taxes Status",
    subTitle:
      "On this page, you can have a look at the duties payments done on your orders and extract the data as csv files",
    dutiesTaxes: "Duties and Taxes",
    importValuesandStatuses: "Import Values and Statuses",
    updateStatus: "Update Status",
    download: "Download",
    new: "New",
    solved: "Solved",
    priority: "Priority",
    inProgress: "In Progress",
    waitingforPayment: "Waiting for Payment",
    sentAgain: "Sent Again",
    paymentDone: "Payment Done",
    pending: "Pending",
    paidExternally: "Paid Externally",
    needSellerAction: "Need Seller Action",
    status: "Status",
    comment: "Comment",
    checktheprocessingdownloads:
      "Check the processing downloads at... downloads page",
    sureWantSave: "Are you sure you want to save the changes",
    newTrackingNumbers: "New Tracking Numbers ",
    shouldbeCommaSeparated:
      "The new numbers should be comma separated and follow the sequence of Tracking Alert Logs selected: TAL_IDs:",
    unsaveddata: "Unsaved data",
    pleasesaveunsaveddata: "Please save unsaved data or cancel",
    okay: "Okay",
    name: "Name",
    browseFilesUpload: "Browse your files and upload your file",
    selectcsvFileUpload: "Select a csv file to upload",
    downloadHere: "Download here",
    instructionsXLSfile: "the instructions XLS file",
    fileUploaded: "File Uploaded",
    blankTable: "a blank table",
    fields: {
      id: "NB Internal Payment Reference",
      sellerName: "Seller Name",
      sellerId: "Seller ID",

      orderNumber: "Order Number",
      orderCreatedAt: "Order Creation Date",
      orderCreatedAtGte: "Start Order Creation Date",
      orderCreatedAtLte: "End Order Creation Date",

      volumeId: "Volume ID",
      volumeLastMileTrackingNumber: "Last Mile Tracking Number",
      orderEstimatedTaxValue: "Estimated Total Duties and Taxes",
      orderEstimatedTaxValueGte: "Start Estimated Total Duties and Taxes",
      orderEstimatedTaxValueLte: "End Estimated Total Duties and Taxes",
      orderEstimatedDutiesTaxValue: "Estimated Duties II",
      orderEstimatedDutiesTaxValueGte: "Start Estimated Duties",
      orderEstimatedDutiesTaxValueLte: "End Estimated Duties",
      orderEstimatedSalesTaxValue: "Estimated Taxes ICMS",
      orderEstimatedSalesTaxValueGte: "Start Estimated Taxes ICMS",
      orderEstimatedSalesTaxValueLte: "End Estimated Taxes ICMS",

      currentStatusId: "Tracking",
      currentStatusTitle: "Tracking Status",
      currentStatusTrackingCode: "Tracking Code",
      currentStatusTrackingCodeGte: "Start Tracking Code",
      currentStatusTrackingCodeLte: "End Tracking Code",

      createdAt: "Customs Start Date",
      createdAtGte: "Start Customs Start Date",
      createdAtLte: "End Customs Start Date",

      statusPaymentDoneAt: "Payment Date",
      statusPaymentDoneAtGte: "Start Payment Date",
      statusPaymentDoneAtLte: "End Payment Date",

      status: "Payment Status",
      paymentCertificate: "Payment Certificate",

      realTax: "Total Paid Duties and Taxes",
      realTaxGte: "Start Total Paid Duties and Taxes",
      realTaxLte: "End Total Paid Duties and Taxes",

      exchangeRate: "Exchange Rate",
      seller: "Seller",
    },
  },
  dutiesAndTaxesStatusViewPage: {
    title: "Duties and Taxes Status #",
    sellerChargedAmount: "Seller Charged Amount",
    exchange: "Exchange",
    incoterm: "Incoterm",
    date: "Date",
    dateIso: "Date Iso",
    orderID: "Order ID",
    volumeID: "Volume ID",
    sellerID: "Seller ID",
    taxID: "Tax ID",
    lastMileNumber: "Last Mile Number",
    productsValue: "Products Value",
    freightValue: "Freight Value",
    expectedTax: "Expected Tax",
    valueR$: "Value R$",

    id: "NB Internal Payment Reference",
    sellerName: "Seller Name",
    sellerId: "Seller ID",

    orderNumber: "Order Number",
    orderCreatedAt: "Order Creation Date",
    orderCreatedAtGte: "Start Order Creation Date",
    orderCreatedAtLte: "End Order Creation Date",

    volumeId: "Volume ID",
    volumeLastMileTrackingNumber: "Last Mile Tracking Number",
    orderEstimatedTaxValue: "Estimated Total Duties and Taxes",
    orderEstimatedTaxValueGte: "Start Estimated Total Duties and Taxes",
    orderEstimatedTaxValueLte: "End Estimated Total Duties and Taxes",
    orderEstimatedDutiesTaxValue: "Estimated Duties II",
    orderEstimatedDutiesTaxValueGte: "Start Estimated Duties",
    orderEstimatedDutiesTaxValueLte: "End Estimated Duties",
    orderEstimatedSalesTaxValue: "Estimated Taxes ICMS",
    orderEstimatedSalesTaxValueGte: "Start Estimated Taxes ICMS",
    orderEstimatedSalesTaxValueLte: "End Estimated Taxes ICMS",

    currentStatusId: "Tracking",
    currentStatusTitle: "Tracking Status",
    currentStatusTrackingCode: "Tracking Code",
    currentStatusTrackingCodeGte: "Start Tracking Code",
    currentStatusTrackingCodeLte: "End Tracking Code",

    createdAt: "Customs Start Date",
    createdAtGte: "Start Customs Start Date",
    createdAtLte: "End Customs Start Date",

    statusPaymentDoneAt: "Payment Date",
    statusPaymentDoneAtGte: "Start Payment Date",
    statusPaymentDoneAtLte: "End Payment Date",

    status: "Payment Status",
    paymentCertificate: "Payment Certificate",

    realTax: "Total Paid Duties and Taxes",
    realTaxGte: "Start Total Paid Duties and Taxes",
    realTaxLte: "End Total Paid Duties and Taxes",

    exchangeRate: "Exchange Rate",
  },
  accessibilitySettingsPage: {
    subtitle: "Configure typography, preferences, language and more.",
    typography: "Typography",
    textPreferenceDescription: "Set your text preferences below.",
    textSize: "Text size",
    language: "Language",
    mainLanguageDescription: "Define the main language of the Platform.",
    selectLanguage: "Select the language",
    changeUnitsText: "change units of weights and measures",
    stillHavingDifficulties: "Still having difficulties?",
    weWantToHelpYou: "We want to help you! Contact us in any way you prefer",
    contactViaPhone: "Contact via telephone",
    contactViaEmail: "Contact via email",
    contactViaChat: "Contact via chat",
  },
  searchHsCodePage: {
    title: "Search HS Code",
    subtitle:
      "Do you want to know an HS Code or if the product is permitted? Search right now!",
    code: "Enter the code",
    category: "Category",
    noHscode: "Record not found.",
    tooltip:
      "An Hs Code or NCM is a 6 or 8 digits code identifying the type of product to import. This page allows you to see if a particular code is allowed to enter Brazil",
  },
  requestsPage: {
    title: "Requests",
    subTitle: "Check and Track Request Status with your ID",
    requestId: "Request ID",
    asynchronousRequests: "Asynchronous Requests",
    result: "Result",
  },
  shippingRulesPage: {
    title: "Shipping Rules",
    subtitle:
      "On this page, you can define shipping rules that will allow you to apply some actions on your orders, depending on some triggers you will configure",
    addNewRule: "Add New Rule",
    seller: "Seller",
    sellers: "Sellers",
    name: "Rule Name",
    expiration: "Expiration",
    condition: "Condition",
    conditions: "Conditions",
    options: "Options",
    operator: "Operator",
    value: "Value",
    selectMultipleSellers: "Please choose one or more sellers",
    createConditionTitle: "Add New Rule",
    createCondition:
      "Select the conditions your shipment needs to satisfy and click on Add Condition to validate your choice. You can select multiple conditions, in order for the rule to apply, all of the conditions must be verified”",
    addCondition: "Add Condition",
    createActionTitle: "Action",
    createAction:
      'In this field you will choose what should happen according to your condition. For example, for the "All orders" condition you can select "always ship from Location X"',
    is_active: "Active",
    is_active_helptext:
      "If you want to create the rule without applying it for now, just de-select this field",
    freightValue: "Freight Value",
    priority: "Priority",
    destinationCountry: "Destination Country",
    destinationStates: "destination States",
    rulePriorityOptions: {
      veryHigh: "Very high",
      high: "High",
      medium: "Medium",
      low: "Low",
      veryLow: "Very low",
    },
    dateFieldHelpText:
      "After this date, the rule will not apply anymore, but will still be available in this page if you want to extend the validity later on",
    priorityFieldHelpText:
      "In case of conflict between various rules, the priority will indicate which rule apply over the other",
    sellersFieldHelpText: "Choose which sellers will be affected by the rule",
    activeFieldHelpText:
      "If not active, the rule will be created but will not be applied until you activate it",
    addNewCondition: "Add New Condition",
    freightValueActionDescription:
      "The freight value of the order will always be taken from the freight cost (computed through price tables",
    fields: {
      priority: "Priority",
      name: "Name",
      sellers: "Sellers",
      expiration: "Expiration",
      condition: "Condition",
      action: "Action",
      isActive: "Is Active?",
      actions: "Actions",
    },
  },
  fiscalPaymentsPage: {
    title: "Fiscal Payments",
    fiscalInfo: "Fiscal Information",
    fiscalParcelData: "Fiscal Parcel Data",
    customerData: "Customer Data",
    fields: {
      reference_number: "Reference",
      barcode: "Barcode",
      authentication: "Authentication",
      status: "Status",
      payment_type: "Payment Type",
      value: "Value",
      fiscal_parcel_id: "Fiscal Parcel ID",
      payment_date: "Payment Date",
      created_at: "Creation Date",
      dir_number: "DIR Number",
      customer_name: "Customer Name",
      customer_cpf: "Customer CPF",
      customer_cep: "Customer CEP",
      tracking_number: "Tracking Number",
      products_description: "Products Description",
      customer_state: "Customer State",
      seller_id: "Seller ID",
      dir_date: "DIR Date",
      start_payment_date: "Start Payment Date",
      end_payment_date: "End Payment Date",
      start_created_at: "Start Creation Date",
      end_created_at: "End Creation Date",
      start_value: "Start Value",
      end_value: "End Value",
      start_dir_date: "Start DIR Date",
      end_dir_date: "End DIR Date",
      boletoGNRE: "Boleto GNRE",
      paymentDate: "Payment Date",
      masterNumber: "Master Number",
      messages: "Messages",
      courrierName: "Courrier Name",
      courrierCNPJ: "Courrier CNPJ",
    },
    status: {
      processing: "Processing",
      processed: "Processed",
      error: "Error",
    },
    downloadBoletoGNRE: "Download Boletos",
  },
  notFoundPage: {
    pageNotFound: "Page not found",
    goToHome: "Go to home page",
  },
  trackingRuleNotificationsPage: {
    title: "Tracking Notification Rules",
    addConfig: "Add configuration",
    createRule: "Create rule",
    giveRuleName: "Give a name to your Rule",
    ruleName: "Rule name",
    applyRuleTo: "You want to apply the rule to:",
    allShipments: "All shipments",
    someShipmentsOnly: "Some shipments only",
    chooseSellers: "First, choose the sellers that will use these rules",
    WhenNewSellersIsCreated:
      "What do you want to do when a new seller is created",
    addThisRuleAutomatically: "Automatically add to this rule",
    addManually:
      "Do not add it to this rule, they will be added manually if needed",
    notifications: "Notifications",
    conditions: "Conditions",
    wouldYouLikeToRemoveRule: "Would you like to remove rule",
    wouldYouLikeToDisableRule: "Would you like to disable rule",
    wouldYouLikeToEnableRule: "Would you like to enable rule",
    removalIsFinal: "Removal is final",
    disableRuleMessage:
      "The rule will be saved in the system but will not be applied to any order, with immediate effect. This can be undone at any time.",
    enableRuleMessage:
      "The rule will be applied to the requests involved with immediate effect. This can be undone at any time.",
    sellersPermission: "Sellers permission",
    sellersThatCanConfigureTheirOwnRules:
      "Sellers that can configure their own rules",
    sellersThatCanConfigureTheirOwnRulesDescription:
      "For these sellers, none of the rules created here will apply and they will be able to configure their own",
    sellersThatWillUseRulesConfiguredHere:
      "Sellers that will use rules configured here",
    sellersThatWillUseRulesConfiguredHereDescription:
      "These sellers will not be able to configure their rules",

    createOrUseExistingRule:
      "Would you like to create or use an existing rule?",
    createFromScratch: "Create from scratch",
    useExistingRule: "Use an existing rule as pre-setup",
    selectRuleToUse: "Select the rule you want to use",
    defineNotificationRules: "Defines notification rules for checkpoint groups",
    allSelected: "All selected",
    selectAll: "Select all",
    onSellerCreation: "What to do when a new seller is created?",
    onContractCreation: "What to do when a new contract is created?",
    toEditSellersList: "To edit sellers list, you need to choose:",
    toEditContractsList: "To edit contracts list, you need to choose:",
    autoAddRule: "Automatically add to this rule",
    notAddThisRule:
      "Do not add it to this rule, they will be added manually if needed",
    searchByNameOrId: "Search: find by name or id",
    noItemsSelected: "No items selected",
    selectedItems: "Selected items",
    chooseContracts:
      "Now, choose to which of the contracts below you want to apply the rule",
    allShipmentWarning:
      "You are about to create a rule applying for all shipments",
    keepEditing: "Keep editing",
    goIt: "Go it",
    wantToCreateRule: "Do you want to create the configured rule?",
    totalSellersLinked: "Total number of sellers linked to this rule",
    totalContractsLinked: "Total number of contracts linked to this rule",
    allSellersIncluded:
      "All sellers have been included in this rule, including new sellers that will be added later.",
    allContractsIncluded:
      "All contracts have been included in this rule, including new contracts that will be added later.",
    almostThere: "Almost there..",
    ruleCreatedSuccessfully: "Rule created successfully!",
    youWantActivate:
      "It is disabled for now. Do you want to enable this rule now?",
    maybeLater: "Maybe later",
    notifsConfigured: "{total} notifs configured",
    allSellers: "All sellers",
    allContracts: "All contracts",
    totalSellers: "{total} sellers",
    totalContracts: "{total} contracts",
    updateRule: "Update rule",
    wantToUpdateRule: "Do you want to update the configured rule?",
    ruleUpdatedSuccessfully: "Rule updated successfully!",
    activateUpdatedRule:
      "The rule has been successfully updated and has been disabled for security reasons. Do you want to enable the rule now?",
    noSellerSelected: "No seller selected",
    noContractSelected: "No contract selected",
    sellersSelected: "sellers selected",
    contractsSelected: "contracts selected",
    youDontHaveSeller:
      "You don’t have any sellers yet. To configure rules, you have to create your first seller first",
    youDontHaveContractAllowed:
      "These sellers don’t have any contracts yet. You have to first allow some contracts to these sellers",
    goToSellersPage: "Go to sellers page",
    gotToContractsPage: "Go to contracts page",
    ruleNameAlreadyExists: "The rule name provided already exists",
  },
};
