export const REPEATED_NUMBERS_REGEX = /([0-9])\1{10}/g;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const CPF_MASK = "###.###.###-##";
export const CNPJ_MASK = "##.###.###/####-##";
export const BRAZIL_ZIP_CODE_MASK = "#####-###";
export const GENERIC_ZIP_CODE_MASK = "###########";
export const BRAZIL_PHONE_NUMBER_MASK = "(##) ####-####";
export const BRAZIL_9TH_DIGIT_PHONE_NUMBER_MASK = "(##) #####-####";

export const BRAZIL_STATES = {
  "AC - Acre": "AC",
  "AL - Alagoas": "AL",
  "AP - Amapá": "AP",
  "AM - Amazonas": "AM",
  "BA - Bahia": "BA",
  "CE - Ceará": "CE",
  "DF - Distrito Federal": "DF",
  "ES - Espírito Santo": "ES",
  "GO - Goiás": "GO",
  "MA - Maranhão": "MA",
  "MT - Mato Grosso": "MT",
  "MS - Mato Grosso do Sul": "MS",
  "MG - Minas Gerais": "MG",
  "PA - Pará": "PA",
  "PB - Paraíba": "PB",
  "PR - Paraná": "PR",
  "PE - Pernambuco": "PE",
  "PI - Piauí": "PI",
  "RJ - Rio de Janeiro": "RJ",
  "RN - Rio Grande do Norte": "RN",
  "RS - Rio Grande do Sul": "RS",
  "RO - Rondônia": "RO",
  "RR - Roraima": "RR",
  "SC - Santa Catarina": "SC",
  "SP - São Paulo": "SP",
  "SE - Sergipe": "SE",
  "TO - Tocantins": "TO",
};

export const HS_CODES_UNITED = {
  "Acessory (no-battery)": 903289,
  "Acessory (with battery)": 851822,
  "Audio Video & Jewelry": 711719,
  "Bags & Luggages": 420299,
  "Board Games & Toys": 950300,
  "Books & Collectibles & Documents": 490199,
  Cameras: 852580,
  "Computers & Laptops & Tablets": 847130,
  "Dry Foods & Supplements": 210690,
  Fashion: 430310,
  Gaming: 950430,
  "Health & Beauty": 330499,
  "Home Appliances": 851640,
  "Home Decor": 442090,
  "Mobile Phone": 851712,
  "Pet Accessory": 420100,
  Shoes: 640590,
  "Sport & Leisures": 420291,
  Watches: 910219,
};

export const HS_CODES = {
  "Acessory (no-battery)": 903289,
  "Acessory (with battery)": 851822,
  "Audio Video": 711719,
  "Bags & Luggages": 420299,
  "Board Games": 950300,
  "Books & Collectibles": 490199,
  Cameras: 852580,
  "Computers & Laptops": 847130,
  Documents: 490199,
  "Dry Foods & Supplements": 210690,
  Fashion: 430310,
  Gaming: 950430,
  "Health & Beauty": 330499,
  "Home Appliances": 851640,
  "Home Decor": 442090,
  Jewelry: 711719,
  "Mobile Phone": 851712,
  "Pet Accessory": 420100,
  Shoes: 640590,
  "Sport & Leisures": 420291,
  Tablets: 847130,
  Toys: 950300,
  Watches: 910219,
};

export const CATEGORIES = [...Object.keys(HS_CODES), "Others"];

export const LOCALES = [
  { iso: "GB", lang: "en", number: 0, text: "english" },
  { iso: "CN", lang: "zh_CN", number: 1, text: "chinese" },
  { iso: "BR", lang: "pt_BR", number: 2, text: "portuguese" },
  { iso: "ES", lang: "es", number: 3, text: "spanish" },
];

export const Profile = {
  ADMIN: "admin",
  SELLER: "seller",
  CARRIER: "carrier",
};

export const FiscalPaymentType = {
  ICMS: "icms",
  DARF: "darf",
  FINE689: "fine689",
  FINE702: "fine702",
  FINE703: "fine703",
  FINE711: "fine711",
  FINE715: "fine715",
  FINE725: "fine725",
  FINE728: "fine728",
};

export const NotificationsTitle = {
  FINANCE: "finance",
  INSUFFICIENT_BALANCE: "insufficient_balance",
  TRACKING_ISSUE: "tracking_issue",
  NEW_TICKET: "new_ticket",
  DATA_UPDATE: "data_update",
  CONTRACT_PERMISSION: "contract_permission",
  CONTRACT_UPDATE: "contract_update",
  PRICE_TABLE_UPDATE: "price_table_update",
  GENERAL_ISSUE: "general_issue",
};

export const NotificationType = {
  SMS_CUSTOMER: "sms_customer",
  WHATSAPP_CUSTOMER: "whats_customer",
  EMAIL_CUSTOMER: "mail_customer",
  EMAIL_SELLER: "mail_seller",
  WEBHOOK_SELLER: "webhook_seller",
  WEBHOOKE_ADMIN: "webhook_admin",
};

export const CHECKPOINT_GROUP = {
  FIRST_MILE: {
    zoneCode: 1,
    name: "First Mile",
  },
  PICKUP: {
    zoneCode: 2,
    name: "Pickup",
  },
  FREIGHT_INTERNATIONAL: {
    zoneCode: 3,
    name: "Freight International",
  },
  DESEMBARACO: {
    zoneCode: 4,
    name: "Customs",
  },
  LAST_MILE: {
    zoneCode: 5,
    name: "Last Mile",
  },
  REVERSE: {
    zoneCode: 6,
    name: "Reverse",
  },
};

export const FlightStatus = {
  open_manifest: "open_manifest",
  departure_confirmed: "departure_confirmed",
  manifest_closed: "manifest_closed",
};

export const INCOTERM = {
  DDP: "DDP",
  DDU: "DDU",
};
