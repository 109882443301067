export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      appliedFilters: {},
    };
  },
  computed: {
    hasFilter() {
      return !!Object.keys(this.appliedFilters).length;
    },
  },
  methods: {
    loadUrlSearchParams() {
      const queryParams = this.$route.query;
      const filterKeys = this.filters.reduce((acc, current) => {
        acc.push(...(current?.inputs?.map((item) => item.key) || []));
        return acc;
      }, []);

      const filteredParams = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([key, value]) => filterKeys.includes(key) && !!value,
        ),
      );

      if (Object.entries(filteredParams).length) {
        this.appliedFilters = filteredParams;
      }
    },
    buildFiltersParams() {
      return this.appliedFilters;
    },
  },
};
