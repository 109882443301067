<template>
  <NbBadge :text="payment" :type="variant" />
</template>
<script>
import NbBadge from "@/components/alerts/NbBadge.vue";
import { FiscalPaymentType } from "../../../../constants/constants";
export default {
  components: {
    NbBadge,
  },
  props: {
    payment: {
      type: String,
      required: true,
    },
  },

  computed: {
    variant() {
      const variants = {
        [FiscalPaymentType.ICMS]: "success",
        [FiscalPaymentType.DARF]: "primary",
        [FiscalPaymentType.FINE689]: "warning",
        [FiscalPaymentType.FINE702]: "success-dark",
        [FiscalPaymentType.FINE703]: "gray-50",
        [FiscalPaymentType.FINE711]: "success",
        [FiscalPaymentType.FINE715]: "attention",
        [FiscalPaymentType.FINE725]: "secondary",
        [FiscalPaymentType.FINE728]: "secondary",
      };

      return variants?.[this.payment] || "primary";
    },
  },
};
</script>

<style lang="scss" scoped></style>
