<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('trackingRulesPage.title')"
      :subTitle="$t('trackingRulesPage.subTitle')"
    >
      <div class="btn-groups d-flex flex-column">
        <NbButton
          class="text-left"
          @click="isModalTrackingRuleCreateOpen = true"
        >
          <NbIcon class="mr-2" icon="plus" />
          {{ $t("trackingRulesPage.addTrackingRule") }}
        </NbButton>
      </div>
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <DatatableRoot
        namespace="tracking_rules"
        ref="trackingRulesTable"
        clickable
        :columns="tableColumns"
        :filters="tableFilters"
        @count="totalTrackingRules = $event"
        @rowClick="
          loadModalData($event.id, 'trackingRulesId', 'ModalTrackingRuleView')
        "
      >
        <template #start_checkpoint_id="{ row }">
          <div
            v-if="row.start_checkpoint_id"
            class="link-1"
            @click="loadCheckpointView(row.start_checkpoint_id)"
          >
            {{ loadCheckpointName(row.start_checkpoint_id) }}
          </div>
          <span v-else>-</span>
        </template>
        <template #end_checkpoint_id="{ row }">
          <div
            class="link-1"
            v-if="row.end_checkpoint_id"
            @click="loadCheckpointView(row.end_checkpoint_id)"
          >
            {{ loadCheckpointName(row.end_checkpoint_id) }}
          </div>
          <span v-else>-</span>
        </template>
        <template #seller_id="{ row }">
          <div v-if="row.seller_id">
            <router-link
              class="link-1"
              :to="`/users_management/sellers?id=` + row.seller_id"
            >
              {{ loadSellerName(row.seller_id) }}
            </router-link>
          </div>
          <span v-else>
            {{ $t("any") }}
          </span>
        </template>
        <template #contract_id="{ row }">
          <div v-if="row.contract_id">
            <router-link
              class="link-1"
              :to="`/contracts/contracts?contractId=${row.contract_id}`"
            >
              {{ loadContractName(row.contract_id) }}
            </router-link>
          </div>
          <span v-else>
            {{ $t("any") }}
          </span>
        </template>
        <template #created_at="{ row }">
          {{ row.created_at | datetime }}
        </template>
      </DatatableRoot>
    </section>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <!-- modals -->
    <Modal v-model="isModalNobordistCheckpointsViewOpen" size="lg">
      <ModalNobordistCheckpointsView
        :itemId="currentItemId"
        @close="resetNobordistCheckpointQuery"
      />
    </Modal>

    <Modal
      v-model="isModalTrackingRuleOpen"
      size="lg"
      @close="setUrlSearchParam('trackingRulesId')"
    >
      <ModalTrackingRuleView
        :itemId="currentItemId"
        @reloadTrackingRules="reloadTrackingRules"
      />
    </Modal>

    <Modal v-model="isModalTrackingRuleCreateOpen" size="lg">
      <ModalTrackingRuleCreate @reloadTrackingRules="reloadTrackingRules" />
    </Modal>
    <!-- end modal -->
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalTrackingRuleView from "@/views/logs/tracking_rules/components/ModalTrackingRuleView.vue";
import ModalTrackingRuleCreate from "@/views/logs/tracking_rules/components/ModalTrackingRuleCreate.vue";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";
import Modal from "@/components/modal/Modal.vue";

import { mapActions } from "vuex";
import queryParamsMixin from "../../../mixins/query-params-mixin";
import ContractService from "@/services/ContractService";
import SellerService from "@/services/SellerService";

const contractService = new ContractService();
const sellerService = new SellerService();

export default {
  name: "TrackingRules",
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbIcon,
    NbButton,
    NbTabs,
    DatatableRoot,
    NbFooter,
    ModalNobordistCheckpointsView,
    ModalTrackingRuleCreate,
    ModalTrackingRuleView,
    Modal,
  },
  data: () => {
    return {
      isModalNobordistCheckpointsViewOpen: false,
      trackingRules: [],
      newTrackingRule: {
        name: null,
        start_checkpoint_id: null,
        end_checkpoint_id: null,
        seller_id: null,
        contract_id: null,
        days: null,
        destination: null,
        tracking_mode: null,
        configuration: {
          log: false,
          notify_seller: false,
          notify_customer: false,
          notify_nobordist: false,
          notify_webhook: false,
          seller_mail_body: null,
          customer_mail_body: null,
          nobordist_mail_body: null,
          seller_mail_title: null,
          customer_mail_title: null,
          nobordist_mail_title: null,
          seller_notification_title: null,
          seller_notification_body: null,
          seller_notification: false,
          webhook: {
            method: null,
            endpoint: null,
            headers: {
              ContentType: "application/json",
            },
            body: null,
          },
        },
      },
      checkpoints: [],
      sellers: [],
      contracts: [],
      currentItemId: "",
      currentTable: "all",
      totalTrackingRules: -1,
      isModalTrackingRuleCreateOpen: false,
      isModalTrackingRuleOpen: false,
    };
  },
  computed: {
    sellersOption() {
      const uniqueSellers = this.sellers.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id),
      );
      const sellersOption = uniqueSellers.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return sellersOption;
    },
    contractsOption() {
      const uniqueContracts = this.contracts.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id),
      );
      const contractsOption = uniqueContracts.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.slug}`,
      }));
      return contractsOption;
    },
    checkpointsOption() {
      return this.allCheckpoints.map((item) => ({
        value: item.id,
        text: `(${item.tracking_code}) ${item.title}`,
      }));
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("trackingRulesPage.title"),
          found: this.totalTrackingRules,
          current: this.currentTable,
        },
      ];
    },
    allCheckpoints() {
      return this.$store.state.nobordist_checkpoints.all_items;
    },
    nobordistCheckpoints() {
      return this.$store.state.nobordist_checkpoints.items;
    },
    tableColumns() {
      return [
        {
          key: "name",
          label: this.$t("trackingRulesPage.fields.name"),
        },
        {
          key: "start_checkpoint_id",
          label: this.$t("trackingRulesPage.fields.startCheckpoint"),
        },
        {
          key: "end_checkpoint_id",
          label: this.$t("trackingRulesPage.fields.endCheckpoint"),
        },
        {
          key: "seller_id",
          label: this.$t("trackingRulesPage.fields.seller"),
        },
        {
          key: "contract_id",
          label: this.$t("trackingRulesPage.fields.contract"),
        },
        {
          key: "days",
          label: this.$t("trackingRulesPage.fields.daysLimit"),
        },
        {
          key: "tracking_mode",
          label: this.$t("trackingRulesPage.fields.mode"),
        },
        {
          key: "created_at",
          label: this.$t("trackingRulesPage.fields.creationDate"),
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("trackingRulesPage.fields.name"),
          inputs: [
            {
              key: "name",
            },
          ],
        },
        {
          label: this.$t("trackingRulesPage.fields.startCheckpoint"),
          inputs: [
            {
              key: "start_checkpoint_id",
              type: "select",
              options: this.checkpointsOption,
            },
          ],
        },
        {
          label: this.$t("trackingRulesPage.fields.endCheckpoint"),
          inputs: [
            {
              key: "end_checkpoint_id",
              type: "select",
              options: this.checkpointsOption,
            },
          ],
        },
        {
          label: this.$t("trackingRulesPage.fields.seller"),
          inputs: [
            {
              key: "seller_id",
              type: "select",
              options: this.sellersOption,
            },
          ],
        },
        {
          label: this.$t("trackingRulesPage.fields.contract"),
          inputs: [
            {
              key: "contract_id",
              type: "select",
              options: this.contractsOption,
            },
          ],
        },
        {
          label: this.$t("trackingRulesPage.fields.mode"),
          inputs: [
            {
              key: "tracking_mode",
              type: "select",
              options: [
                {
                  value: "time_between_checkpoints",
                  text: "Time Between Checkpoints",
                },
                { value: "received_checkpoint", text: "Received Checkpoint" },
              ],
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  mounted() {
    this.loadContracts();
    this.loadSellers();
    this.$store.dispatch("nobordist_checkpoints/fetchAllItems", "checkpoints");
    this.checkQuery();
  },
  methods: {
    async loadContracts() {
      const { data } = await contractService.getContractsList();
      this.contracts = data.data;
    },
    async loadSellers() {
      const { data } = await sellerService.getSellersList();
      this.sellers = data.data;
    },
    resetNobordistCheckpointQuery() {
      if (this.$route.query?.nobordistCheckpointId) {
        this.$urlSearchParams.delete("nobordistCheckpointId");
      }
    },
    loadCheckpointView(id) {
      this.$urlSearchParams.set("nobordistCheckpointId", id);
      this.isModalNobordistCheckpointsViewOpen = true;
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    reloadTrackingRules() {
      this.$refs?.trackingRulesTable?.getData();
    },
    checkQuery() {
      if (this.$route.query.nobordistCheckpointId) {
        this.loadCheckpointView(this.$route.query.nobordistCheckpointId);
        return;
      }
      if (this.$route.query.trackingRulesId) {
        this.$emit("switchTab", "all");
        this.loadModalData(
          this.$route.query.trackingRulesId,
          "trackingRulesId",
          "ModalTrackingRuleView",
        );
        return;
      }
    },
    loadModalData(id, queryKey) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query[queryKey]) {
          this.$router.push({ query: { [queryKey]: id } });
        }
        this.isModalTrackingRuleOpen = true;
        return;
      }
    },
    addSelectOptionsToFilter(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },
    loadCheckpointName(id) {
      if (id) {
        const data = this.allCheckpoints.find((data) => data.id === id);
        if (data) {
          return data.tracking_code + " (" + data.name + ")";
        }
      }
    },
    loadSellerName(id) {
      if (id) {
        const data = this.sellers.find((data) => data.id === id);
        return data?.name;
      }
    },
    loadContractName(id) {
      if (id) {
        const data = this.contracts.find((data) => data.id === id);

        return data?.name;
      }
    },
    ...mapActions("trackingRules", {
      fetchTrackingRules: "fetchTrackingRules",
      setCurrentPage: "setCurrentPage",
    }),
  },
};
</script>
