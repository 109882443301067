export default {
  props: {
    urlState: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    mergeQueryParams(data) {
      if (!this.urlState) {
        return;
      }
      if (JSON.stringify(data) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({
          query: { ...this.$route.query, ...data },
        });
      }
    },
  },
};
