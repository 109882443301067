<template>
  <div>
    <div
      class="d-flex flex-column align-items-start flex-md-row justify-content-md-between"
    >
      <NbPageTitle
        :title="$t('ordersPage.title')"
        :subTitle="$t('ordersPage.subtitle')"
      />
      <div class="d-flex flex-column">
        <NbButton
          @click="showImportOrdersCostsModal = true"
          class="d-flex justify-content-start"
        >
          <NbIcon icon="upload" class="mr-2" size="20" />
          {{ $t("ordersPage.importOrderCosts") }}
        </NbButton>
      </div>
    </div>

    <section class="page-container">
      <NbTabs class="mt-5 mb-4" :tabs="tabsOptions" />
      <DatatableRoot
        namespace="orders"
        ref="ordersTableRef"
        clickable
        selectable
        :columns="tableColumns"
        :filters="tableFilters"
        :selected.sync="selected"
        @count="totalOrders = $event"
        @rowClick="showOrder($event.id)"
        :paginate-by-offset="false"
        method="PATCH"
      >
        <template #actions>
          <DatatableActions :disabled="!selected.length">
            <DatatableAction @click="confirmDownloadOrders()">
              {{ $t("ordersPage.downloadOrders") }}
            </DatatableAction>
            <DatatableAction @click="confirmDownloadOrdersCosts()">
              {{ $t("ordersPage.downloadOrdersCosts") }}
            </DatatableAction>
            <DatatableAction @click="showUpdateMassiveFinancialStatus()">
              {{ $t("ordersPage.updateFinancialStatus") }}
            </DatatableAction>
            <DatatableAction
              v-if="$store.getters.currentPlatformIsTotal"
              @click="confirmRemoveOrders()"
            >
              {{ $t("ordersPage.removeOrders") }}
            </DatatableAction>
          </DatatableActions>
        </template>

        <template #seller_id="{ row }">
          <router-link
            class="link-1"
            :to="`/users_management/sellers?id=` + row.seller_id"
          >
            {{ row.seller_name }}
          </router-link>
        </template>
        <template #contract_id="{ row }">
          <router-link
            v-if="row.contract_id"
            class="link-1"
            :to="`/contracts/contracts?contractId=` + row.contract_id"
          >
            {{ row.contract.name }}
          </router-link>
        </template>
        <template #overpack_id="{ row }">
          <router-link
            v-if="row.overpack_id"
            class="link-1"
            :to="`/shipping/overpacks?id=` + row.overpack_id"
          >
            {{ row.overpack_id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #prc="{ row }">
          <IsCheckedIcon :checked="row.prc" />
        </template>

        <template #is_commercial_destination="{ row }">
          <IsCheckedIcon :checked="row.is_commercial_destination" />
        </template>

        <!-- orders values -->
        <template #products_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.products_value, row.currency) }}
        </template>
        <template #freight_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.freight_value, row.currency) }}
        </template>
        <template #declared_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.declared_value, row.currency) }}
        </template>
        <template #duties_tax_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.duties_tax_value, row.currency) }}
        </template>
        <template #sales_tax_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.sales_tax_value, row.currency) }}
        </template>
        <template #tax_value="{ row }">
          {{ row.currency }} {{ currency.format(row.tax_value, row.currency) }}
        </template>
        <template #total_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.total_value, row.currency) }}
        </template>
        <template #estimated_duties_tax_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.estimated_duties_tax_value, row.currency) }}
        </template>
        <template #estimated_sales_tax_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.estimated_sales_tax_value, row.currency) }}
        </template>
        <template #estimated_tax_value="{ row }">
          {{ row.currency }}
          {{ currency.format(row.estimated_tax_value, row.currency) }}
        </template>
        <template #freight_cost="{ row }">
          {{ row.currency }}
          {{ currency.format(row.freight_cost, row.currency) }}
        </template>
        <!-- end orders values -->
        <template #estimated_freight_cost="{ row }">
          {{ row.currency }}
          {{ currency.format(row.estimated_freight_cost, row.currency) }}
        </template>
        <template #tax_cost="{ row }">
          {{ row.currency }} {{ currency.format(row.tax_cost, row.currency) }}
        </template>
        <template #insurance_cost="{ row }">
          {{ row.currency }}
          {{ currency.format(row.insurance_cost, row.currency) }}
        </template>
        <template #insurance_coverage="{ row }">
          {{ row.currency }}
          {{ currency.format(row.insurance_coverage, row.currency) }}
        </template>
        <template #ddp_cost="{ row }">
          {{ row.currency }} {{ currency.format(row.ddp_cost, row.currency) }}
        </template>
        <template #dollar_reais="{ row }">
          BRL {{ currency.format(row.dollar_reais) }}
        </template>

        <template #order_costs="{ row }">
          <a href="#" class="link-1" @click="setGeneralCosts(row.order_costs)">
            {{ row.currency }}
            {{ row.currency == "USD" ? "$" : "R$"
            }}{{ sumRealValues(row.order_costs) }}
          </a>
        </template>

        <template #reference="{ row }">
          <NbButton
            variant="tertiary"
            v-b-tooltip.hover
            title="Open Tracking Page"
            @click="trackSearch(row.reference)"
          >
            {{ row.reference }}
          </NbButton>
        </template>

        <template #first_mile_tracking_number="{ row }">
          {{ getFirstMileTrackingNumbers(row.volumes) }}
        </template>

        <template #last_mile_carrier_number="{ row }">
          <div v-if="row.volumes.length < 3">
            <div
              v-if="
                checkLastMileCarrierNumber(row.volumes) ===
                'Last Mile Carrier Nº not found'
              "
              class="text-danger"
            >
              {{ $t("ordersPage.lastMileCarrierNotFound") }}
            </div>
            <div v-else-if="row.volumes.length === 1">
              {{ checkLastMileCarrierNumber(row.volumes) }}
            </div>
            <div v-else-if="row.volumes.length === 2">
              {{ checkLastMileCarrierNumber(row.volumes) }},
              {{ row.volumes[0].last_mile_carrier_number }}
            </div>
          </div>
          <div v-else-if="row.volumes.length > 2">
            {{ checkLastMileCarrierNumber(row.volumes) }}
            <a
              v-if="
                checkLastMileCarrierNumber(row.volumes) !==
                'Last Mile Carrier Nº not found'
              "
              href="#"
              data-toggle="modal"
              data-target="#showLastMileCarrierNumber"
              title="Show all Last Mile Carrier Number"
              @click="loadLastMileCarrierNumber(row)"
              class="link-1"
            >
              , and {{ row.volumes.length - 1 }} more
            </a>
          </div>
        </template>

        <template #label="{ row }">
          <div class="d-flex justify-content-center mt-1">
            <a
              v-if="checkTrackingNumber(row.volumes) === 'Track Nº not found'"
              href="#"
              title="Track Nº not found!"
              @click="generateOrderLabel(row.id)"
            >
              <div>
                <i
                  class="fa fa-clock-o text-danger"
                  aria-hidden="true"
                  style="
                    font-size: 8px;
                    height: 25px;
                    position: relative;
                    left: 7px;
                  "
                ></i>
                <i
                  class="far fa-file fa-lg text-dark"
                  style="
                    font-size: 18px;
                    position: relative;
                    left: -4px;
                    bottom: 0px;
                  "
                ></i>
              </div>
            </a>
            <a
              v-else
              href="#"
              title="Order Label"
              @click="generateOrderLabel(row.id)"
            >
              <i class="far fa-file-alt fa-lg text-dark"></i>
            </a>
          </div>
        </template>
        <template #first_mile_label="{ row }">
          <div class="d-flex justify-content-center mt-1">
            <a
              v-if="
                checkFirstMileNumber(row.volumes) === 'First Mile Nº not found'
              "
              href="#"
              title="First Mile Nº not found!"
              @click="generateFirstMile(row.id)"
            >
              <div>
                <i
                  class="fa fa-clock-o text-danger"
                  aria-hidden="true"
                  style="
                    font-size: 8px;
                    height: 25px;
                    position: relative;
                    left: 7px;
                  "
                ></i>
                <i
                  class="far fa-file fa-lg text-dark"
                  style="
                    font-size: 18px;
                    position: relative;
                    left: -4px;
                    bottom: 0px;
                  "
                ></i>
              </div>
            </a>
            <a
              v-else
              href="#"
              title="Order Label"
              @click="generateFirstMile(row.id)"
            >
              <i class="far fa-file-alt fa-lg text-dark"></i>
            </a>
          </div>
        </template>
        <template #shipConfirmation="{ row }">
          <NbButton
            v-if="row.contain_battery"
            variant="tertiary"
            v-b-tooltip.hover
            title="This document is mandatory for this order"
            @click="generateShipperConfirmation(row.id)"
          >
            {{ $t("download") }}
          </NbButton>
          <span v-else>-</span>
        </template>
        <template #is_mini="{ row }">
          <NbIcon
            :icon="row.is_mini ? 'check-circle' : 'x-circle'"
            :class="row.is_mini ? 'text-success' : 'text-danger'"
            size="16"
          />
        </template>
        <template #msds="{ row }">
          <NbButton
            variant="quaternary"
            v-b-tooltip.hover
            title="Check if there are dangerous products"
            @click="downloadMsds(row.id)"
          >
            <div class="text-center" style="width: 35px; height: 25px">
              <i class="fas fa-fire-alt fa-lg"></i>
            </div>
          </NbButton>
        </template>
        <template #financial_status="{ row }">
          <OrderFinancialStatusBadge :status="row.financial_status" />
        </template>
        <template #is_landed_cost="{ row }">
          <IsCheckedIcon :checked="row.is_landed_cost" />
        </template>
        <template #remetente_is_cpf="{ row }">
          <IsCheckedIcon :checked="row.remetente_is_cpf" />
        </template>
        <template #is_dispatched="{ row }">
          <IsCheckedIcon :checked="row.is_dispatched" />
        </template>

        <template #customer_document_type="{ row }">
          <NbBadge
            v-if="row.customer_document_type"
            :text="row.customer_document_type"
          />
        </template>

        <template #customer_country="{ row }">
          <div
            v-if="row.customer_country"
            v-b-tooltip.hover
            :title="
              $helpers.getCountryNameByCode(
                row.customer_country,
                $i18n.locale.replace('_', '-'),
              )
            "
          >
            <img :src="$helpers.getContryFlagURL(row.customer_country)" />
          </div>
          <span v-else>-</span>
        </template>

        <template #seller_country="{ row }">
          <div
            v-if="row.seller_country"
            v-b-tooltip.hover
            :title="
              $helpers.getCountryNameByCode(
                row.seller_country,
                $i18n.locale.replace('_', '-'),
              )
            "
          >
            <img :src="$helpers.getContryFlagURL(row.seller_country)" />
          </div>
          <span v-else>-</span>
        </template>

        <template #currency="{ row }">
          <NbBadge :text="row.currency" />
        </template>
        <template #sales_channel="{ row }">
          {{ row?.sales_channel?.name || "-" }}
        </template>

        <template #incoterm="{ row }">
          <NbBadge :text="row.incoterm" type="warning" />
        </template>

        <template #created_at="{ row }">
          {{ row.created_at | datetime }}
        </template>

        <template #downloads="{ row }">
          <CommercialInvoiceDownloadBtn
            variant="tertiary"
            :order-id="row.id"
            v-b-tooltip.left.hover
            :title="$t('ordersViewPage.commercialInvoice')"
          >
            <NbIcon icon="file-text" :size="18" />
          </CommercialInvoiceDownloadBtn>
          <LastMileLabelDownloadBtn
            variant="tertiary"
            :order-id="row.id"
            v-b-tooltip.left.hover
            :title="$t('ordersViewPage.lastMileLabel')"
          >
            <NbIcon icon="file" :size="18" />
          </LastMileLabelDownloadBtn>
          <PackingSlipDownloadBtn
            variant="tertiary"
            :order-id="row.id"
            v-b-tooltip.left.hover
            :title="$t('ordersViewPage.packingSlip')"
          >
            <NbIcon icon="package" :size="18" />
          </PackingSlipDownloadBtn>
        </template>

        <template #current_status="{ row }">
          <NbBadge v-if="row.current_status" :text="row.current_status.title" />
        </template>
      </DatatableRoot>
    </section>

    <Modal v-model="showImportOrdersCostsModal">
      <ImportOrderCosts @success="showImportOrdersCostsModal = false" />
    </Modal>
    <Modal v-model="showGeneralCostsModal">
      <GeneralCostsModal :order-costs="currentOrderCosts" />
    </Modal>
    <Modal size="sm" v-model="showConfirmRemoveOrders">
      <RemoveOrders
        :order-ids="selected"
        :orders-with-masterboxes="selectedOrdersWithMasterboxes"
        @success="loadItems"
      />
    </Modal>
    <Modal size="sm" v-model="showConfirmDownloadOrders">
      <DownloadOrders :order-ids="selected" />
    </Modal>
    <Modal size="sm" v-model="showConfirmDownloadOrdersCosts">
      <DownloadOrders :order-ids="selected" type="order_costs" />
    </Modal>
    <Modal size="sm" v-model="showUpdateFinancialStatus">
      <UpdateFinancialStatus :order-ids="selected" @new-status="loadItems" />
    </Modal>

    <Modal
      v-model="showOrderDetails"
      size="lg"
      @close="setUrlSearchParam('id')"
    >
      <Order :order-id="selectedOrderId" @load="loadItems" />
    </Modal>

    <div id="showLastMileCarrierNumber" class="modal fade" role="dialog">
      <div class="modal-dialog" style="width: 320px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("ordersPage.orderNo") }} {{ lastMileCarrierNumbers.name }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="list-group list-group-flush">
              <li
                v-for="(cn23, index) in lastMileCarrierNumbers.numbers"
                :key="index"
                class="list-group-item"
              >
                {{ cn23.last_mile_carrier_number }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <a
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              >{{ $t("close") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderLabelService from "../../../services/OrderLabelService";
import ProductDocumentService from "../../../services/ProductDocumentService";
import DownloadsService from "@/services/DownloadsService";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import OrderFinancialStatusBadge from "@/components/OrderFinancialStatusBadge.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import { currency } from "@/utils/filters/currency";
import ImportOrderCosts from "./components/ImportOrderCosts.vue";
import Modal from "../../../components/modal/Modal.vue";
import GeneralCostsModal from "./components/GeneralCostsModal.vue";
import RemoveOrders from "./components/RemoveOrders.vue";
import DownloadOrders from "./components/DownloadOrders.vue";
import Order from "./components/Order.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";
import UpdateFinancialStatus from "./components/UpdateFinancialStatus.vue";
import IsCheckedIcon from "../../../components/IsCheckedIcon.vue";
import CommercialInvoiceDownloadBtn from "@/components/CommercialInvoiceDownloadBtn.vue";
import LastMileLabelDownloadBtn from "@/components/LastMileLabelDownloadBtn.vue";
import PackingSlipDownloadBtn from "@/components/PackingSlipDownloadBtn.vue";
import SellerService from "@/services/SellerService";
import ContractService from "@/services/ContractService";
import CheckpointService from "@/services/CheckpointService";
import CountryService from "@/services/CountryService";
import DatatableActions from "@/components/datatable/DatatableActions";
import DatatableAction from "@/components/datatable/DatatableAction";
import ordersColumns from "./orders-columns";
import ordersFilters from "./orders-filters";

const productDocumentService = new ProductDocumentService();
const orderLabelService = new OrderLabelService();
const downloadsService = new DownloadsService();
const sellerService = new SellerService();
const contractService = new ContractService();
const checkpointService = new CheckpointService();
const countryService = new CountryService();

const tabs = {
  ALL: "all",
};

export default {
  name: "Orders",
  components: {
    NbPageTitle,
    DatatableRoot,
    NbTabs,
    NbIcon,
    NbButton,
    OrderFinancialStatusBadge,
    NbBadge,
    ImportOrderCosts,
    Modal,
    GeneralCostsModal,
    RemoveOrders,
    DownloadOrders,
    Order,
    UpdateFinancialStatus,
    IsCheckedIcon,
    CommercialInvoiceDownloadBtn,
    LastMileLabelDownloadBtn,
    PackingSlipDownloadBtn,
    DatatableActions,
    DatatableAction,
  },
  mixins: [queryParamsMixin, ordersColumns, ordersFilters],
  data: () => {
    return {
      currentTab: tabs.ALL,
      totalOrders: 0,
      currency,
      showImportOrdersCostsModal: false,
      showGeneralCostsModal: false,
      selected: [],
      showConfirmDownloadOrders: false,
      showConfirmDownloadOrdersCosts: false,
      showConfirmRemoveOrders: false,
      showOrderDetails: false,
      selectedOrderId: null,
      selectedOrdersWithMasterboxes: [],
      showUpdateFinancialStatus: false,
      financial_status_selected: null,
      lastMileCarrierNumbers: {},
      isAlertVisible: false,
      currentOrderCosts: [],
      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,
      sellers: [],
      contracts: [],
      checkpoints: [],
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: tabs.ALL,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalOrders,
        },
      ];
    },
  },
  watch: {
    isAlertVisible: function () {
      if (this.isAlertVisible) {
        this.$toasted.show("Processing", {
          type: "warning",
          theme: "bubble",
          duration: 3000,
        });
      }
    },
  },
  methods: {
    showOrder(orderId) {
      this.setUrlSearchParam("id", orderId);
      this.showOrderDetails = true;
      this.selectedOrderId = orderId;
    },
    confirmDownloadOrders() {
      this.showConfirmDownloadOrders = true;
    },
    confirmDownloadOrdersCosts() {
      this.showConfirmDownloadOrdersCosts = true;
    },
    setOrdersWithMasterboxes() {
      const ordersWithMasterbox = this.$refs?.ordersTableRef?.items?.filter(
        (item) =>
          this.selected.includes(item.id) && item?.volumes?.[0]?.masterbox_id,
      );

      this.selectedOrdersWithMasterboxes = ordersWithMasterbox || [];
    },
    confirmRemoveOrders() {
      this.setOrdersWithMasterboxes();
      this.showConfirmRemoveOrders = true;
    },
    showUpdateMassiveFinancialStatus() {
      this.showUpdateFinancialStatus = true;
    },
    optionTook(event) {
      this[event]();
    },
    setGeneralCosts(data) {
      this.currentOrderCosts = data || [];
      this.showGeneralCostsModal = true;
    },
    loadItems() {
      this.$refs.ordersTableRef?.getData();
    },
    selectedRows(items) {
      this.selected = items;
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    checkLastMileCarrierNumber(volumes) {
      let lastMileCarrierIsNull = "";
      volumes?.forEach((volume) => {
        if (volume.last_mile_carrier_number != null) {
          lastMileCarrierIsNull = volume.last_mile_carrier_number;
          return lastMileCarrierIsNull;
        } else {
          lastMileCarrierIsNull = "Last Mile Carrier Nº not found";
        }
      });
      return lastMileCarrierIsNull;
    },
    loadLastMileCarrierNumber(order) {
      this.lastMileCarrierNumbers = {};
      this.lastMileCarrierNumbers.numbers = order.volumes;
      this.lastMileCarrierNumbers.name = order.order_number;
    },
    downloadOrders(data_type) {
      this.initiateFileCreation({
        data_type: data_type,
        reference_ids: this.selected,
      });
    },
    downloadMsds(orderId) {
      this.isAlertVisible = true;
      productDocumentService.downloadMsds(orderId).then((response) => {
        this.isAlertVisible = false;
        const file_path = response.data.data;
        if (file_path) window.open(response.data.data);
        else {
          this.$toasted.clear();
          this.$toasted.show("Nothing to download for this order.", {
            type: "success",
            theme: "bubble",
            duration: 3000,
          });
        }
      });
    },
    trackSearch(traking_number) {
      if (traking_number) {
        const trackingURL = this.$helpers.buildTrackingURL(traking_number);
        window.open(trackingURL, "_blank");
        return;
      }

      location.replace(this.trackingBaseUrl + "404");
    },
    checkTrackingNumber(volumes) {
      let trackIsNull = "";
      volumes?.forEach((volume) => {
        if (volume.last_mile_tracking_number != null) {
          trackIsNull = volume.last_mile_tracking_number;
          return trackIsNull;
        } else {
          trackIsNull = "Track Nº not found";
        }
      });
      return trackIsNull;
    },
    checkFirstMileNumber(volumes) {
      let trackIsNull = "";
      volumes?.forEach((volume) => {
        if (volume.first_mile_tracking_number != null) {
          trackIsNull = volume.first_mile_tracking_number;
          return trackIsNull;
        } else {
          trackIsNull = "First Mile Nº not found";
        }
      });
      return trackIsNull;
    },
    generateShipperConfirmation(orderId) {
      orderLabelService
        .generateShipperConfirmation(orderId)
        .then((response) => {
          window.open(response.data.data);
        });
    },
    generateOrderLabel(orderId) {
      orderLabelService.generateOrderLabel(orderId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateFirstMile(orderId) {
      orderLabelService.generateFirstMile(orderId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    sumRealValues(arrayObjs) {
      let sumTotal = 0;
      arrayObjs?.forEach((obj) => {
        if (
          obj.external_id === 43 &&
          arrayObjs.find((item) => item.external_id === 8)
        )
          return;
        if (
          obj.external_id === 44 &&
          arrayObjs.find((item) => item.external_id === 9)
        )
          return;
        if (
          obj.external_id === 45 &&
          arrayObjs.find((item) => item.external_id === 7)
        )
          return;
        sumTotal += Number(obj.value);
      });
      return sumTotal.toFixed(2);
    },
    sumValuesIsCharged(arrayObjs) {
      let sumTotal = 0;
      arrayObjs?.forEach((arrayObj) => {
        if (arrayObj.is_charged == false) sumTotal += Number(arrayObj.value);
      });
      return sumTotal.toFixed(2);
    },
    getFirstMileTrackingNumbers(volumes) {
      const trackingNumbers = volumes
        .filter((volume) => volume.first_mile_tracking_number)
        .map((volume) => volume.first_mile_tracking_number);
      return trackingNumbers.join(", ") || "-";
    },
    async loadSellers() {
      const { data } = await sellerService.getSellersList();
      this.sellers = data.data.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    async loadContracts() {
      const { data } = await contractService.getContractsList();
      this.contracts = data.data.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    async loadCheckpoints() {
      const { data } = await checkpointService.getAllCheckpoints();
      this.checkpoints = data?.data?.elements?.map((item) => {
        return {
          value: item.tracking_code,
          text: item.title,
        };
      });
    },
    async loadCountries() {
      const { data } = await countryService.getCountries();
      this.countries = data.data;
    },
  },
  mounted() {
    const { id } = this.$route.query;
    if (id) {
      this.showOrder(id);
    }

    this.loadSellers();
    this.loadContracts();
    this.loadCheckpoints();
    this.loadCountries();
  },
};
</script>
