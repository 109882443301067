<template>
  <div>
    <div
      class="d-flex flex-column align-items-start flex-md-row justify-content-md-between"
    >
      <NbPageTitle
        :title="$t('volumesPage.title')"
        :subTitle="$t('volumesPage.subtitle')"
      />
      <div class="d-flex flex-column">
        <NbButton
          @click="showEditMassiveVolumesModal = true"
          v-if="$store.state.platform === 'total'"
          class="d-flex justify-content-start"
          icon="upload"
        >
          {{ $t("volumesPage.editVolumes") }}
        </NbButton>
      </div>
    </div>

    <Modal v-model="showCreateMasterboxModal">
      <CreateMasterbox :volume-ids="volumesSelected" />
    </Modal>
    <Modal v-model="showEditMassiveVolumesModal">
      <EditMassiveVolumes @success="showEditMassiveVolumesModal = false" />
    </Modal>

    <section class="page-container mt-4">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <DatatableRoot
        ref="volumesTableRef"
        namespace="volumes"
        clickable
        selectable
        :columns="tableColumns"
        :filters="tableFilters"
        :selected.sync="volumesSelected"
        @count="totalVolumes = $event"
        @rowClick="showVolumeDetails($event.id)"
        :paginate-by-offset="false"
        method="PATCH"
      >
        <template #actions>
          <DatatableActions :disabled="!volumesSelected.length">
            <DatatableAction @click="showModalDownloadVolumes">
              {{ $t("volumesPage.downloadVolumes") }}
            </DatatableAction>
            <DatatableAction @click="showModalCreateMasterboxes">
              {{ $t("volumesPage.createMasterbox") }}
            </DatatableAction>
          </DatatableActions>
        </template>

        <template #weight="{ row }">
          {{ row.weight | numberFormat }}
        </template>
        <template #cubed_weight="{ row }">
          {{ row.cubed_weight | numberFormat }} kg
        </template>
        <!-- volumes values -->
        <template #products_value="{ row }">
          {{ row.products_value | currency(row.order.currency) }}
        </template>
        <template #freight_value="{ row }">
          {{ row.freight_value | currency(row.order.currency) }}
        </template>
        <template #declared_value="{ row }">
          {{ row.declared_value | currency(row.order.currency) }}
        </template>
        <template #duties_tax_value="{ row }">
          {{ row.duties_tax_value | currency(row.order.currency) }}
        </template>
        <template #sales_tax_value="{ row }">
          {{ row.sales_tax_value | currency(row.order.currency) }}
        </template>
        <template #tax_value="{ row }">
          {{ row.tax_value | currency(row.order.currency) }}
        </template>
        <template #collected_icms="{ row }">
          {{
            row.collected_icms
              | currency(row.order.collected_taxes_currency, "USD")
          }}
        </template>
        <template #collected_ii="{ row }">
          {{
            row.collected_ii
              | currency(row.order.collected_taxes_currency, "USD")
          }}
        </template>
        <template #total_value="{ row }">
          {{ row.total_value | currency(row.order.currency) }}
        </template>
        <template #estimated_duties_tax_value="{ row }">
          {{ row.estimated_duties_tax_value | currency(row.order.currency) }}
        </template>
        <template #estimated_sales_tax_value="{ row }">
          {{ row.estimated_sales_tax_value | currency(row.order.currency) }}
        </template>
        <template #estimated_tax_value="{ row }">
          {{ row.estimated_tax_value | currency(row.order.currency) }}
        </template>
        <template #freight_cost="{ row }">
          {{ row.freight_cost | currency(row.order.currency) }}
        </template>
        <template #estimated_freight_cost="{ row }">
          {{ row.estimated_freight_cost | currency(row.order.currency) }}
        </template>
        <!-- end volumes values -->
        <!-- orders values -->
        <template #order.products_value="{ row }">
          {{ row.order.products_value | currency(row.order.currency) }}
        </template>
        <template #order.freight_value="{ row }">
          {{ row.order.freight_value | currency(row.order.currency) }}
        </template>
        <template #order.declared_value="{ row }">
          {{ row.order.declared_value | currency(row.order.currency) }}
        </template>
        <template #order.duties_tax_value="{ row }">
          {{ row.order.duties_tax_value | currency(row.order.currency) }}
        </template>
        <template #order.sales_tax_value="{ row }">
          {{ row.order.sales_tax_value | currency(row.order.currency) }}
        </template>
        <template #order.tax_value="{ row }">
          {{ row.order.tax_value | currency(row.order.currency) }}
        </template>
        <template #order.total_value="{ row }">
          {{ row.order.total_value | currency(row.order.currency) }}
        </template>
        <template #order.estimated_duties_tax_value="{ row }">
          {{
            row.order.estimated_duties_tax_value | currency(row.order.currency)
          }}
        </template>
        <template #order.estimated_sales_tax_value="{ row }">
          {{
            row.order.estimated_sales_tax_value | currency(row.order.currency)
          }}
        </template>
        <template #order.estimated_tax_value="{ row }">
          {{ row.order.estimated_tax_value | currency(row.order.currency) }}
        </template>
        <template #order.freight_cost="{ row }">
          {{ row.order.freight_cost | currency(row.order.currency) }}
        </template>
        <!-- end orders values -->

        <template #current_status="{ row }">
          <NbButton
            v-if="row?.current_status?.id"
            variant="tertiary"
            @click="loadModalData(row.current_status.id)"
          >
            {{ row?.current_status?.slug || "-" }}
          </NbButton>
          <span v-else>-</span>
        </template>

        <template #order.id="{ row }">
          <router-link
            class="link-1"
            :to="'/shipping/orders?id=' + row.order.id"
          >
            {{ row.order.id }}
          </router-link>
        </template>
        <template #order.data_source="{ row }">{{
          row.order.data_source
        }}</template>
        <template #order.prc="{ row }">
          {{ row.order.prc ? "✅" : "❌" }}
        </template>
        <template #order.seller_id="{ row }">
          <router-link
            v-if="row.order?.seller_id"
            class="link-1"
            :to="'/users_management/sellers?id=' + row.order.seller_id"
          >
            {{ row.order.seller_id }}
          </router-link>
        </template>
        <template #order.contract_id="{ row }">
          <router-link
            v-if="row.order?.contract_id"
            class="link-1"
            :to="'/contracts/contracts?contractId=' + row.order.contract_id"
          >
            {{ row.order.contract_id }}
          </router-link>
        </template>
        <template #order.customer_full_name="{ row }">
          {{ row.order.customer_full_name || "-" }}
        </template>

        <template #order.customer_postal_code="{ row }">
          {{ row.order.customer_postal_code || "-" }}
        </template>

        <template #order.customer_city="{ row }">
          {{ row.order.customer_city || "-" }}
        </template>

        <template #order.customer_state="{ row }">
          {{ row.order.customer_state || "-" }}
        </template>

        <template #order.customer_address="{ row }">
          {{ row.order.customer_address || "-" }}
        </template>

        <template #order.customer_address_number="{ row }">
          {{ row.order.customer_address_number || "-" }}
        </template>

        <template #order.customer_address_complement="{ row }">
          {{ row.order.customer_address_complement || "-" }}
        </template>

        <template #order.customer_address_reference="{ row }">
          {{ row.order.customer_address_reference || "-" }}
        </template>

        <template #order.customer_address_quarter="{ row }">
          {{ row.order.customer_address_quarter || "-" }}
        </template>

        <template #order.customer_document_type="{ row }">
          {{ row.order.customer_document_type || "-" }}
        </template>

        <template #order.customer_document_number="{ row }">
          {{ row.order.customer_document_number || "-" }}
        </template>
        <template #order.customer_country="{ row }">
          {{ row.order.customer_country || "-" }}
        </template>

        <template #order.customer_email="{ row }">
          {{ row.order.customer_email || "-" }}
        </template>
        <template #order.customer_phone="{ row }">
          {{ row.order.customer_phone || "-" }}
        </template>

        <template #order.currency="{ row }">
          {{ row.order.currency || "-" }}
        </template>

        <template #order.estimated_delivery_date="{ row }">
          {{ row.order.estimated_delivery_date || "-" }}
        </template>

        <template #order.incoterm="{ row }">
          {{ row.order.incoterm || "-" }}
        </template>

        <template #order.order_number="{ row }">
          {{ row.order.order_number || "-" }}
        </template>

        <template #order.sales_channel_name="{ row }">
          {{ row.order.sales_channel_name || "-" }}
        </template>

        <template #order.sales_channel_order_number="{ row }">
          {{ row.order.sales_channel_order_number || "-" }}
        </template>

        <template #order.reference="{ row }">
          {{ row.order.reference || "-" }}
        </template>

        <template #order.is_commercial_destination="{ row }">
          <IsCheckedIcon :checked="row?.order?.is_commercial_destination" />

          <!-- <NbIcon
            :attributes="iconAttrs"
            :class="
              row?.order?.is_commercial_destination
                ? 'text-success'
                : 'text-danger'
            "
            :icon="
              row?.order?.is_commercial_destination
                ? 'check-circle'
                : 'x-circle'
            "
          /> -->
        </template>

        <template #order.seller_rfb_code="{ row }">
          {{ row.order.seller_rfb_code || "-" }}
        </template>

        <template #order.seller_legal_name="{ row }">
          {{ row.order.seller_legal_name || "-" }}
        </template>

        <template #order.overpack.id="{ row }">
          <router-link
            v-if="row.order?.overpack?.id"
            class="link-1"
            :to="'/shipping/overpacks?id=' + row.order.overpack.id"
          >
            {{ row.order.overpack.id }}
          </router-link>
        </template>

        <template #order.overpack.sent_at="{ row }">
          {{ row.order?.overpack?.sent_at || "-" }}
        </template>

        <template #order.overpack.reference="{ row }">
          {{ row.order?.overpack?.reference || "-" }}
        </template>

        <template #masterbox.id="{ row }">
          <router-link
            v-if="row?.masterbox?.id"
            class="link-1"
            :to="'/shipping/masterboxes?id=' + row.masterbox.id"
          >
            {{ row.masterbox.id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #masterbox.shipment.id="{ row }">
          {{ row.masterbox?.shipment?.id || "-" }}
        </template>
        <template #masterbox.shipment.flight.id="{ row }">
          <router-link
            v-if="row?.masterbox?.shipment?.flight?.id"
            class="link-1"
            :to="'/shipping/flights?id=' + row.masterbox.shipment.flight.id"
          >
            {{ row.masterbox.shipment.flight.id }}
          </router-link>
        </template>
        <template #masterbox.shipment.flight.flight_number="{ row }">
          {{ row.masterbox?.shipment?.flight?.flight_number || "-" }}
        </template>
        <template #masterbox.shipment.flight.mawb_number="{ row }">
          {{ row.masterbox?.shipment?.flight?.mawb_number || "-" }}
        </template>
        <template #order.financial_status="{ row }">
          <OrderFinancialStatusBadge :status="row.order.financial_status" />
        </template>
        <template #cn23_label="{ row }">
          <NbButton
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            v-if="row.last_mile_tracking_number"
            @click="generateBoxLabel(row.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>

          <span v-else>-</span>
        </template>
        <template #first_mile_label="{ row }">
          <NbButton
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            v-if="row.first_mile_tracking_number"
            @click="generateVolumeFirstMile(row.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>

          <span v-else>-</span>
        </template>
        <template #cn38_label="{ row }">
          <NbButton
            v-if="hasCn38Label(row?.masterbox?.shipment?.cn38)"
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            @click="generateShipmentLabel(row.masterbox.shipment.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>
          <span v-else>-</span>
        </template>
        <template #flight_label="{ row }">
          <NbButton
            v-if="row.masterbox?.shipment?.flight?.mawb_number"
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            @click="generateFlightLabel(row.masterbox.shipment.flight.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>
          <span v-else>-</span>
        </template>

        <template #masterbox.shipment.flight.reference="{ row }">
          {{ row?.masterbox?.shipment?.flight?.reference || "-" }}
        </template>

        <template #masterbox.shipment.flight.airline="{ row }">
          {{ row?.masterbox?.shipment?.flight?.airline || "-" }}
        </template>

        <template #masterbox.shipment.flight.green_light="{ row }">
          <NbIcon
            v-if="
              typeof row?.masterbox?.shipment?.flight?.green_light === 'boolean'
            "
            :attributes="iconAttrs"
            :class="
              row.masterbox.shipment.flight.green_light
                ? 'text-success'
                : 'text-danger'
            "
            :icon="
              row.masterbox.shipment.flight.green_light
                ? 'check-circle'
                : 'x-circle'
            "
          />
        </template>

        <template #masterbox.shipment.flight.prealerts_sent="{ row }">
          {{ row?.masterbox?.shipment?.flight?.prealerts_sent || "-" }}
        </template>

        <template #masterbox.shipment.flight.departure_airport="{ row }">
          {{ row?.masterbox?.shipment?.flight?.departure_airport || "-" }}
        </template>

        <template #masterbox.shipment.flight.arrival_airport="{ row }">
          {{ row?.masterbox?.shipment?.flight?.arrival_airport || "-" }}
        </template>

        <template #masterbox.shipment.flight.departure_date="{ row }">
          {{ row?.masterbox?.shipment?.flight?.departure_date || "-" }}
        </template>

        <template #hs_code="{ row }">
          <div v-for="order_item in row.order_items" :key="order_item.id">
            {{ order_item.hs_code }}
          </div>
        </template>
        <template #msds="{ row }">
          <NbButton
            v-if="row.order?.id"
            @click="downloadMsds(row.order.id)"
            v-b-tooltip.hover
            variant="quaternary"
            title="Check if there are dangerous products"
          >
            <div class="text-center" style="width: 35px; height: 25px">
              <i class="fas fa-fire-alt fa-lg"></i>
            </div>
          </NbButton>

          <span v-else>-</span>
        </template>
        <template #description="{ row }">
          <div v-for="order_item in row.order_items" :key="order_item.id">
            {{ order_item.description }}
          </div>
        </template>

        <template #first_mile_tracking_number="{ row }">
          {{ row?.first_mile_tracking_number || "-" }}
        </template>

        <template #order.overpack.first_mile_tracking_number="{ row }">
          {{ row.order?.overpack?.first_mile_tracking_number || "-" }}
        </template>

        <template #order.overpack.first_mile_carrier_name="{ row }">
          {{ row.order?.overpack?.first_mile_carrier_name || "-" }}
        </template>

        <template #masterbox.dispatch_id="{ row }">
          {{ row.masterbox?.dispatch_id || "-" }}
        </template>

        <template #masterbox.reference="{ row }">
          {{ row.masterbox?.reference || "-" }}
        </template>

        <template #masterbox.shipment.cn38="{ row }">
          {{ row.masterbox?.shipment?.cn38 || "-" }}
        </template>

        <template #cn35_label="{ row }">
          <NbButton
            v-if="row.masterbox?.reference"
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            @click="generateMasterBoxLabel(row.masterbox?.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>
          <span v-else>-</span>
        </template>
      </DatatableRoot>
    </section>

    <SelectColumnsDownloadModal
      :loading="isDownloading"
      :options="downloadFields"
      @selected="downloadVolumes"
    />
    <Modal v-model="isModalNobordistCheckpointsViewOpen" size="lg">
      <ModalNobordistCheckpointsView :itemId="currentItemId" />
    </Modal>

    <Modal
      v-model="isVolumeModalOpen"
      @close="setUrlSearchParam('id')"
      size="lg"
    >
      <VolumeModal v-if="selectedVolumeId" :volumeId="selectedVolumeId" />
    </Modal>
  </div>
</template>

<script>
import OrderLabelService from "@/services/OrderLabelService";
import MasterBoxService from "@/services/MasterBoxService";
import ShipmentService from "@/services/ShipmentService";
import FlightService from "@/services/FlightService";
import ProductDocumentService from "@/services/ProductDocumentService";
import DownloadsService from "@/services/DownloadsService";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import DatatableActions from "@/components/datatable/DatatableActions.vue";
import DatatableAction from "@/components/datatable/DatatableAction.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import OrderFinancialStatusBadge from "@/components/OrderFinancialStatusBadge.vue";
import SelectColumnsDownloadModal from "@/components/SelectColumnsDownloadModal.vue";
import VolumeModal from "./VolumeModal.vue";
import queryParamsMixin from "@/mixins/query-params-mixin";
import CreateMasterbox from "./components/CreateMasterbox.vue";
import Modal from "@/components/modal/Modal.vue";
import EditMassiveVolumes from "./components/EditMassiveVolumes.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";

const orderLabelService = new OrderLabelService();
const masterBoxService = new MasterBoxService();
const shipmentService = new ShipmentService();
const flightService = new FlightService();
const productDocumentService = new ProductDocumentService();
const downloadsService = new DownloadsService();

function parseNumber(value) {
  const parse = Number(value);
  return !isNaN(parse) ? parse.toFixed(2) : "0.00";
}

const availableCurrenciesCode = {
  R$: "BRL",
  USD: "USD",
};
export default {
  name: "Volumes",
  mixins: [queryParamsMixin],
  components: {
    ModalNobordistCheckpointsView,
    NbPageTitle,
    DatatableRoot,
    NbButton,
    OrderFinancialStatusBadge,
    NbIcon,
    SelectColumnsDownloadModal,
    NbTabs,
    VolumeModal,
    Modal,
    EditMassiveVolumes,
    CreateMasterbox,
    IsCheckedIcon,
    DatatableAction,
    DatatableActions,
  },
  filters: {
    numberFormat(value) {
      return parseNumber(value);
    },
    currency(value, currencyCode, defaultCurrency = "BRL") {
      const data = parseNumber(value);

      if (!defaultCurrency && !(currencyCode in availableCurrenciesCode)) {
        return data;
      }

      const formatter = new Intl.NumberFormat("en", {
        style: "currency",
        currency:
          availableCurrenciesCode?.[currencyCode?.toUpperCase()] ||
          defaultCurrency,
      });

      return formatter.format(data);
    },
  },
  data: () => {
    return {
      showEditMassiveVolumesModal: false,
      isAlertVisible: false,
      currentItemId: "",
      tableFields: [],
      volumesSelected: [],
      currentTab: "all",
      totalVolumes: 0,
      isDownloading: false,
      iconAttrs: {
        width: "16px",
        height: "16px",
      },
      showCreateMasterboxModal: false,
      isVolumeModalOpen: false,
      selectedVolumeId: null,
      isModalNobordistCheckpointsViewOpen: false,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          found: this.totalVolumes,
          current: this.currentTab,
        },
      ];
    },
    tableOptions() {
      return [
        {
          text: this.$t("volumesPage.downloadVolumes"),
          value: "showModalDownloadVolumes",
        },
        {
          text: this.$t("volumesPage.createMasterbox"),
          value: "showModalCreateMasterboxes",
        },
      ];
    },
    items() {
      let testItem = JSON.parse(
        JSON.stringify(this.$store.state[this.namespace].items),
      );
      return testItem;
    },
    tableColumns() {
      return [
        { key: "id", label: "ID" },
        {
          key: "weight",
          label: this.$t("volumesPage.fields.weight"),
        },
        {
          key: "updated_weight",
          label: this.$t("volumesPage.fields.updatedWeight"),
        },
        {
          key: "cubed_weight",
          label: this.$t("volumesPage.fields.cubedWeight"),
        },
        {
          key: "height",
          label: this.$t("volumesPage.fields.height"),
        },
        {
          key: "width",
          label: this.$t("volumesPage.fields.Width"),
        },
        {
          key: "length",
          label: this.$t("volumesPage.fields.length"),
        },
        {
          key: "reference",
          label: this.$t("volumesPage.fields.reference"),
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("volumesPage.fields.lastMileCarrierNumber"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("volumesPage.fields.lastMileTrackingNumber"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
        },
        {
          key: "cn23_label",
          label: this.$t("volumesPage.fields.cN23"),
        },
        {
          key: "first_mile_label",
          label: this.$t("volumesPage.fields.firstMileLabel"),
        },
        {
          key: "created_at",
          label: this.$t("volumesPage.fields.creationDate"),
          sortable: true,
        },
        //detail volume vals
        {
          key: "products_value",
          label: this.$t("volumesPage.fields.volumeProductsValue"),
          sortable: true,
        },
        {
          key: "freight_value",
          label: this.$t("volumesPage.fields.volumeFreightValue"),
          sortable: true,
        },
        {
          key: "declared_value",
          label: this.$t("volumesPage.fields.volumeDeclaredValue"),
          sortable: true,
        },
        {
          key: "duties_tax_value",
          label: this.$t("volumesPage.fields.volumeDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "sales_tax_value",
          label: this.$t("volumesPage.fields.volumeSalesTaxValue"),
          sortable: true,
        },
        {
          key: "tax_value",
          label: this.$t("volumesPage.fields.volumeTaxValue"),
          sortable: true,
        },
        {
          key: "collected_icms",
          label: this.$t("volumesPage.fields.collectedIcms"),
        },
        {
          key: "collected_ii",
          label: this.$t("volumesPage.fields.collectedIi"),
        },
        {
          key: "estimated_duties_tax_value",
          label: this.$t("volumesPage.fields.volumeEstimatedDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "estimated_sales_tax_value",
          label: this.$t("volumesPage.fields.volumeEstimatedSalesTaxValue"),
          sortable: true,
        },
        {
          key: "estimated_tax_value",
          label: this.$t("volumesPage.fields.volumeEstimatedTaxValue"),
          sortable: true,
        },
        {
          key: "freight_cost",
          label: this.$t("volumesPage.fields.volumeFreightCost"),
          sortable: true,
        },
        {
          key: "estimated_freight_cost",
          label: this.$t("volumesPage.fields.volumeEstimatedFreightCost"),
          sortable: true,
        },
        // order items data
        {
          key: "hs_code",
          label: this.$t("volumesPage.fields.hSCode"),
        },
        {
          key: "description",
          label: this.$t("volumesPage.fields.description"),
        },
        {
          key: "current_status",
          label: this.$t("volumesPage.fields.currentStatus"),
        },
        // order data
        {
          key: "order.id",
          label: this.$t("volumesPage.fields.orderID"),
        },
        {
          key: "order.prc",
          label: "PRC",
        },
        {
          key: "msds",
          label: this.$t("volumesPage.fields.mSDS"),
        },
        {
          key: "order.data_source",
          label: this.$t("ordersPage.fields.data_source"),
        },
        {
          key: "order.seller_id",
          label: this.$t("volumesPage.fields.sellerID"),
        },
        {
          key: "order.first_mile_number",
          label: this.$t("volumesPage.fields.firstMileN"),
        },
        {
          key: "order.contract_id",
          label: this.$t("volumesPage.fields.contractID"),
        },
        {
          key: "order.customer_full_name",
          label: this.$t("volumesPage.fields.customerName"),
        },
        {
          key: "order.customer_postal_code",
          label: this.$t("volumesPage.fields.cPostalCode"),
        },
        {
          key: "order.customer_city",
          label: this.$t("volumesPage.fields.customerCity"),
        },
        {
          key: "order.customer_state",
          label: this.$t("volumesPage.fields.customerState"),
        },
        {
          key: "order.customer_address",
          label: this.$t("volumesPage.fields.customerAddress"),
        },
        {
          key: "order.customer_address_number",
          label: this.$t("volumesPage.fields.customerAddressNumber"),
        },
        {
          key: "order.customer_address_complement",
          label: this.$t("volumesPage.fields.customerAddressComplement"),
        },
        {
          key: "order.customer_address_reference",
          label: this.$t("volumesPage.fields.customerAddressReference"),
        },
        {
          key: "order.customer_address_quarter",
          label: this.$t("volumesPage.fields.customerAddressQuarter"),
        },
        {
          key: "order.customer_document_type",
          label: this.$t("volumesPage.fields.customerDocumentType"),
        },
        {
          key: "order.customer_document_number",
          label: this.$t("volumesPage.fields.customerDocumentNumber"),
        },
        {
          key: "order.customer_country",
          label: this.$t("volumesPage.fields.customerCountry"),
        },
        {
          key: "order.customer_email",
          label: this.$t("volumesPage.fields.customerEmail"),
        },
        {
          key: "order.customer_phone",
          label: this.$t("volumesPage.fields.customerPhone"),
        },
        {
          key: "order.currency",
          label: this.$t("volumesPage.fields.currency"),
        },
        {
          key: "order.estimated_delivery_date",
          label: this.$t("volumesPage.fields.estimatedDeliveryDate"),
        },
        {
          key: "order.incoterm",
          label: this.$t("volumesPage.fields.incoterm"),
        },
        {
          key: "order.order_number",
          label: this.$t("volumesPage.fields.orderNumber"),
        },
        {
          key: "order.sales_channel_name",
          label: this.$t("volumesPage.fields.salesChannel"),
        },
        {
          key: "order.sales_channel_order_number",
          label: this.$t("volumesPage.fields.salesChOrderNumber"),
        },
        {
          key: "total_value",
          label: this.$t("volumesPage.fields.totalValue"),
        },
        {
          key: "order.reference",
          label: this.$t("volumesPage.fields.orderReference"),
        },
        {
          key: "order.financial_status",
          label: this.$t("volumesPage.fields.financialStatus"),
          sortable: true,
        },
        {
          key: "order.is_commercial_destination",
          label: this.$t("volumesPage.fields.orderIsCommercialDestination"),
          sortable: true,
        },
        {
          key: "order.seller_rfb_code",
          label: this.$t("volumesPage.fields.orderSellerRfbCode"),
          sortable: true,
        },
        {
          key: "order.seller_legal_name",
          label: this.$t("volumesPage.fields.orderSellerLegalName"),
          sortable: true,
        },
        //detail order vals
        {
          key: "order.products_value",
          label: this.$t("volumesPage.fields.orderProductsValue"),
          sortable: true,
        },
        {
          key: "order.freight_value",
          label: this.$t("volumesPage.fields.orderFreightValue"),
          sortable: true,
        },
        {
          key: "order.declared_value",
          label: this.$t("volumesPage.fields.orderDeclaredValue"),
          sortable: true,
        },
        {
          key: "order.duties_tax_value",
          label: this.$t("volumesPage.fields.orderDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "order.sales_tax_value",
          label: this.$t("volumesPage.fields.orderSalesTaxValue"),
          sortable: true,
        },
        {
          key: "order.tax_value",
          label: this.$t("volumesPage.fields.orderTaxValue"),
          sortable: true,
        },
        {
          key: "order.estimated_duties_tax_value",
          label: this.$t("volumesPage.fields.orderEstimatedDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "order.estimated_sales_tax_value",
          label: this.$t("volumesPage.fields.orderEstimatedSalesTaxValue"),
          sortable: true,
        },
        {
          key: "order.estimated_tax_value",
          label: this.$t("volumesPage.fields.orderEstimatedTaxValue"),
          sortable: true,
        },
        {
          key: "order.freight_cost",
          label: this.$t("volumesPage.fields.orderFreightCost"),
          sortable: true,
        },
        // overpack data
        {
          key: "order.overpack.id",
          label: this.$t("volumesPage.fields.overpackID"),
        },
        {
          key: "order.overpack.sent_at",
          label: this.$t("volumesPage.fields.overpackSentDate"),
        },
        {
          key: "order.overpack.reference",
          label: this.$t("volumesPage.fields.overpackReference"),
        },
        {
          key: "order.overpack.first_mile_carrier_name",
          label: this.$t("volumesPage.fields.firstMileCompany"),
        },
        //Descobrir como est'a vindo
        // masterbox data
        {
          key: "masterbox.id",
          label: this.$t("volumesPage.fields.masterboxID"),
        },
        {
          key: "masterbox.dispatch_id",
          label: this.$t("volumesPage.fields.dispatchID"),
        },
        {
          key: "masterbox.reference",
          label: this.$t("volumesPage.fields.cN35"),
        },
        {
          key: "cn35_label",
          label: this.$t("volumesPage.fields.cN35Label"),
        },
        // shipment data
        {
          key: "masterbox.shipment.id",
          label: this.$t("volumesPage.fields.shipmentID"),
        },
        {
          key: "masterbox.shipment.category",
          label: this.$t("volumesPage.fields.shipmentCategory"),
        },
        {
          key: "masterbox.shipment.cn38",
          label: this.$t("volumesPage.fields.cN38"),
        },
        {
          key: "cn38_label",
          label: this.$t("volumesPage.fields.cN38Label"),
        },
        // flight data
        {
          key: "masterbox.shipment.flight.id",
          label: this.$t("volumesPage.fields.flightID"),
        },
        {
          key: "masterbox.shipment.flight.flight_number",
          label: this.$t("volumesPage.fields.flightN"),
        },
        {
          key: "masterbox.shipment.flight.mawb_number",
          label: this.$t("volumesPage.fields.flightMAWB"),
        },
        {
          key: "flight_label",
          label: this.$t("volumesPage.fields.mAWBLabel"),
        },
        {
          key: "masterbox.shipment.flight.reference",
          label: this.$t("volumesPage.fields.flightReference"),
        },
        {
          key: "masterbox.shipment.flight.airline",
          label: this.$t("volumesPage.fields.airline"),
        },
        {
          key: "masterbox.shipment.flight.green_light",
          label: this.$t("volumesPage.fields.greenLight"),
        },
        {
          key: "masterbox.shipment.flight.prealerts_sent",
          label: this.$t("volumesPage.fields.prealertsSent"),
        },
        {
          key: "masterbox.shipment.flight.departure_airport",
          label: this.$t("volumesPage.fields.departureAirport"),
        },
        {
          key: "masterbox.shipment.flight.arrival_airport",
          label: this.$t("volumesPage.fields.arrivalAirport"),
        },
        {
          key: "masterbox.shipment.flight.departure_date",
          label: this.$t("volumesPage.fields.departureStartDate"),
        },
      ];
    },
    tableFilters() {
      return [
        // volume filters
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.volumeID"),
          inputs: [
            {
              key: "volume.id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.reference"),
          inputs: [
            {
              key: "volume.reference",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.lastMileTrackingNumber"),
          inputs: [
            {
              key: "volume.last_mile_tracking_number",
              popoverText: this.$t("volumesPage.filterOptions.lmtnPopover"),
            },
          ],
        },
        {
          label: this.$t(
            "volumesPage.filterOptions.withoutLastMileTrackingNumber",
          ),
          inputs: [
            {
              key: "volume.last_mile_tracking_number_null",
              type: "boolean",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.lastMileCarrierNumber"),
          inputs: [
            {
              key: "volume.last_mile_carrier_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
          inputs: [
            {
              key: "volume.first_mile_tracking_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.estimatedFreightCost"),
          inputs: [
            {
              key: "volume.estimated_freight_cost",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.collectedIcms"),
          inputs: [
            {
              key: "volume.collected_icms",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.collectedIi"),
          inputs: [
            {
              key: "volume.collected_ii",
              type: "number",
            },
          ],
        },
        // order filters
        {
          label: this.$t("volumesPage.filterOptions.orderDate"),
          inputs: [
            {
              key: "order.start_registration_date",
              type: "date",
            },
            {
              key: "order.end_registration_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.orderID"),
          inputs: [
            {
              key: "order.id",
              type: "number",
            },
          ],
        },
        {
          label: "PRC",
          inputs: [{ key: "order.prc", type: "boolean" }],
        },
        {
          label: this.$t("volumesPage.filterOptions.contractID"),
          inputs: [
            {
              key: "order.contract_id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.sellerID"),
          inputs: [
            {
              key: "order.seller_id",
              type: "number",
              initialVal: this.$route.query.sellerId || "",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.freightCost"),
          inputs: [{ key: "order.freight_cost" }],
        },
        {
          label: this.$t("volumesPage.filterOptions.firstMileN"),
          inputs: [
            {
              key: "order.first_mile_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.lastMileN"),
          inputs: [
            {
              key: "order.last_mile_number_father",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.orderNumber"),
          inputs: [
            {
              key: "order.order_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.salesChannel"),
          inputs: [
            {
              key: "order.sales_channel_name",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.salesChOrderNumber"),
          inputs: [
            {
              key: "order.sales_channel_order_number",
            },
          ],
        },
        {
          key: "order.reference",
          inputs: [
            {
              label: this.$t("volumesPage.filterOptions.orderReference"),
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.incoterm"),
          inputs: [{ key: "order.incoterm" }],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerName"),
          inputs: [
            {
              key: "order.customer_full_name",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerAddress"),
          inputs: [
            {
              key: "order.customer_address",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerAddressNumber"),
          inputs: [
            {
              key: "order.customer_address_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerAddressComplement"),
          inputs: [
            {
              key: "order.customer_address_complement",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerAddressReference"),
          inputs: [{ key: "order.customer_address_reference" }],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerAddressQuarter"),
          inputs: [
            {
              key: "order.customer_address_quarter",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerDocumentType"),
          inputs: [
            {
              key: "order.customer_document_type",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerDocumentNumber"),
          inputs: [
            {
              key: "orders.customer_document_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerCountry"),
          inputs: [
            {
              key: "order.customer_country",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerEmail"),
          inputs: [
            {
              key: "order.customer_email",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.customerPhone"),
          inputs: [
            {
              key: "order.customer_phone",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.financialStatus"),
          inputs: [
            {
              key: "order.financial_status",
              type: "select",
              options: this.financial_statuses,
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.orderIsCommercialDestination"),
          inputs: [
            {
              key: "order.is_commercial_destination",
              type: "boolean",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.orderSellerRfbCode"),
          inputs: [
            {
              key: "order.seller_rfb_code",
            },
          ],
        },
        {
          label: this.$t("volumesPage.fields.orderSellerLegalName"),
          inputs: [
            {
              key: "order.seller_legal_name",
            },
          ],
        },
        // overpack filters
        {
          label: this.$t("volumesPage.filterOptions.overpackDate"),
          inputs: [
            {
              key: "overpack.start_registration_date",
              type: "date",
            },
            {
              key: "overpack.end_registration_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.overpackSendDate"),
          inputs: [
            {
              key: "overpack.start_send_date",
              type: "date",
            },
            {
              key: "overpack.end_send_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.overpackID"),
          inputs: [
            {
              key: "overpack.id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.overpackReference"),
          inputs: [
            {
              key: "overpack.reference",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.bagNumber"),
          inputs: [
            {
              key: "overpack.bag_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.firstMileTrackingN"),
          inputs: [
            {
              key: "overpack.first_mile_tracking_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.firstMileCarrierName"),
          inputs: [
            {
              key: "overpack.first_mile_carrier_name",
            },
          ],
        },
        // masterbox filters
        {
          label: this.$t("volumesPage.filterOptions.masterboxDate"),
          inputs: [
            {
              type: "date",
              key: "masterbox.start_registration_date",
            },
            {
              type: "date",
              key: "masterbox.end_registration_date",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.masterboxID"),
          inputs: [
            {
              key: "masterbox.id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.cn35"),
          inputs: [
            {
              key: "masterbox.reference",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.dispatchID"),
          inputs: [
            {
              key: "masterbox.dispatch_id",
              type: "number",
            },
          ],
        },
        // shipment filters
        {
          label: this.$t("volumesPage.filterOptions.shipmentDate"),
          inputs: [
            {
              key: "shipment.start_registration_date",
              type: "dates",
            },
            {
              key: "shipment.end_registration_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.shipmentID"),
          inputs: [
            {
              key: "shipment.id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.cN38"),
          inputs: [
            {
              key: "shipment.cn38",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.shipmentCategory"),
          inputs: [
            {
              key: "shipment.category",
            },
          ],
        },
        // flight filters
        {
          label: this.$t("volumesPage.filterOptions.flightDate"),
          inputs: [
            {
              key: "flight.start_registration_date",
              type: "date",
            },
            {
              key: "flight.end_registration_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.flightID"),
          inputs: [
            {
              key: "flight.id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.flightMAWB"),
          inputs: [
            {
              key: "flight.mawb_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.flightN"),
          inputs: [
            {
              key: "flight.flight_number",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.flightreference"),
          inputs: [
            [
              {
                key: "flight.reference",
              },
            ],
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.airline"),
          inputs: [
            {
              key: "flight.airline",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.greenLight"),
          inputs: [{ key: "flight.green_light", type: "boolean" }],
        },
        {
          label: this.$t("volumesPage.filterOptions.departureAirport"),
          inputs: [{ key: "flight.departure_airport" }],
        },
        {
          label: this.$t("volumesPage.filterOptions.arrivalAirport"),
          inputs: [
            {
              key: "flight.arrival_airport",
            },
          ],
        },
        {
          label: this.$t("volumesPage.filterOptions.flightDepartureDate"),
          inputs: [
            [
              {
                key: "flight.start_departure_date",
                type: "date",
              },
              {
                key: "flight.end_departure_date",
                type: "date",
              },
            ],
          ],
        },
      ];
    },
    downloadFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "weight",
          label: this.$t("volumesPage.downloadFields.weight"),
        },
        {
          key: "height",
          label: this.$t("volumesPage.downloadFields.height"),
        },
        {
          key: "width",
          label: this.$t("volumesPage.downloadFields.width"),
        },
        {
          key: "length",
          label: this.$t("volumesPage.downloadFields.length"),
        },
        {
          key: "reference",
          label: this.$t("volumesPage.downloadFields.reference"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("volumesPage.downloadFields.lastMileTrackingNumber"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
        },
        {
          key: "created_at",
          label: this.$t("volumesPage.downloadFields.creationDate"),
          sortable: true,
        },
        //detail volume vals
        {
          key: "products_value",
          label: this.$t("volumesPage.downloadFields.volumeProductsValue"),
          sortable: true,
        },
        {
          key: "freight_value",
          label: this.$t("volumesPage.downloadFields.volumeFreightValue"),
          sortable: true,
        },
        {
          key: "declared_value",
          label: this.$t("volumesPage.downloadFields.volumeDeclaredValue"),
          sortable: true,
        },
        {
          key: "duties_tax_value",
          label: this.$t("volumesPage.downloadFields.volumeDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "sales_tax_value",
          label: this.$t("volumesPage.downloadFields.volumeSalesTaxValue"),
          sortable: true,
        },
        {
          key: "tax_value",
          label: this.$t("volumesPage.downloadFields.volumeTaxValue"),
          sortable: true,
        },
        {
          key: "total_value",
          label: this.$t("volumesPage.downloadFields.volumeTotalValue"),
          sortable: true,
        },
        {
          key: "estimated_duties_tax_value",
          label: this.$t(
            "volumesPage.downloadFields.volumeEstimatedDutiesTaxValue",
          ),
          sortable: true,
        },
        {
          key: "estimated_sales_tax_value",
          label: this.$t(
            "volumesPage.downloadFields.volumeEstimatedSalesTaxValue",
          ),
          sortable: true,
        },
        {
          key: "estimated_tax_value",
          label: this.$t("volumesPage.downloadFields.volumeEstimatedTaxValue"),
          sortable: true,
        },
        {
          key: "freight_cost",
          label: this.$t("volumesPage.downloadFields.volumeFreightCost"),
          sortable: true,
        },
        {
          key: "estimated_freight_cost",
          label: this.$t(
            "volumesPage.downloadFields.volumeEstimatedFreightCost",
          ),
          sortable: true,
        },
        {
          key: "collected_icms",
          label: this.$t("volumesPage.fields.collectedIcms"),
          sortable: true,
        },
        {
          key: "collected_ii",
          label: this.$t("volumesPage.fields.collectedIi"),
          sortable: true,
        },
        // order items data
        {
          key: "hs_code",
          label: this.$t("volumesPage.downloadFields.hSCode"),
        },
        {
          key: "description",
          label: this.$t("volumesPage.downloadFields.description"),
        },
        {
          key: "current_status",
          label: this.$t("volumesPage.downloadFields.currentStatus"),
        },
        // order data
        {
          key: "order.data_source",
          label: this.$t("ordersPage.fields.data_source"),
        },
        {
          key: "order.id",
          label: this.$t("volumesPage.downloadFields.orderID"),
        },
        {
          key: "order.seller_id",
          label: this.$t("volumesPage.downloadFields.sellerID"),
        },
        {
          key: "order.contract_id",
          label: this.$t("volumesPage.downloadFields.contractID"),
        },
        {
          key: "order.customer_full_name",
          label: this.$t("volumesPage.downloadFields.customerName"),
        },
        {
          key: "order.customer_postal_code",
          label: this.$t("volumesPage.downloadFields.cPostalCode"),
        },
        {
          key: "order.customer_city",
          label: this.$t("volumesPage.downloadFields.customerCity"),
        },
        {
          key: "order.customer_state",
          label: this.$t("volumesPage.downloadFields.customerState"),
        },
        {
          key: "order.customer_address",
          label: this.$t("volumesPage.downloadFields.customerAddress"),
        },
        {
          key: "order.customer_address_number",
          label: this.$t("volumesPage.downloadFields.customerAddressNumber"),
        },
        {
          key: "order.customer_address_complement",
          label: this.$t(
            "volumesPage.downloadFields.customerAddressComplement",
          ),
        },
        {
          key: "order.customer_address_reference",
          label: this.$t("volumesPage.downloadFields.customerAddressReference"),
        },
        {
          key: "order.customer_address_quarter",
          label: this.$t("volumesPage.downloadFields.customerAddressQuarter"),
        },
        {
          key: "order.customer_document_type",
          label: this.$t("volumesPage.downloadFields.customerDocumentType"),
        },
        {
          key: "order.customer_document_number",
          label: this.$t("volumesPage.downloadFields.customerDocumentNumber"),
        },
        {
          key: "order.customer_country",
          label: this.$t("volumesPage.downloadFields.customerCountry"),
        },
        {
          key: "order.customer_email",
          label: this.$t("volumesPage.downloadFields.customerEmail"),
        },
        {
          key: "order.customer_phone",
          label: this.$t("volumesPage.downloadFields.customerPhone"),
        },
        {
          key: "order.currency",
          label: this.$t("volumesPage.downloadFields.currency"),
        },
        {
          key: "order.freight_cost",
          label: this.$t("volumesPage.downloadFields.freightCost"),
        },
        {
          key: "order.freight_value",
          label: this.$t("volumesPage.downloadFields.freigtValue"),
        },
        {
          key: "order.estimated_delivery_date",
          label: this.$t("volumesPage.downloadFields.estimatedDeliveryDate"),
        },
        {
          key: "order.incoterm",
          label: this.$t("volumesPage.downloadFields.incoterm"),
        },
        {
          key: "order.order_number",
          label: this.$t("volumesPage.downloadFields.orderNumber"),
        },
        {
          key: "order.sales_channel_name",
          label: this.$t("volumesPage.downloadFields.salesChannel"),
        },
        {
          key: "order.sales_channel_order_number",
          label: this.$t("volumesPage.downloadFields.salesChOrderNumber"),
        },
        {
          key: "order.total_value",
          label: this.$t("volumesPage.downloadFields.totalValue"),
        },
        {
          key: "order.reference",
          label: this.$t("volumesPage.downloadFields.orderReference"),
        },
        {
          key: "order.financial_status",
          label: this.$t("volumesPage.downloadFields.financialStatus"),
          sortable: true,
        },
        {
          key: "order.is_commercial_destination",
          label: this.$t("volumesPage.fields.orderIsCommercialDestination"),
          sortable: true,
        },
        {
          key: "order.seller_rfb_code",
          label: this.$t("volumesPage.fields.orderSellerRfbCode"),
          sortable: true,
        },
        {
          key: "order.seller_legal_name",
          label: this.$t("volumesPage.fields.orderSellerLegalName"),
          sortable: true,
        },
        // overpack data
        {
          key: "order.overpack.id",
          label: this.$t("volumesPage.downloadFields.overpackID"),
        },
        {
          key: "order.overpack.sent_at",
          label: this.$t("volumesPage.downloadFields.overpackSentDate"),
        },
        {
          key: "order.overpack.reference",
          label: this.$t("volumesPage.downloadFields.overpackReference"),
        },
        {
          key: "order.overpack.first_mile_tracking_number",
          label: this.$t("volumesPage.downloadFields.fMTrackingNumber"),
        },
        {
          key: "order.overpack.first_mile_carrier_name",
          label: this.$t("volumesPage.downloadFields.firstMileCompany"),
        },
        // masterbox data
        {
          key: "masterbox.id",
          label: this.$t("volumesPage.downloadFields.masterboxID"),
        },
        {
          key: "masterbox.dispatch_id",
          label: this.$t("volumesPage.downloadFields.dispatchID"),
        },
        {
          key: "masterbox.reference",
          label: this.$t("volumesPage.downloadFields.cN35"),
        },
        // shipment data
        {
          key: "masterbox.shipment.id",
          label: this.$t("volumesPage.downloadFields.shipmentID"),
        },
        {
          key: "masterbox.shipment.category",
          label: this.$t("volumesPage.downloadFields.shipmentCategory"),
        },
        {
          key: "masterbox.shipment.cn38",
          label: this.$t("volumesPage.downloadFields.cN38"),
        },
        // flight data
        {
          key: "masterbox.shipment.flight.id",
          label: this.$t("volumesPage.downloadFields.flightID"),
        },
        {
          key: "masterbox.shipment.flight.flight_number",
          label: this.$t("volumesPage.downloadFields.flightN"),
        },
        {
          key: "masterbox.shipment.flight.mawb_number",
          label: this.$t("volumesPage.downloadFields.mAWBLabel"),
        },
        {
          key: "masterbox.shipment.flight.reference",
          label: this.$t("volumesPage.downloadFields.flightReference"),
        },
        {
          key: "masterbox.shipment.flight.airline",
          label: this.$t("volumesPage.downloadFields.airline"),
        },
        {
          key: "masterbox.shipment.flight.green_light",
          label: this.$t("volumesPage.downloadFields.greenLight"),
        },
        {
          key: "masterbox.shipment.flight.prealerts_sent",
          label: this.$t("volumesPage.downloadFields.prealertsSent"),
        },
        {
          key: "masterbox.shipment.flight.departure_airport",
          label: this.$t("volumesPage.downloadFields.departureAirport"),
        },
        {
          key: "masterbox.shipment.flight.arrival_airport",
          label: this.$t("volumesPage.downloadFields.arrivalAirport"),
        },
        {
          key: "masterbox.shipment.flight.departure_date",
          label: this.$t("volumesPage.downloadFields.departureStartDate"),
        },
      ];
    },
    financial_statuses() {
      return [
        {
          value: "new_order",
          text: this.$t("volumesPage.financialStatuses.nNew"),
        },
        {
          value: "to_invoice",
          text: this.$t("volumesPage.financialStatuses.toInvoice"),
        },
        {
          value: "invoiced",
          text: this.$t("volumesPage.financialStatuses.invoiced"),
        },
        {
          value: "canceled",
          text: this.$t("volumesPage.financialStatuses.canceled"),
        },
        {
          value: "not_charged",
          text: this.$t("volumesPage.financialStatuses.notCharged"),
        },
      ];
    },
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    optionTook(event) {
      this[event]();
    },
    hasCn38Label(cn38) {
      return cn38 && cn38?.substring(0, 9) != "TEMPORARY";
    },
    showModalCreateMasterboxes() {
      this.showCreateMasterboxModal = true;
    },
    showModalDownloadVolumes() {
      this.$helpers.openModal("select-columns-download");
    },
    showVolumeDetails(id) {
      this.setUrlSearchParam("id", id);
      this.selectedVolumeId = id;
      this.isVolumeModalOpen = true;
    },
    checkQuery() {
      const { id, nobordistCheckpointId } = this.$route.query;

      if (nobordistCheckpointId) {
        this.loadModalData(nobordistCheckpointId);
        return;
      }

      if (id) {
        this.showVolumeDetails(id);
      }
    },
    resetQuery() {
      this.$urlSearchParams.delete("nobordistCheckpointId");
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$urlSearchParams.set("nobordistCheckpointId", id);
        }
        this.isModalNobordistCheckpointsViewOpen = true;
        return;
      }
    },
    generateBoxLabel(volumeId) {
      orderLabelService.generateBoxLabel(volumeId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateVolumeFirstMile(volumeId) {
      orderLabelService.generateVolumeFirstMile(volumeId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateMasterBoxLabel(MasterboxId) {
      masterBoxService.generateMasterBoxLabel(MasterboxId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateShipmentLabel(ShipmentId) {
      shipmentService.generateShipmentLabel(ShipmentId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateFlightLabel(FlightId) {
      flightService.generateFlightLabel(FlightId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    getCurrency(currency) {
      if (currency) {
        if (currency.toUpperCase() === "USD") {
          return "$ ";
        }
        return "R$ ";
      }
      return;
    },
    downloadVolumes(columns) {
      this.isDownloading = true;

      downloadsService
        .loadProcessing({
          data_type: "volumes",
          reference_ids: this.volumesSelected,
          columns: columns,
        })
        .then(() => {
          this.$helpers.closeModal("select-columns-download");
          this.$router.push("/archives");
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    downloadMsds(orderId) {
      this.isAlertVisible = true;
      productDocumentService.downloadMsds(orderId).then((response) => {
        const file_path = response.data.data;
        this.isAlertVisible = false;
        if (file_path) window.open(response.data.data);
        else {
          this.$toasted.show("Nothing to download for this order.", {
            type: "warning",
            theme: "bubble",
            duration: 4000,
          });
        }
      });
    },
  },
};
</script>
