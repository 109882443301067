<template>
  <div>
    <div>
      <ModalHeader>
        <ModalTitle>
          {{ $t("deliveryMethodsPage.addDeliveryMethod") }}
        </ModalTitle>
      </ModalHeader>
      <div>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="name-deliverymethods"
            class="w-48-5 mb-2 mt-2"
            :name="$t('deliveryMethodsPage.name')"
            :placeholder="$t('name')"
            :error="errors.name"
            v-model="newDeliveryMethod.name"
          />
          <NbSelectInput
            id="service-deliverymethods"
            class="w-48-5 mb-2 mt-2"
            :name="$t('deliveryMethodsPage.service')"
            placeholder="Select group"
            :options="methodsServiceOptions"
            :error="errors.service"
            v-model="newDeliveryMethod.service"
          />
          <NbTextInput
            id="type-deliverymethods"
            class="w-48-5 mb-2 mt-2"
            :name="$t('deliveryMethodsPage.type')"
            :placeholder="$t('deliveryMethodsPage.type')"
            :error="errors.type"
            v-model="newDeliveryMethod.type"
          />
          <NbSelectInput
            id="carrier_id-deliverymethods"
            class="w-48-5 mb-2 mt-2"
            :name="$t('deliveryMethodsPage.carrier')"
            placeholder="Select Carrier"
            :options="carriersOptions"
            :error="errors.carrier"
            v-model="newDeliveryMethod.carrier_id"
          />
          <NbSelectInput
            id="slug_id-deliverymethods"
            class="w-100 mb-2 mt-2"
            :name="$t('deliveryMethodsPage.cooperationSlug')"
            placeholder="Select Cooperation"
            :options="cooperationsOptions"
            :error="errors.slug"
            @input="slugSelected($event)"
            v-model="newDeliveryMethod.slug_id"
          />
          <NbSelectInput
            v-if="ifNationalization"
            id="nationalization-deliverymethods"
            class="w-100 mb-2 mt-2"
            :name="$t('deliveryMethodsPage.nationalization')"
            placeholder="Select Carrier"
            :options="nationalizationOptions"
            :error="errors.additional_settings"
            v-model="newDeliveryMethod.additional_settings.nationalization"
          />
        </div>
        <div class="d-flex justify-content-between flex-wrap w-100 my-3">
          <div class="w-48-5 border border-gray-20 my-1 py-2 px-3 h-fit">
            <span class="w-100 heading-1 mb-3">
              {{ $t("deliveryMethodsPage.addDispatch") }}:
            </span>
            <div class="border-top border-gray-20 mt-2 py-1 w-100">
              <NbTextInput
                id="dkey"
                class="w-fill-available mb-2"
                :name="$t('deliveryMethodsPage.key')"
                :error="errors.headers"
                v-model="dkey"
              />
              <NbTextInput
                id="dvalue"
                class="w-fill-available mb-2 mt-2"
                :name="$t('deliveryMethodsPage.value')"
                :error="errors.headers"
                v-model="dvalue"
              />
              <NbButton
                class="mt-0"
                variant="primary"
                icon="plus"
                @click="addDCredential(dvalue, dkey)"
              >
                {{ $t("add") }}
              </NbButton>
            </div>
          </div>
          <div class="w-48-5 border border-gray-20 my-1 py-2 px-3 h-fit">
            <span class="w-100 heading-1 mb-3">
              {{ $t("deliveryMethodsPage.dispatchCredentials") }}
            </span>
            <div
              v-for="(
                value, key, index
              ) in newDeliveryMethod.dispatch_api_credentials"
              :key="index"
              class="w-100"
            >
              <div class="border-top border-gray-20 mt-2 py-1 d-flex w-100">
                <NbTextInput
                  :id="`credential-${key}-${index}`"
                  class="w-fill-available"
                  :name="key"
                  :error="checkDispatchApiCredentialErr(key)"
                  v-model="newDeliveryMethod.dispatch_api_credentials[key]"
                />
                <NbButton
                  v-if="!mandatoryCredential.includes(key)"
                  class="mt-4"
                  variant="tertiary"
                  icon="trash"
                  @click="removeDCredential(key)"
                >
                </NbButton>
                <div class="mt-4 ml-3 body-2" v-else>Mandatory Credential</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton @click="checkDeliveryMethod()">
          {{ newDeliveryMethod.id ? $t("update") : $t("save") }}
        </NbButton>
      </ModalFooter>
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import DeliveryMethodsService from "@/services/DeliveryMethodsService";
const deliveryMethodsService = new DeliveryMethodsService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbTextInput,
    NbSelectInput,
  },
  name: "ModalDeliveryMethodsCreate",
  props: {
    id: {
      type: String,
      default: "ModalDeliveryMethodsCreate",
    },
    deliveryMethod: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cooperations: [
        {
          name: null,
          id: null,
          integration_credentials: [
            {
              credential: "",
            },
          ],
        },
      ],
      mandatoryCredential: [],
      ifNationalization: false,
      dkey: "",
      dvalue: "",
      errors: {},
    };
  },
  inject: ["deliveryMethodsContext", "context"],
  created() {
    this.loadCarriers();
  },
  computed: {
    methodsServiceOptions() {
      return [
        { value: "postal", text: "postal" },
        { value: "courier", text: "courier" },
      ];
    },
    nationalizationOptions() {
      return [
        { value: "return to origin", text: "return to origin" },
        { value: "treat as abandoned", text: "treat as abandoned" },
      ];
    },
    allCarriers() {
      return this.$store.state.carriers.all_items;
    },
    carriersOptions() {
      const carrierOptions = this.allCarriers.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return carrierOptions;
    },
    cooperationsOptions() {
      return (
        this.deliveryMethodsContext.cooperations?.map((item) => ({
          value: item.id,
          text: `(${item.id}) ${item.title}`,
        })) || []
      );
    },
    newDeliveryMethod: {
      get() {
        return this.deliveryMethod;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    webhookOptions() {
      return [
        { text: "POST", value: "POST" },
        { text: "PUT", value: "PUT" },
      ];
    },
  },
  methods: {
    clear() {
      this.errors = {};
    },
    checkDispatchApiCredentialErr(key) {
      if (key) {
        if (this.errors && this.errors.dispatch_api_credentials) {
          const finalErr = this.errors.dispatch_api_credentials.find((erro) =>
            erro.includes(key),
          );
          return [finalErr];
        }

        return [];
      }

      /* 
      if(credential.length < 1) {
        if(this.errors && this.errors.dispatch_api_credentials) {
          return this.errors.dispatch_api_credentials
        }
      } */
      return [];
    },
    checkDeliveryMethod() {
      if (this.newDeliveryMethod.id) {
        this.updateDeliveryMethod();
        return;
      }
      this.addDeliveryMethod();
    },
    addDeliveryMethod() {
      deliveryMethodsService
        .setDeliveryMethod(this.newDeliveryMethod)
        .then(() => {
          this.$emit("reloadDeliveryMethods");
          this.context?.close();
        })
        .catch((error) => {
          this.errors = error.response.data.messages[0];
        });
    },
    updateDeliveryMethod() {
      deliveryMethodsService
        .updateDeliveryMethod(this.newDeliveryMethod.id, this.newDeliveryMethod)
        .then(() => {
          this.$emit("reloadDeliveryMethods");
          this.context?.close();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    removeDCredential(key) {
      delete this.newDeliveryMethod.dispatch_api_credentials[key];
      this.newDeliveryMethod.dispatch_api_credentials;
      const updatedCredentials = {
        ...this.newDeliveryMethod.dispatch_api_credentials,
      };
      delete updatedCredentials[key];

      this.newDeliveryMethod.dispatch_api_credentials = updatedCredentials;
    },
    addDCredential(value, key) {
      this.newDeliveryMethod.dispatch_api_credentials[key] = value;
      this.dkey = "";
      this.dvalue = "";
    },
    slugSelected(slug_id) {
      if (typeof slug_id !== "number") {
        return;
      }

      const selectedSlug = this.deliveryMethodsContext.cooperations.find(
        (c) => c.id === slug_id,
      );
      this.setNationalization(selectedSlug.title);

      this.mandatoryCredential = selectedSlug.integration_credentials.map(
        (c) => c.credential,
      );
      this.setcredentialsDefault(this.mandatoryCredential);
    },
    setNationalization(selectedSlug) {
      this.ifNationalization = ["correios", "skypostal"].includes(selectedSlug);
      if (this.ifNationalization) {
        return;
      }
      this.newDeliveryMethod.additional_settings.nationalization = null;
    },
    setcredentialsDefault(credentials) {
      if (this.newDeliveryMethod.id) {
        this.mandatoryCredential.forEach((c) => {
          !Object.keys(
            this.newDeliveryMethod.dispatch_api_credentials,
          ).includes(c) && this.addDCredential("", c);
        });
        return;
      }

      this.newDeliveryMethod.dispatch_api_credentials = {};
      credentials.forEach((c) => {
        this.addDCredential("", c);
      });
    },
    loadCarriers() {
      this.$store.dispatch("carriers/fetchAllItems", "carriers");
    },
  },
};
</script>

<style scoped></style>
