<template>
  <div>
    <DatatableRoot
      ref="table"
      :namespace="namespace"
      clickable
      :columns="tableColumns"
      selectable
      :filters="tableFilters"
      :selected.sync="rowSelection"
      @count="$emit('total', $event)"
      @rowClick="checkPaymentLoadModalData($event)"
      :actions="tableActions"
      @updateStatusModal="updateStatusModal"
      @downloadModal="downloadModal"
    >
      <template #seller_name="{ row }">
        <router-link
          :to="`/users_management/sellers?id=${row.seller_id}`"
          class="link-1"
        >
          {{ row.seller_name }}
        </router-link>
      </template>
      <template #attachment="{ row }">
        <template v-if="row.attachment">
          <a href="" @click="openDocument(row.attachment)">
            <i class="far fa-file-alt fa-lg text-dark"></i>
          </a>
        </template>
        <template v-else>
          <div>
            <i
              class="fa fa-clock-o text-danger"
              aria-hidden="true"
              style="
                font-size: 8px;
                height: 25px;
                position: relative;
                left: 7px;
              "
            ></i>
            <i
              class="far fa-file fa-lg text-dark"
              style="
                font-size: 18px;
                position: relative;
                left: -4px;
                bottom: 0px;
              "
            ></i>
          </div>
        </template>
      </template>

      <template #comment="{ row }">
        <template v-if="row.comment">
          {{ row.comment }}
        </template>
        <template v-else>
          {{ "N/A" }}
        </template>
      </template>

      <template #duties_status="{ row }">
        <template v-if="!!row.duties_status">
          <div>
            {{ row.duties_status }}
          </div>
        </template>
      </template>

      <template #real_tax="{ row }">
        <template v-if="!!row.real_tax">
          <div>
            {{ row.real_tax }}
          </div>
        </template>
      </template>

      <template #barcode="{ row }">
        <template v-if="!!row.barcode && isFinance">
          <div>
            {{ row.barcode }}
          </div>
        </template>
        <template v-else>
          {{ "N/A" }}
        </template>
      </template>

      <template #payment_certificate="{ row }">
        <template v-if="!!row.barcode">
          <a @click="getPaymentPdf(row.id)">
            <i
              class="far fa-file fa-lg text-dark"
              style="
                font-size: 18px;
                position: relative;
                left: -4px;
                bottom: 0px;
              "
            ></i>
          </a>
        </template>
        <template v-else> - </template>
      </template>

      <template #tracking_code="{ row }">
        {{ row.checkpoint.tracking_code }}
      </template>

      <template #title="{ row }">
        <template v-if="row">
          <router-link
            v-if="row.checkpoint_id"
            :to="`/checkpoints/checkpoints?nobordistCheckpointId=${row.checkpoint_id}`"
            class="link-1"
          >
            {{ row.checkpoint?.title }}
          </router-link>
          <span v-else>-</span>
        </template>
      </template>

      <template #tracking_rule_id="{ row }">
        <router-link
          v-if="row.tracking_rule_id"
          class="link-1"
          :to="'/logs/tracking_rules?trackingRulesId=' + row.tracking_rule_id"
        >
          {{ getTrackingRuleName(row.tracking_rule_id) }}
        </router-link>
        <span v-else>-</span>
      </template>

      <template #status="{ row }">
        <AlertLogStatusBadge :data="row.status" />
      </template>

      <template #last_mile_tracking_number="{ row }">
        <router-link
          class="link-1"
          :to="`/shipping/volumes?id=${row.volume_id}`"
        >
          {{ row.last_mile_tracking_number }}
        </router-link>
      </template>

      <template #type="{ row }">
        <AlertLogTypeBadge :data="row.type" />
      </template>

      <template #date_iso="{ row }">
        {{ row.date_iso | datetime }}
      </template>

      <template #created_at="{ row }">
        {{ row.created_at | datetime }}
      </template>
    </DatatableRoot>
    <!-- modals -->
    <Modal v-model="isUploadPdfModalOpen">
      <ModalHeader>
        <div>
          <ModalTitle>{{ $t("upload") }}</ModalTitle>
          <p class="body-4">
            {{ $t("alertLogsPage.importPdfPaymentCertificatesHelp") }}
          </p>
        </div>
      </ModalHeader>
      <div>
        <NbDropFile
          v-model="chosenFile"
          :accept="['application/pdf']"
          :label="$t('alertLogsPage.browseFilesUpload')"
        />
      </div>
      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose>
          {{ $t("close") }}
        </ModalClose>
        <NbButton @click="sendFile()" :disabled="isLoading">
          {{ $t("upload") }}
        </NbButton>
      </ModalFooter>
    </Modal>

    <Modal v-model="isImportValueStatus">
      <ModalHeader>
        <ModalTitle>
          {{ $t("alertLogsPage.importValuesandStatuses") }}
        </ModalTitle>
      </ModalHeader>
      <div>
        <Accordion open>
          <AccordionItem>
            <AccordionTrigger>{{ $t("help") }}</AccordionTrigger>
            <AccordionContent>
              {{ $t("priceTablesPage.dropDownHelp") }}
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <NbTextInput
          required
          id="name"
          :name="$t('alertLogsPage.name')"
          class="my-4"
          v-model="newItem.name"
        />

        <NbDropFile
          ref="dropFile"
          v-model="chosenFile"
          :label="$t('alertLogsPage.browseFilesUpload')"
          :accept="['text/csv']"
        >
          <div class="py-4">
            <div class="text-center">{{ $t("dragDropFile") }}</div>
            <div class="text-center mb-3">
              {{ $t("alertLogsPage.selectcsvFileUpload") }}
            </div>

            <NbButton variant="secondary d-block mx-auto">
              {{ $t("upload") }}
            </NbButton>

            <NbButton
              variant="secondary my-2 d-block mx-auto"
              @click.stop="downloadCsvExample"
            >
              {{ $t("alertLogsPage.downloadHere") }}
              {{ $t("alertLogsPage.blankTable") }}
            </NbButton>

            <div class="text-center">
              {{ $t("alertLogsPage.downloadHere") }}
              <a
                href="javascript:void;"
                @click.stop="downloadInstructionsFile"
                class="text-center link-1"
              >
                {{ $t("alertLogsPage.instructionsXLSfile") }}
              </a>
            </div>
          </div>
        </NbDropFile>
        <p>{{ $t("alertLogsPage.updateStatusInfo") }}</p>
      </div>
      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton
          :disabled="newItem.name.length > 0 ? false : true"
          @click.prevent="importFile()"
        >
          {{ $t("save") }}
        </NbButton>
      </ModalFooter>
    </Modal>

    <Modal
      v-model="isUpdateStatusModalOpen"
      id="updateStatusModal"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <ModalHeader>
        <ModalTitle>{{ $t("update") }}</ModalTitle>
      </ModalHeader>
      <div>
        <p>{{ $t("alertLogsPage.updateAllSelectedLogs") }}</p>
        <NbSelectInput
          required
          id="select-status"
          class="mb-3"
          :name="$t('alertLogsPage.status')"
          :error="errors.name"
          :options="statusOptions"
          v-model="newLog.status"
        />

        <NbTextInput
          id="comment-status"
          :name="$t('alertLogsPage.comment')"
          class="mb-3"
          v-model="newLog.comment"
        />
      </div>
      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton :disabled="!newLog.status" @click="handleUpdate">
          {{ $t("update") }}
        </NbButton>
      </ModalFooter>
    </Modal>

    <Modal v-model="isConfirmSaveModalOpen">
      <ModalHeader>
        <ModalTitle>
          {{ $t("confirm") }}
        </ModalTitle>
      </ModalHeader>
      <div>
        <p v-if="newLog.status != 'sent_again'">
          {{ $t("alertLogsPage.sureWantSave") }}?
        </p>
        <div v-else>
          <p>
            {{ $t("alertLogsPage.shouldbeCommaSeparated") }}{{ rowSelection }}
          </p>
          <div class="form-group">
            <NbTextInput
              required
              id="new_tracking_number"
              :name="$t('alertLogsPage.newTrackingNumbers')"
              class="mb-4"
              v-model="newLog.new_tracking_number"
            />
          </div>
        </div>
      </div>
      <ModalFooter>
        <NbButton
          v-if="newLog.status != 'sent_again'"
          class="float-right mr-3"
          :disabled="loadingUpdateMassive"
          @click="updateMassiveStatus()"
        >
          {{ $t("save") }}
        </NbButton>
        <NbButton
          v-else
          :disabled="!newLog.new_tracking_number || loadingUpdateMassive"
          class="float-right mr-3"
          @click="updateMassiveStatus()"
        >
          {{ $t("save") }}
        </NbButton>
        <ModalClose />
      </ModalFooter>
    </Modal>

    <Modal v-model="isDownloadModalOpen">
      <ModalHeader>
        <ModalTitle>
          {{ $t("download") }}
        </ModalTitle>
      </ModalHeader>
      <div>
        <p>{{ $t("alertLogsPage.checktheprocessingdownloads") }}</p>
      </div>
      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton @click="downloadLogs">
          {{ $t("start") }}
        </NbButton>
      </ModalFooter>
    </Modal>

    <Modal
      v-model="isModalAlertLogsViewOpen"
      size="lg"
      @close="removeQueryParams"
    >
      <ModalAlertLogsView :itemId="currentItemId" />
    </Modal>
    <!-- end-modals -->
  </div>
</template>

<script>
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import ModalAlertLogsView from "@/views/logs/alert_logs/components/ModalAlertLogsView.vue";
import AlertLogsService from "../../../services/AlertLogsService";
import DownloadsService from "@/services/DownloadsService";
import TrackingRuleService from "../../../services/TrackingRuleService";
import CheckpointService from "../../../services/CheckpointService";
import store from "@/store/index";
import SellerService from "../../../services/SellerService";
import AlertLogTypeBadge from "./components/AlertLogTypeBadge.vue";
import AlertLogStatusBadge from "./components/AlertLogStatusBadge.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import AccordionTrigger from "@/components/accordion/AccordionTrigger.vue";
import AccordionContent from "@/components/accordion/AccordionContent.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";

import alertLogMixin from "./mixins/alert-log-mixin";
const alertLogsService = new AlertLogsService();
const trackingRuleService = new TrackingRuleService();
const downloadsService = new DownloadsService();
const checkpointService = new CheckpointService();
const isFinance = store.state.user.group_name === "Platform-Finance";
const sellerService = new SellerService();

const ALERT_LOG_TYPE = {
  normal_type: 0,
  payment_for_the_seller: 1,
  received_checkpoint: 2,
  time_between_checkpoints: 3,
  duties_payment: 4,
};

export default {
  name: "AlertLogs",
  components: {
    DatatableRoot,
    NbButton,
    NbDropFile,
    NbTextInput,
    NbSelectInput,
    ModalAlertLogsView,
    AlertLogStatusBadge,
    AlertLogTypeBadge,
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  mixins: [alertLogMixin],
  data: () => {
    return {
      isLoading: false,
      loadingUpdateMassive: false,
      rowSelection: [],
      errors: {},
      newItem: {
        name: "",
      },
      logs: [],
      trackingRules: [],
      isFinance: isFinance,
      newLog: {
        new_tracking_number: null,
        status: null,
        comment: null,
      },
      file: null,
      chosenFile: "",
      namespace: "tracking_alert_logs",
      currentItemId: "",
      sellers: [],
      ruleOptions: [],
      checkpointsOption: [],
      isModalAlertLogsViewOpen: false,
      isDownloadModalOpen: false,
      isUpdateStatusModalOpen: false,
      isConfirmSaveModalOpen: false,
      isUploadPdfModalOpen: false,
      isImportValueStatus: false,
    };
  },
  provide() {
    const vm = this;
    const alertLogContext = {
      alertLogTypes: Object.keys(ALERT_LOG_TYPE),
      getTrackingRuleName: (id) => vm.getTrackingRuleName(id),
    };

    return {
      alertLogContext,
    };
  },
  computed: {
    statusOptions() {
      let finalOptions = [
        {
          text: this.$t("alertLogsPage.new"),
          value: "new_issue",
        },
        {
          text: this.$t("alertLogsPage.solved"),
          value: "solved_issue",
        },
        {
          text: this.$t("alertLogsPage.priority"),
          value: "priority_issue",
        },
        {
          text: this.$t("alertLogsPage.inProgress"),
          value: "in_progress_issue",
        },
        {
          text: this.$t("alertLogsPage.waitingforPayment"),
          value: "waiting_payment",
        },
        /* {
          text: this.$t("alertLogsPage.sentAgain"),
          value: "sent_again",
        }, */
        {
          text: this.$t("alertLogsPage.paymentDone"),
          value: "payment_done",
        },
        {
          text: this.$t("alertLogsPage.pending"),
          value: "pending",
        },
        {
          text: this.$t("alertLogsPage.paidExternally"),
          value: "paid_external",
        },
        {
          text: this.$t("alertLogsPage.needSellerAction"),
          value: "need_seller",
        },
      ];

      if (this.trackingCodeReturnMassive(this.rowSelection)) {
        const sentAgain = {
          text: this.$t("alertLogsPage.sentAgain"),
          value: "sent_again",
        };
        finalOptions.splice(5, 0, sentAgain);
      }

      return finalOptions;
    },
    tableActions() {
      return [
        {
          label: this.$t("alertLogsPage.updateStatus"),
          action: "updateStatusModal",
        },
        {
          label: this.$t("alertLogsPage.downloadLogs"),
          action: "downloadModal",
        },
      ];
    },
    items() {
      return this.$store.state[this.namespace].items;
    },
    tableColumns() {
      return [
        {
          key: "tracking_code",
          label: this.$t("alertLogsPage.fields.trackingCode"),
        },
        {
          key: "seller_name",
          label: this.$t("alertLogsPage.fields.seller"),
        },
        {
          key: "message",
          label: this.$t("alertLogsPage.fields.message"),
        },
        {
          key: "attachment",
          label: this.$t("alertLogsPage.fields.attachment"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("alertLogsPage.fields.volume"),
        },
        {
          key: "date_iso",
          label: this.$t("alertLogsPage.fields.dateISO"),
        },
        {
          key: "created_at",
          label: this.$t("alertLogsPage.fields.creationTime"),
          sortable: true,
        },
        {
          key: "title",
          label: this.$t("alertLogsPage.fields.title"),
        },
        {
          key: "tracking_rule_id",
          label: this.$t("alertLogsPage.fields.rule"),
        },
        {
          key: "type",
          label: this.$t("alertLogsPage.fields.type"),
        },
        {
          key: "status",
          label: this.$t("alertLogsPage.fields.status"),
        },
        {
          key: "comment",
          label: this.$t("alertLogsPage.fields.comment"),
        },
        {
          key: "real_tax",
          label: "Real Tax",
        },
        {
          key: "barcode",
          label: "Barcode",
        },
        {
          key: "duties_status",
          label: "Duties Status",
        },
        {
          key: "payment_certificate",
          label: "Payment Certificate",
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("alertLogsPage.fields.trackingCode"),
          inputs: [
            {
              key: "tracking_code",
              type: "select",
              options: this.checkpointsOption.map((item) => ({
                text: `${item.trackingCode} - ${item.title}`,
                value: item.trackingCode,
              })),
            },
          ],
        },

        {
          label: this.$t("alertLogsPage.fields.volume"),
          inputs: [
            {
              key: "last_mile_tracking_number",
            },
          ],
        },

        {
          label: this.$t("alertLogsPage.fields.rule"),
          inputs: [
            {
              key: "rule",
              type: "select",
              options: this.ruleOptions,
            },
          ],
        },
        {
          label: this.$t("alertLogsPage.fields.type"),
          inputs: [
            {
              key: "type",
              type: "select",
              options: [
                { value: null, text: "Select" },
                { value: ALERT_LOG_TYPE.normal_type, text: "Normal Type" },
                {
                  value: ALERT_LOG_TYPE.payment_for_the_seller,
                  text: "Payment for the Seller",
                },
                {
                  value: ALERT_LOG_TYPE.received_checkpoint,
                  text: "Received Checkpoint",
                },
                {
                  value: ALERT_LOG_TYPE.time_between_checkpoints,
                  text: "Time Between Checkpoints",
                },
                {
                  value: ALERT_LOG_TYPE.duties_payment,
                  text: "Duties Payment",
                },
              ],
            },
          ],
        },
        {
          label: this.$t("alertLogsPage.fields.title"),
          inputs: [
            {
              key: "title",
              type: "select",
              options: this.checkpointsOption.map((item) => ({
                value: item.title,
                text: item.title,
              })),
            },
          ],
        },
        {
          label: this.$t("alertLogsPage.fields.status"),
          inputs: [
            {
              key: "status",
              type: "select",
              options: [
                { value: null, text: "Select" },
                { value: 0, text: "New" },
                { value: 1, text: "Solved" },
                { value: 2, text: "Priority" },
                { value: 3, text: "In Progress" },
                { value: 4, text: "Waiting for Payment" },
                { value: 5, text: "Sent Again" },
                { value: 6, text: "Payment Done" },
                { value: 7, text: "Pending" },
                { value: 8, text: "Paid Externally" },
                { value: 9, text: "Need Seller Action" },
              ],
            },
          ],
        },
        {
          label: this.$t("alertLogsPage.fields.seller"),
          inputs: [
            {
              key: "seller_id",
              type: "select",
              options: this.sellers.length
                ? this.sellers.map((seller) => ({
                    value: seller.id,
                    text: `${seller.id} - ${seller.name}`,
                  }))
                : [{ text: "Loading...", value: null, disabled: true }],
            },
          ],
        },
        {
          label: this.$t("alertLogsViewPage.realTax"),
          inputs: [
            {
              key: "start_real_tax",
              type: "number",
            },
            {
              key: "end_real_tax",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("alertLogsPage.barcode"),
          inputs: [
            {
              key: "barcode",
            },
          ],
        },
        {
          label: this.$t("alertLogsPage.fields.dateISO"),
          inputs: [
            {
              key: "start_date_iso",
              type: "date",
            },
            {
              key: "end_date_iso",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  methods: {
    handleUpdate() {
      this.isConfirmSaveModalOpen = true;
    },
    removeQueryParams() {
      this.$urlSearchParams.delete("alertLogsId");
    },
    async loadSellers() {
      const { data } = await sellerService.getSellersList();

      this.sellers = data?.data?.map((seller) => ({
        id: seller.id,
        name: seller.name,
      }));
    },
    checkQuery() {
      if (this.$route.query.alertLogsId) {
        this.loadModalData(this.$route.query.alertLogsId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;
        this.$urlSearchParams.set("alertLogsId", id);
        this.isModalAlertLogsViewOpen = true;
        return;
      }
    },
    checkPaymentLoadModalData(event) {
      if (event.type == "duties_payment") {
        this.loadModalData(event.id);
        return;
      }
    },

    downloadCsvExample() {
      window.open("/files/import_values_and_statuses.csv", "_blank");
    },
    downloadInstructionsFile() {
      window.open(
        "/files/instructions_import_values_and_statuses.xls",
        "_blank",
      );
    },
    downloadModal() {
      this.isDownloadModalOpen = true;
    },
    updateStatusModal() {
      this.newLog.status = null;
      this.newLog.comment = "";
      this.isUpdateStatusModalOpen = true;
    },
    uploadPdfModal() {
      this.isUploadPdfModalOpen = true;
    },
    importValueStatus() {
      this.errors = {};
      this.newItem.name = "";
      this.chosenFile = "";
      this.file = null;
      this.isImportValueStatus = true;
    },
    optionTook(event) {
      this[event]();
    },
    loadItems() {
      this.$refs?.table?.getData();
    },
    sendFile() {
      this.file = new FormData();
      this.file.append("file", this.chosenFile, this.chosenFile.name);
      this.file.append("name", this.newItem.name);

      if (this.file) {
        this.isLoading = true;
        alertLogsService
          .sendPaymentPdf(this.file)
          .then(() => {
            this.uploadPdfModal();
          })
          .finally(() => (this.isLoading = false));
        this.loadItems();
      }
    },
    initiateFileCreation(dataQuery) {
      this.isDownloadModalOpen = false;
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    downloadLogs() {
      this.initiateFileCreation({
        data_type: "logs",
        reference_ids: this.rowSelection,
      });
    },
    importFile() {
      this.file = new FormData();
      this.file.append("file", this.chosenFile, this.chosenFile.name);
      this.file.append("name", this.newItem.name);
      this.file.append("data_type", "import_value_status");
      downloadsService.loadProcessing(this.file);
      setTimeout(() => {
        this.isImportValueStatus = false;
        this.$router.push("/archives");
      }, 1500);
    },
    loadTrackingRules() {
      trackingRuleService.getTrackingRulesList().then((response) => {
        this.trackingRules = response.data.data;
        this.rulesOptions = this.trackingRules.map((rule) => ({
          value: rule.id,
          text: rule.name ? rule.name : rule.id,
        }));
      });
    },
    getTrackingRuleName(id) {
      let trackingName = this.trackingRules.filter(
        (trackingRule) => trackingRule.id == id,
      );
      if (trackingName.length > 0) {
        return trackingName[0].name;
      }
      return "-";
    },
    updateMassiveStatus() {
      let dataToSend = {};
      dataToSend.ids = this.rowSelection;
      dataToSend.tracking_alert_log = this.newLog;
      this.loadingUpdateMassive = true;
      alertLogsService
        .updateMassiveLog(dataToSend)
        .then(() => {
          this.isConfirmSaveModalOpen = false;
          this.isUpdateStatusModalOpen = false;
          this.newLog.status = null;
          this.newLog.comment = null;
          this.newLog.new_tracking_number = null;
          this.loadItems();
        })
        .catch(() => {
          this.$bvToast.toast(
            "Something gone wrong... check the form and try again.",
            {
              title: "Error",
              solid: true,
              variant: "danger",
              autoHideDelay: 2000,
            },
          );
        })
        .finally(() => {
          this.loadingUpdateMassive = false;
        });
    },
    trackingCodeReturnMassive(ids) {
      let count = 0;
      const codes = [1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007];

      ids.forEach((id) => {
        if (
          codes.includes(
            this.items.find((item) => item.id === id)?.checkpoint
              ?.tracking_code,
          )
        ) {
          count++;
        }
      });

      if (ids.length === count) {
        return true;
      }
      return false;
    },
    loadCheckpoints() {
      checkpointService.getAllCheckpoints().then((response) => {
        this.checkpoints = response.data.data.elements;
        this.checkpointsOption = this.checkpoints.map((checkpoint) => ({
          id: checkpoint.id,
          title: checkpoint.title,
          trackingCode: checkpoint.tracking_code,
        }));
      });
    },
  },
  mounted() {
    this.checkQuery();
    this.loadTrackingRules();
    this.loadCheckpoints();
    this.loadSellers();
  },
};
</script>
