<template>
  <div>
    <div>
      <ModalHeader>
        <div class="d-flex flex-column mb-4 gap-4">
          <div>
            <ModalTitle>
              {{
                $t("generalEmailsPage.emailTo", {
                  email: "",
                })
              }}
            </ModalTitle>
            <small>{{ generalEmail.destinationFormated }}</small>
          </div>
          <NbBadge
            v-if="generalEmail.id"
            :text="generalEmail?.sent ? 'sent' : 'not sent'"
            :type="generalEmail?.sent ? 'success' : 'danger'"
            class="d-flex"
          />
        </div>
      </ModalHeader>
      <div>
        <NbCard
          id="generalemail_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="grid gap-4">
              <NbTextInput
                id="general_email_title"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('gGeneralEmailsViewPage.titleGeneral')"
                v-model="generalEmail.title"
                disabled
              />
              <NbTextInput
                id="general_email_body"
                variant="borderless-gray-10"
                :textarea="true"
                :placeholder="$t('notFound')"
                :name="$t('gGeneralEmailsViewPage.body')"
                v-model="generalEmail.body"
                disabled
              />
            </div>
          </template>
        </NbCard>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import GeneralEmailService from "@/services/GeneralEmailService";

const generalEmailService = new GeneralEmailService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTextInput,
    NbCard,
    NbFooter,
    NbBadge,
  },
  name: "ModalGeneralEmailsView",
  props: {
    id: {
      type: String,
      default: "ModalGeneralEmailsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      generalEmail: {},
      newGeneralEmail: {},
    };
  },
  computed: {},
  methods: {
    copyGeneralEmail() {
      this.newGeneralEmail = Object.assign({}, this.generalEmail);
      this.newGeneralEmail.destinationType = "destination";
    },
    getData() {
      generalEmailService.getEmail(this.itemId).then((response) => {
        this.generalEmail = response.data.data;
        this.generalEmail.destinationFormated =
          this.generalEmail.destination.replace(/,/g, ", ");
        this.generalEmail.created = this.formateDate(
          this.generalEmail.created_at,
        );
      });
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        data.getFullYear() +
        "-" +
        this.addZero(data.getMonth() + 1).toString() +
        "-" +
        this.addZero(data.getDate().toString());
      return formatedDate;
    },
    deleteGeneralEmail() {
      generalEmailService.deleteGeneralEmail(this.itemId).then(() => {
        this.$helpers.closeAllModals();
        this.$router.push({ query: {} });
        this.$emit("reloadGeneralEmail");
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped></style>
