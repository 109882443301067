import { CHECKPOINT_GROUP, NotificationType } from "../constants/constants";

export default {
  namespaced: true,
  state: () => ({
    ruleName: "",
    applyRuleTo: "",
    sellerCreation: "",
    contractCreation: "",
    sellerIds: [],
    contractIds: [],
    isAllSellersSelected: false,
    isAllContractsSelected: false,
    notifications: [],
    checkpoints: [],
  }),
  mutations: {
    UPDATE_RULE_NAME(state, payload) {
      state.ruleName = payload;
    },
    UPDATE_APPLY_RULE_TO(state, payload) {
      state.applyRuleTo = payload;
    },
    UPDATE_SELLER_IDS(state, payload) {
      state.sellerIds = payload;
    },
    UPDATE_CONTRACT_IDS(state, payload) {
      state.contractIds = payload;
    },
    UPDATE_SELLER_CREATION(state, payload) {
      state.sellerCreation = payload;
    },
    UPDATE_CONTRACT_CREATION(state, payload) {
      state.contractCreation = payload;
    },
    UPDATE_IS_ALL_SELLERS_SELECTED(state, payload) {
      state.isAllSellersSelected = payload;
    },
    UPDATE_IS_ALL_CONTRACTS_SELECTED(state, payload) {
      state.isAllContractsSelected = payload;
    },
    UPDATE_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
    RESET_STATE(state) {
      state.ruleName = "";
      state.applyRuleTo = "";
      state.sellerCreation = "";
      state.contractCreation = "";
      state.sellerIds = [];
      state.contractIds = [];
      state.isAllSellersSelected = false;
      state.isAllContractsSelected = false;
      state.notifications = [];
    },
    UPDATE_RULE(state, payload) {
      state.ruleName = payload.ruleName;
      state.applyRuleTo = payload.applyRuleTo;
      state.sellerCreation = payload.sellerCreation;
      state.contractCreation = payload.contractCreation;
      state.sellerIds = payload.sellerIds;
      state.contractIds = payload.contractIds;
      state.isAllSellersSelected = payload.isAllSellersSelected;
      state.isAllContractsSelected = payload.isAllContractsSelected;
    },
    UPDATE_CHECKPOINTS(state, payload) {
      state.checkpoints = payload;
    },
  },
  actions: {
    applyRuleToAllShipments({ commit }) {
      commit("UPDATE_SELLER_CREATION", "auto_add_seller");
      commit("UPDATE_CONTRACT_CREATION", "auto_add_contract");
      commit("UPDATE_SELLER_IDS", []);
      commit("UPDATE_CONTRACT_IDS", []);
      commit("UPDATE_IS_ALL_SELLERS_SELECTED", true);
      commit("UPDATE_IS_ALL_CONTRACTS_SELECTED", true);
    },
    applyRuleToSomeShipments({ commit }) {
      commit("UPDATE_SELLER_CREATION", "");
      commit("UPDATE_CONTRACT_CREATION", "");
    },
    reset({ commit }) {
      commit("RESET_STATE");
    },
    setNotificationElements({ state, commit }, payload = []) {
      const notificationType = [
        NotificationType.SMS_CUSTOMER,
        NotificationType.WHATSAPP_CUSTOMER,
        NotificationType.EMAIL_CUSTOMER,
        NotificationType.EMAIL_SELLER,
        NotificationType.WEBHOOK_SELLER,
        NotificationType.WEBHOOKE_ADMIN,
      ];

      const notifications = notificationType.reduce((acc, current) => {
        acc[current] = false;
        return acc;
      }, {});

      const checkpoints = state.checkpoints.map((item) => ({
        id: item.id,
        trackingCode: item.tracking_code,
        title: item.title,
        zoneCode: item.zone_code,
        notifications: JSON.parse(JSON.stringify(notifications)),
      }));

      payload.forEach((item) => {
        const checkpointIndex = checkpoints.findIndex(
          (checkpoint) => checkpoint.id === item.checkpoint_id,
        );

        if (checkpointIndex !== -1) {
          checkpoints[checkpointIndex].notifications[item.notification_type] =
            true;
        }
      });

      const result = Object.values(CHECKPOINT_GROUP).map((item) => ({
        ...item,
        checkpoints: checkpoints.filter(
          (checkpoint) => checkpoint.zoneCode === item.zoneCode,
        ),
      }));

      commit("UPDATE_NOTIFICATIONS", result);
    },
    setRule({ commit, dispatch }, payload) {
      const state = {
        ruleName: "",
        applyRuleTo: "",
        sellerCreation: "",
        contractCreation: "",
        isAllContractsSelected: false,
        isAllSellersSelected: false,
        sellerIds: [],
        contractIds: [],
      };

      const isAllSellersSelected = payload.seller_ids[0] === "all";
      const isAllContractsSelected = payload.contract_ids[0] === "all";
      const isAllShipment = isAllSellersSelected && isAllContractsSelected;

      state.ruleName = payload.name;

      if (isAllSellersSelected) {
        state.isAllSellersSelected = true;
        state.sellerCreation = "auto_add_seller";
      } else {
        state.sellerIds = payload.seller_ids || [];
        state.sellerCreation = "manual_add_seller";
      }

      if (isAllContractsSelected) {
        state.isAllContractsSelected = true;
        state.contractCreation = "auto_add_contract";
      } else {
        state.contractIds = payload.contract_ids || [];
        state.sellerCreation = "manual_add_contract";
      }

      let _applyRuleTo = "some_shipments";
      if (isAllShipment) {
        _applyRuleTo = "all_shipments";
        state.sellerCreation = "auto_add_seller";
        state.contractCreation = "auto_add_contract";
      }

      state.applyRuleTo = _applyRuleTo;
      state.notifications = [];

      commit("UPDATE_RULE", state);
      dispatch("setNotificationElements", payload.elements || []);
    },
    matchContractIds({ state, commit }, newContractIdsRequested) {
      const selectedContractsFIltered = state.contractIds.filter((contractId) =>
        newContractIdsRequested.includes(Number(contractId)),
      );

      commit("UPDATE_CONTRACT_IDS", selectedContractsFIltered);
    },
  },
  getters: {
    ruleToAllSellers(state) {
      return (
        state.isAllSellersSelected && state.sellerCreation === "auto_add_seller"
      );
    },
    ruleToAllContracts(state) {
      return (
        state.isAllContractsSelected &&
        state.contractCreation === "auto_add_contract"
      );
    },
  },
};
