<template>
  <div>
    <NbPageTitle :title="$t('fiscalPaymentsPage.title')" />

    <Modal
      v-model="showFiscalPayment"
      size="lg"
      @close="setUrlSearchParam('id')"
    >
      <FiscalPayment />
    </Modal>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <DatatableRoot
        namespace="fiscal_payments"
        clickable
        selectable
        :columns="tableColumns"
        :filters="tableFilters"
        @count="totalFiscalPayments = $event"
        @rowClick="showFiscalPaymentDetails($event.id)"
        :selected.sync="selectedFiscalPaymentIds"
      >
        <template #actions>
          <DatatableActions :disabled="!selectedFiscalPaymentIds.length">
            <DatatableAction @click="download">
              {{ $t("download") }}
            </DatatableAction>
            <DatatableAction @click="downloadBoletosGNRE">
              {{ $t("fiscalPaymentsPage.downloadBoletoGNRE") }}
            </DatatableAction>
          </DatatableActions>
        </template>

        <template #reference_number="{ row }">
          <NbButton
            variant="tertiary"
            @click="showFiscalPaymentDetails(row.id)"
          >
            {{ row.reference_number }}
          </NbButton>
        </template>
        <template #boleto_gnre="{ row }">
          <DownloadGNRE :fiscal-payment-id="row.id" />
        </template>
        <template #status="{ row }">
          <FiscalPaymentStatus :status="row.status" />
        </template>
        <template #payment_type="{ row }">
          <FiscalPaymentTypeBadge :payment="row.payment_type" />
        </template>

        <template #value="{ row }">
          {{ row.value | currencyBRL }}
        </template>

        <template #payment_date="{ row }">
          {{ row.payment_date | datetime }}
        </template>
        <template #created_at="{ row }">
          {{ row.created_at | datetime }}
        </template>

        <template #dir_number="{ row }">
          {{ row?.fiscal_parcel?.dir_number || "-" }}
        </template>
        <template #fiscal_parcel.master_number="{ row }">
          {{ row?.fiscal_parcel?.master_number || "-" }}
        </template>
        <template #customer_name="{ row }">
          {{ row?.fiscal_parcel?.customer_name || "-" }}
        </template>
        <template #customer_cpf="{ row }">
          {{ row?.fiscal_parcel?.customer_cpf || "-" }}
        </template>
        <template #customer_cep="{ row }">
          {{ row?.fiscal_parcel?.customer_cep || "-" }}
        </template>
        <template #tracking_number="{ row }">
          {{ row?.fiscal_parcel?.tracking_number || "-" }}
        </template>
        <template #products_description="{ row }">
          <NbButton
            :id="`popover-target-${row.id}`"
            class="truncate link-1"
            variant="tertiary"
            style="width: 200px"
          >
            {{ row?.fiscal_parcel?.products_description || "-" }}
          </NbButton>
          <b-popover
            :target="`popover-target-${row.id}`"
            triggers="hover"
            placement="top"
          >
            <template #title>
              {{ $t("fiscalPaymentsPage.fields.products_description") }}
            </template>
            <div class="grid gap-4">
              <span
                v-for="(
                  item, index
                ) in row?.fiscal_parcel?.products_description?.split(',')"
                :key="index"
              >
                {{ item }}
              </span>
            </div>
          </b-popover>
        </template>

        <template #messages="{ row }">
          <NbButton
            v-show="row.messages"
            :id="`messages-${row.id}`"
            class="truncate link-1"
            variant="tertiary"
            style="width: 200px"
          >
            {{ row.messages }}
          </NbButton>
          <span v-if="!row.messages">-</span>
          <b-popover
            :target="`messages-${row.id}`"
            triggers="hover"
            placement="top"
          >
            <template #title>
              {{ $t("fiscalPaymentsPage.fields.messages") }}
            </template>
            <div class="grid gap-4">
              <span
                v-for="(item, index) in row.messages?.split(',')"
                :key="index"
              >
                {{ item }}
              </span>
            </div>
          </b-popover>
        </template>
        <template #customer_state="{ row }">
          {{ row.fiscal_parcel?.customer_state }}
        </template>

        <template #dir_date="{ row }">
          {{ row.fiscal_parcel?.dir_date | formatDate }}
        </template>
      </DatatableRoot>
    </section>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import DatatableActions from "@/components/datatable/DatatableActions.vue";
import DatatableAction from "@/components/datatable/DatatableAction.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import FiscalPaymentStatus from "./components/FiscalPaymentStatus.vue";
import FiscalPayment from "./components/FiscalPayment.vue";
import Modal from "@/components/modal/Modal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import DownloadGNRE from "./components/DownloadGNRE.vue";
import queryParamsMixin from "@/mixins/query-params-mixin";
import moment from "moment";
import { FiscalPaymentType } from "../../../constants/constants";
import FiscalPaymentTypeBadge from "./components/FiscalPaymentTypeBadge.vue";
import SellerService from "@/services/SellerService";
import DownloadsService from "@/services/DownloadsService";

const downloadsService = new DownloadsService();

const sellerService = new SellerService();

const currencyFormat = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export default {
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    DatatableRoot,
    NbTabs,
    FiscalPaymentStatus,
    FiscalPayment,
    Modal,
    NbButton,
    DownloadGNRE,
    FiscalPaymentTypeBadge,
    DatatableActions,
    DatatableAction,
  },
  filters: {
    datetime(value) {
      return (value && moment(value).format("DD/MM/YYYY HH:mm:ss")) || "-";
    },
    formatDate(value) {
      return (value && moment(value).format("DD/MM/YYYY")) || "-";
    },
    currencyBRL(value) {
      return value && currencyFormat.format(Number(value));
    },
  },
  data() {
    return {
      currentTab: "all",
      totalFiscalPayments: 0,
      showFiscalPayment: false,
      fields: [],
      filterByDirNumber: "",
      sellers: [],
      selectedFiscalPaymentIds: [],
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalFiscalPayments,
        },
      ];
    },
    tableColumns() {
      return [
        {
          key: "reference_number",
          label: this.$t("fiscalPaymentsPage.fields.reference_number"),
        },
        { key: "barcode", label: this.$t("fiscalPaymentsPage.fields.barcode") },
        {
          key: "authentication",
          label: this.$t("fiscalPaymentsPage.fields.authentication"),
        },
        {
          key: "boleto_gnre",
          label: this.$t("fiscalPaymentsPage.fields.boletoGNRE"),
        },
        { key: "status", label: this.$t("fiscalPaymentsPage.fields.status") },
        {
          key: "payment_type",
          label: this.$t("fiscalPaymentsPage.fields.payment_type"),
        },
        { key: "value", label: this.$t("fiscalPaymentsPage.fields.value") },
        {
          key: "fiscal_parcel_id",
          label: this.$t("fiscalPaymentsPage.fields.fiscal_parcel_id"),
        },
        {
          key: "courrier_name",
          label: this.$t("fiscalPaymentsPage.fields.courrierName"),
        },
        {
          key: "courrier_cnpj",
          label: this.$t("fiscalPaymentsPage.fields.courrierCNPJ"),
        },
        {
          key: "payment_date",
          label: this.$t("fiscalPaymentsPage.fields.payment_date"),
        },
        {
          key: "created_at",
          label: this.$t("fiscalPaymentsPage.fields.created_at"),
        },
        {
          key: "dir_number",
          label: this.$t("fiscalPaymentsPage.fields.dir_number"),
        },
        {
          key: "fiscal_parcel.master_number",
          label: this.$t("fiscalPaymentsPage.fields.masterNumber"),
        },
        {
          key: "customer_name",
          label: this.$t("fiscalPaymentsPage.fields.customer_name"),
        },
        {
          key: "customer_cpf",
          label: this.$t("fiscalPaymentsPage.fields.customer_cpf"),
        },
        {
          key: "customer_cep",
          label: this.$t("fiscalPaymentsPage.fields.customer_cep"),
        },
        {
          key: "tracking_number",
          label: this.$t("fiscalPaymentsPage.fields.tracking_number"),
        },
        {
          key: "products_description",
          label: this.$t("fiscalPaymentsPage.fields.products_description"),
        },
        {
          key: "messages",
          label: this.$t("fiscalPaymentsPage.fields.messages"),
        },
        {
          key: "customer_state",
          label: this.$t("fiscalPaymentsPage.fields.customer_state"),
        },
        {
          key: "dir_date",
          label: this.$t("fiscalPaymentsPage.fields.dir_date"),
        },
      ];
    },
    paymentTypeOptions() {
      return [
        FiscalPaymentType.ICMS,
        FiscalPaymentType.DARF,
        FiscalPaymentType.FINE689,
        FiscalPaymentType.FINE702,
        FiscalPaymentType.FINE703,
        FiscalPaymentType.FINE711,
        FiscalPaymentType.FINE715,
        FiscalPaymentType.FINE725,
        FiscalPaymentType.FINE728,
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("fiscalPaymentsPage.fields.reference_number"),
          inputs: [{ key: "reference_number" }],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.barcode"),
          inputs: [{ key: "barcode" }],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.authentication"),
          inputs: [{ key: "authentication" }],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.status"),
          inputs: [
            {
              key: "status",
              type: "select",
              options: [
                {
                  value: "processing",
                  text: this.$t("fiscalPaymentsPage.status.processing"),
                },
                {
                  value: "processed",
                  text: this.$t("fiscalPaymentsPage.status.processed"),
                },
                {
                  value: "error",
                  text: this.$t("fiscalPaymentsPage.status.error"),
                },
              ],
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.fiscal_parcel_id"),
          inputs: [{ key: "fiscal_parcel_id", type: "number" }],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.payment_type"),
          inputs: [
            {
              key: "payment_type",
              options: this.paymentTypeOptions.map((value) => ({
                text: value,
                value,
              })),
              type: "select",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.paymentDate"),
          inputs: [
            {
              key: "start_payment_date",
              type: "date",
            },
            {
              key: "end_payment_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.courrierName"),
          inputs: [{ key: "courrier_name" }],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.courrierCNPJ"),
          inputs: [{ key: "courrier_cnpj" }],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_created_at",
              type: "date",
            },
            {
              key: "end_created_at",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.value"),
          inputs: [
            {
              key: "start_value",
              type: "money",
            },
            {
              key: "end_value",
              type: "money",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.dir_number"),
          inputs: [
            {
              key: "fiscal_parcels.dir_number",
              initialVal: this.filterByDirNumber,
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.masterNumber"),
          inputs: [
            {
              key: "fiscal_parcels.master_number",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.dir_date"),
          inputs: [
            {
              key: "fiscal_parcels.start_dir_date",
              type: "date",
            },
            {
              key: "fiscal_parcels.end_dir_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.customer_name"),
          inputs: [{ key: "fiscal_parcels.customer_name" }],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.customer_cpf"),
          inputs: [
            {
              key: "fiscal_parcels.customer_cpf",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.customer_cep"),
          inputs: [
            {
              key: "fiscal_parcels.customer_cep",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.tracking_number"),
          inputs: [
            {
              key: "fiscal_parcels.tracking_number",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.products_description"),
          inputs: [
            {
              key: "fiscal_parcels.products_description",
            },
          ],
        },
        {
          label: this.$t("fiscalPaymentsPage.fields.customer_state"),
          inputs: [
            {
              key: "fiscal_parcels.customer_state",
            },
          ],
        },
        {
          label: this.$t("seller"),
          inputs: [
            {
              key: "fiscal_parcels.seller_id",
              type: "select",
              options: this.sellers,
            },
          ],
        },
      ];
    },
  },
  methods: {
    async processDownload(dataType, docType) {
      try {
        await downloadsService.loadProcessing({
          data_type: dataType,
          reference_ids: this.selectedFiscalPaymentIds,
          doc_type: docType,
        });
        this.$router.push("/archives");
      } catch {
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      }
    },
    downloadBoletosGNRE() {
      this.processDownload("boletos_zip", "zip");
    },
    download() {
      this.processDownload("fiscal_payments", "csv");
    },
    async loadAllSellers() {
      const { data } = await sellerService.getSellers();
      this.sellers =
        data?.data?.elements?.map((item) => ({
          text: item.name?.concat(` (${item.id})`),
          value: item.id,
        })) || [];
    },
    showFiscalPaymentDetails(id) {
      this.setUrlSearchParam("id", id);
      this.showFiscalPayment = true;
    },
  },
  created() {
    this.loadAllSellers();
    const { id, dir_number } = this.$route.query;
    if (!isNaN(Number(id))) {
      this.showFiscalPaymentDetails(id);
    }

    if (dir_number) {
      this.filterByDirNumber = dir_number;
      this.setUrlSearchParam("dir_number");
    }
  },
};
</script>

<style lang="scss" scoped></style>
