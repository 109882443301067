<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <h5 class="heading-4">
          {{ $t("generalEmailsPage.title") }}
        </h5>
        <p class="body-4">
          {{ $t("generalEmailsPage.subTitle") }}
          <strong v-if="platform?.notification_email">
            {{ platform.notification_email }}
          </strong>
          <strong v-else-if="!loadingPlatform">
            {{ $t("generalEmailsPage.youDontHaveAnyAddressSetUpYet") }}
          </strong>
          <a
            href="javascript:void;"
            @click="isChangeEmailAddressModalOpen = true"
            class="text-primary d-block"
          >
            {{ $t("generalEmailsPage.clickHereToChangeThisAddress") }}
          </a>
        </p>
      </div>
      <div class="btn-groups d-flex flex-column">
        <NbButton class="text-left" @click="initializeGeneralEmail()">
          <NbIcon class="mr-2" icon="plus" />
          {{ $t("generalEmailsPage.createGeneralEmails") }}
        </NbButton>
      </div>
    </div>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <DatatableRoot
        ref="generalEmailTable"
        :namespace="namespace"
        clickable
        :columns="tableColumns"
        :filters="tableFilters"
        @count="totalGeneralEmail = $event"
        @rowClick="
          loadModalData($event.id, 'generalEmailId', 'ModalGeneralEmailsView')
        "
      >
        <template #title="{ row }">
          <strong>{{ row.title }}</strong>
        </template>
        <template #destination="{ row }">
          {{ displayMaxCharacters(row.destination, 70) }}
        </template>
        <template #body="{ row }">
          {{ displayMaxCharacters(row.body, 70) }}
        </template>
        <template #sent="{ row }">
          <IsCheckedIcon :checked="row.sent" />
        </template>
        <template #created_at="{ row }">
          {{ row.created_at | dateTime }}
        </template>
      </DatatableRoot>
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <!-- modals -->
    <Modal
      v-model="isModalGeneralEmailsViewOpen"
      @close="removeQueryParam"
      size="lg"
    >
      <ModalGeneralEmailsView
        @reloadGeneralEmail="reloadGeneralEmail"
        :itemId="currentItemId"
      />
    </Modal>

    <Modal v-model="isModalGeneralEmailsCreateOpen">
      <ModalGeneralEmailsCreate
        :generalEmail.sync="newGeneralEmail"
        @reloadGeneralEmail="reloadGeneralEmail"
      />
    </Modal>

    <Modal v-model="isChangeEmailAddressModalOpen">
      <PlatformEmailAddressModal
        :email.sync="platform.notification_email"
        :platform-id="platformId"
      />
    </Modal>

    <!-- end modal -->
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalGeneralEmailsView from "@/views/logs/general_emails/components/ModalGeneralEmailsView.vue";
import ModalGeneralEmailsCreate from "@/views/logs/general_emails/components/ModalGeneralEmailsCreate.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import moment from "moment";
import Modal from "@/components/modal/Modal.vue";
import PlatformEmailAddressModal from "./components/PlatformEmailAddressModal.vue";
import { mapState } from "vuex";
import PlatformService from "@/services/PlatformService";
const platformService = new PlatformService();

export default {
  name: "GeneralEmails",

  components: {
    NbIcon,
    NbButton,
    NbTabs,
    DatatableRoot,
    NbFooter,
    ModalGeneralEmailsView,
    ModalGeneralEmailsCreate,
    IsCheckedIcon,
    Modal,
    PlatformEmailAddressModal,
  },
  filters: {
    dateTime(value) {
      return value && moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      newGeneralEmail: {
        title: "",
        destinations: "",
        body: "",
      },
      namespace: "general_emails",
      totalGeneralEmail: -1,
      currentTable: "all",
      currentItemId: "",
      isChangeEmailAddressModalOpen: false,
      loadingPlatform: false,
      platform: { notification_email: "" },
      isModalGeneralEmailsViewOpen: false,
      isModalGeneralEmailsCreateOpen: false,
    };
  },
  computed: {
    ...mapState({
      platformId: (state) => state.user?.platform_id,
    }),
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("generalEmailsPage.title"),
          found: this.totalGeneralEmail,
          current: this.currentTable,
        },
      ];
    },
    tableColumns() {
      return [
        {
          key: "title",
          label: this.$t("generalEmailsPage.fields.titleGeneral"),
        },
        {
          key: "destination",
          label: this.$t("generalEmailsPage.fields.Destination"),
        },
        {
          key: "body",
          label: this.$t("generalEmailsPage.fields.Body"),
        },
        {
          key: "sent",
          label: this.$t("generalEmailsPage.fields.Sent"),
        },
        {
          key: "created_at",
          label: this.$t("registrationDate"),
          sortable: true,
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("generalEmailsPage.fields.titleGeneral"),
          inputs: [{ key: "title" }],
        },
        {
          label: this.$t("generalEmailsPage.fields.Destination"),
          inputs: [{ key: "destination" }],
        },
        {
          label: this.$t("generalEmailsPage.fields.Body"),
          inputs: [{ key: "body" }],
        },
        {
          label: this.$t("generalEmailsPage.fields.Sent"),
          inputs: [
            {
              key: "sent",
              type: "select",
              options: [
                { value: null, text: "Select" },
                { value: true, text: "True" },
                { value: false, text: "False" },
              ],
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            { key: "start_registration_date", type: "date" },
            { key: "end_registration_date", type: "date" },
          ],
        },
      ];
    },
  },
  created() {
    this.getPlatform();
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    async getPlatform() {
      try {
        this.loadingPlatform = true;
        const { data } = await platformService.getPlatform(this.platformId);

        this.platform = {
          ...data.data,
          notification_email: data.data.notification_email || "",
        };
      } finally {
        this.loadingPlatform = false;
      }
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    displayMaxCharacters(str, limit) {
      let finalStr = str;
      if (typeof finalStr !== "string") {
        finalStr = JSON.stringify(str);
      }
      if (finalStr.length > limit) {
        return finalStr.substring(0, limit - 5) + "(...)";
      }
      return finalStr;
    },
    reloadGeneralEmail() {
      this.$refs?.generalEmailTable?.getData();
    },
    checkQuery() {
      if (this.$route.query.generalEmailId) {
        //this.$emit("switchTab", "nobordistCheckpoints");
        this.loadModalData(this.$route.query.generalEmailId, "generalEmailId");
        return;
      }
    },
    removeQueryParam() {
      this.$urlSearchParams.delete("generalEmailId");
    },
    loadModalData(id, queryKey) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query[queryKey]) {
          this.$urlSearchParams.set(queryKey, id);
        }
        this.isModalGeneralEmailsViewOpen = true;
        return;
      }
    },
    initializeGeneralEmail() {
      this.newGeneralEmail = {
        sent: "",
        title: "",
        destination: "",
        body: "",
      };
      this.isModalGeneralEmailsCreateOpen = true;
    },
  },
};
</script>
