<template>
  <div>
    <div>
      <ModalHeader>
        <ModalTitle>
          {{ `${$t("statusCheckpointsPageView.title")} ${itemId}` }}
        </ModalTitle>
      </ModalHeader>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="checkpoint-status_code"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-100 my-2"
                :name="$t('statusCheckpointsPage.fields.statusCode')"
                v-model="checkpoint.status_code"
                disabled
              />
              <NbTextInput
                id="checkpoint-tracking_function"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('statusCheckpointsPage.fields.trackingFunction')"
                v-model="checkpoint.tracking_function"
                disabled
              />
              <NbTextInput
                id="checkpoint-checkpoint_id"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('statusCheckpointsPage.fields.checkpoint')"
                v-model="checkpoint.checkpoint_id"
                disabled
              />
            </div>
          </template>
        </NbCard>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import StatusCheckpointService from "@/services/StatusCheckpointService";

const statusCheckpointService = new StatusCheckpointService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTextInput,
    NbCard,
    NbFooter,
  },
  name: "ModalStatusCheckpointsView",
  props: {
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      checkpoint: {},
    };
  },
  created() {
    statusCheckpointService
      .getStatusCheckpoint(this.itemId)
      .then((response) => {
        this.checkpoint = response.data.data;
      });
  },
};
</script>

<style scoped></style>
