<template>
  <div>
    <NbButton
      :disabled="isLoading"
      @click="generate"
      variant="secondary"
      class="w-100"
    >
      {{ $t("usersPage.denerateAPIToken") }}
    </NbButton>

    <Modal v-model="isModalTokenOpen">
      <ModalHeader>
        <ModalTitle>
          {{ $t("usersPage.tokenCopiedSuccesfully") }}
        </ModalTitle>
      </ModalHeader>
      <div>
        <p>
          {{ $t("usersPage.yourTokenWasCopiedToClipboard") }}
        </p>
      </div>

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import UsersService from "@/services/UsersService";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import { copyClipboard } from "@/directives/copy-clipboard";

const usersService = new UsersService();

export default {
  components: {
    NbButton,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  directives: {
    copy: copyClipboard,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isModalTokenOpen: false,
      token: "",
    };
  },
  methods: {
    async generate() {
      try {
        this.isLoading = true;
        const { data } = await usersService.getAPIToken(this.userId);
        this.token = data.data;

        await navigator.clipboard.writeText(data.data);

        this.isModalTokenOpen = true;
      } catch (error) {
        this.$bvToast.toast(error.response.data.messages[0], {
          title: "Error",
          solid: true,
          variant: "danger",
          autoHideDelay: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
