<template>
  <div>
    <div :id="id" modalConfig="modal-dialog-centered">
      <ModalHeader>
        <ModalTitle>
          {{ $t("generalEmailsPage.createGeneralEmails") }}
        </ModalTitle>
      </ModalHeader>
      <div class="modal-body mb-5">
        <NbTextInput
          required
          id="title-generalemail"
          class="w-100 my-2"
          :name="$t('generalEmailsPage.chooseATitle')"
          :placeholder="$t('generalEmailsPage.titleGeneral')"
          :error="errors.title"
          v-model="newGeneralEmail.title"
        />
        <NbRadio
          id="generalemail-destinationtype"
          class="w-100 pt-3"
          :name="$t('generalEmailsPage.chooseTheRecipient')"
          required
          :options="[
            {
              text: $t('generalEmailsPage.typeEmailAddress'),
              value: 'destination',
            },
            {
              text: $t('generalEmailsPage.chooseAmongMySellers'),
              value: 'destinationsSellers',
            },
          ]"
          @change="clearSelected"
          v-model="newGeneralEmail.destinationType"
        />
        <div v-if="newGeneralEmail.destinationType">
          <div class="d-flex justify-content-between flex-wrap">
            <div
              v-if="newGeneralEmail.destinationType == 'destination'"
              class="d-flex justify-content-between flex-wrap w-100"
            >
              <NbTextInput
                required
                id="destination-generalemail"
                class="w-100 my-2"
                type="textarea"
                :placeholder="$t('generalEmailsPage.destination')"
                :error="errors.destination"
                v-model="newGeneralEmail.destination"
              />
            </div>
            <div v-else class="d-flex justify-content-between flex-wrap w-100">
              <NbSelecMultipletInput
                required
                id="general-multiple-destinations"
                class="w-100 my-2"
                placeholder="Select Sellers"
                :options="allSellerEmails"
                :error="errors.destination"
                v-model="newGeneralEmail.destinations"
              />
            </div>
          </div>
          <NbTextInput
            required
            id="body-generalemail"
            class="w-100 my-2"
            :name="$t('generalEmailsPage.body')"
            type="textarea"
            :placeholder="$t('generalEmailsPage.body')"
            :error="errors.body"
            v-model="newGeneralEmail.body"
            :rows="20"
          />
        </div>
      </div>
      <ModalFooter class="d-flex justify-content-between">
        <ModalClose />
        <NbButton @click="checkGeneralEmail()">
          {{ newGeneralEmail.id ? $t("update") : $t("save") }}
        </NbButton>
      </ModalFooter>
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbSelecMultipletInput from "@/components/input/select/NbSelecMultipletInput.vue";
import GeneralEmailService from "@/services/GeneralEmailService";

const generalEmailService = new GeneralEmailService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbTextInput,
    NbRadio,
    NbSelecMultipletInput,
  },
  name: "ModalGeneralEmailCreate",
  props: {
    id: {
      type: String,
      default: "ModalGeneralEmailCreate",
    },
    generalEmail: {
      type: Object,
      required: true,
    },
  },
  inject: ["context"],
  data() {
    return {
      errors: {},
    };
  },
  created() {
    this.$store.dispatch("sellers/fetchAllItems", "sellers");
  },
  computed: {
    newGeneralEmail: {
      get() {
        return this.generalEmail;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    allSellers() {
      return this.$store.state.sellers.all_items;
    },
    allSellerEmails() {
      const sellerEmails = this.allSellers.map((item) => ({
        value: item.email,
        text: `(${item.id}) ${item.name}: ${item.email}`,
      }));
      return sellerEmails;
    },
  },
  methods: {
    clearSelected() {
      if (this.newGeneralEmail.destinationType !== "destinationsSellers") {
        this.newGeneralEmail.destinations = [];
      }
    },
    checkGeneralEmail() {
      if (this.newGeneralEmail.id) {
        this.updateGeneralEmail();
        return;
      }
      this.createGeneralEmail();
    },
    buildDataToSend() {
      let finalDataToSend = {};
      finalDataToSend.title = this.newGeneralEmail.title;
      finalDataToSend.destination = this.newGeneralEmail.destination;
      finalDataToSend.body = this.newGeneralEmail.body;

      if (this.newGeneralEmail.destinationType == "destinationsSellers") {
        finalDataToSend.destination = this.newGeneralEmail.destinations.join();
      }

      return finalDataToSend;
    },
    createGeneralEmail() {
      const dataToSend = this.buildDataToSend();
      generalEmailService
        .postEmail(dataToSend)
        .then(() => {
          this.$emit("reloadGeneralEmail");
          this.context?.close();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    updateGeneralEmail() {
      const dataToSend = this.buildDataToSend();
      generalEmailService
        .updateGeneralEmail(this.newGeneralEmail.id, dataToSend)
        .then(() => {
          this.$emit("uploadGeneralEmail");
          this.context?.close();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
  },
};
</script>

<style scoped></style>
