<template>
  <div class="language">
    <button v-b-toggle.locale>
      <div class="flag">
        <img
          :id="`${currentLocale.iso}-id`"
          :src="getFlag(currentLocale.iso)"
          :alt="currentLocale.iso"
          @error="tryAlternativeFlag(currentLocale.iso)"
        />
        <span class="label">{{ getFlagLabel(currentLocale.iso) }}</span>
      </div>
      <NbIcon
        icon="chevron-right"
        :attributes="{
          width: '1.3rem',
          height: '1.3rem',
        }"
      />
    </button>

    <b-collapse id="locale" class="mt-0 colapse-card z-100">
      <b-card class="p-0">
        <div
          v-for="flag in flags"
          :key="flag.lang"
          v-b-toggle.locale
          @click="switchLocale(flag)"
          class="locale"
        >
          <img
            class="flag mr-1"
            :id="`${flag.iso}-id`"
            :src="getFlag(flag.iso)"
            :alt="flag.iso"
            @error="tryAlternativeFlag(flag.iso)"
          />
          <span class="label">{{ getFlagLabel(flag.iso) }}</span>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import UserService from "@/services/UserService";
import UsersService from "@/services/UsersService";
import { mapState, mapActions } from "vuex";
import { LOCALES } from "../../constants/constants";

const userService = new UserService();
const usersService = new UsersService();

export default {
  name: "Locale",
  components: { NbIcon },
  computed: {
    ...mapState({
      currentLocale: (state) => state.accessibility.currentLocale,
    }),
    flags() {
      return LOCALES.filter((locale) => locale.lang !== this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions({
      setLocale: "accessibility/setLocale",
    }),
    getFlag(iso) {
      return `/flags/${iso.toLowerCase()}.png`;
    },
    getFlagLabel(iso) {
      const items = {
        GB: this.$t("locales.en"),
        CN: this.$t("locales.cn"),
        BR: this.$t("locales.ptBR"),
        ES: this.$t("locales.es"),
      };
      return items[iso] || "";
    },
    tryAlternativeFlag(iso) {
      document.getElementById(`${iso}-id`).src =
        `http://purecatamphetamine.github.io/country-flag-icons/3x2/${iso}.svg`;
    },
    switchLocale(locale) {
      const user = userService.getLoggedInUser();
      const data = { language: locale.number };
      this.$store.dispatch("accessibility/setLocale", locale);

      if (user && user.language) {
        this.$root.$emit("isSaving", true);
        usersService
          .updateUserLanguage(data)
          .then(() => {
            let user = userService.getLoggedInUser();
            user.language = locale.text;
            userService.setLoggedInUser(JSON.stringify(user));
          })
          .finally(() => {
            this.$root.$emit("isSaving", false);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0px;
  border-radius: 4px !important;
  background-color: var(--gray-05);
}

.colapse-card {
  position: absolute;
  top: 4rem;
  width: 100%;
}

.language {
  position: relative;
  button {
    width: 100%;
    border: none;
    background-color: var(--gray-10);
    padding: 0.75rem 1rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 4rem;
    border-bottom: 1px solid var(--gray-40);
    .flag {
      flex: 1 1 0%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      img {
        width: 4.3rem;
        height: 2.5rem;
        border-right: 1px solid var(--gray-20);
        padding-right: 16px;
        box-sizing: content-box;
      }
    }

    .i,
    i {
      transition: all 0.2s ease-in;
    }

    &.not-collapsed > .i,
    &.not-collapsed > i {
      transform: rotate(90deg);
    }
  }

  .label {
    display: inline-block;
    text-align: left;
  }

  .locale {
    padding: 12px;
    display: flex;
    align-items: center;
    & + .locale {
      border-top: 1px solid var(--gray-10);
    }
    &:hover {
      background-color: var(--gray-10);
    }
    .flag {
      width: 20px;
      cursor: pointer;
      transition: all 0.4s ease;
    }
    .flag.active {
      width: 30px;
    }
    .flag:hover {
      width: 30px;
    }
  }
}
</style>
